// Widget title
// -------------------------
$widget-title-font-size        : 15px !default;

// Widget Video
// -------------------------
$widget-video-name-line-height : 1.6667 !default;

// Widget post
// -------------------------
$widget-post-meta-font-size    : 11px !default;
$widget-post-margin            : ($theme-margin / 4) !default;

// Widget twitter
// -------------------------
$widget-twitter-margin-bottom  : 40px !default;

//Widget nav layered
//--------------------------
$layered-nav-font-size			: 14px !default;

// Widget tags cloud
// -------------------------
$widget-tag-color              : $light-gray !default;
$widget-tag-color-hover        : $white !default;
$widget-tag-bg                 : #f5f5f5 !default;
$widget-tag-bg-hover           : var(--theme-color,$theme-color) !default;
$widget-tag-font-size          : calc(var(--font-size-body,14px) - 2px) !important;
$widget-tag-padding            : 5px 25px !default;
$widget-tag-margin             : 0px 6px 10px 0 !default;
$widget-tag-border             : none !default;
$widget-tag-border-color-hover : $widget-tag-bg-hover !default;
$widget-tag-border-radius      : none !default;
$widget-tag-transform          : capitalize !default;

// Widget vertical menu
// -------------------------
$vertical-menu-padding:                       0px !default;
$vertical-menu-bg-hover:                      $nocolor !default;
$vertical-menu-line-height:                   25px !default;
$vertical-menu-font-size:                     12px !default;
$vertical-menu-icon-font-size:                14px !default;
$vertical-menu-link-padding:                  13px 0 !default;
$vertical-menu-bg:                            #fff !default;
$vertical-menu-link-color:                    #666666 !default;
$vertical-menu-link-font-weight:              300 !default;
$vertical-menu-link-transform:				  uppercase !default;
$vertical-menu-link-hover-color:              var(--theme-color,$theme-color) !default;
$vertical-menu-border:             			  none !default;
$vertical-menu-link-border:             	  1px solid $border-color !default; 
// Widget deals product
// -------------------------
$deals-times-padding:					  5px 0 !default;
$deals-times-bg:						  $red !default; 
$deals-times-color:						  #fff !default;   
$deals-times-font-family:				  var(--font-family-base,$font-family-base) !default;  
$deals-times-font-size:					  10px !default;
