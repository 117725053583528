// Base
//---------------------------------
.bwp-slider .slick-carousel > .item{
	position:absolute;
	top:0;
	left:0;
}
.bwp-slider .slick-carousel > .item:first-child{
	position: relative;
    z-index: 10;
}
.bwp-slider .slick-carousel:not(.slick-initialized){
	display:block;
}
.wpcf7-spinner{
	position:absolute;
	margin:0;
	right: 0;
}
body:not(.elementor-editor-active) .slick-carousel:not(.slick-initialized){
	display:none;
}
body:not(.elementor-editor-active) .bwp-quick-view .slick-carousel:not(.slick-initialized){
	display:block;
	.img-thumbnail:not(:first-child){
		display: none;
	}
	.video-additional{
		display: none;
	}
}
.home, .page-template-homepage, .page-template-homepage-fixed, .page-template-homepage-v2{
    .page-title{
        display: none;
    }
    #bwp-footer{
        margin-top: 0;
		padding-top: 0;
    }
}
.page-template-homepage{
	#bwp-footer{
		.container{
			@media(min-width:1440px){
				max-width:1440px;  
			}
		}
	}
}
@include selection($white,var(--theme-color,$theme-color));
iframe{
	border: none;
}
del{
    @include font-size(font-size, $del-font-size);
    color: $del-color;
	font-weight: 400;
}
img{
    border: 0;
    vertical-align: top;
    max-width: 100%;
    height: auto;
}
.video-responsive {
    height: 0;
    padding-top: 0;
    padding-bottom: 56.25%;
    margin-bottom : 10px;
    position: relative;
    overflow: hidden;
	embed,iframe,object,video{
		top: 0;
		left: 0;
		position: absolute;
        @include square(percentage(1));
	}
}
blockquote{
	margin:50px 0;
	@include rtl-padding(0 ,100px ,0 ,60px);
    color: var(--gray-dark,$gray-dark);
	@include rtl-border-left(2px solid var(--theme-color,$theme-color));
	position:relative;
	font-size: calc(var(--font-size-heading,25px) - 3px);
	line-height: calc(var(--font-size-heading,25px) + 5px);;
	text-align: center;
	&:before{
		content:"";
	}
	p{
		margin:0;
	}
    strong, b{
        font-weight: 400;
    }
	h2{
		font-size:calc(var(--font-size-body,14px) - 2px);
		font-weight:700;
		text-transform:uppercase;
		margin:15px 0 0;
	}
	i{
		display:block;
		font-size:calc(var(--font-size-heading,25px) - 2px);
		color:#dadada;
		margin-bottom:20px;
	}
	@media (max-width:$screen-xs){
		font-size:var(--font-size-body,$font-size-body);
		@include rtl-padding(0 ,50px ,0 ,30px);
	}
}
.our-goal{
	font-size: 34px;
	color: var(--gray-dark,$gray-dark);
	line-height: 40px;
	margin-bottom: 45px;
	text-align: center;
	@media(max-width: $screen-xs-max){
		font-size: 30px;
	}
}
.audio-responsive{
	iframe{
		@include size(percentage(1), 126px);
	}
}
textarea:focus, input:focus{
    outline: none;
}
//Load Image
.fade-in{
	@include transform(scale(1.1));
	opacity: 0;
	@include transition(transform .8s cubic-bezier(.215,.61,.355,1),opacity .8s cubic-bezier(.215,.61,.355,1));
	transition-delay: .5s;
}
.fade-in.lazyloaded {
	opacity: 1;
	@include transform(scale(1));
}

// Google Map
//---------------------------------
.map_canvas{
	img{
		max-width: none;
	}
}
.spinner{
	background-size: 20px 20px;
	display: block;
	margin:0 auto;
    background: url('#{$image-theme-path}spinner.gif') no-repeat;
    @include opacity(.7);
    @include square(20px);
}

// BOXED
//---------------------------------

// Tabs
//---------------------------------

// Table
//---------------------------------
table,.table{
	.product-remove{
		position: relative;
        a.remove{
            font-size: 0;
            background: transparent !important;
            position: absolute;
            top: calc(50% - 10px);
            @include rtl-left(0);
            width: 100%;
			text-align:center;
			text-indent: 1px;
            &:before{
                display: inline-block;
                content: "\e905";
                font-family:wpbingofont;
                font-size:calc(var(--font-size-body,14px) + 2px);
				line-height: 16px;
                color: var(--gray-dark,$gray-dark) !important;
				@include transition(all .3s ease);
            }
            &:hover{
                &:before{
                    @include transform(rotate(90deg));
                }                           
            }
        }
    }
    .product-thumbnail{
        a{
            img{
                width: 80px;
            }
        }
    }
	> thead > tr {
		> th,
		> td {
			border-bottom-width: 0px;
		}
	}
	&.shop_table{
        @include size(100%,auto);
        margin-top: 0;
        margin-bottom: $theme-margin + 1;
		border:0;
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th{
					padding        : $table-cell-padding;
                    line-height    : $line-height-base;
                    vertical-align : top;
                    border: 1px solid $gray-lighter;
					color:var(--gray-dark,$gray-dark);
				}
                > td {
                    padding        : $table-cell-padding;
                    line-height    : $line-height-base;
                    vertical-align : top;
					border: 1px solid $gray-lighter;
					
                }
            }
        }
        thead{
			border:1px solid #e8e8e8;
            th{
                font-size:calc(var(--font-size-body,14px) + 3px);
				border:0 !important;
            }
        }
        &.cart{
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        vertical-align: middle;
						&.product-subtotal span{
							color: var(--theme-color,$theme-color);
							font-weight: 500;
		                }
                        &.product-quantity{
                            >.quantity{
								min-width: 130px;
								border:1px solid #e8e8e8;
                                display:inline-block;
								input[type=number]::-webkit-inner-spin-button,
								input[type=number]::-webkit-outer-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
								input{
									border: 0;
									text-align:center;
									width:40px;
									@include rtl-float-right();
								}
								button{
									border: 0;
									width:40px;
									&.plus{
										@include rtl-float-right();
									}
								}
								@media (max-width:$screen-xs){
									min-width:70px;
									input{
										width:25px;
									}
									button{
										width:20px;
									}
								}
                            }
                        }
                        &.product-thumbnail{
							display:flex;
							align-items:center;
                        	a{
                        		min-width: 80px;
                        		display: inline-block;
                        	}
                        }
						&.product-price{
							color: var(--gray-dark,$gray-dark);
							bdi{
								color: var(--theme-color,$theme-color);
								font-weight: 500;
							}
						}
						.product-name{
							display:inline-block;
							vertical-align:middle;
							@include rtl-margin-left(30px);
							position: relative;
							a{
								font-size:var(--font-size-body,$font-size-body);
								text-transform: uppercase;
								color: var(--gray-dark,$gray-dark);
								font-weight: 400;
							}
							.price{
								display: none;
								color: var(--gray-dark,$gray-dark);
								margin-bottom: 10px;
							}
							.remove{
								font-size: 0;
								background: transparent !important;
								width: 100%;
								text-align:center;
								text-indent: 1px;
								display: none;
								&:before{
									display: inline-block;
									content: "\ea02";
									font-family: "feather";
									position: absolute;
									@include rtl-left(0);
									font-size: 10px;
									color: var(--gray-dark,$gray-dark) !important;
									@include square(16px);
									border:1px solid var(--gray-dark,$gray-dark);
									@include border-radius(50%);
									line-height:15px;
								}
							}
						}
						&.product-quantity{
							.subtotal{
								display: none;
								color: var(--gray-dark,$gray-dark);
							}
						}
						@media(max-width: $screen-sm){
							&.product-price{
								display: none;
							}
							.product-name{
								.price{
									display: block;
								}
							}
						}
						@media(max-width: $screen-xs-max){
							&.product-remove{
								display: none;
							}
							&.product-subtotal{
								display: none;
							}
							&.product-quantity{
								.subtotal{
									display: block;
								}
							}
							.product-name{
								.remove{
									display: block;
								}
							}
						}
                    }
                }
                >tr:not(.cart_item) >td{
                    @include rtl-text-align-left();
                }
            }
			>thead{
				th{
					padding:15px;
					border:0;
					border-bottom: 1px solid #e8e8e8;
					&.product-remove{
						width:80px;
					}
				}
			}
			>tbody{
				border: 1px solid #e8e8e8;
				tr{
					border-bottom: 1px solid #e8e8e8;
				}
				td{
					padding:15px;
					border:0;
				}
			}
			@media (max-width:$screen-sm-max){
				>thead{
					display:none;
				}
				>tbody{
					>tr{
						td{
							border:0;
							&.product-quantity{
								flex:1;
							}
							&.actions{
								flex:0 0 100%;
							}
							&.product-remove{
								a.remove{
									top: calc(50% - 10px);
								}
							}
						}
						&:last-child{
							border-bottom:0;
						}
					}
				}
			}
			@media (max-width:$screen-xs){
				>tbody{
					>tr{
						@include rtl-padding-left(100px);
						position:relative;
						td{
							border:0;
							flex:0 0 100%;
							&.product-thumbnail{
								flex:0 0 100%;
							}
							&.product-thumbnail{
								.product-name{
									@include rtl-margin-left(15px);
								}
							}
							&.actions{
								flex:0 0 100%;
							}
							&.product-remove{
								position:absolute;
								border:0;
								left: 35px;
								bottom: 10px;
								a.remove{
									position:unset;
								}
							}
						}
						&:last-child{
							padding:10px;
						}
					}
				}
			}
        }
        .button{
            @include border-radius($btn-border-radius);
        }
    }
	&.cart{
		.product-remove{
			vertical-align: middle;
		}
		.actions{
			padding: 15px;
			vertical-align: middle;
		}
	}
	&.table--style{
		.price{
            display: table-cell;
            vertical-align: middle;
            font-size: var(--font-size-body,$font-size-body);
        }
        .label{
            padding: 10px;
        }
	}
}
.widget_calendar{
	#wp-calendar{
		margin-bottom:0;
	}
	.wp-calendar-nav{
		border: 1px solid var(--border-color,$border-color);
		border-top:0;
		display:flex;
		a{
			color:var(--theme-color,$theme-color);
		}
		span{
			display:inline-block;
			padding:5px 10px;
		}
		.pad{
			width:20%;
		}
		.wp-calendar-nav-prev{
			width:40%;
			border-right:1px solid var(--border-color,$border-color);
		}
		.wp-calendar-nav-next{
			width:40%;
			border-left:1px solid var(--border-color,$border-color);
			text-align:right;
		}
	}
}
#wp-calendar{
	width: 100%;
	margin-bottom: $line-height-lg;
	border: 1px solid $table-border-color;
	td,th{
	padding: 5px;
	text-align: center;
	}
	caption{
	margin-top:7px;
	margin-bottom: 10px;
	caption-side: top;
	}
	> thead > tr {
	> th,
	> td {
		border-bottom-width: 0px !important;
	}
	}
	#today{
		color: var(--theme-color,$theme-color);
		font-weight: 500;
	}
}

ul {
 	&.treemenu{
	    li{
	        padding: $block-sidebar-list-padding-vertical;
	        background: $block-sidebar-list-image;
	        text-transform: capitalize;
	        border-bottom: 1px solid $block-sidebar-list-border-color;
	        &:first-child{
	            padding-top: 0;
	            background-position: 0 10px;
	        }
	        &:last-child{
	            border-bottom: 0;
	            padding-bottom: 0;
	        }
	        > ul{
	            @include rtl-padding-left($theme-padding);
	            li{
	                &:first-child{
	                    padding-top: $block-sidebar-list-padding-vertical;
	                }
	            }
	        }
	    }
	}
    &.hasicon{
    	li{
	        float: none;
	        margin: 0 0 $padding-sm-horizontal 0;
	        .fa{
                margin-top: 5px;
	            @include rtl-margin-right($padding-sm-vertical);
	        }
            br{display: none;}
	    }
    }
    &.list-group-highlighted{
    	margin-bottom: 0;
    	li{
    		margin: 0;
    		padding: 0;
    		border: 0;
    		background: $block-sidebar-hightlight-content-bg;
    		@include rtl-text-align-left;
    		a{
    			color: $block-sidebar-hightlight-content-color;
    			@include font-size(font-size, $block-sidebar-hightlight-font-size);
                font-weight: $block-sidebar-hightlight-font-weight;
    			text-transform: $block-sidebar-hightlight-transform;
    			padding: 0px 5px;
    			display: block;
    		}
    		.count{
    			color: $block-sidebar-hightlight-content-color;
    			@include font-size(font-size, $block-sidebar-hightlight-font-size);
    			font-weight: $block-sidebar-hightlight-font-weight;
    			text-transform: $block-sidebar-hightlight-transform;
				position: relative;
				z-index: 100;
    			margin: 0;
    			top: 18px;
    			@include rtl-right(5px); 
                position: absolute;
    			background: transparent;
    		}
    		ul{
    			li{
    				padding-left: 0;
    				padding-right: 0;
    			}
    		}
    		&:last-child{
    			ul{
    				li{
    					&:last-child{
	    					a{
	    						border: 0;
	    					}
	    				}
    				}
    			}
    		}
    	}
    }
    &.contact-us{
        text-align: center;
        li{
            color: $white;
            .fa{
                color: var(--theme-color,$theme-color);
                @include font-size(font-size,20px);
            }
        }
    }
    &.list-contact{
        li{
            text-transform: none;
            @include font-size(font-size,11px);
        }
    }
}
.align-right {
	@include rtl-text-align-right();
}
.align-left {
	@include rtl-text-align-left();
}
.icons {
	font-size:calc(var(--font-size-body,14px) + 4px);
}
.fancybox-overlay.fancybox-overlay-fixed{
	z-index: 9999;
}
.fancybox-opened{
	z-index: 99999;
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.rtl{
	.mafoiltooltip{
		@include translateX(50%);
	}
}
.mafoiltooltip {
	color: $white;
	display: block;
	float: left;
	font-size: 11px;
	@include rtl-left(25px);
	line-height: 22px;
	padding: 4px 8px;
	position: absolute;
	top: -40px;
	white-space: nowrap;
	width: auto;
	z-index: 2;
	@include translateX(-50%);
	background: var(--theme-color,$theme-color);
	margin: 0 !important;
	text-transform: capitalize;
	.fa{
		font-size: 0;
	}
}
.mafoiltooltip:after {
	background: none;
	border: 7px solid transparent;
	border-top-color: var(--theme-color,$theme-color);
	top: 97%;
	content: "";
	display: block;
	height: 0;
	left: calc(50% - 7px);
	@include translateX(-50%);
	position: absolute;
	transform: none;
	width: 0;
}
body i.slick-arrow{
	text-align:center;
	line-height: 25px;
	font-size: 30px; 
    top:35%;
    position: absolute;
    z-index: 1;
    color:var(--gray-dark,$gray-dark);
	@include transition(all 0.3s ease);
	font-family: icomoon;
	@include opacity-h();
	&.fa-angle-left{
        left: -25px;
		&:before{
			content: "\e900";
		}
    }
	&.fa-angle-right{
		right: -25px;
		&:before{
			content: "\e901";
		}
	}
    &:hover{
        cursor: pointer;
		color: var(--theme-color,$theme-color);
    }
	@media(max-width: $screen-md-max){
		width: 30px;
		top: 35%;
		opacity: 1 !important;
		visibility: visible !important;
		&.fa-angle-right{
			right: 15px;
		}
		&.fa-angle-left{
			left: 15px;
		}
	}
	@media(max-width: $screen-xs-max){
		top: 25%;
	}
}
.slick-slider{
    overflow: unset;
	.slick-list{
        margin: 0 -15px; 
        .slick-slide{
            padding: 0 15px;
        }
		.slick-track, .slick-slide, .carousel-body{
			&:focus{
				outline: none;
			}
		}
		@media(max-width: $screen-xs){
			margin: 0 -7.5px;
			.slick-slide{
				padding: 0 7.5px;
			}
		}
	}
}
ul.slick-dots{
    bottom: 30px;  
	list-style-type: none;
	position:unset;
	li{
		display: inline-block;
        vertical-align: bottom;
		margin: 0 3px;
        @include square(18px);
        @include transition(all 0.3s ease);
		@include border-radius(50%);
		&:focus{
			outline: none;
		}
		button{
			font-size: 0;
			height: 18px;
			width: 18px;
			@include border-radius(50%);
			position: relative;
			background: transparent;
			box-sizing: content-box;
			padding: 0;
			@include transition(all .3s ease);
			&:after{
				content: "";
				position: absolute;
				@include square(6px);
				background: var(--gray-dark,$gray-dark);
				@include border-radius(50%);
				top: 6px;
				@include rtl-left(6px);
			}
			&:before{
				display: none;
			}
			&:focus{
				outline: none;
			}
			&:hover{
				@include box-shadow(inset 0px 0px 0px 1px var(--gray-dark,$gray-dark) !important);
			}
		}
		&.slick-active{
			button{
				@include box-shadow(inset 0px 0px 0px 1px var(--gray-dark,$gray-dark) !important);
			}
		}
	}
}
.open >.dropdown-menu{
	display: block; 
	opacity: 1;
	visibility: visible;
}
a{
	color: $link-color;
	@include transition(all .3s ease);
	&:hover,
	&:focus {
		color: $link-hover-color;
		text-decoration: unset;
		outline: unset;
	}
}
ol, ul, dl{
	margin:0;
}
.form-control:focus{
	@include box-shadow(unset);
}
.btn:not(:disabled):not(.disabled):active,
.clear-all:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.clear-all:not(:disabled):not(.disabled).active{
	@include box-shadow(unset);
}
code, kbd, pre, samp{
	font-family: monospace;
}
.btn:focus, .clear-all:focus, .btn.focus, .focus.clear-all{
	@include box-shadow(unset !important);
}
.dokan-form-control{
	height:35px;
	line-height:35px;
}
body.not-scroll{
	overflow:hidden;
}
body .fa{
	font-family:"FontAwesome" ;
	font-weight: inherit ;
}
body.logged-in{
	.woocommerce-tabs #tab-reviews #reviews #respond #commentform .content-info-reviews{
		order:2;
	}
}
body.search.search-results{
	.blog-content-standar{
		h3.entry-title{
			font-size:var(--font-size-heading,$font-size-heading);
		}
		.post-content{
			margin-top:20px;
		}
	}
	@media (min-width:$screen-xs-max){
		.list-post >article{
			display:inline-block;
			width:100%;
		}
		.blog-content-standar{
			.entry-thumb{
				max-width:20%;
				@include rtl-float-left();
				@include rtl-margin-right(30px);
			}
			.post-content{
				overflow:hidden;
				margin-top:0;
			}
		}
	}
}
.wc-stripe-elements-field, .wc-stripe-iban-element-field,#add_payment_method #payment ul.payment_methods .stripe-card-group, .woocommerce-checkout #payment ul.payment_methods .stripe-card-group{
	width:100%;
}
.wpcf7-form p{
	margin:0;
	display:contents;
}
.wpcf7-form br{
	display:none;
}