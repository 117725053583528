/*
*  Responsive
*/
.container{
	@media (min-width: 992px){
		max-width:100%;
	}
	@media (min-width: 768px){
		max-width:100%;
	}
	@media (min-width: 576px){
		max-width:100%;
	}
}
@media (max-width: $screen-xs-max) {
  @include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg-min) {
  @include responsive-invisibility('.hidden-lg');
}
@media (min-width: 768px){
    .navbar-toggle{
		display:none; 
	} 
}
.dropdown-toggle{  
	&:after{
		display:none;
	}
}
.hide{
	display:none;
}
@media (min-width: 1440px){
	.container{
		max-width:1440px;
	}
}
@media (min-width: $screen-md){
    .bwp-navigation{
        display: inline-block;
        vertical-align: top;
    }
}
@media (max-width: $screen-md){
    // menu
    #main-menu{
        margin: 0;
        @include rtl-float-left;
    } 
}

@media (min-width: $screen-sm){
    .contents-detail .bwp-single-product .image-additional{
        .image-zoom{
            position: relative;
            @include square(100%);
            display: inline-block;
			border:1px solid rgba($light-gray,0.25);
			overflow:hidden;
            &:before{
                position: absolute;
                content: "\54";
                font-family:ElegantIcons;
                bottom: 15px;
                @include rtl-right(15px);
                z-index: 9;
                font-size:calc(var(--font-size-body,14px) + 4px);
                @include square(40px);
                line-height: 38px;
                text-align: center;
                @include border-radius(50%);
                color: var(--gray-dark,$gray-dark);
                text-indent: -2px;
                border: 1px solid $light-gray;
                @include transition(all 0.2s ease);
            }
            &:hover{
                &:before{
                    background: var(--theme-color,$theme-color);
                    color: $white;
                    border-color: transparent;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max){
    .bwp-footer{
        .wpbingo-newsletter input[type="submit"]{
            padding: 0 25px;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .active-mobile{
        width: 70px;
        > .fa, > .icon{
           font-size: 25px!important;
        }
    }
    .woocommerce-tabs{
        .nav-tabs{
            margin-bottom: 20px;
            li{
                @include rtl-float-left();
                margin-right: 10px;
            }
        }
    }
    .blog-type{
        .entry-image{
            height: auto;
        }
    }
    .navbar-toggle{
        display: block;
    }
    .quickview-button, .product-quickview,.compare-button{
        display: none !important;
    }
    .wpbingo-menu-mobile{
		position:unset !important;
		.navbar-default{
			.navbar-header{
				float: none;
			}
			.navbar-toggle{
				font-size:var(--font-size-body,$font-size-body);
				font-weight: 500;
				padding: 0;
				text-transform: uppercase;
				color:var(--theme-color,$theme-color);
				border:0;
                border-top: 1px solid var(--theme-color,$theme-color);
                border-bottom: 1px solid var(--theme-color,$theme-color);
				position:unset;
				background:transparent;
			}
		}
	}
	.wpbingo-verticalmenu-mobile{
		position:unset;
		.navbar-header{
			float: none;
			.navbar-toggle{
				font-size:var(--font-size-body,$font-size-body);
				font-weight: 500;
				padding: 0;
				text-transform: uppercase;
				color:var(--theme-color,$theme-color);
				border:0;
                border-top: 1px solid var(--theme-color,$theme-color);
                border-bottom: 1px solid var(--theme-color,$theme-color);
				position:unset;
				background:transparent;
			}
		}
	}
    .main-archive-product,.main-single-product{
        display : flex;
        flex-direction: column;
        >.col-lg-12.col-md-12{
            display : flex;
            flex-direction: column; 
        }
    }
    .category-posts,.single-post-content{
        display : flex;
        flex-direction: column; 
        .sidebar-blog{      
            order: 2;
			margin-top:45px;
        }   
    }
    .header-categories{
        .title-navigation-categories{
            font-size:calc(var(--font-size-body,14px) + 2px);
            font-weight: 700;
            color: $white;
        }
        .menu-categories{
            position: fixed;
            top: 0px;
            left: 0;
            bottom: 0px;
            background-color: #fff;
            width: 280px;
            padding: 100px 50px 0 50px !important;
            z-index: 9999;
            overflow-x: hidden;
            overflow-y: auto;
            visibility: hidden;
            border: 1px solid #f3f3f3;
            border-width: 1px 0 0 1px;
            @include transition(all 0.5s ease-in-out 0s);        
            @include transform(translate3d(-280px, 0, 0));
            &.active{
                visibility: visible;
                @include transform(translate3d(0, 0, 0));
            }
            .mafoil-close{
                text-align: center;
                margin-bottom: 40px;
                &:hover{
                    cursor: pointer;
                }
                &:before{
                    content: "\ea02";
                    font-family: "feather";
                    color: red;
                    font-size: 30px;
                }
            }
        }
    }
    .rtl .header-categories.menu-categories{
        @include transform(translate3d(-280px, 0, 0));
        &.active{
            visibility: visible;
            @include transform(translate3d(0, 0, 0));
        }
    }
    .bwp-woo-categories.layout2{
        border-bottom: 1px solid $white;
        .content-category{
            display: flex;
            overflow: auto;
            padding: 20px;
            >div{
                white-space: nowrap;
                padding: 0 10px;
                .item-title{
                    padding: 0;
                }
                &:first-child{
                    @include rtl-padding-left(0);
                }
                &:last-child{
                    @include rtl-padding-right(0);
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .bwp-lookbook{
        display: flex;
        flex-direction: column;
        .close-lookbook{
            &.active{
				background: var(--gray-dark-50,rgba($gray-dark, 0.5));
            }
        }
        .bwp-content-lookbook{
            img{
                width: 100%;
            }
        }
        .content-lookbook{
            background-color: $white;
            top: auto !important;
            bottom: 0;
            left: 50% !important;
            @include transform(translate(-50%, 100%) !important);
            max-height: 80vh;
            position: fixed;
            width: 100%;
            padding: 0;
            pointer-events: none;
            z-index: 1002;
            overflow: hidden;
			box-shadow:0 0 20px var(--gray-dark-25,rgba($gray-dark, 0.25));
            @include transition(all .8s cubic-bezier(0.2,1,.3,1));
            .content-product{
                padding: 20px;
            }
            .item-thumb{
                width: 225px;
                margin: auto;
            }
            &.active{
                @include opacity-s();
                pointer-events: auto;
                @include transform(translate(-50%, 0) !important);
                top: auto;
            }
        }
        .item{
            .info-lookbook{
                text-align: center;
                margin-top: 15px;
            }
        }
        .item-lookbook{
            &:before, &:after{
                @include square(28px);
                top: calc(50% - 14.5px);
                left: calc(50% - 14.5px);
            }
            .number-lookbook{    
				position:relative;
				top:0;
				left:0;
				@include square(28px);
				line-height: 28px;
            }
            .content-lookbook{
                position: initial;
                display: flex;
                width: calc(100% - 45px);
                @include rtl-margin-left(15px);
                flex-direction: column;
            }
        }
        .mobile-lookbook{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e5e5e5;
            .title{
                font-size: 12px;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: var(--gray-dark,$gray-dark);
            }
            .close-lookbook-mobile{
                font-size: 0;
				cursor:pointer;
				$size: 35px;
				height: $size;
				width: $size;
				position: relative;
				top: -2px;
				@include rtl-right(0);
				box-sizing: border-box;
				line-height: $size;
				display: inline-block;
				background: $white;
				box-shadow: 0px 7px 44px 0px var(--gray-dark-10,rgba($gray-dark, 0.1));
				@include border-radius(50%);
				z-index: 10;
				&:before, &:after {
					$width: 15px;
					$height: 1px;
					@include transform(rotate(-45deg));
					content: '';
					position: absolute;
					top: 50%;
					left: calc(50% - 7.5px);
					display: block;
					height: $height;
					width: $width;
					background-color: var(--gray-dark,$gray-dark);
					@include transition(all .3s ease-out);
				}
				&:after {
					@include transform(rotate(-135deg));
				}
				&:hover {
					&:before, &:after {
						@include transform(rotate(0deg));
					}
				}
            }
        }
    }
    .active-mobile{
        &.setting-popup{
            .active-content{
                width: 100%;
                min-width: 320px;
                right: -100%;
                left: auto;
            }
        }
    } 
    .zoomContainer{
        display: none;
    }
	.woocommerce-MyAccount-navigation,.woocommerce-MyAccount-content{
		width : 100%;
	}
	.post-single .post-content .entry-meta-content{ 
		display : block;
		.entry-meta,.social-share{
			width : 100%;
			text-align : center;
		}
	}	
	#yith-wcwl-form, .woocommerce-cart-form{
        overflow-x: auto;
        margin-bottom: 30px;
        overflow-y: hidden;
    }
    .border-policy{
        padding: 20px 0;
    }
    .vc_col-sm-1\/5{
        display: inline-block;
        vertical-align: top;
    }
}

@media (max-width: $screen-xs-max) {
    .active-mobile{
        &.setting-popup{
            .active-content{
                width: 100%;
                min-width: 320px;
                right: -100%;
                left: auto;
            }
        }
    } 
    .zoomContainer{
        display: none;
    }
	.woocommerce-MyAccount-navigation,.woocommerce-MyAccount-content{
		width : 100%;
	}
	.post-single .post-content .entry-meta-content{ 
		display : block;
		.entry-meta,.social-share{
			width : 100%;
			text-align : center;
		}
	}	
	.woocommerce-cart-form{
        overflow-x: auto;
        margin-bottom: 30px;
        overflow-y: hidden;
    }
    .border-policy{
        padding: 20px 0;
    }
    .vc_col-sm-1\/5{
        display: inline-block;
        vertical-align: top;
    }
}

@media (max-width: $screen-phone) {
    .single-product{
        .woocommerce-tabs{
            ul.tabs{
                text-align: center;
                li{
                    &.active{
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}
@media (max-width:1600px) and (min-width:$screen-md-max){
	.wpb-col-xl-40{
		width:40% !important;
	}
	.wpb-col-xl-60{
		width:60% !important;
	}
	.m-t-xl-50{
		> .elementor-element-populated{
			margin-top:50px !important;
		}
	}
}
@media (max-width:1440px) and (min-width:$screen-md-max){
	.p-l-0{
		padding-left:0 !important;
	}
	.p-r-0{
		padding-left:0 !important;
	}
}
@media (max-width:1024px) and (min-width:992px) {
	.order-wpb-md-1{
		order:1;
	}
	.order-wpb-md-2{
		order:2;
	}
	.order-wpb-md-3{
		order:3;
	}
}
@media (max-width:991px) and (min-width:768px) {
	.wpb-col-sm-100{
		width:100% !important;
	}
	.wpb-col-sm-50{
		width:50% !important;
	}
	.wpb-col-sm-40{
		width:40% !important;
	}
	.wpb-col-sm-60{
		width:60% !important;
	}
	.wpb-col-sm-25{
		width:25% !important;
	}
	.m-t-100{
		margin-top:100px;
	}
	.m-t-30{
		&.elementor-inner-section{
			margin-top:30px !important;
		}
		.elementor-element-populated{
			margin-top:30px !important;
		}
	}
	.m-t-0{
		.elementor-element-populated{
			margin-top:0 !important;
		}
		&.elementor-inner-section{
			margin-top:0 !important;
		}
	}
	.m-t-50{
		margin-top:50px !important;
	}
    .m-b-50{
		margin-bottom:50px !important;
	}
    .m-t-20{
		margin-top:20px !important;
	}
	.p-t-30{
		>.elementor-element-populated{
			padding-top:30px !important;
		}
		&.elementor-inner-section{
			padding-top:30px !important;
		}
	}
	.p-t-50{
		padding-top:50px !important;
	}
	.m-b-30{
		margin-bottom:30px;
	}
	.m-b-15{
		margin-bottom:15px !important;
	}
    .m-t-15{
		margin-top:15px !important;
	}
	.order-wpb-sm-1{
		order:1 !important;
	}
	.order-wpb-sm-2{
		order:2 !important;
	}
	.order-wpb-sm-3{
		order:3 !important;
	}
	.order-wpb-sm-4{
		order:4 !important;
	}
	.wpb-p-0{
		padding:0 !important;
	}
    .text-center-sm{
        *>div{
            text-align: center !important;
        }
    }
    .hidden-border-sm{
        >div{
            border-width: 0 !important;
        }
    }
}
@media (max-width:767px) {
	.order-wpb-xs-1{
		order:1 !important;
	}
	.order-wpb-xs-2{
		order:2 !important;
	}
	.order-wpb-xs-3{
		order:3 !important;
	}
	.order-wpb-xs-4{
		order:4 !important;
	}
}
@media (max-width: 1510px) and (min-width: 1200px){
	.container,.related, .cross_sell, .upsells,.recent-view {
		padding-left: 30px;
		padding-right: 30px;
	}
	.woocommerce-tabs .content-woocommerce-tabs{
		.content-ul-tab ul.tabs,.content-tab {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	.contents-detail .bwp-single-product{
		padding-left: 30px;
		padding-right: 30px;
	}
	.woocommerce-notices-wrapper{
		padding-left: 15px;
		padding-right: 15px;
	}
}
//Slider Products Mobile
@media(max-width: $screen-xs){
    body{
        .bwp_slick-margin-mobile:not(.sidebar){
            .slick-arrow{
                display: none !important;
            }
            .slick-slider{
                overflow: hidden;
                @include rtl-margin-right(-15px !important);
                .slick-list{
                    @include rtl-padding-right(33% !important);
                }
            }
        }
    }
}