//
// Mixins
// --------------------------------------------------

// Utilities
// -------------------------

/* RIGHT TO LEFT */

// BASIC CONVERTER (ignore these)

@mixin rtl-base-simple ($property, $direction) {
  #{$property}:$direction;
  .rtl & {
    @if $direction == $rtl-right {
      #{$property}:$rtl-left;
    }
    @else {
      #{$property}:$rtl-right;
    }
  }
}
@mixin rtl-base-inherit ($property, $direction, $value, $inherit : inherit) {
  #{$property}-#{$direction}: $value;
  .rtl & {
    @if $direction == $rtl-right {
      #{$property}-#{$rtl-left}: $value;
    }
    @else {
      #{$property}-#{$rtl-right}: $value;
    }
    #{$property}-#{$direction}: 0;
  }
}

@mixin rtl-base-toprightbottomleft ($property, $t, $r, $b, $l) {
  #{$property}: $t $r $b $l;
  .rtl & {
    #{$property}: $t $l $b $r;
  }
}

// BODY STYLES

@mixin rtl-direction ($forBody : true) {
  direction: ltr;
  @if $forBody {
    &.rtl {
      direction: rtl;
    }
  }
  @else {
    .rtl & {
      direction: rtl;
    }
  }
}

@mixin rtl-font-family ($ltr, $rtl, $forBody : false) {
  font-family: $ltr;
  @if $forBody {
    &.rtl, &.non-latin {
      font-family:$rtl;
    }
  }
  @else {
    .rtl &, .non-latin & {
      font-family:$rtl;
    }
  }
}


// MARGIN

@mixin rtl-margin ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(margin,$t, $r, $b, $l);
}
@mixin rtl-margin-left ($value) {
  @include rtl-base-inherit(margin,$rtl-left,$value);
}
@mixin rtl-margin-right ($value) {
  @include rtl-base-inherit(margin,$rtl-right,$value);
}

// PADDING

@mixin rtl-padding ($t, $r, $b, $l) {
  @include rtl-base-toprightbottomleft(padding,$t, $r, $b, $l);
}
@mixin rtl-padding-left ($value) {
  @include rtl-base-inherit(padding,$rtl-left,$value);
}
@mixin rtl-padding-right ($value) {
  @include rtl-base-inherit(padding,$rtl-right,$value);
}

// BORDER

@mixin rtl-border-left ($value) {
  @include rtl-base-inherit(border,$rtl-left,$value);
}
@mixin rtl-border-right ($value) {
  @include rtl-base-inherit(border,$rtl-right,$value);
}

// POSITION

@mixin rtl-left ($value) {
  #{$rtl-left}: $value;
  .rtl & {
    #{$rtl-right}: $value;
    #{$rtl-left}: auto;
  }
}
@mixin rtl-right ($value) {
  #{$rtl-right}: $value;
  .rtl & {
    #{$rtl-left}: $value;
    #{$rtl-right}: auto;
  }
}

// TEXT-ALIGN

@mixin rtl-text-align-left () {
  @include rtl-base-simple(text-align, $rtl-left);
}
@mixin rtl-text-align-right () {
  @include rtl-base-simple(text-align, $rtl-right);
}

// FLOAT

@mixin rtl-float-left () {
  @include rtl-base-simple(float, $rtl-left);
}
@mixin rtl-float-right () {
  @include rtl-base-simple(float, $rtl-right);
}
@mixin rtl-clear-left () {
  @include rtl-base-simple(clear, $rtl-left);
}
@mixin rtl-clear-right () {
  @include rtl-base-simple(clear, $rtl-right);
}


// BACKGROUND-POSITION

@mixin rtl-background-position-left ($vertical) {
  background-position:$rtl-left $vertical;
  .rtl & {
    background-position:$rtl-right $vertical;
  }
}
@mixin rtl-background-position-right ($vertical) {
  background-position:$rtl-right $vertical;
  .rtl & {
    background-position:$rtl-left $vertical;
  }
}

@mixin rtl-background-position-percent ($vertical, $horPercent) {
  background-position:$horPercent $vertical;
  .rtl & {
    background-position:100% - $horPercent $vertical;
  }
}

// TEXT-SHADOW & BOX-SHADOW

@mixin rtl-text-shadow ($x, $rest) {
  text-shadow: $x $rest;
  .rtl & {
    text-shadow: -1 * $x $rest;
  }
}
@mixin rtl-box-shadow ($x, $rest) {
  -moz-box-shadow: $x $rest;
  -webkit-box-shadow: $x $rest;
  box-shadow: $x $rest;
  .rtl & {
    -moz-box-shadow: -1 * $x $rest;
    -webkit-box-shadow: -1 * $x $rest;
    box-shadow: -1 * $x $rest;
  }
}

// BORDER-RADIUS

@mixin rtl-border-radius-topright ($value) {
  -moz-border-radius-top#{$rtl-right}: $value;
  -webkit-border-top-#{$rtl-right}-radius: $value;
  border-top-#{$rtl-right}-radius: $value;
  .rtl & {
    -moz-border-radius-top#{$rtl-left}: $value;
    -webkit-border-top-#{$rtl-left}-radius: $value;
    border-top-#{$rtl-left}-radius: $value;
    -moz-border-radius-top#{$rtl-right}: inherit;
    -webkit-border-top-#{$rtl-right}-radius: inherit;
    border-top-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomright ($value) {
  -moz-border-radius-bottom#{$rtl-right}: $value;
  -webkit-border-bottom-#{$rtl-right}-radius: $value;
  border-bottom-#{$rtl-right}-radius: $value;
  .rtl & {
    -moz-border-radius-bottom#{$rtl-left}: $value;
    -webkit-border-bottom-#{$rtl-left}-radius: $value;
    border-bottom-#{$rtl-left}-radius: $value;
    -moz-border-radius-bottom#{$rtl-right}: inherit;
    -webkit-border-bottom-#{$rtl-right}-radius: inherit;
    border-bottom-#{$rtl-right}-radius: inherit;
  }
}

@mixin rtl-border-radius-topleft ($value) {
  -moz-border-radius-top#{$rtl-left}: $value;
  -webkit-border-top-#{$rtl-left}-radius: $value;
  border-top-#{$rtl-left}-radius: $value;
  .rtl & {
    -moz-border-radius-top#{$rtl-right}: $value;
    -webkit-border-top-#{$rtl-right}-radius: $value;
    border-top-#{$rtl-right}-radius: $value;
    -moz-border-radius-top#{$rtl-left}: inherit;
    -webkit-border-top-#{$rtl-left}-radius: inherit;
    border-top-#{$rtl-left}-radius: inherit;
  }
}

@mixin rtl-border-radius-bottomleft ($value) {
  -moz-border-radius-bottom#{$rtl-left}: $value;
  -webkit-border-bottom-#{$rtl-left}-radius: $value;
  border-bottom-#{$rtl-left}-radius: $value;
  .rtl & {
    -moz-border-radius-bottom#{$rtl-right}: $value;
    -webkit-border-bottom-#{$rtl-right}-radius: $value;
    border-bottom-#{$rtl-right}-radius: $value;
    -moz-border-radius-bottom#{$rtl-left}: inherit;
    -webkit-border-bottom-#{$rtl-left}-radius: inherit;
    border-bottom-#{$rtl-left}-radius: inherit;
  }
}

//
// Mixins
// --------------------------------------------------


// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
  }
}

@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}
@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

@mixin float-grid-columns($class) {
  $list: '';
  $i: 1;
  $list: ".col-#{$class}-#{$i}";
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: left;
  }
}
@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  &:after {
    clear: both;
  }
}

// Webkit-style focus
@mixin tab-focus() {
  // Default
  outline: 0;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  &:-moz-placeholder            { color: $color; } // Firefox 4-18
  &::-moz-placeholder           { color: $color; } // Firefox 19+
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757
@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
@mixin text-hide() {
  @include hide-text();
}


// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
   border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
     border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
   border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
     border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
     -moz-transition: -moz-transform $transition;
       -o-transition: -o-transform $transition;
          transition: transform $transition;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
      -ms-transform: rotate($degrees); // IE9+
          transform: rotate($degrees);
}
@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
      -ms-transform: rotateY($degrees); // IE9+
          transform: rotateY($degrees);
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
      -ms-transform: scale($ratio); // IE9+
          transform: scale($ratio);
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9+
          transform: translate($x, $y);
}
@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
      -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
          transform: skew($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
@mixin backface-visibility($visibility){
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
     -moz-box-sizing: $boxmodel;
          box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
     -moz-user-select: $select;
      -ms-user-select: $select; // IE10+
       -o-user-select: $select;
          user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
     -moz-column-count: $column-count;
          column-count: $column-count;
  -webkit-column-gap: $column-gap;
     -moz-column-gap: $column-gap;
          column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
     -moz-hyphens: $mode;
      -ms-hyphens: $mode; // IE10+
       -o-hyphens: $mode;
          hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}



// GRADIENTS
// --------------------------------------------------



// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, $start-percent top, $end-percent top, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent)); // Safari 5.1+, Chrome 10+
  background-image: -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
  background-image:  linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $start-color, $start-percent, $end-color, $end-percent); // Safari 5.1+, Chrome 10+
  background-image:  -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1+, Chrome 10+
  background-image: -moz-linear-gradient($deg, $start-color, $end-color); // FF 3.6+
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($inner-color), to($outer-color));
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: -moz-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255,255,255,.15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255,255,255,.15)), color-stop(.75, rgba(255,255,255,.15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255,255,255,.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.15) 50%, rgba(255,255,255,.15) 75%, transparent 75%, transparent);
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}



// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url("#{$file-1x}");

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url("#{$file-2x}");
    background-size: $width-1x $height-1x;
  }
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (($line-height-lg / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

// Panels
// -------------------------
@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;
  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .panel-collapse .panel-body {
      border-top-color: $border;
    }
  }
  & > .panel-footer {
    + .panel-collapse .panel-body {
      border-bottom-color: $border;
    }
  }
}

// Alerts
// -------------------------
@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;
  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}

// Tables
// -------------------------
@mixin table-row-variant($state, $background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td,
    &:hover > .#{$state},
    &.#{$state}:hover > th {
      background-color: darken($background, 5%);
    }
  }
}
// Labels
// -------------------------
@mixin label-variant($color) {
  background-color: $color;
  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
@mixin navbar-vertical-align($element-height) {
  margin-top: (($navbar-height - $element-height) / 2);
  margin-bottom: (($navbar-height - $element-height) / 2);
}

// Progress bars
// -------------------------
@mixin progress-bar-variant($color) {
  background-color: $color;
  .progress-striped & {
    @include gradient-striped($color);
  }
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
// $parent hack because sass doesn't support tr& (without space)
@mixin responsive-visibility($parent) {
  #{$parent} { display: block ; }
  tr#{$parent} { display: table-row ; }
  th#{$parent},
  td#{$parent} { display: table-cell ; }
}

@mixin responsive-invisibility($parent) {
  #{$parent} { display: none !important; }
  tr#{$parent} { display: none !important; }
  th#{$parent},
  td#{$parent} { display: none !important; }
}

// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left:  ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @include clearfix();
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix();
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  // Calculate width based on number of columns available
  @media (min-width: $screen-sm) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the small column offsets
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  // Calculate width based on number of columns available
  @media (min-width: $screen-md) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large column offsets
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  // Calculate width based on number of columns available
  @media (min-width: $screen-lg) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large column offsets
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg) {
    right: percentage(($columns / $grid-columns));
  }
}


// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label {
    color: var(--text-color,$text-color);
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
      @include box-shadow($shadow);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: var(--text-color,$text-color);
    border-color: $border-color;
    background-color: $background-color;
  }
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  #{$parent} { height: $input-height;
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius; }
  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent} {
    height: auto;
  }
}


//custom prestafox

/******************************************************
 * @package wpbingo Theme Framework for WordPress
 * @version 1.0
 * @author http://www.wpbingosite.com
 * @copyright Copyright (C) Augus 2016 wpbingo.com <@emai:wpbingo@gmail.com>.All rights reserved.
 * @license   GNU General Public License version 1
*******************************************************/

@mixin border-exclude-top($border-deep, $border-type, $border-color ){
  border-bottom: $border-deep $border-type $border-color ;
    border-left: $border-deep $border-type $border-color ;
    border-right: $border-deep $border-type $border-color ;

}

@mixin border-exclude-bottom($border-deep, $border-type, $border-color ){
  border-top: $border-deep $border-type $border-color ;
    border-left: $border-deep $border-type $border-color ;
    border-right: $border-deep $border-type $border-color ;

}

@mixin clearboxstyle(){
  background: none;
  border:none;
}

@mixin linear-gradient-vertical($color1, $color2, $color3){
    background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.16, $color1),
    color-stop(0.53, $color2),
    color-stop(0.83, $color3)
    );
    background-image: -o-linear-gradient(bottom, $color1 16%, $color2 53%, $color3 83%);
    background-image: -moz-linear-gradient(bottom, $color1 16%, $color2 53%, $color3 83%);
    background-image: -webkit-linear-gradient(bottom, $color1 16%, $color2 53%, $color3 83%);
    background-image: -ms-linear-gradient(bottom, $color1 16%, $color2 53%, $color3 83%);
    background-image: linear-gradient(to bottom, $color1 16%, $color2 53%, $color3 83%);
}

// POSITION

@mixin rtl-left ($value) {
  #{$rtl-left}: $value;
  .rtl & {
    #{$rtl-right}: $value;
    #{$rtl-left}: auto;
  }
}
@mixin rtl-right ($value) {
  #{$rtl-right}: $value;
  .rtl & {
    #{$rtl-left}: $value;
    #{$rtl-right}: auto;
  }
}

//rotate
@mixin rtl-rotate ($value) {
  @include rotate($value);
  .rtl & {
    @include rotate(-$value);
  }
}
//rotate 180
@mixin rtl-rotate-180 ($value) {
  @include rotateY($value);
  .rtl & {
    @include rotateY($value - 180deg);
  }
}

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover {
    color: darken($color, 10%);
  }
}
// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color;
  }
  a#{$parent}:hover {
    background-color: darken($color, 10%);
  }
}
// Animations
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
@mixin animation($animation) {
  -webkit-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}
@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    // [converter] extracted a& to a.list-group-item-#{$state}
  }

  a.list-group-item-#{$state} {
    color: $color;

    .list-group-item-heading {
      color: inherit;
    }

    &:hover,
    &:focus {
      color: $color;
      background-color: darken($background, 5%);
    }
    &.active,
    &.active:hover,
    &.active:focus {
      color: #fff;
      background-color: $color;
      border-color: $color;
    }
  }
}

/* ***********************************************************************************************
    MIXIN
************************************************************************************************ */
@mixin btn-add{
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    text-transform: capitalize;
    padding: 0 25px;
    border: 1px solid $light-gray;     
    color: var(--gray-dark,$gray-dark);
    background: $white;
}
@mixin btn-default{
    width: 44px;
    height: 44px;
    padding: 0px;
    background-color: #878787;
    color: $white;
    @include border-radius(50%);
    text-align: center;
    line-height: 44px;
    -webkit-transition: all 0.35s ease;
    -o-transition: all 0.35s ease; 
    transition: all 0.35s ease;
    
}

@mixin btn-bt{
  width: 40px;
  height: 40px; 
  background-color: transparent; 
  &:hover{background-color:var(--theme-color,$theme-color);} 
}
@mixin opacity-h{
  opacity: 0;
  visibility: hidden;
}
@mixin opacity-s{
  opacity: 1;
  visibility: visible;
}
@mixin btn-post-default{
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #747474;
  color: #747474;
  margin: 0px;
  display: inline-block;
  padding: 10px 20px;
  font-family: var(--font-family-base,$font-family-base);
  @include transition(all .3s ease 0s);
}
// TEXT-ALIGN

@mixin rtl-text-align-center () {
  @include rtl-base-simple(text-align, $rtl-center);
}

@mixin make-column-5(){
	@media (min-width: $screen-lg-min) {
		.col-xl-2-4{
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	@media (min-width: $screen-md-min){
		.col-lg-2-4{
			flex: 0 0 20%;
			max-width: 20%;
		}
	}	
	@media (min-width: $screen-sm-min) {
		.col-md-2-4{
			flex: 0 0 20%;
			max-width: 20%;
		}
	}
	@media (min-width: $screen-xs-min) {
		.col-sm-2-4{
			flex: 0 0 20%;
			max-width: 20%;
		}
	}	
}