@media (max-width: 991px) {
	.bwp-navigation{
		display : none;
	}
	.rtl{
		.bwp-canvas-navigation .menu li .mm-next::before{
			content: "\f177";
		}
	}
	.mm-btn{
		height: 50px;
	}
	/* Main Menu */
	.remove-mobile-menu{
		position: fixed;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin:0;
		z-index: 9999;
		background: rgba(0,0,0,.4);
		display: none;
		-webkit-transition: opacity .4s cubic-bezier(0,.8,1,1);
		transition: opacity .4s cubic-bezier(0,.8,1,1);
		&.active{
			display: block;
		}
	}
	.content-mobile-menu, .bwp-canvas-vertical{
		&.active {
			visibility: visible;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			@include box-shadow(0px 1px 12px 2px rgba(144, 144, 144,0.3));
		}
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: fixed;
		top: 0px;
		left: 0;
		bottom: 0px;
		background-color: $white;
		width: 280px;
		z-index: 999999;
		overflow-x: hidden;
		overflow-y: auto;
		visibility: hidden;
		border-width: 1px 0 0 1px;
		@include transform(translate3d(-280px, 0, 0));
		@include transition(all 0.5s ease-in-out 0s);
		.bwp-canvas-navigation{
			position: relative;
			flex: 1;
			.remove-megamenu {
				font-size: 0;
				cursor:pointer;
				$size: 35px;
				height: $size;
				width: $size;
				position: absolute;
				top: 20px;
				@include rtl-right(20px);
				box-sizing: border-box;
				line-height: $size;
				display: inline-block;
				background: $white;
				box-shadow: 0px 7px 44px 0px var(--gray-dark-10,rgba($gray-dark, 0.1));
				@include border-radius(50%);
				z-index: 10;
				&:before, &:after {
					$width: 15px;
					$height: 1px;
					@include transform(rotate(-45deg));
					content: '';
					position: absolute;
					top: 50%;
					left: calc(50% - 7.5px);
					display: block;
					height: $height;
					width: $width;
					background-color: var(--gray-dark,$gray-dark);
					@include transition(all .3s ease-out);
				}
				&:after {
					@include transform(rotate(-135deg));
				}
				&:hover {
					&:before, &:after {
						@include transform(rotate(0deg));
					}
				}
			}
			.mm-menu{
				background: $white;
				padding-top: 80px;
				div{
					position: unset;
					padding-top: 0;
					@include transition(all 1s cubic-bezier(0.2,1,.3,1));
					background: $white;
					&.mm-hasnavbar:not(.sub-menu){
						position: absolute;
						padding-top: 80px;
						.sub-menu{
							width: calc(100% - 30px);
						}
					}
				}
				.mm-panels{
					>.sub-menu{
						@media(max-width: $screen-sm){
							.elementor-widget-wrap{
								padding: 0 !important;
							}
						}
					}
					>.mm-panel{
						&.mm-subopened{
							@include transform(translate3d(-100%, 0, 0));
						}
						&:before{
							display: none;
						}
						>.mm-listview{
							margin: 0;
							margin-top: 0px;
							.vertical-menu{
								background-image:none !important;
							}
							>li.level-0{
								>a, >span{
									padding: 0;
									@include rtl-text-align-left();
								}
								
							}
						}
					}
				}
				.sub-menu{
					position: absolute;
					padding-top: 80px;
					@media (max-width:$screen-sm-max){
						>div >div >div >section{
							background:transparent !important;
						}
						.wpb-col-sm-100.elementor-column{
							margin-bottom:0 !important;
							&:last-child{
								margin-bottom:0 !important;
							}
							.elementor-column-wrap{
								padding:0;
							}
						}
						ul{
							li{
								a{
									position:relative;
									color:var(--text-color,$text-color);
								}
							}
						}
					}
				}
				.title {
					margin-bottom: 15px;
					h2{
						font-size:calc(var(--font-size-body,14px) + 1px);
						padding-bottom:5px !important;
						position:relative;
						text-transform: uppercase;
					}
				}
				.mm-listview{
					margin: 0;
					padding: 0;
					>li:not(.mm-divider){
						&:after{
							display: none;
						}
					}
					@media (max-width:$screen-sm-max){
						ul{
							padding:0;
							margin-bottom:30px;
						}
						.title h2{
							display: inline-block;
							color: var(--gray-dark,$gray-dark);
							font-weight:700;
							margin-top: 0px;
							margin-bottom: 17px;
							text-transform: uppercase;
							font-size:calc(var(--font-size-body,14px) + 1px);
						}
					}
				}
				.mm-navbar{
					height: 45px;
					line-height: 30px;
					border: none;
					margin-bottom: 20px;
					border-bottom: 1px solid #f0f0f0;
					position: relative;
					a{
						color: var(--theme-color,$theme-color);
						text-transform: uppercase;
						font-weight: 400;
						font-size: calc(var(--font-size-body,14px) - 2px);
						&.mm-prev{
							&:before{
								left: 0;
								width: 7px;
								height: 7px;
							}
						}
					}
				}
				.menu-item-image{
					border-bottom: none;
					text-align: center;
					margin-top: 10px;
					>span.title, >a{
						display: none;
					}
				}
				.sub-menu li{
					border:0;
					padding: 0;
					> a:not(.mm-next), span.title{
						text-transform: capitalize;
						font-size:var(--font-size-body,$font-size-body);
						color:var(--text-color,$text-color);
						letter-spacing: 0;
					}
				}
				ul > li, ul > li.level-0 {
					display: inline-block;
					width: 100%;
					position: relative !important;
					padding-top: 8px;
					padding-bottom: 6px !important;
					border-bottom: 1px solid var(--border-color-50,rgba($border-color,0.5));
					&.current_page_item{
						> a > span.menu-item-text{
							font-weight: 500;	
							color: var(--theme-color,$theme-color);		
						}			
					}
					.mm-next{
						position: absolute;
						top: 0;
						@include rtl-right(0);
						@include size(30px, 43px);
						text-align: center;
						&:before{
							display: none;
						}
						&:after{
							@include rtl-right(17px);
						}
					}
					> a:not(.mm-next), span.title{
						color: var(--gray-dark,$gray-dark);
						padding: 0;
						line-height: calc(var(--font-size-body,14px) + 16px);
						font-size: calc(var(--font-size-body,14px) - 2px);
						text-transform: uppercase;
						.menu-img{
							@include rtl-margin-right(10px);
							width: 31px;
							display: inline-block;
							text-align: center;
						}
					}
					&:last-child{
						border-bottom:0;
					}
				}
				ul.link-vertical{
					li{
						a{
							color:var(--text-color,$text-color);
							&:hover{
								color:var(--theme-color,$theme-color);
							}
						}
					}
				}
				.elementor-top-column{
					width:100% !important;
					ul{
						padding:0;
					}
				}
				.elementor-row{
					flex-wrap:wrap;
				}
			}
		}
		.content{
			background: $white;
			z-index: 3;
		}
		.login-header{
			width: 100%;
			text-align: center;
			padding: 20px;
			a{
				display: inline-block;
				border: 1px solid #e7e7e7;
				line-height: 35px;
				height: 42px;
				padding: 0 20px;
				width: 100%;
				overflow: hidden;
				position: relative;
				&:before{
					position: relative;
					font-size:calc(var(--font-size-body,14px) + 6px);
					content: "\e909";
					font-family: icomoon;
					top: 2px;
					@include rtl-margin-right(10px);
				}
				&:after{
					content: "";
					position: absolute;
					z-index: -1;
					@include transition(all .3s ease);
					width: 0;
					height: 100%;
					top: 50%;
					left: 50%;
					background: var(--gray-dark,$gray-dark);
					@include opacity-h();
					@include transform(translate(-50%, -50%));
				}
				&:hover{
					color: $white;
					&:after{
						width: 100%;
						@include opacity-s();
					}
				}
			}
		}
		//Block Top Menu
		.block-top-menu{
			padding: 0 20px 40px;
			>*{
				display: inline-block;
				@include rtl-margin-right(15px);
			}
			.widget-custom-menu{
				.widget-title{
					font-size:calc(var(--font-size-body,14px) + 1px);
					position: relative;
					@include rtl-padding-right(20px);
					margin: 0;
					font-weight: 400;
					cursor: pointer;
					&.active{
						&:before{
							@include transform(rotate(180deg));
						}
					}
					&:before{
						position: absolute;
						top: 0;
						@include rtl-right(0);
						content: "\e92e";
						font-size:calc(var(--font-size-body,14px) + 1px);
						font-family: 'feather';
						@include transition(all .3s ease);
					}
					&:after{
						display: none;
					}
				}
				>div {
					display: none;
					margin: 0;
					padding: 15px 30px;
					font-size:calc(var(--font-size-body,14px) + 2px);
					text-transform: capitalize;
					position: absolute;
					z-index: 1001;
					background: $white;
					line-height: 28px;
					border: 1px solid rgba($light-gray,0.1);
					top: -15px;
					@include rtl-text-align-left();
					@include box-shadow( 0px 5px 15px 5px rgba(144, 144, 144, 0.15));
					@include transform(translateY(-100%));
					@media (min-width:$screen-md){
						right:0;
					}
					.widget{
						margin-bottom: 0;
						ul{
							padding: 0;     
							list-style: none;
						}
					}
					ul.menu{
						min-width: auto !important;
						padding: 0;
						li{
							border-bottom: 1px solid $table-bg-hover;
							a{
								color:var(--text-color,$text-color);
								padding:5px 0;
								display:inline-block;
								&:hover{
									color:var(--theme-color,$theme-color);
								}
							}
							&:last-child{
								border-bottom:0;
								a{
									padding-bottom:0;
								}
							}
							&:first-child{
								a{
									padding-top:0;
								}
							}
						}
					}
					ul{
						li{
							border-bottom:1px solid  $table-bg-hover;
							a{
								color:var(--text-color,$text-color);
								padding:5px 0;
								display:inline-block;
								font-size:var(--font-size-body,$font-size-body);
								&:hover{
									color:var(--theme-color,$theme-color);
								}
							}
							&:last-child{
								border-bottom:0;
								a{
									padding-bottom:0;
								}
							}
							&:first-child{
								a{
									padding-top:0;
								}
							}
						}
					}
				}
			}
		} 	
	}
	.bwp-canvas-vertical{
		right: 0;
		left:auto;
		@include transform(translate3d(280px, 0, 0));
		.remove-verticalmenu{
			text-align: left;
		}
		.menu-item-desc{
			display:none;
		}
		.more-wrap{
			display:none;
		}
		.mm-menu .mm-panels > .mm-panel > .mm-listview > li > a >span{
			>i{
				@include rtl-margin-right(8px);
				width: 20px;
				font-size:calc(var(--font-size-body,14px) + 2px);
				position:relative;
				top:2px;
			}
		}
		.remove-verticalmenu {
			display: block;
			padding: 0;
			background: var(--theme-color,$theme-color);
			padding:10px 20px 20px;
			@include rtl-text-align-left();
			color:$white;
			text-transform:uppercase;
			color:$white;
			font-size:calc(var(--font-size-body,14px) - 1px);
			&::after{
				content: "\4d";
				font-family: eleganticons;
				color: $white;
				font-size:var(--font-size-heading,$font-size-heading);
				line-height: 25px;
				@include rtl-margin-left(5px);
				@include transition(all 0.3s ease-in-out);
				display: inline-block;
				position: relative;
				top: 5px;
			}
			&:hover{
				&::after{
					color: var(--text-color,$text-color);
				}
			}
		}
	}
} 



.wpbingo-menu-wrapper .navbar-default{ border: none; background: transparent; }

/* ---------- Menu Sidebar ---------- */
.menu-sidebar{
	.header-main{
		position: fixed;
		top: 0;
		left: 0;
		background: var(--gray-dark,$gray-dark);
		height: 100%;
		padding: 50px;
		width: 310px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include transform(translateX(-105%));
		@include transition(all .3s ease);
		@include opacity-h();
		z-index: 99999;
		overflow-y: auto;
		&::-webkit-scrollbar-track{
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar{
			width: 5px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-thumb{
			background-color: #000000;
			border: 3px solid #555555;
		}
		&.active{
			@include transform(translateX(0));
			@include opacity-s();
		}
		.wpbingo-menu-sidebar{
			max-height: 500px;
		}
	}
	.close-sidebar{
		position: absolute;
		top: 70px;
		@include rtl-right(20px);
		display: block;
		z-index: 1000;
		color: $white;
		cursor: pointer;
		svg{
			width: 19px;
   	 		height: 17px;
			line{
				fill: none;
				stroke: currentColor;
				stroke-dasharray: 22;
				stroke-dashoffset: 0;
				-webkit-transition: .4s cubic-bezier(.59,.02,.17,.95);
				-o-transition: .4s cubic-bezier(.59,.02,.17,.95);
				transition: .4s cubic-bezier(.59,.02,.17,.95);
				&:nth-child(2){
					-webkit-transition-delay: .1s;
					-o-transition-delay: .1s;
					transition-delay: .1s;
				}
			}
		}
		&:hover{
			svg line{
				stroke-dashoffset: -44;
			}	
		}
	}
	.overlay-sidebar{
		position: fixed;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin: 0;
		z-index: 9999;
		background:var(--gray-dark-50,rgba($gray-dark, 0.5));
		display: none;
		-webkit-transition: opacity 0.4s cubic-bezier(0, 0.8, 1, 1);
		transition: opacity 0.4s cubic-bezier(0, 0.8, 1, 1);
		&.active{
			display: block;
		}
	}
	.open-menu{
		color: $white;
		position: relative;
		cursor: pointer;
		top: 2px;
		svg{
			width: 20px;
    		height: 15px;
			line{
				fill: none;
				stroke: currentColor;
				stroke-linecap: round;
				stroke-linejoin: round;
				stroke-miterlimit: 10;
				stroke-dasharray: 30;
				stroke-dashoffset: 0;
				-webkit-transition: stroke-dashoffset .4s cubic-bezier(.59,.02,.17,.95);
				-o-transition: stroke-dashoffset .4s cubic-bezier(.59,.02,.17,.95);
				transition: stroke-dashoffset .4s cubic-bezier(.59,.02,.17,.95);
				&:nth-child(2){
					-webkit-transition-delay: .2s;
					-o-transition-delay: .2s;
					transition-delay: .2s;
				}
				&:nth-child(3){
					-webkit-transition-delay: .1s;
					-o-transition-delay: .1s;
					transition-delay: .1s;
				}
			}
			&:hover{
				line{
					stroke-dashoffset: -60;
				}
			}
		}
	}
	.wpbingo-menu-sidebar{
		.bwp-navigation{
			ul li.level-0{
				>a{
					color: $white;
					>span{
						&:before{
							background: $white;
						}
					}
				}
				&.mega-menu{
					.title h2{
						color: $white;
					}
				}
				>.sub-menu{
					@include rtl-padding-left(0 !important);
					height: 350px;
					overflow-y: auto;
					&::-webkit-scrollbar-track{
						-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
						background-color: #F5F5F5;
					}
					&::-webkit-scrollbar{
						width: 5px;
						background-color: #F5F5F5;
					}
					&::-webkit-scrollbar-thumb{
						background-color: #000000;
						border: 3px solid #555555;
					}
					li a{
						color: #bababa;
						&:hover{
							color: $white;
						}
					}
					.elementor-row{
						flex-wrap: wrap;
						.elementor-column{
							width: 100% !important;
							.elementor-widget-wrap{
								padding: 0;
							}
							.img-menu{
								>div{
									margin-bottom: 20px;
								}
							}
						}
						.bwp-recent-post{
							&.blog-menu{
								overflow-x: hidden;
								@include rtl-padding-right(15px);
								.item{
									display: block;
								}
								.post-thumbnail{
									max-width: 100%;
									margin-bottom: 15px;
									img{
										max-width: 100%;
									}
								}
								.post-content{
									padding: 0 !important;
								}
								.entry-title a{
									color: $white;
								}
							}
						}
					}
				}
			}
		}
	}

}

.wpbingo-menu-mobile{
	&.wpbingo-menu-sidebar{
		.bwp-navigation{
            display: block;
            span.grower {
                display: block;
                opacity: 1;
                position: absolute;
                @include rtl-right(0);
                cursor: pointer;
                font-family:ElegantIcons;
                font-size:calc(var(--font-size-body,14px) + 4px);
                top: 10px;
                text-align: center;
                color: var(--text-color,$text-color);
                background: transparent;
                @include square(18px);
                line-height: 18px;
				@include transition(all 0.3s ease);
				&:before{
					content: "\35";
				}
                &.open {
                    @include transform(rotate(90deg));
                }
            }
            ul.menu{
                > li.level-0{
                    float: none;
                    font-size:calc(var(--font-size-body,14px) + 2px);
                    padding: 12px 0 !important;
                    position: relative !important;
                    border-bottom: 1px solid rgba($light-gray, 0.4);
                    &:last-child{
                        padding-bottom: 12px !important;
						border-bottom : 0;
                    }
                    >a{
                        margin: 0;
                    }
                    > ul.sub-menu li a{
                    	font-weight: 400;
                    }
                    &.mega-menu{
                        > ul.sub-menu > li{
                            margin-bottom: 0;
                        }
                        .menu-item-has-children{
                            span.title, >a{
                                margin: 0;
                                font-weight: 400;
                                font-size:var(--font-size-body,$font-size-body);
                                text-transform: capitalize;
                            }
                        }
                    }
                    >span.grower{
                        top: 14px;
                        color: var(--text-color,$text-color);
                    }
                    a{
                        &:before{
                            display: none;
                        }
                    }
                    &.menu-item-has-children >.sub-menu{
                        position: initial;
                        display: none;
                        width: auto;
                        opacity: 1;
                        visibility: inherit;
                        margin-top: 25px;
                        float:none;
                        min-width: auto !important;
                        box-shadow:none ;
                        border:none ;
                        border-radius: 0 ;
                        padding: 0;
                        background: transparent;
                        @include transform(none !important);
                        @include transition(none !important);
						.content-megamenu{
							padding: 0;
						}
                        li{
                            border: none;
                            &.level-1{
                                padding: 0;
                                &.menu-item-has-children{
                                    >a{
                                        padding: 0;
                                        font-size:var(--font-size-body,$font-size-body);
                                        font-weight: 400;
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }
                    }
                }
                li{
                    width: 100%;
                    position: relative;
                    padding: 3px 0 !important;
                    &:last-child{
                        padding-bottom: 0 !important;
                    }
                    &.menu-item-has-children{
                        > a > span, >a{
                            &:after{
                                display: none;
                            }
                        }
                    }
                    &.menu-hide-title:not(.menu-item-image){
                        > span.title, >a{
	                        @media (min-width: $screen-sm){
	                            display: block !important;
	                        }
	                    }
                    }
                }
            }
        }
	}
}

.menu-left{
	.wpbingo-menu-mostsearch{
		.#{$app-prefix}-navigation{
			ul{
				> li.level-0{
					padding: 0 18px;
					&:first-child{
						@include rtl-padding-left(0);
					}
					&:last-child{
						@include rtl-padding-right(0);
					}
					>a{
						text-transform: none;
						letter-spacing: 0;
						font-size:calc(var(--font-size-body,14px) + 2px);
					}
					&.mega-menu{
						&.mega-menu-fullwidth-width{
							position: static;
							>.sub-menu{
								width: 100%;
								left: 50% !important;
								@include transform(translate(-50%, 20px) !important);
								padding: 0;
								max-width: 1410px;
								@media (max-width:$screen-md-max){
									width: 100vw;
								}
							}
							&:hover{
								position: static;
								>.sub-menu{
									@include transform(translate(-50%, 0) !important);
								}
							}
						}
					}
					@media(max-width: $screen-md-max){
						padding: 0 10px;
					}
				}
			}
		}
	}
}

.#{$app-prefix}-navigation{
	float: none;
	> .open{
		> a{
			color: $navbar-link-hover-color;
			&:after{
				content: "";
				position: absolute;
				width: 100%;
				height: 3px;
				background: var(--theme-color,$theme-color);
				left: 0;
				bottom: -1px;
				@include transition(all 0.35s);
			}
			&:hover,
			&:focus{
				color: $navbar-link-hover-color;
			}
		}
	}
	ul.menu {
		@include clear-list();
		float: none;
		@include transition(all 0.35s);
		display:flex;
		flex-wrap:wrap;
		margin:0;
		li {
			@include rtl-text-align-left();
			&.parent{
				position: relative;
			}
			&.dropdown-submenu{
				position: relative;
				.dropdown-menu{
					top: 0;
					left: 100%;
					right: auto;
					position: absolute;
				}
			}
			&.menu-hide-title{
				> span.title, >a{
					@media (min-width: $screen-sm){
						display: none !important;
					}
				}
			}
		}
		> li.level-0{
			position: relative;
			display: table;
			@include rtl-float-left();
			padding:0 32px;
			line-height:24px;
			&:last-child{
				@include rtl-padding-right(0);
			}
			&:first-child{
				@include rtl-padding-left(0);
			}
			@media (max-width: 1500px){
				padding: 0 20px;
			}
			@media(max-width: $screen-md-max){
				padding: 0 10px;
			}
			&:not(.mega-menu){
				ul.sub-menu{
					li.level-1{
						&:first-child{
							padding-top:0;
						}
						&.sub-menu-left{
							ul.sub-menu{
								right:calc(100% - 10px)!important;
								left:auto!important;
							}
						}
					}
				}
			}
			> a{
				position: relative;
				text-transform: $navbar-text-transform;
				font-family: $navbar-mega-font-family;
				font-weight: $navbar-font-weight;
				font-size: var(--font-size-body,$font-size-body);
			    background-color: transparent;
			    color: $navbar-link-color;  
				text-transform: uppercase;
			    display: table;
				white-space: nowrap;
			    @include transition(all .2s ease 0s);
				.caret{
					color: $navbar-link-color;
				}
				&:hover{
					color: $navbar-link-hover-color;
				}
				> span{
                    position: relative;
					display:inline-block;
					&:before{
						bottom:0;
						right: 0;
						content:"";
						position:absolute;
						@include size(0,1px);
						@include transition(all 0.5s ease);
						background: var(--theme-color,$theme-color);
						@include opacity-h();
					}
                }
			}
			&.menu-item-has-children{
				>a{
					position:relative;
					&:after{
                    	content: "\e92e";
                    	font-family: 'feather';
                    	font-size: 12px;
                    	display: inline-block;
                    	vertical-align: middle;
                    	@include rtl-margin-left(5px);
						position:relative;
						top:-1px;
						font-weight: 400;
                    }
					&:before{
						content:"";
						position:absolute;
						bottom: -40px;
						@include size(100%, 65px);
						left:0;
					}
					@media(max-width: $screen-md-max){
						&:after{
							@include rtl-margin-left(3px);
							top: -2px;
						}
					}
                }
			}
			&:hover{
				&.menu-item-has-children{
					> a{
						&:after{
							@include transform(rotateX(-180deg));
						}
					}
				}
			}
			&.current_page_item, &:hover, &.current-menu-item, &.current-menu-ancestor{
                > a{
                	color: $navbar-link-hover-color;
					>span{
                		&:before{
                			@include opacity-s();
							left: 0;
                			width: 100%;
                		}
                	}
                }
            }
			> ul.sub-menu,div.sub-menu{
				li{
					padding-top:5px;
					padding-bottom:5px;
					position:relative;
					&:last-child{
						border: none;
						padding-bottom:0;
					}
					@media (max-width: 991px){
						&.menu-item-image{
							margin-bottom: 10px;
							> span.title, >a{
								display: none;
							}
						}
					}
					a{
						color: var(--text-color,$text-color);
						position:relative;
						&:hover{
							color: var(--theme-color,$theme-color);
						}
						@media (min-width: $screen-md){
							position: relative;
							@include transition(all 0.2s ease);
			            }
						>span:not(.elementor-icon-list-text){
							line-height:17px;
							font-size:8px;
							color:$white;
							text-transform:uppercase;
							background:#45c03b;
							padding:0 5px;
							position:absolute;
							top:-15px;
							@include rtl-right(-24px);
							margin:0;
							min-width:auto;
							@include border-radius(0);
							&:before{
								content:"";
								position:absolute;
								bottom:-4px;
								border-style:solid;
								border-width:2px 3.5px;
								border-color:#45c03b;
								border-right-color: transparent;
								border-bottom-color: transparent;
							}
							&.hot{
								background:#ff4a4a;
								&:before{
									border-color:#ff4a4a;
									border-right-color: transparent;
									border-bottom-color: transparent;
								}
							}
						}
					}
				}
				@media (min-width: $screen-sm-max){
					li.level-1{
						.sub-menu{
							@include rtl-left(100%);
							margin-top: -30px;
						}
					}
				}	
			}
			&.mega-menu{
				&.mega-menu-fullwidth-width{
					position: static;
					>.sub-menu{
						width: 100%;
						left:50%;
						@include transform(translate(-50%, 20px) !important);
						padding:0;
						@include box-shadow(3px 3px 45px 0px rgba(0, 0, 0, 0.05));
						max-width: 1000px;
						padding: 0 15px;
						.content-megamenu{
							padding:40px 0 60px;
						}
						@media (max-width:$screen-md-max){
							width: 100vw;
						}
					}
					&:hover{
						position: static;
						>.sub-menu{
							@include transform(translate(-50%, 0) !important);
						}
					}
				}
				.title {
					margin-bottom: 15px;
					h2{
						font-size:calc(var(--font-size-body,14px) + 2px);
						text-transform: uppercase;
					}
				}
				.menu-homepage{
					a{
						text-transform:uppercase;
						font-weight:500;
						color:var(--gray-dark,$gray-dark);
						margin-bottom:10px;
						display:inline-block;
						&:hover{
							color:var(--theme-color,$theme-color);
						}
					}
					.mega-menu-image{
						display:inline-block;
						border:1px solid rgba($light-gray,0.25);
						>a{
							margin-bottom:0;
						}
						&:hover{
							border:1px solid var(--theme-color,$theme-color);
						}
					}
				}
				> ul.sub-menu{
					@media (min-width: $screen-sm-max){
						min-width: 340px;
					}
					li{
						&.menu-item-has-children, &.menu-item-image{
							border: none;
						}
					}
					li.level-1{
						.sub-menu{
							margin-top: 0;
							position: inherit;
						    @include rtl-left(0);
						    @include rtl-margin-left(0);
						    padding: 0;
    						border: 0;
    						@include box-shadow(none);
    						@include transform(none !important)
							li.level-2{
								.mega-menu-image{
									display: inline-block;
									padding-bottom: 10px;
								}
							}
						}
					}
					@media (min-width: $screen-sm){
						> li{
							margin-bottom: 30px;
						}
					}
				}
				&:hover{
					> ul.sub-menu{
						li.level-1{
							.sub-menu{
	    						opacity: 1 !important;
	    						visibility: visible !important;
							}
						}
					}
				}
				&.mega-menu-fullwidth-width .sub-menu{
					padding:0 15px;
					min-width: auto;
				}
				.sub-menu li.level-1{
					padding: 0px 15px;
					> a{ 
					    padding: 0px 0px 8px;
					    display: block;
					    font-size:calc(var(--font-size-body,14px) + 2px);
					    color: var(--gray-dark,$gray-dark);
					    position: relative;
					    font-weight: bold;
					    text-transform: uppercase;
					    @media (max-width: 991px){
					    	font-size:var(--font-size-body,$font-size-body);
					    }
					}
				}
			}
			&.megamenu-home{
				.sub-menu{
					min-width: 500px;
				}
			}
			.sub-menu{
				display: block;
				min-width: 225px;   				
				position: absolute;
				top: 100%;
				z-index: 9999;
				background: $white;
				opacity: 0;
				visibility: hidden;
				@include transition(opacity 0.1s linear, transform 0.1s linear);
				@include transform(translate(0px, 20px));
				list-style:none;
				.menu-item-has-children{
					position: relative;
				}				
			}
			ul.sub-menu{
				padding:22px 0;
				li{
					padding:5px 30px;
				}
			}
		}
		ul.sub-menu,div.sub-menu{
			padding:22px 30px ;
			@include box-shadow(0 0 2px rgba(0, 0, 0, 0.1));
		}
	}
	
	> .active{
		a{
			color: $navbar-link-active-color;
		}
	}
	.menu-item-has-children {
		&:hover {
			> .sub-menu {
				opacity: 1 !important;
				visibility: visible !important;
				@include transform(translate(0px, 0px) !important);
				display: block;
				@include box-shadow(3px 3px 45px 0px rgba(0, 0, 0, 0.05));
			}
		}
	}
	.menu-item-new-badge, .menu-item-sale-badge{
		> a{
			position: relative;
			> .sale-badge, .new-badge{
				position: absolute;
				top: -17px;
				font-size: 12px;
				text-transform: capitalize;
				color: $white;
				padding: 0 5px;
				&:before{
				    content: "";
				    @include square(0);
				    border-style: solid;
				    border-width: 5px 0 5px 5px;
				    position: absolute;
				    top: 14px;
				}
			}
		}
	}
	.menu-item-new-badge{
		> a{
			> .new-badge{
				background: #3a9be6;
				left: calc(100% + 5px);
				&:before{
					left: 0;
					border-color: transparent transparent transparent #3a9be6;
				}
			}
		}
	}
	.menu-item-sale-badge{
		> a{
			> .sale-badge{
				right: calc(100% + 5px);
				background: red;
				&:before{
					border-color: transparent transparent transparent red;
					right: 0;
					@include rotate(180deg);
				}
			}
		}
	}
}

.menu li{
	&.menu-item-loggedin{
		display: none !important;
	}
}
.logged-in{
	.menu li{
		&.menu-item-loggedin{
			display: block !important;
		}
	}	
}

