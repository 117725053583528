/*------------------- Content Layout -----------------*/
.icon-scroll{
	-webkit-animation: icon-scroll 1s infinite  alternate;
    animation: icon-scroll 1s infinite  alternate;
}
.height-100{
	height:100%;
	>div{
		height:100%;
	}
}
.width-100{
	.elementor-image{
		img{
			width: 100%;
		}
	}
}
.img-menu{
	img{
		@include box-shadow(3px 4px 18px 0px rgba(0, 0, 0, 0.05) !important);
	}
}
@media(min-width: $screen-xs-max){
	.writing-mode{
		h2{
			writing-mode: vertical-lr;
		}
	}
}
/*------------------- End Content Layout -----------------*/


/*------------------- Style Button -----------------*/
.btn-style{
	a{
		position: relative;
		>span{
			display: flex;
			align-items: center;
			&:after{
				position: relative;
				content: "\e901";
				font-family: icomoon;
				font-size: 30px;
				top: 0;
				@include rtl-right(0);
				order: 50;
				@include rtl-margin-left(18px);
			}
		}
	}
}
/*-------------------End Style Button -----------------*/


/*------------------- Content Animation -----------------*/
.img-animation{
	.elementor-image{
		img{
			animation: rotation 15s infinite linear;
		}
	}
}
/*------------------- End Animation -----------------*/


/*------------------- Navigation -----------------*/
.style-navigation{
	.content-product-list{
		.slick-arrow{
			&.fa-angle-left{
				@include rtl-left(25px !important);
			}
			&.fa-angle-right{
				@include rtl-right(25px !important);
			}
		}
		&:hover{
			.slick-arrow{
				&.fa-angle-left{
					@include rtl-left(0 !important);
				}
				&.fa-angle-right{
					@include rtl-right(0 !important);
				}
			}
		}
	}
}
/*------------------- End Navigation -----------------*/

/*------------------- Newsletter -----------------*/
.style-newsletter_white{
	.wpbingo-newsletter.newsletter-default .content-newsletter {
		border-color: $white;
		input[type="email"]{
			color: $white;
			&::placeholder{
				color: $white;
			}
		}
		.clearfix{
			&:before{
				color: $white;
			}
		}
	}
}
.style-newsletter_two{
	.wpbingo-newsletter.newsletter-default{
		.content-newsletter{
			input[type="email"]{
				background: $white;
				line-height: 60px;
				height: 60px;
				padding: 0 30px;
			}
			.clearfix{
				@include rtl-margin-left(0);
				&:before{
					display: none;
				}
			}
			input[type="submit"]{
				line-height: 60px;
				height: 60px;
				font-size:var(--font-size-body,$font-size-body);
				text-transform: uppercase;
				color: $white;
				border: 1px solid #999999;
				padding: 0 30px;
				&:hover{
					background: var(--theme-color,$theme-color);
					border-color: var(--theme-color,$theme-color);
				}
			}
		}
	}
}
/*------------------- End Newsletter -----------------*/


/*------------------- Other layout -----------------*/
.marginright-auto{
	>.elementor-container{
		margin-left:0 !important;
	}
}
.marginleft-auto{
	>.elementor-container{
		margin-right: 0 !important;
	}
}
@media (min-width:$screen-sm-max){
	.marginright-sm-auto{
		>.elementor-container{
			margin-left:0 !important;
		}
	}
	.marginleft-sm-auto{
		>.elementor-container{
			margin-right: 0 !important;
		}
	}
}
@media (max-width:$screen-sm-max){
	.marginright-xs-auto{
		>.elementor-container{
			margin-left:0 !important;
		}
	}
	.marginleft-xs-auto{
		>.elementor-container{
			margin-right: 0 !important;
		}
	}
}
.elementor-icon-box-title{
	margin-top:0;
	strong{
		font-style: italic;
		font-weight: 400;
	}
}
.content-container-full{
	@include rtl-padding-left(70px);
	@media (max-width:1770px){
		@include rtl-padding-left(15px);
	}
	@media (max-width:$screen-md-max){
		@include rtl-padding-left(0);
	}
}
/*------------------- End layout -----------------*/
.elementor-section:not(.elementor-section-full_width,.elementor-inner-section){
	@media (max-width:1770px) and (min-width:$screen-md-max){
		padding-left: 15px ;
		padding-right: 15px ;
	}
}
/*------------------- Accordion -----------------*/
.content-accordion{
	.elementor-accordion{
		.elementor-accordion-item{
			.elementor-tab-title{
				border-bottom: 1px solid #e8e8e8 !important;
				&.elementor-active{
					border-color: var(--gray-dark,$gray-dark) !important;
				}
			}
			.elementor-tab-content{
				border-bottom: 1px solid #e8e8e8 !important;
				height: auto !important;
				padding: 0 !important;
				&.elementor-active{
					padding: 30px 0 !important;
				}
			}
		}
	}
}
/*------------------- End Accordion -----------------*/
