/************************************************************************************************
    BLOCK MENU 
*************************************************************************************************/
.#{$app-prefix}-menu-wrapper {
	border-top: 1px solid var(--border-color,$border-color);
	border-bottom: 4px double var(--border-color,$border-color);
	.container{display: table;}
	#primary-navigation{
		display: table-cell;
		vertical-align: middle;
	}
	.block-cart{
		display: table-cell;
		vertical-align: middle;
		@include rtl-text-align-right();
		.cart-popup {
			min-width: 320px;
			@include rtl-right(0);
			@include rtl-left(inherit);
			.cart_list {
				list-style: none;
			}
		}
	}
}

/************************************************************************************************
    BREADCRUMB
*************************************************************************************************/
.breadcrumb-noheading{
	border-top: 1px solid #e9e9e9;
	.breadcrumb{
		font-size:calc(var(--font-size-body,14px) + 1px);
		line-height:24px;
		color:var(--gray-dark,$gray-dark);
		justify-content:flex-start;
		padding:20px 0;
		a{
			color:var(--text-color,$text-color);
			position:relative;
			&:hover {
				color: var(--theme-color,$theme-color);
			}
		}
		.current{
			color:var(--gray-dark,$gray-dark);
		}
		.delimiter {
			margin: 0 8px;
			color:var(--text-color,$text-color);
			&:before{
			  content: "\f105";
			  display: inline-block;
			  font-family:FontAwesome;
			  font-size:calc(var(--font-size-body,14px) + 1px);
			}
		}
	}
}
.breadcrumb{
    @include border-radius(0);
    margin: $breadcrumb-margin;
    color: $breadcrumb-color;
    padding:0;
    font-size: calc(var(--font-size-body,14px) + 1px);
	font-weight:400;
	width:100%;
	justify-content:center;
	background:transparent;
	a{
        color: var(--text-color,$text-color);
		position:relative;
        &:hover {
            color: var(--theme-color,$theme-color);
        }
    }
	.current{
		color: var(--theme-color,$theme-color);
	}
    .delimiter {
        margin: 0 8px;
        &:before{
          content: "\f105";
          display: inline-block;
		  font-family:FontAwesome;
		  font-size:calc(var(--font-size-body,14px) + 1px);
        }
    } 
}

/* Slideshow -------------------------------------------------------------------------------------- */
.#{$app-prefix}-slideshow{
    .wpb-inner{
        margin-bottom: 0 !important;
    }
}


/* Container -------------------------------------------------------------------------------------- */
.#{$app-prefix}-main{
    @include box-size($container-bg, $container-padding-top, $container-padding-bottom);
    .page-title{
		margin: $page-title-margin;
		font-size:calc(var(--font-size-body,14px) + 6px);
		padding: 70px 0 75px;
    	background-position: center;
		text-align:center;
		position:relative;
		background-repeat: no-repeat;
		background-size: cover;
		&.no-pagetitle{
			padding:0;
			border-top: 1px solid #e9e9e9;
		}
		.content-title-heading{
			display:inline-flex;
			position:relative;
			align-items:center;
		}
		.back-to-shop{
			display:none;
			font-size:0;
			cursor:pointer;
			@include rtl-margin-right(10px);
			&.active{
				display:block;
			}
			&:before{
				content: "\e900";
				font-family: icomoon;
				font-size: 35px;
				line-height: 35px;
			}
			&:hover{
				color:var(--theme-color,$theme-color);
			}
		}
		h1{
			margin-top: 0;
			font-size: 40px;
			text-transform: uppercase;
			text-align:center;
			@media (max-width:$screen-xs-max){
				font-size: 30px;
			}
		}
		&.empty-image{
			background:$white;
			padding-bottom:0;
			border-top: 1px solid #e9e9e9;
		}
		&.dark{
			.back-to-shop{
				color:var(--gray-dark,$gray-dark);
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			h1{
				color:var(--gray-dark,$gray-dark);
			}
			a{
				color:var(--gray-dark,$gray-dark);
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			.breadcrumb{
				color:var(--gray-dark,$gray-dark);
			}
			.current{
				color:var(--text-color,$text-color);
			}
			.delimiter{
				color:var(--text-color,$text-color);
			}
			ul.woocommerce-product-subcategories{
				li h2 a{
					color:var(--gray-dark,$gray-dark);
					&:hover{
						color:var(--theme-color,$theme-color);
					}
				}
				&.image_categories li{
					> a:hover:before{
						border-color:var(--theme-color-50,rgba($theme-color, 0.5));
					}
					&.active{
						>a{
							&:before{
								border-color:var(--theme-color-50,rgba($theme-color, 0.5));
							}
						}
					}
				}
			}
			.woocommerce-product-subcategorie-content .slick-arrow{
				color:var(--gray-dark,$gray-dark);
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
		}
		&.light{
			.back-to-shop{
				color:$white;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			h1{
				color:$white;
			}
			a{
				color:$white;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			.breadcrumb{
				color:$white;
			}
			.current{
				color:$white;
			}
			.delimiter{
				color:$white;
			}
			ul.woocommerce-product-subcategories{
				li h2 a{
					color:$white;
					&:hover{
						color:var(--theme-color,$theme-color);
					}
				}
				&.image_categories li{
					> a:hover:before{
						border-color:var(--theme-color-50,rgba($theme-color, 0.5));
					}
					&.active{
						>a{
							&:before{
								border-color:var(--theme-color-50,rgba($theme-color, 0.5));
							}
						}
					}
				}
			}
			.woocommerce-product-subcategorie-content .slick-arrow{
				color:$white;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
		}
		>.container{
			position:relative;
		}
		@media(max-width: $screen-xs-max){
			padding: 40px 0;
			margin-bottom: 50px;
		}
    }
}

/* Content -------------------------------------------------------------------------------------- */
.#{$app-prefix}-content{
    background: $content-bg;
    @include clearfix();
}
/* Pagination -------------------------------------------------------------------------------------- */
.#{$app-prefix}-pagination{
    margin: 0;
}

/************************************************************************************************
    BLOCK FOOTER 
*************************************************************************************************/


##{$app-prefix}-footer{ 
    @include box-size($footer-bg, $footer-padding-top, $footer-padding-bottom);
    position: relative;
    color: $footer-color;
    border-bottom: $footer-border;
    z-index: 2;
    margin-top: 100px;
	@media (max-width:$screen-sm-max){
		padding-bottom: 50px;
	}
    a{
        &:hover,
        &:focus,
        &:active{
            color: $footer-link-hover-color;
			@include transition(all .3s ease);
        }
    }
    ul {
        list-style: none;
        padding: 0;
    }
    ul.social-link{
		li{
			display: inline-block;
			@include rtl-margin-right(20px);
			a{
				display: inline-block;
				i{
					font-size:calc(var(--font-size-body,14px) + 1px);
					@include transition(all .3s ease);
				}
				&:hover{
					i{
						color: var(--theme-color,$theme-color);
					}
				}
			}
			&:last-child{
				@include rtl-margin-right(0);
			}
		}
    }
	.social-link_white{
		ul.social-link{
			li{
				a{
					border: 1px solid rgba($white, .3);
					i{
						color: $white;
					}
					&:hover{
						background-color: $white;
						border-color: $white;
						i{
							color: var(--gray-dark,$gray-dark);
						}
					}
				}
			}
		}
	}
} 

/*  */
/* Newletter Popup */
/*  */
body:not(.page-template-homepage){
	.newsletterpopup_overlay, .newsletterpopup{
		display:none !important;
	}
}
.newsletterpopup{
	position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
	z-index: 9999;
	.newsletterpopup_overlay{
		position: fixed;
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		margin:0;
		z-index: 9999;
		background: rgba(0,0,0,.4);
		@include opacity-h();
	}
	.close-popup {
		$size: 35px;
		height: $size;
		width: $size;
		z-index: 10;
		cursor: pointer;
		position: absolute;
		top: 15px;
		@include rtl-right(15px);
		box-sizing: border-box;
		line-height: $size;
		display: inline-block;
		@include border-radius(50%);
		background: $white;
		box-shadow: 0px 1px 12px 2px var(--gray-dark-10,rgba($gray-dark, 0.1));
		&:before, &:after {
			$width: 15px;
			$height: 1px;
			@include transform(rotate(-45deg));
			content: '';
			position: absolute;
			top: 50%;
			left: calc(50% - 8px);
			display: block;
			height: $height;
			width: $width;
			background-color: var(--gray-dark,$gray-dark);
			@include transition(all .3s ease-out);
		}
		&:after {
			@include transform(rotate(-135deg));
		}
		&:hover{
			&:before, &:after {
				@include transform(rotate(0deg));
			}
		}
	}
	&.show{
		display: flex;
		opacity: 1;
		visibility: visible;
	}
	&.newsletterpopup-active{
		.newsletterpopup_overlay{
			@include opacity-s();
		}
		.wp-newsletter{
			opacity: 1;
			visibility: visible;
			.close-popup{
				transition-delay: .5s;
			}
		}
	}
	&.transition{
		.wp-newsletter{
			max-width: 720px;
		}
		.widget{
			opacity: 1;
			visibility: visible;
			z-index: unset;
			height: 100%;
			overflow: auto;
			width: 100%;
			@include rtl-padding(50px, 15px, 50px, 375px);
		}
	}
	.wp-newsletter{
		position: relative;
		z-index: 9999;
		width: 100%;
		max-width: 360px;
		overflow: hidden;
		background-color: #fff;
		@include transition(all .5s ease);
		opacity: 0;
		visibility: hidden;
		display: flex;
	}
	.image{
		max-width: 360px;
		width: 100%;
		position: relative;
		z-index: 3;
	}
	.widget{
		position: absolute;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: opacity .5s,visibility 0s;
		transition: opacity .5s,visibility 0s;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
	}
	.wpbingo-newsletter-popup{
		text-align:center;
		padding: 25px 10px;
		margin:auto;
		.title-newsletter{
			font-size:var(--font-size-heading,$font-size-heading);
			font-weight: 400;
			margin:auto;
			position:relative;
			margin-bottom: 20px;
			span{
				font-style: italic;
			}
		}
		.text-newsletter{
			color:var(--text-color,$text-color);
			font-weight:400;
			margin-bottom:30px;
		}
	}
	.content-newsletter{
		width: 100%;
		margin: 0;
		display:flex;
		background:$white;
		.wpcf7-form-control-wrap{
			width:100%;
			.wpcf7-not-valid-tip{
				bottom: 0;
			}
		}
		input[type="email"]{
			border:0;
			border-bottom: 2px solid var(--gray-dark,$gray-dark);
			padding: 0;
			font-size: 13px;
			background: transparent;
			line-height: 35px;
			height: 35px;
			width: 100%;
			&::placeholder{
				color: #ababab;
			}
		}
		.clearfix{
			position: relative;
			@include rtl-margin-left(6px);
			@include transition(all .3s ease);
			height: 35px;
			&:before{
				position: absolute;
				content: "\e98a";
				font-family: 'feather';
				display: inline-block;
				font-size:calc(var(--font-size-body,14px) + 6px);
				color: var(--gray-dark,$gray-dark);
				top: calc(50% - 17px);
				left: calc(50% - 9px);
				z-index: 2;
				@include transition(all .3s ease);
			}
		}
		input[type="submit"]{
			border: 0;
			border-bottom: 2px solid var(--gray-dark,$gray-dark);
			color: transparent;
			background: transparent;
			position: relative;
			padding: 0 20px;
			z-index: 3;
			font-size: 0;
			line-height: 36px;
			height: 35px;
			cursor: pointer;
			@include border-radius(0 !important);
		}
		.ajax-loader{
			position:absolute;
		}
	}
	@media(max-width: $screen-xs-max){
		.wp-newsletter{
			min-height: 360px;
		}
		.image{
			display: none;
		}
		&.transition{
			.widget{
				padding: 30px;
			}
		}
	}
}


/* Copyright -------------------------------------------------------------------------------------- */
.#{$app-prefix}-copyright{
    color: $copyright-color;
    font-size: $copyright-font-size;
    font-weight: $copyright-font-weight;
    background: $copyright-bg;
    font-family: $copyright-font-family;
    margin-top: 80px;
	@media (max-width:$screen-sm-max){
		&:not(.no-padding){padding-bottom:50px};
	}
    .#{$block-selector} {
        .#{$block-heading-selector}{
            color: $footer-heading-color;
            text-transform: $footer-heading-transform;
            margin: $footer-heading-margin;
            @include font-size(font-size,$footer-heading-font-size);
        }
    }
    .container{
        padding-bottom: $copyright-padding-bottom;
        position: relative;
        padding-top: $copyright-padding-top;
        &:before{
			position: absolute;
			top: 0;
			content: "";
			background: #dedede;
			@include rtl-left(15px);
			width: calc(100% - 30px);
			height: 1px;
        }
        .payment{
            @include rtl-text-align-right();
        }
    }
}
