/* Page default --------------------------------- */
.default-template .article{
	background: $white;
}

/* Elements Boostrap Page ---------------------------------- */
.bs-component,
.bs-docs-section{
	.modal{
		bottom: auto;
		display: block;
		left: auto; 
		position: relative;
		right: auto;
		top: auto;
		z-index: 1;
	}
	p:empty{
		margin: 0;
		padding: 0; 
		@include hide-text();
	}
	br{
		display: none;
	}
	.modal-dialog{
		width: 90%;
	}
}

/* Contact Page ---------------------------------- */


##{$app-prefix}-main{
    .wpb_gmaps_widget .wpb_wrapper {background: none; padding: 0;}
    
    
    #wpcf7-f8-p321-o1 {
        p {
            .wpcf7-form-control-wrap {
				display: block;
            }
        }
    }
}

.text-block-page{
	border-bottom: 1px solid var(--border-color,$border-color);
	padding-bottom: 10px;
	@include rtl-margin(0, 0, 22px, 0);
}
h3.text-block-page{
	font-size:calc(var(--font-size-body,14px) + 4px);
}
.contact-info{
	ul{
		list-style-type: none;
		@include rtl-padding-left(0px);
		li{
			margin-bottom: 25px;
			font-weight:400;
			position:relative;
			strong{
				display: block;
				font-weight: 700;
				color: var(--gray-dark,$gray-dark);
				padding-bottom: 13px;
				font-size:calc(var(--font-size-body,14px) + 4px);
				text-transform:uppercase;
			}
		}
	}
	@media (max-width:$screen-xs-max){
		@include rtl-padding-left(0);
		margin-bottom:50px !important;
	}
}
.wpb_gmaps_widget{
	iframe{
		height:540px;
	}
}
.contact-form{
	h2{
		font-size:calc(var(--font-size-heading,25px) + 3px);
		font-weight:500;
		line-height:1.3;
		max-width:314px;
	}
}
.contact-info-form{
	h2.vc_custom_heading{
		margin-bottom: 35px;
		margin-top:2px;
		font-weight:700;
	}
	.title-input{
		font-size:var(--font-size-body,$font-size-body);
	}
	.form-required{
		.title-input{
			position:relative;
			&:before{
				content:"*";
				position:absolute;
				color:red;
				right:-10px;
				top:0;
			}
		}
	}
	p{
		margin-bottom:0;
	}
	.ajax-loader{
		position:absolute;
	}
}
.contact{
	.wpcf7-form div.wpcf7-validation-errors, .wpcf7-form div.wpcf7-acceptance-missing{
		margin-top: 15px !important;
	}
}
.wpcf7-not-valid-tip{
	position: relative;
	bottom: 10px;
	left: 0;
}
.social-link-contact{
	.social-link{
		padding: 0;
		list-style: none;
		li{
			margin:0;
			@include rtl-margin-right(15px);
			display: inline-block;
			&:last-child{
				@include rtl-margin-right(0);
			}
			a{
                display: inline-block;
                @include transition(all 0.2s ease);
				i{
					font-size:calc(var(--font-size-body,14px) + 2px);
				}
            }
		}
	}
}
.contact-us-form{
	>.row{
		margin:0 -7.5px;
		>div{
			padding:0 7.5px;
		}
	}
	label{
		color:var(--gray-dark,$gray-dark);
		font-size:var(--font-size-body,$font-size-body);
		margin-bottom:10px;
		position:relative;
		font-weight:600;
	}
	.form-required{
		.wpcf7-form-control-wrap{
			position: relative;
			&:after{
				position: absolute;
				content: "*";
				color: red;
				@include rtl-right(15px);
				top: -3px;
				font-size:calc(var(--font-size-body,14px) + 6px);
			}
		}
	}
	input[type="text"], input[type="email"]{
		height: 50px;
		line-height:50px;
		margin-bottom: 20px;
		width: 100%;
		padding: 10px 20px;
		border:0;
		font-weight:400;
		@include placeholder(var(--text-color-75,rgba($text-color, 0.75)));
		border: 1px solid var(--border-color,$border-color);
		&:focus{
			border-color:var(--gray-dark,$gray-dark);
		}
	}
	textarea{
		width: 100%;
		max-height: 370px;
		padding: 10px 20px;
		border:0;
		font-weight:400;
		margin-bottom:23px;
		border: 1px solid var(--border-color,$border-color);
		&:focus{
			border-color:var(--gray-dark,$gray-dark);
		}
	}
	.ajax-loader{
		position:absolute;
		right:20px;
		top:30px;
	}
	.button{
		position:relative;
		display:inline-block;
	}
	input[type="submit"]{
		height: 55px;
		line-height: 55px;
		font-size:var(--font-size-body,$font-size-body);
		padding: 0 75px;
		color: $white;
		background: var(--button-color,$gray-dark);
		border: none;
		@include transition(all 0.3s ease); 
		cursor:pointer;
		font-weight:500;
		text-transform: uppercase;
		&:hover{
			background: var(--theme-color,$theme-color);
		} 
	}
	.wpcf7-text,.wpcf7-textarea{
		&::placeholder {
			font-size:var(--font-size-body,$font-size-body);
			font-weight:300;
		}
	}
}
/* Page About Us ---------------------------------- */
.about-us{
	.page-title{
		display: none;
	}
	.bwp-header{
		border-bottom: 0 !important;
	}
}


/* Page Not Found ---------------------------------- */

.woocommerce-page .header-title{
	display: none;
}
.blog-page-list{
	.entry-title{
		font-size: var(--font-size-body,$font-size-body) + 3;
		line-height: 1.2;
		padding-top: 10px;
		margin-bottom: 0!important;
	}
	.entry-meta{
		margin-top: 5px!important;
	}
}

.error404{
	.bwp-main{
		text-align: center;
		.page-title{
			display:none;
		}
	}
	.page-404{
		position:relative;
		.title-error{
			font-size:300px;
			line-height:1;
			color:var(--gray-dark,$gray-dark);
		}
		.sub-title{
			font-size:50px;
			color:var(--gray-dark,$gray-dark);
			font-weight:500;
			margin-bottom:25px;
			position:relative;
			padding-bottom:10px;
			&:before{
				position:absolute;
				content:"";
				@include size(140px,2px);
				bottom:0;
				left:calc(50% - 70px);
				background:$gray-light;
			}
		}
		.sub-error{
			max-width:325px;
			margin:auto;
		}
		.content-page-404{
			padding:100px 0 60px;
			a{
				margin-top: 30px;
				padding: 12px 40px;
				line-height:calc(var(--font-size-body,14px) + 6px);
				color: $white;
				font-size:calc(var(--font-size-body,14px) + 2px);
				background: var(--button-color,$gray-dark);
				@include border-radius(0);
				position:relative;
				text-transform: uppercase;
				font-weight: 500;
				z-index:1;
				@include transition(all .3s ease);
				&:after{
					display:inline-block;
					content: "\24";
					font-family: eleganticons;
					font-size:calc(var(--font-size-body,14px) + 6px);
					line-height:1;
					position:relative;
					top:5px;
					@media (max-width:$screen-xs-max){
						font-size:calc(var(--font-size-body,14px) + 1px);
					}
				}
				&:hover{
					background:var(--theme-color,$theme-color);
				}
			}
		}
		@media (max-width:$screen-sm-max){
			.content-page-404{
				position:unset;
			}
		}
		@media (max-width:$screen-xs-max){
			.content-page-404{
				position:unset;
				a{
					margin-top:20px;
				}
			}
			.title-error{
				font-size:200px;
				margin-bottom:-10px;
			}
			.sub-title{
				font-size:calc(var(--font-size-body,14px) + 6px);
			}
			.sub-error{
				font-size:calc(var(--font-size-body,14px) + 1px);
			}
		}
	}
}

/* Page My Account ---------------------------------- */
.woocommerce-MyAccount-navigation{
	background: #f5f5f5;
    border: 1px solid #f5f5f5;
    margin-bottom: 60px;
    padding: 20px 30px;
	width : 30%;
	@include rtl-float-left();
	
	ul{
		padding: 0;     
		list-style: none;
		li{
			border-bottom: 1px solid rgba($gray-lighter,0.5);
			list-style-type: none;
			padding: 10px 0;
			vertical-align: middle;
			&:last-child{
				border-bottom : none;
			}
			a, span{
                @include transition(all .2s ease 0s);
				font-weight: 500;
				text-transform: uppercase;
                &.active, &:hover{
                    color: var(--theme-color,$theme-color);
                    cursor: pointer;
                }
            }
		}
	}
}
.woocommerce-MyAccount-content{
	width : 68%;
	@include rtl-float-right();
	overflow-x: auto;
	&::-webkit-scrollbar {
		height: 3px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	   
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #999;
	}
	h2{
		font-size:calc(var(--font-size-body,14px) + 2px);
	}
	h3{
		font-size: 12px;
		margin: 0 0 20px;
		text-transform: uppercase;
		font-size:calc(var(--font-size-body,14px) + 1px);
	}
	input[type="submit"]{
		background: var(--theme-color,$theme-color);
		color: $white;
		border: none;
		@include transition(all 0.3s ease); 
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		padding: 5px 10px;
	    &:hover{
			background: lighten($theme-color, 5%);
			background: var(--theme-color-10);
		}   
	}
}

.lost_reset_password{
	.form-row{
		margin: 0;
		input[type="submit"]{
			position: absolute;
			bottom: 0;
			height: 35px;
		}
	}
}
.woocommerce-lost-password .lost_reset_password{
	.form-row.form-row-first{
		float: none;
		@media (max-width: $screen-xs-max){
			width: 100%;
		}
	}
	button[type="submit"]{
		margin-top: 10px;
	}
}
.woocommerce-cart-form{
	margin-bottom: 30px;
	.percent-cart{
		padding: 20px;
		border: 2px dashed #e8e8e8;
		margin-bottom: 20px;
		@media(max-width: $screen-xs-max){
			padding: 15px;
		}
		.free-ship{
			font-size:calc(var(--font-size-body,14px) + 2px);
			@media (max-width:$screen-xs){
				font-size:var(--font-size-body,$font-size-body);
			}
			strong{
				color: var(--gray-dark,$gray-dark);
			}
			.total-percent{
				position:relative;
				width:100%;
				background:#e9e9e9;
				height: 7px;
				margin: 25px 0;
				@include border-radius(3px);
				.percent{
					position:absolute;
					height:100%;
					left:0;
					background: var(--theme-color,$theme-color);
					top:0;
					@include transition(all 0.5s ease);
					@include border-radius(3px);
					.percent-2{
						font-size: 0;
					}
					&.free{
						background: green;
						&:before{
							color: green;
							border-color: green;
						}
					}
				}
			}
		}
	}
	table.shop_table{
		margin:0;
		.button{
			padding:0 35px;
			background:var(--gray-dark,$gray-dark);
			color:$white;
			font-size:calc(var(--font-size-body,14px) - 3px);
			@include border-radius(0px);
			line-height:40px;
			cursor:pointer;
			&:disabled{
				opacity:0.8;
				background:var(--gray-dark,$gray-dark);
				color:$white;
				cursor: not-allowed;
				&:hover{
					opacity:0.8;
					background:var(--gray-dark,$gray-dark);
					color:$white;
				}
			}
			&:hover{
				background:darken($theme-color,10%);
				background:var(--theme-color);
			}
		}
		.coupon{
			.button{
				padding:10px 35px;
				background:var(--button-color,$gray-dark);
				color:$white;
				font-size:var(--font-size-body,$font-size-body);
				@include border-radius(0);
				line-height: calc(var(--font-size-body,14px) + 6px);
				cursor:pointer;
				@include transition(all .2s ease);
				position: relative;
				text-transform: uppercase;
				font-weight: 500;
				font-family:var(--font-family-heading,$font-family-base);
				&:hover{
					background: var(--theme-color,$theme-color);
				}
				@media(max-width: $screen-xs-max){
					padding: 0 15px;
				}
			}
		}
	}
}

.cart_totals{
	.woocommerce-shipping-destination{
		margin-bottom:10px;
		>strong{
			color:var(--gray-dark,$gray-dark);
			font-weight:700;
		}
	}
	table.shop_table{
		>tbody{
			border: 1px solid rgba($gray-lighter,0.25);
			border-top:0;
			>tr{
				td,th{
					padding:15px;
					border:0;
					border-bottom: 1px solid rgba($gray-lighter,0.5);
				}
				&.order-total{
					.woocommerce-Price-amount{
						font-size:calc(var(--font-size-heading,25px) - 1px);
					}
				}
				&.shipping{
					ul {
						&.woocommerce-shipping-methods{
							margin-bottom:10px;
							@media (max-width:$screen-xs-max){
								display:block;
							}
						}
						li {
							@include rtl-margin-right(20px);
							input[type="radio"], input[type="checkbox"]{
								margin-top:0;
							}
							.shipping_method{
								display:none;
							}
							label{
								margin:0;
								@include rtl-margin-left(0);
								font-weight:400;
								margin-bottom:0;
								position:relative;
								@include rtl-padding-left(18px);
								cursor:pointer;
								&:before{
									position:absolute;
									@include rtl-left(0);
									@include border-radius(50%);
									top:calc(50% - 6px);
									@include square(12px);
									border:1px solid var(--text-color,$text-color);
									content:"";
								}
							}
							.shipping_method:checked +label{
								&:before{
									background:var(--theme-color,$theme-color);
									border-color:var(--theme-color,$theme-color);
								}
							}
						}
					}
				}
				>td{
					a.shipping-calculator-button{
						color: var(--gray-dark,$gray-dark);
						text-transform: uppercase;
						font-weight:700;
						position:relative;
						display:inline-block;
						padding-bottom:2px;
						font-size:calc(var(--font-size-body,14px) - 1px);
						&:before{
							content:"";
							@include size(100%,2px);
							position:absolute;
							bottom:0;
							left:50%;
							@include transform(translateX(-50%));
							background:var(--gray-dark,$gray-dark);
							@include transition(all 0.3s ease);
						}
						&:hover{
							color:var(--theme-color,$theme-color);
							&:before{
								background:var(--theme-color,$theme-color);
								width:60%;
							}
						}
					}
					.shipping-calculator-form{
						margin-top:30px;
						.select2{
							height:40px;
							line-height:40px;
							@include border-radius(0);
							span{
								height:40px;
								line-height:40px;
								@include border-radius(0);
							}
						}
						.button{
							background:var(--theme-color,$theme-color);
							color:$white;
							font-weight:700;
							font-size:calc(var(--font-size-body,14px) - 1px);
							text-transform:uppercase;
							letter-spacing:2px;
							@include border-radius(0);
							cursor:pointer;
							padding:0 20px;
							line-height:40px;
							&:hover{
								background:darken($theme-color,10%);
								background:var(--theme-color);
							}
						}
					}
					.woocommerce-shipping-destination{
						strong{
							font-weight:500;
						}
					}
					.woocommerce-Price-amount{
						font-weight:500;
					}
				}
			}
		}
	}
	.wc-proceed-to-checkout{
		padding:0 30px;
		>a.wc-forward{
			background: var(--button-color,$gray-dark);
			color: $white;  
			padding: 24px 20px;
			@include border-radius(0);
			display:inline-block;
			width:100%;
			text-align:center;
			position: relative;
			text-transform: uppercase;
			font-weight: 500;
			@include transition(all .2s ease);
			&:hover{
				background: var(--theme-color,$theme-color);
			}
			&:before{
				display: none;
			}
		}
	}
}
body.faq, .faq-2{
	.page-title{
		display: none;
	} 
}
