.sticky{
	position:relative;
	padding: 30px 30px 25px;
	.entry-meta{
		>span{
			&:nth-child(2) {
			    padding-left: 0 !important;
			    &:before{
			    	display: none;
			    }
			}
		}
	}
	.sticky-post{
		background-color: $white;
		color: var(--gray-dark,$gray-dark);
		font-weight: 500;
		letter-spacing: 0.04em;
		margin-bottom:25px;
		display:inline-block;
		padding: 5px 10px !important;
		text-transform: uppercase;	
		line-height: 19px;
		letter-spacing:2px;
		&.have-thumbnail{
			position:absolute;
			top:30px;
			@include rtl-right(30px);
		}
	}
}
/************************************************************************************************
    POST ARCHIVES
*************************************************************************************************/
.category-posts .cate-post-content, .author, .search, .post-related{
	&.left:not(.col-xl-12, .col-lg-12){
		@media(min-width:$screen-md-max){
			@include rtl-padding-left(15px);
		}
	}
	&.right:not(.col-xl-12, .col-lg-12){
		@media(min-width:$screen-md-max){
			@include rtl-padding-right(15px);
		}
	}
	&.col-lg-12.col-md-12{
		max-width:1200px;
		margin-left:auto;
		margin-right:auto;
	}
	.entry-thumb{
		.gallery-slider{
			vertical-align: top;
			overflow: hidden;
		}
	}
	.navigation{
		@include rtl-text-align-left();
		margin-top: 45px;
	}
	.post-categories{
		position: absolute;
		bottom: 20px;
		@include rtl-left(20px);
		list-style: none;
		padding: 0;
		li{
			display: inline-block;
			@include rtl-margin-right(5px);
		}
		a{
			background: $white;
			padding: 0 15px;
			height: calc(var(--font-size-body,14px) + 16px);
			line-height: calc(var(--font-size-body,14px) + 16px);
			font-size: calc(var(--font-size-body,14px) - 2px);
			display: inline-block;
			@include transition(all .3s ease);
			text-transform: uppercase;
			font-weight: 500;
			&:hover{
				background: var(--theme-color,$theme-color);
				color: $white;
			}
		}
	}
	.entry-date{
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	.read-more{
		position: relative;
		display: inline-block;
		border: 1px solid var(--border-color,$border-btn);
		line-height: 55px;
		padding: 0 60px;
		text-transform: uppercase;
		font-weight: 500;
		@include transition(all .3s ease);
		&:hover{
			background: var(--theme-color,$theme-color);
			border-color: var(--theme-color,$theme-color);
			color: $white;
		}
		@media(max-width: $screen-md-max){
			line-height: 50px;
			padding: 0 50px;
		}
	}
	.blog-content-grid{
		margin: 0 -15px;
		.grid-post{
			&.sticky {
				background:transparent;
				padding:0 15px;
			}
		}
		.entry-post{
			position: relative;
			.entry-thumb{
				width:100%;
				position:relative;
				margin-bottom: 20px;
			}
		}
		.post-categories{
			@include rtl-right(20px);
			left: auto;
		}
		h3.entry-title{
			font-size: calc(var(--font-size-heading,25px) - 1px);
			line-height: 1.1;
			width: 100%;
			margin: 0 0 10px;
		}
	}
	.blog-content-card{
		.card-post{
			&.sticky {
				background:transparent;
				padding:0 15px;
			}
			&:hover{
				.info{
					@include transform(translateY(0));
				}
			}
		}
		.info{
			@include transform(translateY(70px));
			@include transition(all .5s ease);
		}
		.post-categories{
			top: 20px;
			right: 20px;
			left: auto;
			bottom: auto;
		}
		.entry-date{
			time{
				color: $white;
			}
		}
		.entry-post{
			position: relative;
			&:before{
				content:"";
				position:absolute;
				bottom:0;
				height:50%;
				left:0;
				width:100%;
				z-index:1;
				background: -moz-linear-gradient(bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}
			.entry-thumb{
				width:100%;
				position:relative;
			}
			.post-content{
				z-index: 9;
			  	@include transition(all 0.3s ease);
				position:absolute;
				bottom: 40px;
				width:100%;
				padding:0 25px;
				overflow: hidden;
				left:0;
			}
		}
		h3.entry-title{
			font-size: calc(var(--font-size-heading,25px) - 3px);
			line-height: 1;
			width: 100%;
			margin: 0 0 20px;
			a{
				color:$white;
			}
		}
		.read-more{
			color: $white;
			padding-bottom: 3px;
			&:after{
				background: $white;
			}
		}
	}
	.blog-content-list{
		@media (max-width:$screen-xs-max){
			.type-post{
				margin-bottom:50px;
			}
		}
		.entry-thumb{
			position: relative;
			display: inline-block;
			overflow: hidden;
			@include rtl-margin-right(30px);
			width:50%;
			@media (max-width:$screen-xs-max){
				width:100%;
				margin:0 0 15px;
			}
		}
		.post-content{
			flex:1;
			@media (max-width:$screen-xs-max){
				margin-top:20px;
			}
		}
		.list-post{
			>article{
				width:100%;
				display:flex;
				flex-wrap:wrap;
				margin-bottom:45px;
				align-items:center;
				padding-bottom:45px;
				border-bottom:1px solid #e2e2e2;
				.post-content{
					h3.entry-title{
						font-size:var(--font-size-heading,$font-size-heading);
						margin:0 0 5px;
						word-break: break-word;
					}
					@media (max-width:$screen-xs-max){
						order:2;
					}
				}
			}
			&:last-child{
				>article{
					margin-bottom:0;
				}
			}
		}
		.post-excerpt{
			margin-bottom:15px !important;
		}
		.list-post{
			&:last-child{
				.type-post{
					margin-bottom:70px;
				}
			}
		}
		.post_format-post-format-gallery{
			.gallery-slider{
				.slick-arrow{
					border:none;
					@include square(30px);
					line-height:30px;
					right: 50px;
					top:10px;
					left: auto;
					color: var(--gray-dark,$gray-dark);
					background: $white;
					font-family: FontAwesome;
					@include opacity-s();
					font-size:calc(var(--font-size-body,14px) + 1px);
					&:after{
						background: $white;
					}
					&:hover{
						background:var(--theme-color,$theme-color);
						color:$white;
					}
					&.fa-angle-left{
						&:before{
							content:"\f177";
						}
					}
					&.fa-angle-right{
						&:before{
							content:"\f178";
						}
						right: 15px;
					}
				}
			}
		}
	}
	.blog-content-modern{
		.entry-meta-head{
			display:flex;
			margin-bottom:30px;
			.entry-author{
				&:after{
					content:"|";
					margin:0 15px;
					display:inline-block;
					color:#d5d5d5;
				}
				a{
					color:var(--text-color,$text-color);
				}
			}
		}
		.content-moderns{
			margin:0 -15px;
			display:flex;
			width:calc(100% + 30px);
			flex-wrap:wrap;
			.list-post{
				padding:0 15px;
				width:50%;
			}
			.entry-title{
				font-size:var(--font-size-heading,$font-size-heading);
			}
		}
		.post-content{
			margin-top:15px;
		}
		h3.entry-title{
			font-size:35px;
			margin:0 0 5px;
			word-break: break-word;
		}
		.entry-thumb{
			position: relative;
			overflow: hidden;
		}
		.post-excerpt{
			margin-bottom:15px !important;
		}
		.cat-links{
			margin-bottom:5px;
			ul{
				padding:0;
				list-style:none;
				li{
					display:inline-block;
					&:first-child{
						a{
							&:before{
								display:none;
							}
						}
					}
				}
			}
			a{
				font-size:calc(var(--font-size-body,14px) - 3px);
				text-transform:uppercase;
				color:var(--text-color,$text-color);
				font-weight:600;
				&:hover{
					color:darken($theme-color,10%);
					color:var(--theme-color);
				}
			}
		}
		.post_format-post-format-gallery{
			.gallery-slider{
				.slick-arrow{
					border:none;
					@include square(30px);
					line-height:30px;
					right: 50px;
					top:10px;
					left: auto;
					color: var(--gray-dark,$gray-dark);
					@include opacity-s();
					font-size:calc(var(--font-size-body,14px) + 1px);
					&:after{
						background: $white;
					}
					&:hover{
						background:var(--theme-color,$theme-color);
						color:$white;
					}
					&.fa-angle-left{
						&:before{
							content:"\f177";
						}
					}
					&.fa-angle-right{
						&:before{
							content:"\f178";
						}
						right: 15px;
					}
				}
			}
		}
		@media (max-width:$screen-xs-max){
			.content-moderns .list-post{
				width:100%;
			}
			h3.entry-title{
				font-size:calc(var(--font-size-heading,25px) + 5px);
			}
		}
	}
	.blog-content-standar{
		.list-post{
			>article{
				margin-bottom: 60px;
				&.sticky{
					background: #e5e5e5;
					padding-bottom:30px;
					.read-more{
						z-index:2;
					}
				}
				@media(max-width: $screen-xs-max){
					margin-bottom: 20px;
					padding-bottom: 20px;
				}
			}
		}
		.entry-thumb{
			margin-bottom:20px;
			position: relative;
			.slick-list{
				margin: 0;
				.item{
					padding: 0;
				}
			}
		}
		h3.entry-title{
			font-size:var(--font-size-heading,$font-size-heading);
			line-height: 30px;
			width: 100%;
			margin:0 0 15px;
			position:relative;
		}
		.post-excerpt{
			margin-bottom: 15px;
			word-break: break-word;
		}
		.content-bottom{
			display:flex;
			justify-content:space-between;
			flex-wrap:wrap;
			.entry-social-share{
				a{
					@include square(40px);
					@include border-radius(50%);
					@include rtl-margin-right(10px);
					background:rgba($light-gray,0.75);
					display:inline-block;
					text-align:center;
					line-height:40px;
					color:$white;
					&:hover{
						background:var(--theme-color,$theme-color);
					}
				}
			}
		}
		@media (max-width:$screen-xs-max){
			h3.entry-title{
				font-size:var(--font-size-heading,$font-size-heading);
			}
		}
	}
	h3.entry-title{
		margin-bottom: 8px;
		line-height:1.3;
		font-size: 30px;
		word-break: break-word;
		margin-top:5px;
		text-transform: uppercase;
		@media(max-width:$screen-sm-max){
			margin-top:10px;
		}
	}
	.entry-meta{
		.entry-meta-link{
			a{
				text-transform: capitalize;
				color:var(--theme-color,$theme-color);
			}
			img{
				@include border-radius(50%);
				@include rtl-margin-right(10px);
			}
		}
	}
	.byline,.published:not(.updated) {
		display: none;
	}
}
.post_format-post-format-gallery{
	.gallery-slider{
		display: inline-block;
		width: 100%;
		.slick-arrow{
			border:none;
			@include square(30px);
			line-height:30px;
			left: auto;
			top: 15px;
			color: var(--theme-color,$theme-color);
			font-size:calc(var(--font-size-body,14px) + 4px);
			font-family:FontAwesome;
			@include opacity-s();
			background: $white;
			&:after{
				background: $white;
			}
			&:hover{
				background: var(--theme-color,$theme-color);
				color: $white;
			}
			&.fa-angle-left{
				@include rtl-right(55px);
				&:before{
					content:"\f177";
				}
			}
			&.fa-angle-right{
				@include rtl-right(15px);
				&:before{
					content:"\f178";
				}
			}
		}
	}
}

.blog-content-grid, .blog-content-card{
	display: flex;
	flex-wrap: wrap;
}

/************************************************************************************************
    POST SINGLE
*************************************************************************************************/
.content-image-single{
	position:relative;
	margin: 0 0 50px;
	.content-info{
		text-align:center;
		position: relative;
		padding-bottom: 40px;
		&:after{
			position: absolute;
			content: "";
			bottom: 0;
			left: 50%;
			@include transform(translateX(-50%));
			@include size(210px, 1px);
			background: #e2e2e2;
		}
	}
	.single-thumb{
		margin-bottom: 50px;
		img{
			width:100%;
		}
	}
	.entry-title{
		font-size: 50px;
		word-break: break-word;
		margin:0 auto 15px;
		max-width: 920px;
		line-height: 1.2;
		@media (max-width:$screen-xs-max){
			font-size:calc(var(--font-size-heading,25px) + 5px);
		}
	}
	.entry-meta-head{
		font-size:var(--font-size-body,$font-size-body);
	}
	.entry-date{
		display: inline-block;
	}
	.byline,.published:not(.updated) {
		display: none;
	}
	.post-categories{
		margin-bottom: 15px;
		padding: 0;
		list-style: none;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		li{
			margin: 4px;
		}
		a{
			border: 1px solid $border-btn;
			padding: 0 15px;
			line-height: calc(var(--font-size-body,14px) + 16px);
			display: inline-block;
			font-size: calc(var(--font-size-body,14px) - 2px);
			font-weight: 500;
			text-transform: uppercase;
			@include transition(all .3s ease);
			&:hover{
				background: var(--theme-color,$theme-color);
				border-color: var(--theme-color,$theme-color);
				color: $white;
			}
		}
	}
	.entry-by{
		color:var(--text-color,$text-color);
		font-size:calc(var(--font-size-body,14px) - 1px);
		font-weight: 400;
		a{
			color: var(--text-color,$text-color);
			&:hover{
				color: var(--theme-color,$theme-color);
			}
		}
		>*{
			display:inline-block;
		}
		.entry-author{
			a{
				color:var(--text-color,$text-color);
				&:hover{
					color: var(--theme-color,$theme-color);
				}
			}
			&:after{
				content:"";
				width:1px;
				height:18px;
				margin:0 11px;
				display:inline-block;
				background:#dedede;
				vertical-align: middle;
			}
		}
		i{
			font-size:calc(var(--font-size-body,14px) + 1px);
			color:var(--gray-dark,$gray-dark);
			position:relative;
			top:1px;
			@include rtl-margin-right(5px);
		}
	}
	&.no-thum{
		margin:0 0 25px;
		.content-info{
			margin-bottom:20px;
		}
	}
}
.post-related{
	margin-top: 20px;
	border-top: 1px solid var(--border-color,$border-color);
	.entry-post{
		text-align:center;
		&:hover{
			.entry-thumb{
				img{
					@include scale(1.1);
				}
			}
		}
	}
	.type-post{
		margin-bottom: 35px;
	}
	.updated{
		font-size:var(--font-size-body,$font-size-body);
		color: var(--text-color,$text-color);
		@include transition(all .3s ease);
		&:hover{
			color: var(--theme-color,$theme-color);
		}
	}
	.title-block{
		padding-top: 40px;
		h2{
			margin:0 0 40px;
			font-size:calc(var(--font-size-heading,25px) + 5px);
			text-align: center;
		}
	}
	.entry-thumb{
		position:relative;
		margin-bottom:15px;
		overflow:hidden;
		img{
			@include transition(all 0.5s ease);
			@include scale(1);
		}
		span.entry-date{
			&:before{
				display:none;
			}
		}
		img{
			width:100%;
			object-fit: cover;
		}
	}
	&:last-child{
		margin-bottom: 0;
		border-bottom:0;
		padding-bottom:0;
	}
	.entry-date{
		color: #fff;
		position: absolute;
		font-weight: 700;
		left: 30px;
		bottom: 20px;
		z-index:10;
	}
	.post-categories{
		@include rtl-right(20px);
		left: auto;
	}
	.post-content{
		@include rtl-text-align-left();
		margin: 0;
		.entry-title{
			font-size:var(--font-size-heading,$font-size-heading);
		}
	}
	@media(max-width: $screen-md-max){
		.slick-list{
			margin: 0 -7.5px;
			.slick-slide{
				padding: 0 7.5px;
			}
		}
	}
}
.comments-area{
	display: inline-block;
    width: 100%;
    > ol, > ul{	
		> li{
			padding: 0 !important;
			&.bypostauthor{
				font-style: normal;
				font-size: 13px;
			}
		}
    }
	ul{list-style : disc;}
	ol{list-style : decimal;}
	.comments-title{
		font-size: 30px;
		margin-bottom: 0;
		color:var(--gray-dark,$gray-dark);
		margin-top:55px;
		text-align:center;
		@media (max-width:$screen-xs-max){
			font-size:var(--font-size-heading,$font-size-heading);
		}
	}
	.comment-navigation{
		display : inline-block;
		width : 100%;
		margin-bottom:50px;
		>div{
			font-weight: 400;
			text-transform: uppercase;
			@media(max-width:$screen-xs-max){
				font-size:calc(var(--font-size-body,14px) - 2px);
			}
			a{
				color:$white;
				position:relative;
				padding: 0 20px;
				background:var(--theme-color,$theme-color);
				display:inline-block;
				line-height:40px;
				@include transition(all 0.3s ease);
				@media(max-width:$screen-xs-max){
					padding:0 15px;
				}
				&:before{
					display:none;
					@include transition(all 0.3s ease);
					@include opacity-h();
					position:absolute;
					content:"\23";
					font-family:ElegantIcons;
					@include rtl-left(-15px);
					top:0;
					font-size:calc(var(--font-size-body,14px) + 2px);
					line-height: 40px;
					color:$white;
					@media(max-width:$screen-xs-max){
						font-size: 13px;
					}
				}
				&:hover{
					color:$white;
					background:darken($theme-color,10%);
					background:var(--theme-color);
				}
			}
			&:empty{
				display:none;
			}
		}
		.nav-previous{
			@include rtl-float-left();
		}
		.nav-next{
			@include rtl-float-right();
			a{
				&:before{
					content:"\24";
					@include rtl-left(auto);
					@include rtl-right(0);
				}
				&:hover{
					color:$white;
					&:before{
						@include rtl-right(11px);
						@include rtl-left(auto);
						@include opacity-s();
					}
				}
			}
		}
	}
	.comment-list{
		list-style : none;
		padding : 0px;
		margin-top:42px;
		.children{
			list-style : none;
			@include rtl-padding-left(90px);
			.media{
				&:last-child{
					border-bottom:0;
				}
			}
			@media (max-width:$screen-xs-max){
				@include rtl-padding-left(0px);
			}
		}
		>.children{
			border-bottom: 1px solid var(--border-color,$border-color);
			margin-bottom:35px;
		}
		b.fn{ color: #252525; }
		.media{
			position: relative;
			margin-bottom: 40px;
			padding-bottom:35px;
			display:block;
			&:last-child{
				margin-bottom:0;
			}
			.media-left{
				@include rtl-float-left();
				img{
					margin:0;
					@include rtl-margin-right(25px);
					min-width: 120px;
					@include border-radius(50%);
					@media (max-width:$screen-xs-max){
						width:45px;
						min-width:45px;
					}
				}
			}
			.media-body{
				overflow:hidden;
				>.media-content{
					padding-bottom: 0;
					p:empty{
						display:none;
					}
					.media-silver{
						margin-top:-8px;
						a{
							font-size:var(--font-size-body,$font-size-body);
							line-height:18px;
							color: var(--text-color-75,rgba($text-color, 0.75));
							font-weight: 400;
							&:hover{
								color:var(--theme-color,$theme-color);
							}
						}
						.comment-edit-link{
							&:before{
								content:"-";
								display:inline-block;
								margin:0 10px;
								color:$light-gray;
							}
						}
					}
					h2.media-heading{
						line-height:1.8;
						font-size:calc(var(--font-size-body,14px) + 2px);
						margin-bottom: 7px;
						margin-top:0;
						a{
							color: var(--gray-dark,$gray-dark);
							&:hover{
								color : var(--theme-color,$theme-color);
							}
						}
					}
					.media-silver{
						margin-bottom:7px;
						display:block;
						font-size:var(--font-size-body,$font-size-body);
						i{
							@include rtl-margin-right(5px);
						}
					}
					.comment-content{
						color:var(--text-color,$text-color);
						font-weight:400;
						p{
							margin:0;
						}
						@media (max-width:$screen-xs-max){
							margin-top:15px;
						}
					}
					.comment-reply-link{
						color: var(--gray-dark,$gray-dark) !important;
						position: relative;
						@include rtl-padding-left(10px);
						@include rtl-margin-left(5px);
						&:before{
							position: absolute;
							content: "";
							@include size(1px, 100%);
							background: var(--text-color,$text-color);
							left: 0;
							top: 0;
							@include transform(rotate(20deg));
						}
						&:hover{
							color: var(--theme-color,$theme-color) !important;
						}
					}
				}
				@media (max-width:$screen-xs-max){
					overflow:unset;
					.comment-text{
						table,.table{
							td,th{
								padding:10px 5px;
							}
						}
					}
				}
			}
		}
	}
	.comment-form,.comment-respond{
		a{
			color: var(--gray-dark,$gray-dark);
			position: relative;
			&:after{
				content: "";
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				@include size(0 ,1px);
				background: var(--gray-dark,$gray-dark);
				@include transition(width .48s cubic-bezier(.77,0,.175,1));
			}
			&:hover{
				&:after{
					width: 100%;
				}
			}
		}
		.comment-notes, .logged-in-as{
			margin-bottom: 25px;
		}
		.comment_reply_header{
			h3{
				font-size: 30px;
				margin-top:0;
				margin-bottom: 10px;
				color:var(--gray-dark,$gray-dark);
				@media (max-width:$screen-xs-max){
					font-size:var(--font-size-heading,$font-size-heading);
				}
			}
		}
		.comment-form-cookies-consent{
			display:inline-block;
			padding:0 15px; 
			input{
				@include rtl-margin-right(5px);
			}
		}
		.form-group{
			position: relative;
			margin-bottom: 30px;
			.form-control{				
				height: 47px;
				padding: 10px 25px;
				border: none;
				@include placeholder(var(--text-color-75,rgba($text-color, 0.75)));
				border: 1px solid var(--border-color,$border-color);
				&:focus{
					border-color:var(--gray-dark,$gray-dark);
				}
			}
			textarea.form-control{
				height: auto;
			}
			>#comment{
				margin-top: 15px;
				@include box-shadow(unset);
			}
			input[type="submit"]{
				@include border-radius(0);
				color: $white;
				padding: 15px 35px;
				font-weight: 500;
				font-size:var(--font-size-body,$font-size-body);
				text-transform: uppercase;
				background: var(--button-color,$gray-dark);
				position: relative;
				@include transition(all .3s ease);
				&:hover{
					background: var(--theme-color,$theme-color);
				}
			}
			.author, .email{
				position: relative;
				input + label {
					color: var(--text-color-75,rgba($text-color, 0.75));
					position: absolute;
					left: 25px;
					top: 50%;
					font-weight: 400;
					@include transform(translateY(-50%));
					margin: 0;
					cursor: text; 
				}
				
				input[required] + label:after {
					content:'*';
					color: red;
					position: relative;
					@include rtl-right(-3px);
				}
				
				
				input[required]:invalid + label {
					display: inline-block;
				}
				
				input[required]:valid + label{
					display: none;
				}
			}
			.required{
				color: red;
				position: absolute;
				@include rtl-right(20px);
				top: 17px;
			}
		}
	}
	.comment-form{
		margin-top:55px;
		text-align:center;
	}
	.comment-respond{
		margin-top:50px;
	}
	.no-comments{
		font-weight: 500;
		color: var(--gray-dark,$gray-dark);
		margin-top : 25px;
	}
}
.content-single-sidebar{
	.post-single:not(.col-xl-12, .col-lg-12){
		@media(min-width:$screen-md){
			@include rtl-padding-left(35px);
		}
	}
}
.post-single{
	&.col-xl-12.col-lg-12{
		max-width:1300px;
		margin-left:auto;
		margin-right:auto;
	}
	.type-post {
		margin-bottom: 0;
	}
	.entry-meta{
		.entry-meta-link{
			a{
				text-transform: capitalize;
			}
			img{
				@include border-radius(50%);
				@include rtl-margin-right(10px);
			}
		}
	}
	.format-gallery{ 
		.post-content{ 
			.post-excerpt{
				display: inline-block;
				margin : 0 -5px;
				>div.gallery{
					display: inline-block;
					width: 100%;
					vertical-align: top;
					margin-bottom: 20px;
				}
				p,h2{
					padding:0 5px;
				}
				.page-links{
					padding:0 5px;
				}
				.gallery-icon{
					a{
						pointer-events: none;
					}
				}
			}
		}
	}
	.format-video{
		iframe{
			width: 100%;
		}
	}
	.prevNextArticle{
		padding:30px 0;
		display: inline-block;
		width: 100%;
		border-bottom:1px solid var(--border-color,$border-color);
		position:relative;
		&:before{
			content:"";
			position:absolute;
			left:50%;
			top:calc(50% - 39px);
			@include size(1px,78px);
			background:var(--border-color,$border-color);
		}
		.hoverExtend{
			font-size:calc(var(--font-size-body,14px) - 2px);
			text-transform:uppercase;
			margin-bottom:5px;
		}
		.previousArticle,.nextArticle{
			width:50%;
			@include rtl-float-left();
			a{
				color:$light-gray;
				font-size:calc(var(--font-size-body,14px) + 1px);
				text-transform:capitalize;
				display:block;
				.title{
					overflow-wrap: break-word;
					margin:0;
					font-size:calc(var(--font-size-body,14px) + 6px);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-transform: uppercase;
				}
				&:hover{
					.title{
						color:var(--theme-color,$theme-color);
					}
					.hoverExtend{
						color:var(--theme-color,$theme-color);
					}
				}
			}
		}
		.previousArticle{
			@include rtl-padding-right(15px);
		}
		.nextArticle{
			@include rtl-text-align-right();
			@include rtl-float-right();
			@include rtl-padding-left(15px);
		}
		@media (max-width:$screen-xs-max){
			&:before{
				display:none;
			}
			.previousArticle,.nextArticle{
				width:100%;
				margin:5px 0;
				padding:0;
				a{
					.title{
						font-size:calc(var(--font-size-body,14px) + 4px);
					}
				}
			}
		}
	}
	.post-content-entry{
		text-align: center;
		flex-wrap: wrap;
		margin-top:0;
		padding-bottom: 55px;
		width:100%;
		border-bottom:1px solid var(--border-color,$border-color);
		&:empty{
			display:none;
		}
		.entry-social-share{
			margin-bottom:0;
			margin-top:20px;
			label{
				margin:0;
				color:var(--gray-dark,$gray-dark);
			}
			.social-share {
				display: inline-block;
				a {
					display: inline-block;
					@include square(40px);
					@include rtl-margin-right(5px);
					text-align:center;
					line-height:40px;
					border:1px solid var(--border-color,$border-color);
					i{
						text-align: center;
						font-size: 13px; 
						color:var(--gray-dark,$gray-dark);
					}
					&:hover{
						background:var(--theme-color,$theme-color);
						border-color:var(--theme-color,$theme-color);
					}
					&:last-child {
						@include rtl-margin-right(0);
					}
				}
			}
		}
		.tags-links{
			margin-top:20px;
			label{
				margin:0;
				color:var(--gray-dark,$gray-dark);
			}
			a{
				font-size: calc(var(--font-size-body,14px) - 2px);
				font-weight: 500;
				text-transform: uppercase;
				color: var(--gray-dark,$gray-dark);
				line-height:calc(var(--font-size-body,14px) + 16px);
				margin: 3px;
				border:1px solid var(--border-color,$border-color);
				display:inline-block;
				padding:0 25px;
				background:transparent;
				white-space:nowrap;
				position: relative;
				&:before{
					position: absolute;
					content: "";
					background: var(--theme-color,$theme-color);
					width: 0;
					height: 100%;
					top: 0;
					left: 0;
					@include transition(width .5s ease);
					z-index: -1;
				}
				&:hover{
					color: $white;
					border-color:var(--theme-color,$theme-color);
					&:before{
						width: 100%;
					}
				}
			}
		}
	}
	.edit-link{
		display:block;
		color: $light-gray;
		font-weight: 400;
		text-transform: inherit;
		margin-top:29px;
		padding-bottom:27px;
		border-bottom:1px solid var(--border-color,$border-color);
		a{
			color:darken($theme-color,10%);
			color:var(--theme-color);
			@include rtl-margin-right(15px);
			font-weight: 700;
		}
		&:before{
			display:none;
		}
	}
 	.post-content{ 
 		margin-bottom: 65px;
		font-size:calc(var(--font-size-body,14px) + 1px);
		.post-excerpt{
			.first-letter{
				font-size:40px;
				color:var(--gray-dark,$gray-dark);
				@include square(60px);
				border:2px solid var(--gray-dark,$gray-dark);
				display:inline-block;
				text-align:center;
				line-height:50px;
				@include rtl-float-left();
				@include rtl-margin-right(20px);
				position: relative;
				top: 4px;
			}
			h3{
				margin-top: 30px;
			}
			strong{
				color:var(--gray-dark,$gray-dark);
				font-weight:600;
			}
			figure{
				word-break: break-word;
				&.wp-block-image{
					text-align:center;
					margin-top: 45px;
				}
			}
			iframe{
				margin-top: 20px;
				width:100%;
			}
			ul.list-type-blog{
				margin-bottom: 30px;
				list-style-type: none;
				padding: 0;
				li{
					padding: 10px 0;
					&:before{
						content: "\4e";
						color: var(--theme-color,$theme-color);
    					font-family: 'ElegantIcons';
    					@include rtl-margin-right(10px);
					}
					&:last-child{
						padding-bottom: 0;
					}
				}
			}
			.post-password-form{
				margin-bottom:-10px;
				label{
					margin-bottom:0;
				}
				input[type="password"]{
					padding: 5px 15px;
				}
				input[type="submit"]{
					background: var(--theme-color,$theme-color);
					color: $white;
					padding: 5px 15px;
					@include rtl-margin-left(-5px);
					border: 1px solid var(--theme-color,$theme-color);
					&:hover{
						background:darken($theme-color,10%);
						border-color:darken($theme-color,10%);
						background:var(--theme-color);
						border-color:var(--theme-color);
					}
				}
			}
		}
		h2.post-btn{
			@include rtl-float-right();
			margin: 0;
			.post-btn-more{
				text-transform: uppercase;
				@include btn-post-default();
				&:hover{color: #fff; background: var(--theme-color,$theme-color); border-color: var(--theme-color,$theme-color); }
			}
		}
		.entry-tag{
			@include rtl-float-left();
			@media (max-width: $screen-xs-max){
				@include rtl-float-left();
				width: 100%;
				margin-top: 10px;
			}
			a{
				color: var(--text-color,$text-color);
				&:hover{
					color: var(--theme-color,$theme-color);
				}
			}
			i{
				font-size:calc(var(--font-size-body,14px) + 3px);
				color: var(--gray-dark,$gray-dark);
				@include rtl-margin-right(20px);
				position:relative;
				&:after{
					position:absolute;
					content:":";
					@include rtl-right(-10px);
					color:var(--gray-dark,$gray-dark);
					font-weight:500;
				}
			}
			.title-tag{
				display:none;
			}
		}
	}
	.entry-meta-author{
		display:inline-block;
		padding:30px;
		background:#f5f5f5;
		margin-top:50px;
		width:100%;
		@include border-radius(8px);
		@media(max-width:$screen-xs-max){
			padding:15px;
		}
		.author-avatar{
			display:inline-block;
			vertical-align: middle;
			@media(max-width:$screen-xs-max){
				display: block;
				text-align:center;
			}
			.author-image{
				img{
					@include border-radius(50%);
				}
			}
		}
		.author-info{
			display: inline-block;
			vertical-align: middle;
			padding:0 20px;
			width: calc(100% - 105px);
			@media(max-width:$screen-xs-max){
				padding: 0;
				width: 100%;
				text-align: center;
				margin-top: 10px;
			}
			.author-link{
				display:block;
				margin-bottom:10px;
				a{
					font-size:calc(var(--font-size-body,14px) + 2px);
					font-weight: 700;
				}
			}
		}
	}
	&.one_column,&.simple_title,&.prallax_image{
		max-width:1055px;
		margin-left:auto;
		margin-right:auto;
	}
}
.content-single-simple_title{
	>.content-image-single{
		margin:0 0 50px;
		background:#f6f6f6;
		padding:90px 0;
		.content-info{
			max-width:1440px;
			padding-left:15px;
			padding-right:15px;
			margin:auto;
		}
	}
}
.content-single-sticky_title{
	.entry-thumb{
		background-attachment: fixed;
		background-size: cover;
		height:800px;
		margin-bottom:60px;
		background-position:center;
		&:before{
			content:"";
			position:absolute;
			@include square(100%);
			top:0;
			left:0;
			background:var(--gray-dark-25,rgba($gray-dark, 0.25));
		}
	}
	.content-info{
		@include rtl-text-align-left();
		margin:0;
		&:after{
			left: 0;
			@include transform(translateX(0));
		}
	}
	.content-image-single{
		margin:0;
		position:sticky;
		top: 45px;
		.post-categories{
			justify-content: flex-start;
		}
	}
	>.container{
		>.row{
			display:inline-block;
			>div{
				@include rtl-float-left();
			}
		}
	}
	@media (max-width:$screen-sm-max){
		.content-image-single{
			margin:0 0 30px;
			position:relative;
			top:0;
		}
		.entry-thumb{
			height:500px;
		}
	}
	@media (max-width:$screen-xs){
		.entry-thumb{
			height:350px;
		}
	}
}
.content-single-prallax_image{
	.content-image-single{
		.entry-thumb{
			background-attachment: fixed;
			background-size: cover;
			height:800px;
			margin-bottom:60px;
			background-position:center;
			position:relative;
			&:before{
				content:"";
				position:absolute;
				@include square(100%);
				top:0;
				left:0;
				background:var(--gray-dark-25,rgba($gray-dark, 0.25));
			}
		}
	}
	.content-image-single{
		margin:0 0 50px;
		position:relative;
		.entry-title{
			color:$white;
		}
		.cat-links{
			color:$white;
			a{
				color:$white;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
		}
		.entry-by{
			color:$white;
			i{
				color:$white;
			}
			a{
				color:$white;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			.entry-author:after{
				color:$white;
			}
		}
		.post-categories{
			a{
				border-color: $white;
				color: $white;
				span{
					&:after{
						background: $white;
					}
				}
				&:hover{
					border-color: var(--theme-color,$theme-color);
					background: var(--theme-color,$theme-color);
					color: $white;
				}
			}
		}
		.entry-meta-head{
			color: $white;
			a{
				color: $white;
			}
		}
		.entry-date{
			time{
				color: $white;
			}
		}
	}
	.content-info{
		width:100%;
		padding:50px 15px;
		bottom:0;
		left:0;
		position:absolute;
		margin:0;
		&:after{
			display: none;
		}
	}
	@media (max-width:$screen-sm-max){
		.entry-thumb{
			height:500px;
		}
	}
	@media (max-width:$screen-xs){
		.entry-thumb{
			height:350px;
		}
	}
	
}
.single-post-sticky_title,.single-post-prallax_image{
	.breadcrumb-noheading{
		display:none;
	}
}
/************************************************************************************************
    POST NAVIGATION
*************************************************************************************************/
.navigation.paging-navigation{
	@media(max-width:$screen-sm-max){
		margin-bottom:50px;
	}
	.screen-reader-text{ display: none; }
	.loop-pagination{
		display: inline-block;
	}
}

/*
* General Post Style using for all with naming class entry
*/

.entry-date{
	time{
		color:var(--text-color,$text-color);
		font-size:var(--font-size-body,$font-size-body);
		@include transition(all .3s ease);
	}
	&:hover{
		cursor:pointer;
		time{
			color:var(--theme-color,$theme-color);
		}
	}
}
.entry-content{
	.page-links{
		width: 100%;
		display: inline-block;
	}
}
.entry-vote{
	z-index: 1;
	display: table;
	text-align: center;
	top: 20px;
	position: absolute;
	background: rgba($black,.5);
	@include square(44px);
	@include rtl-right(20px);
	.entry-vote-inner{
		color: $white;
		display: table-cell;
		vertical-align: middle;
		font-weight: $headings-font-weight;
	}
	&.vote-perfect{
		.entry-vote-inner{
			color: $red;
		}
	}
	&.vote-good{
		.entry-vote-inner{
			color: $yellow;
		}
	}
	&.vote-average{
		.entry-vote-inner{
			color: #91e536;
		}
	}
	&.vote-bad{
		.entry-vote-inner{
			color: $orange;
		}
	}
	&.vote-poor{
		.entry-vote-inner{
			color: $green;
		}
	}
}
.entry-image{
	margin: -1px;
	display: block;
}
.entry-thumb{
	position: relative;
}
.entry-title{
	margin-top: 0;
	line-height: $entry-title-line-height;
	text-transform: $entry-title-transform;
}
.entry-content-inner{
	padding: $theme-padding;
}
.type-post{
	margin-bottom: $post-type-margin;
}
.blog-type{
	.entry-image{
		@include size(auto,169px);
		img{
			width: auto;
		}
	}
}

/* Using for new, magazine ---------------------------------------------------------------*/
.post-type{

}
.post-title{
	@include font-size(font-size,$entry-title-font-size);
}
.post-thumb{
	position: relative;
}
.blog-meta{
	top: 0;
	position: absolute;
	@include rtl-right($entry-meta-position);
}

.single-ourteam-content{
	.ourteam-content{
		@include rtl-padding-left(20px);
		@media (max-width:$screen-sm-max){
			margin-top:50px;
			@include rtl-padding-left(0);
		}
	}
	.entry-thumb{
		text-align:center;
	}
	.ourteam{
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}
	.entry-title{
		font-size:calc(var(--font-size-heading,25px) + 5px);
		text-transform:capitalize;
		margin:0;
		color:var(--gray-dark,$gray-dark);
		line-height:1;
		margin-bottom:45px;
	}
	.team-job{
		margin-bottom:15px;
		color:var(--theme-color,$theme-color);
	}
	.social-link{
		margin-top:20px;
		li{
		margin:0 7px;
			a{
				i{
					color:var(--text-color,$text-color);
					font-size:var(--font-size-heading,$font-size-heading);
				}
				&:hover{
					i{
						color:var(--theme-color,$theme-color);
					}
				}
			}
		}
	}
}
body.single-post.elementor-page #bwp-main > .container{
	max-width:1440px;
}
