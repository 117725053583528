// 404 page
// -------------------------
$error-title-font-size      : 80px !default;
$error-title-margin         : 0 !default;
$error-title-letter-spacing : 15px !default;

$error-content-font-size    : 28px !default;

// Title All Page
// -------------------------
$page-title-margin          : 0 0 90px !default;