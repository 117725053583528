/*Font WooCommerce*/
/* Wooecommerce - Button - Input Submit
------------------------------------------------*/
.woocommerce #respond input#submit, .woocommerce button.button, .woocommerce input.button{
	color:$white;
	background:var(--button-color,$gray-dark);
	font-size:calc(var(--font-size-body,14px) + 1px);
	padding:0 50px;
	line-height:50px;
	cursor:pointer;
	border : 0;
	text-transform: uppercase;
	font-weight: 500;
	@include transition(all .3s ease);
    &:hover{
		background: var(--theme-color,darken($theme-color, 10%));
    }
	&:focus{
		outline : none;
	}
}

.button, #submit {
	&.loading {
		&:before {
			content: "";
			@include vertical-center(100%,100%);
			background: url('#{$image-theme-path}loading-cart.gif') center no-repeat transparent;
		}
	}
}

/* End
------------------------------------------------*/
.clear-all{
    @extend .btn, .btn-link;
    &:hover{
        text-decoration: none !important;
    }  
}
/*Messenger
-------------------------------------------------
*/
.mafoil-product-added{
    background: var(--gray-dark,$gray-dark);
    line-height: 1.5;
	@include box-shadow(unset);
	color:$white;
	top:50px;
	left:auto;
	right:30px;
    padding: 15px 25px;
	font-weight:400;
	position:fixed;
	z-index:9999;
	>div{
		display:inline-block;
		&:before{
			display:inline-block;
			content: "\e91d";
			font-family:wpbingofont;
			@include rtl-margin-right(10px);
		}
		
	}
	@media (max-width:$screen-xs){
		right:50%;
		@include transform(translateX(50%));
		max-width:280px;
	}
}
.woocommerce-error,
.alert-success, .woocommerce-message {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
    list-style: none;
    .wc-forward{
    	background: var(--theme-color,$theme-color);
	    color: #fff;
	    padding: 8px 12px;
		font-weight:400;
		font-size:var(--font-size-body,$font-size-body);
	    &:hover{
	    	background: darken($theme-color, 10%);
			background: var(--theme-color);
			color:$white;
	    }
		&:before{
			display:none;
		}
		&:after{
			content: "\24";
			font-family: eleganticons;
			@include rtl-margin-left(10px);
			font-size:calc(var(--font-size-body,14px) + 4px);
			line-height: 1;
			position: relative;
			top: 4px;
		}
    }
}
//WooCommerce Info
.woocommerce-notices-wrapper{
	max-width:1410px;
	margin:auto;
	width:100%;
}
.woocommerce-error,.woocommerce-info,.woocommerce-message {
	@include rtl-padding(12px,22px,12px,42px);
	@include rtl-margin(0,0,24px,0);
	position: relative;
	background-color: #f7f6f7;
	color: #515151;
	border-top: 3px solid #a46497;
	list-style: none outside;
	width: auto;
	word-wrap: break-word;
	border-top-color: #b81c23;
	width : 100%;
	.button {
		@include rtl-float-right();
	}
	li {
		list-style: none outside!important;
		@include rtl-padding-left(0!important);
		@include rtl-margin-left(0!important);
	}
}

.woocommerce-info,.woocommerce-message,.woocommerce-error{
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

.woocommerce-info,.woocommerce-message,.woocommerce-error{
	&:before {
		content: "\e97c";
		display: table;
		font-family: feather;
		display: inline-block;
		position: absolute;
		top: 8px;
		font-size : 18px;
		@include rtl-left(20px);
		color: #b81c23;
	}
}
.woocommerce-info,.woocommerce-message{
	border-top: 3px solid var(--gray-dark,$gray-dark);
	text-transform:capitalize;
	z-index: 100;
	&:before {
		content: "\e902";
		font-family:"feather";
		color:var(--gray-dark,$gray-dark);
		font-size:calc(var(--font-size-body,14px) + 2px);
		@include rtl-margin-right(10px);
		line-height:1;
		top: 15px;
		margin-bottom:15px;
	}
	a{
		color : var(--gray-dark,$gray-dark);
		font-weight : 700;
		&:hover{
			color : var(--theme-color,$theme-color);
		}
	}
	.button.wc-forward{
		background:transparent;
		line-height:29px;
		border:1px solid var(--gray-dark,$gray-dark);
		color:var(--gray-dark,$gray-dark);
		padding:0;
		padding:0 15px 0 20px;
		font-size:var(--font-size-body,$font-size-body);
		text-transform: uppercase;
		font-weight: 500;
		&:after{
			content: "\35";
			font-size:calc(var(--font-size-body,14px) + 1px);
			top:2px;
			@include rtl-margin-left(5px);
		}
		&:hover{
			background:var(--theme-color,$theme-color);
			color:$white;
			border-color:var(--theme-color,$theme-color);
		}
	}
}
/* 
	End
*------------------------------------------------
*/
.category-image{
    img{
        @extend .filter-grayscale;
    }
}

p.demo_store {
	top: 0;
	position:fixed;
	@include rtl-left(0);
	@include rtl-right(0);
	@include size(percentage(1),auto);
	@include rtl-text-align-center();
    @include font-size(font-size,$font-size-md);
	padding: .5em 0;
	z-index: 99998;
	border: 1px solid var(--border-color,darken($border-color, 10));
	@include box-shadow(0, 0, 0, 3px, rgba($white,0.2));
}

/*
    Utilities
*---------------------------------------------------------------------------
*/
.woocommerce{
	.wc-forward, .wc-forward a {
		&:before {
			content: "\f00c";
			font-family: $theme-font-icon-name;
			@include rtl-margin(0, 5px, 0, 0);
		}
	}
	.wc-backward, .wc-backward a {
		line-height:35px;
		background:var(--gray-dark,$gray-dark);
		color:$white;
		padding:0 20px;
		display:inline-block;
		text-transform:uppercase;
		font-size:var(--font-size-body,$font-size-body);
		font-weight:500;
		height:40px;
		@include transition(all .3s ease);
		&:before {
			content: "\23";
			font-size:calc(var(--font-size-body,14px) + 4px);
			font-family: 'ElegantIcons';
			@include rtl-margin(0, 5px, 0, 0);
			position:relative;
			top:4px;
		}
		&:hover{
			background: var(--theme-color,$theme-color);
		}
	}
	.return-to-shop{
		text-align:center;
	}
	.cart-empty.woocommerce-info{
		justify-content: center;
		font-size:calc(var(--font-size-heading,25px) + 5px);
		padding:0;
		display:flex;
		align-items:center;
		background:transparent;
		border:0;
		color: var(--gray-dark,$gray-dark);
		text-transform: uppercase;
		&:before{
			display:none;
		}
		@media (max-width:$screen-xs-max){
			font-size:calc(var(--font-size-body,14px) + 6px);
		}
	}
}

/*
    woocommerce tabs
*---------------------------------------------------------------------------
*/
.woocommerce-tabs{
    margin: $woocommerce-tabs-margin;
    table{
        margin: 0;
    }
    .tab-title{
    	@include font-size(font-size, 18px);
    	text-transform: uppercase;
		position: relative; 
		font-weight: 500;
    }
	@media(max-width: $screen-sm-max){
		.container-tab{
			margin-bottom: 10px;
			padding-bottom: 20px;
			border-bottom: 1px solid #e5e5e5;
			&:last-child{
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}
		}
		.tab-title{
			&:before{
				content: "";
				position: absolute;
				font-family: 'ElegantIcons';
				content: "\32";
				@include rtl-right(0);
				@include transition(all .3s ease);
				@include transform(rotate(180deg));
				font-weight: 400;
			}
			&.active{
				color: var(--theme-color,$theme-color);
				margin-bottom: 15px;
				&:before{
					@include transform(rotate(0));
					color: var(--theme-color,$theme-color);
				}
			}
		}
	}
	#tab-vendor{
		.product-vendor{
			padding:33px 50px 20px;
			h2{
				font-size:calc(var(--font-size-body,14px) + 6px);
			}
		}
	}
	#tab-wcmp_customer_qna{
		#cust_qna_form{
			padding:45px 50px 30px;
			h2{
				font-size:calc(var(--font-size-body,14px) + 6px);
				margin-bottom:25px;
			}
		}
	}
	#tab-singleproductmultivendor{
		padding:40px 50px;
	}
	.content-woocommerce-tabs{
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 15px;
		position: relative;
	}
	.content-ul-tab{
		text-align: center;
		margin-bottom: 45px;
		border-bottom: 1px solid #e9e9e9;
		@media(max-width: $screen-sm-max){
			display: none;
		}
	}
	.content-tab{
		padding-bottom: 45px;
		border-bottom: 1px solid #e9e9e9;
		.panel{
			padding:0;
		}
	}
	ul.tabs {
		position:relative;
		margin:0;
		padding: 0;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
		li{
			padding:0;
			border:0;
			margin: 0 30px;
			@media(max-width: $screen-xs){
				margin: 0 20px;
			}
			@include box-shadow(unset);
			&:before,&:after{
				display:none;
			}
			a{
				color: var(--text-color,$text-color);
				font-size:calc(var(--font-size-body,14px) + 4px);
				text-transform: uppercase;
				padding: 0 0 10px;
				border: 0;
				display: inline-block;
				position: relative;
				&:before{
					position: absolute;
					bottom: 0;
					content: "";
					left: 0;
					@include transform(scaleX(0));
					transform-origin: 100% 50%;
					@include transition(transform .3s cubic-bezier(.25,.46,.45,.94));
					@include size(100%, 1px);
					background: var(--gray-dark,$gray-dark);
				}
				@media (max-width:$screen-xs){
					font-size:calc(var(--font-size-body,14px) + 6px);
				}
			}
			&.active{
				background:transparent;
				a{
					font-weight: 500;
					color:var(--gray-dark,$gray-dark);
					&:before{
						@include transform(scaleX(1));
						transform-origin: 0 50%;
						transition-delay: .3s;
					}
				}
			}
			&:hover{
				a{
					color:var(--gray-dark,$gray-dark);
				}
			}
		}
	}
	.panel {
		background: transparent;
		@include rtl-text-align-left();
		line-height: 2em;
		margin-left:auto;
		margin-right:auto;
		&.woocommerce-Tabs-panel--seller{
			h2{
				margin-top:0;
				margin-bottom:30px;
				font-size:calc(var(--font-size-body,14px) + 4px);
				text-transform:uppercase;
			}
			.seller-rating{
				.star-rating{
					>span{
						>strong{
							opacity:0;
						}
					}
				}
			}
		}
		&.woocommerce-Tabs-panel--more_seller_product{
			border:0;
			ul{
				li{
					&:before{
						display:none;
					}
				}
			}
		}
		&.woocommerce-Tabs-panel--description{
			>.row{
				align-items:center;
				margin-bottom:70px;
				>div:last-child{
					.box-title{
						max-width:640px;
						@include rtl-margin-left(auto);
					}
					@media (max-width:$screen-sm-max){
						margin-top:30px;
					}
				}
				img{
					margin:0;
				}
			}
			h1{
				font-size:34px;
				margin-top:0;
				margin-bottom:75px;
			}
			h2{
				font-size:calc(var(--font-size-heading,25px) - 1px);
				margin:0 0 20px;
			}
			.box-title{
				margin-bottom:45px;
			}
			@media (max-width:$screen-xs){
				h1{
					font-size:var(--font-size-heading,$font-size-heading);
					margin-bottom:30px;
				}
				h2{
					font-size:calc(var(--font-size-body,14px) + 4px);
				}
			}
		}
		ul{
			padding: 0;     
			list-style: none;
		}
		ol{
			@include rtl-padding-left(15px);
			 li{
				margin-bottom: 5px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	#tab-seller{
		ul.list-unstyled{
			>li{
				&.clearfix{
					display: flex;
					.seller-rating{
						order: 2;
						display: inline-block;
						vertical-align: bottom;
						padding-top: 2px;
						@include rtl-margin-left(15px);
					}
					.text{
						&:after{
							content: ":";
							@include rtl-margin-left(3px);
						}
					}
				}
			}
		}
	}
	#tab-description{
		>ul{
			margin-bottom: 12px;
		}
	}
	#tab-additional_information{
		table.shop_attributes{
			border: none;
			p{
				margin: 0;
			}
			> tbody{
				> tr {
					background: $white;
					>th{
						text-transform: capitalize;
						font-weight:700;
						color:var(--gray-dark,$gray-dark);
					}
				}
			}
		}
	}
	#tab-reviews{
		#reviews{
			.woocommerce-verification-required{
				margin-top:20px;
			}
			#comments{
				margin-bottom: 15px;
				.woocommerce-Reviews-title{
					font-size: calc(var(--font-size-heading,25px) + 5px);
					margin: 0 0 30px;
					span{
						color: darken($theme-color, 10%);
						color: var(--theme-color);
					}
					@media(max-width: $screen-xs-max){
						font-size:var(--font-size-heading,$font-size-heading);
					}
				}
			}
			.commentlist{
				display:flex;
				flex-wrap:wrap;
				list-style:none;
				padding:0;
				margin:0 -15px;
				li{
					padding:0 15px;
					flex:1;
					min-width:50%;
					margin-bottom:25px;
					.description{
						color:var(--gray-dark,$gray-dark);
						line-height:22px;
						p{
							margin:0;
						}
					}
					.content_comment_container{
						border:1px solid #d9d9d9;
						padding:30px;
					}
					@media (max-width:$screen-xs-max){
						flex:0 0 100%;
					}
				}
				.comment-text{
					margin:0;
					@include rtl-padding-left(15px);
					.meta{
						margin:0;
						.woocommerce-review__author{
							display:block;
							color:var(--gray-dark,$gray-dark);
							margin-bottom:-5px;
						}
						.woocommerce-review__dash{
							display:none;
						}
						.woocommerce-review__published-date{
							font-size:calc(var(--font-size-body,14px) - 2px);
						}
					}
				}
				.comment_container{
					display:flex;
					align-items:center;
					flex-wrap:wrap;
					position:relative;
					margin-bottom:10px;
					>img{
						@include border-radius(50%);
						margin:0;
					}
				}
			}
			.button-reviews{
				background: transparent;
				cursor: pointer;
				padding: 0 25px;
				border: 1px solid var(--gray-dark,$gray-dark);
				position: relative;
				overflow: hidden;
				line-height: 45px;
				color: var(--gray-dark,$gray-dark);
				text-transform: uppercase;
				font-weight: 500;
				@include transition(all .3s ease);
				&:hover{
					color: $white;
					background: var(--theme-color,$theme-color);
					border-color: var(--theme-color,$theme-color);
				}
			}
			#review_form_wrapper{
				&.modal{
					position: fixed;
					height: 100%;
					right: 0;
					top: 0;
					z-index: 100000;
					@include transform(translateX(107%));
					top: 0;
					background: $white;
					max-width: 80%;
					overflow: hidden;
					@include transition(all .8s cubic-bezier(0.2,1,.3,1));
					#review_form{
						@include transform(translateX(-107%));
						height: 100%;
						padding: 40px;
						min-height: calc(100vh - 80px);
						overflow-y: auto;
						@include transition(all .8s cubic-bezier(0.2,1,.3,1));
					}
					&.open{
						@include transform(translateX(0));
						#review_form{
							@include transform(translateX(0));
						}
					}
					@media(min-width: 1000px){
						max-width: 50%;
					}
				}
				.close-btn{
					$size: 35px;
					height: $size;
					width: $size;
					position: absolute;
					top: 30px;
					@include rtl-right(40px);
					box-sizing: border-box;
					line-height: $size;
					display: inline-block;
					@include border-radius(50%);
					background: $white;
					box-shadow: 0px 1px 12px 2px var(--gray-dark-10,rgba($gray-dark, 0.1));
					cursor: pointer;
					&:before, &:after {
						$width: 15px;
						$height: 1px;
						@include transform(rotate(-45deg));
						content: '';
						position: absolute;
						top: 50%;
						left: calc(50% - 8px);
						display: block;
						height: $height;
						width: $width;
						background-color: var(--gray-dark,$gray-dark);
						@include transition(all .3s ease-out);
					}
					&:after {
						@include transform(rotate(-135deg));
					}
					&:hover{
						&:before, &:after {
							@include transform(rotate(0deg));
						}
					}
					@media(max-width: $screen-md-max){
						top: 15px;
						@include rtl-right(15px);
					}
				}
				@media(max-width: $screen-xs-max){
					&.modal{
						#review_form{
							padding: 70px 30px 30px;
						}
					}
					.close-btn{
						top: 15px;
						@include rtl-right(25px);
					}
				}
			}
			.close-reviews-form{
				position: fixed;
				content: "";
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				margin:0;
				z-index: 9999;
				background: rgba(0,0,0,.4);
				display: none;
				-webkit-transition: opacity .4s cubic-bezier(0,.8,1,1);
				transition: opacity .4s cubic-bezier(0,.8,1,1);
				&.open{
					display: block;
				}
			}
			#respond{
				position: static;
				margin: 0;
				width: auto;
				padding: 0;
				background: transparent none;
				border: 0;
				#reply-title{
					font-weight: 500;
					color: var(--gray-dark,$gray-dark);
					font-size:var(--font-size-body,$font-size-body);
					text-transform: uppercase;
					margin-bottom: 40px;
					display: inline-block;
					border:2px solid var(--gray-dark,$gray-dark);
					line-height:45px;
					padding:0 35px;
					@media(max-width: $screen-md-max){
						padding: 0 15px;
					}
					@media(max-width: $screen-xs-max){
						line-height: 30px;
					}
				}
				.comment-notes{
					margin-bottom:15px;
				}
				.comment-form {
					.comment-form-rating label{
						font-weight: 500;
						@include rtl-margin-right(0);
						margin-bottom: 10px;
						color:var(--gray-dark,$gray-dark);
					}
				}
				.form-submit{
					margin: 0;
					width: 100%;
				}
				input[type="submit"]{
					background: var(--gray-dark,$gray-dark);
					font-size:calc(var(--font-size-body,14px) + 2px);
					text-transform: uppercase;
					font-weight: 500;
					padding: 0 50px;
					height: 50px;
					color:$white;
					cursor:pointer;
					@include transition(all .3s ease);
					@include border-radius(0 !important);
					&:hover{
						background: var(--theme-color,$theme-color);
					}
				}
				textarea{
					height: 100% !important;
				}
				input[type="text"], input[type="email"], textarea{
					background: $white;
					padding: 14px 20px;
					border: 1px solid #d6d6d6;
					height: 60px;
					font-weight: 300;
					@include placeholder(var(--text-color,$text-color));
				}
				#commentform{
					display:flex;
					flex-wrap:wrap;
					margin:0 -10px;
					.comment-notes,.comment-form-rating{
						width:100%;
						padding:0 10px;
					}
					.comment-form-comment{
						padding:0 10px;
						margin:0 0 30px;
						width: 100%;
					}
					.content-info-reviews{
						padding:0 10px;
						margin:0;
						width: 100%;
						display: flex;
						flex-wrap: wrap;
					}
					.required{
						color:red;
					}
					.comment-form-author, .comment-form-email{
						width: 50%;
						margin: 0 0 30px!important;
						input{
							width: 100%;
						}
					}
					.comment-form-author{
						@include rtl-padding-right(15px);
					}
					.comment-form-email{
						@include rtl-padding-left(15px);
					}
					.stars{
						padding-top: 0;
						display:inline-block;
						@include rtl-margin-left(20px);
						margin-bottom:7px;
						a{
							&:hover,&.active{
								&:after{
								  color: $star-rating-active-color;
								}
								color: $star-rating-active-color;
							}
						}
						@media(max-width: $screen-xs-max){
							@include rtl-margin-left(15px);
						}
					}
				}
				@media(max-width: $screen-xs-max){
					#reply-title{
						padding: 0 20px;
					}
					#commentform{
						.content-info-reviews{
							>*{
								width: 100%;
								padding: 0;
							}
						}
					}
				}
			}
		}
	}
	#tab-vendor{
		h2{
			text-transform: capitalize;
		}
		a{
			color: var(--theme-color,$theme-color);
			position: relative;
			&:after{
				content:"\f178";
				font-family: FontAwesome;
				display: inline-block;
				top: -4px;
				@include rtl-right(0);
				opacity: 0;
				position: absolute;
				@include transition(all 0.2s ease);
			}
			&:hover{
				&:after{
					opacity: 1;
					@include rtl-right(-20px);
				}
			}
		}
	}
	#tab-wcmp_customer_qna{
		input{
			padding: 10px 20px;
		}
	}
	.woocommerce-Tabs-panel--singleproductmultivendor{
		.rowbody{
			background : $white;
			.rowsub:nth-of-type(3){
				@include rtl-text-align-right();
			}
			&:last-child{
				border-top:none;
			}
		}
		.row.rowhead {
			background : transparent;
			>.rowsub{
				font-size : 14px;
				text-transform : uppercase;
				font-weight : 700;
				color : var(--text-color,$text-color);
				background : #f9f9f9;
				line-height :35px;
			}
		}
		.container{
			.vendor_name{
				@include rtl-float-left();
				a{
					color: var(--text-color,$text-color);
					&:hover{
						color: var(--theme-color,$theme-color);
					}
				}
			}
			.buttongap{
				margin : 0 10px;
				&:last-child{
					margin : 0px;
				}
			}
		}
	}
	&.description-style-vertical{
		padding:45px 0 75px;
		.content-woocommerce-tabs{
			display:flex;
		}
		.content-tab{
			@include rtl-padding-left(90px);
			flex:1;
			.woocommerce-Reviews-title{
				margin-top:0;
			}
		}
		.content-ul-tab{
			padding:0;
			flex:0 0 240px;
			@include rtl-text-align-left();
			margin:0;
			ul{
				padding:0;
				height:100%;
				@include rtl-border-right(3px solid #e9e9e9);
				overflow: unset;
				white-space: inherit;
				li{
					margin:0;
					width:100%;
					padding:19px 0;
					position:relative;
					&:before{
						content:"";
						@include size(3px,100%);
						background:var(--gray-dark,$gray-dark);
						position:absolute;
						top:0;
						right:-3px;
						left:auto;
						@include box-shadow(unset);
						border:0;
						@include border-radius(0);
						display:inline-block;
						@include opacity-h();
					}
					a{
						color:var(--gray-dark,$gray-dark);
						padding:0;
						&:before{
							height:1px;
						}
					}
					&.active{
						&:before{
							@include opacity-s();
						}
					}
				}
			}
		}
		@media (max-width:$screen-sm-max){
			.content-woocommerce-tabs{
				flex-wrap:wrap;
			}
			.content-ul-tab{
				flex:0 0 100%;
				margin-bottom:50px;
				ul{
					border:0;
					text-align:center;
					li{
						padding:0;
						margin:0 20px;
						width:auto;
						padding:5px 0;
						&:before{
							left:0;
							bottom:-3px;
							top:auto;
							@include size(100%,3px);
						}
					}
				}
			}
			.content-tab{
				padding:0;
			}
		}
		@media (max-width:$screen-sm-max){
			.content-ul-tab{
				margin-bottom:50px;
				ul{
					border:0;
					text-align:center;
					li{
						padding:0;
						margin:2.5px 0;
						width:100%;
						padding:5px 0;
						background:#f5f5f5;
						&:before{
							display:none;
						}
						a{
							color:var(--text-color,$text-color);
						}
						&.active{
							a{
								color:var(--gray-dark,$gray-dark);
							}
						}
					}
				}
			}
		}
	}
	&.description-style-accordion{
		border:0;
		padding:0;
		margin:65px 0 0;
		.content-woocommerce-tabs{
			padding:0;
		}
		.accordion-item{
			margin-bottom:25px;
			padding-bottom: 5px;
			border-bottom:1px solid #e5e5e5;
			&:last-child{
				margin-bottom:0;
				padding-bottom: 0;
				border-bottom: 0;
				.collapse{
					padding:30px 0 0;
					border-bottom:0;
				}
			}
			a{
				border:0;
				background:transparent;
				font-size:calc(var(--font-size-body,14px) + 6px);
				padding:0;
				cursor:pointer;
				width:100%;
				@include rtl-text-align-left();
				position:relative;
				display:inline-block;
				font-weight:400;
				&:before{
					content:"";
					position:absolute;
					font-family: 'ElegantIcons';
					content: "\32";
					@include rtl-right(0);
					@include transition(all 0.3s ease);
					@include transform(rotate(180deg));
					font-weight:400;
				}
				&.collapsed{
					&:before{
						@include transform(rotate(0));
					}
				}
			}
			.woocommerce-Reviews-title{
				margin-top:0;
			}
			.collapse{
				padding: 15px 0;
				@include transition(all 0.3s ease);
			}
		}
		#tab-reviews #reviews{
			.commentlist li{
				flex:0 0 100%;
			}
			#respond #commentform{
				.content-info-reviews{
					flex:0 0 100%;
					order:2;
				}
				.comment-form-comment{
					margin-bottom:25px;
				}
			}
		}
	}
	&.description-style-full-content{
		padding:45px 0 60px;
		.content-tab-woocommerce{
			>h2{
				font-size:calc(var(--font-size-body,14px) + 6px);
				margin:0 0 30px;
				position:relative;
				padding-bottom:10px;
				font-weight:400;
				&:before{
					content:"";
					position:absolute;
					bottom:0;
					@include rtl-left(0);
					@include size(32px,1px);
					background:var(--gray-dark,$gray-dark);
				}
				@media (max-width: $screen-sm-max){
					display: none;
				}
			}
			.tab-content{
				@include rtl-padding-left(240px);
				margin-bottom:60px;
				&:last-child{
					margin-bottom:0;
				}
				@media(min-width: 992px){
					display: block !important;
				}
			}
			.woocommerce-Reviews-title{
				margin-top:0;
			}
			@media (max-width:$screen-sm-max){
				.tab-content{
					padding: 15px 0;
				}
			}
		}
	}
	@media(max-width: $screen-sm-max){
		padding-top: 40px;
	}
}

#respond {
	line-height: 1.4em;
	.comments {
		margin-bottom: 15px;
	}
	.comment-form {
		label {
			font-weight: 400;
			color: var(--gray-dark,$gray-dark);
			margin-bottom: 20px;
			@include rtl-margin-right(10px);
		}
	}
	.comment-form-rating{
		margin-bottom:10px;
	}
	textarea {
		@include box-sizing(border-box);
	}
	p.stars {
		position: relative;
		padding: 5px 0;
		width:160px;
		position:relative;
		top:2px;
		@media(max-width: $screen-xs-max){
			width:100px;
		}
		span{
			display:inline-block;
		}
		&:hover,&.selected{
			a:after{
				color:#ff9000;
			}
		}
		a {
			@include inline-block();
			line-height: 100%;
			position: relative;
			font-size:0;
			width:auto;
			&:after {
				content: "\e90a";
				font-family: $theme-icon-font-name;
				font-size:calc(var(--font-size-body,14px) + 2px);
				font-weight: unset;
				text-indent: 0;
				color:#cecece;
			}
			&:last-child {
				@include rtl-margin-right(0);
			}
			&:hover~a::after{
				color:#cecece;
			}
			&.active~a::after{
				color:#cecece;
			}
		}
	}
}

/*
    Quantity inputs
*---------------------------------------------------------------------------
*/
.quantity {
	width    : auto;
	position : relative;
	margin   : 0 auto;
	overflow : hidden;
	zoom     : 1;
	input.qty {
		float: left;
		@include size(45px, 38px);
		text-align: center;
		padding         : 0;
		-moz-appearance : textfield;
		&:focus{
			@include form-control-focus();
		}
	}
	.plus, .minus {
		background  	: $white;
		float   		: left;
		display         : block;
		padding         : 0;
		margin          : 0;
		vertical-align  : text-top;
		text-decoration : none;
		overflow        : visible;
		text-decoration : none;
		cursor          : pointer;
		line-height     : 18px;
		font-size       : 14px;
		color           : var(--gray-dark,$gray-dark);
		border          : 1px solid var(--border-color,$border-color);
		text-align: center;
		@include size(45px , 38px);
	}
}

/*
    Forms
*---------------------------------------------------------------------------
*/
.form-row {
	@include clearfix();
    label.hidden {
        visibility:hidden;
    }
    label.inline {
        display: inline;
    }
    label{
    	display: block;
    }
    select {
        cursor: pointer;
    }
    .required {
        color: $red;
        font-weight: $headings-font-weight;
        border: 0;
    }
    .input-text{
    	width: 100%;
    }
    &.form-row-wide{
    	clear: both;
		margin:0;
		margin-bottom:14px;
    }
}

ul.tabs {
	list-style:none;
	padding: 0 0 0 1em;
	margin: 0 0 1.618em;
	overflow:hidden;
	position: relative;
	li {
		@include inline-block();
		position: relative;
		z-index: 0;
		margin: 0 -5px;
		padding: 0 1em;
		border: 1px solid var(--border-color,darken( $border-color, 10 ));
		@include box-shadow(0 3px 3px rgba($black, 0.2), inset 0 1px 0 rgba($white,0.6));
		a {
			@include inline-block();
			padding: .5em 0;
			font-weight: $headings-font-weight;
			color: var(--border-color,$border-color);
			text-decoration: none;
			&:hover {
				text-decoration:none;
				color: var(--border-color,lighten( $border-color, 10 ));
			}
		}
		&.active {
			background: var(--border-color,$border-color);
			z-index: 2;
			border-bottom-color: var(--border-color,$border-color);
			a {
				color: inherit;
				text-shadow: inherit;
			}
			&:before {
				@include box-shadow(2px 2px 0 var(--border-color,$border-color));
			}
			&:after {
				@include box-shadow(-2px 2px 0 var(--border-color,$border-color));
			}
		}
		&:before, &:after {
			border: 1px solid var(--border-color,darken($border-color, 10));
			position:absolute;
			bottom: -1px;
			content: "";
			@include size(5px,5px);
		}
		&:before {
			border-width: 0 1px 1px 0;
			@include rtl-left(-6px);
			@include border-right-radius(4px);
			box-shadow:-2px 2px 0  var(--border-color,darken($border-color, 10));
		}
		&:after {
			border-width: 0 0 1px 1px;
			@include rtl-right(-6px);
			@include border-left-radius(4px);
			box-shadow:-2px 2px 0  var(--border-color,darken($border-color, 10));
		}
	}
}

.product-quantity{
	.input-text{
		@include input-size('.input-sm', $input-height-sm, $padding-base-vertical, $padding-base-vertical, $font-size-sm, $line-height-sm, 0);
	}
}

.i-am-new{
    li{
        background-image: none !important;
        background-color: $white !important;
        -webkit-border-radius: 0px !important;
        border-radius: 0px !important;
        .noty_message{
            padding: 20px 0 !important;
        }
    }
}

/*
    Sale Label
*---------------------------------------------------------------------------
*/
.product-lable{
	position: absolute;
	@include rtl-left(15px);
    top: 15px;
    font-weight: $sale-label-font-weight;
    text-transform: $sale-label-transform;
    display: inline-block;
    text-align: center;
	z-index: 5;
	>div{
		margin-bottom: 5px;
		&:last-child{
			margin: 0;
		}
	}
}
.onsale, .hot{
	text-align: $sale-label-align;
	color: var(--gray-dark,$gray-dark);
	background: $white;
    @include font-size(font-size,$sale-label-font-size);
	padding: $sale-label-padding;
	position:relative;
	line-height: 24px;
	min-width: 50px;
	z-index: 1;
	text-transform: uppercase;
	&:empty{
		display:none;
	}
}
.onsale{
	font-size: 13px;
}
/*
    Star rating
*---------------------------------------------------------------------------
*/
.star-rating {
	overflow: hidden;
	position: relative;
	@include size(85px, 1.2em);
	line-height: 1.2em;
	display:inline-block;
	font-family: icomoon;
    @include font-size(font-size,$star-rating-font-size);
	&:before {
		content: "\e90a\e90a\e90a\e90a\e90a";
		color: $gray-light;
		@include rtl-float-left();
		top: 0;
		@include rtl-left(0);
		position: absolute;
		letter-spacing: 4.5px;
	}
	span {
		overflow: hidden;
		@include rtl-float-left();
		top: 0;
		@include rtl-left(0);
		position: absolute;
		padding-top: 1.5em;
	}
	span:before {
		content: "\e90a\e90a\e90a\e90a\e90a";
		top: 0;
		position: absolute;
		@include rtl-left(0);
		color: #de982b;
		letter-spacing: 4.5px;
	}
}

/*
    Mini cart
*---------------------------------------------------------------------------
*/
.mafoil-topcart{
	&.active{
		&.popup{
			.cart-popup{
				&.popup{
					@include transform(translateX(0) !important);
					@include opacity-s();
				}
			}
		}
		.remove-cart-shadow{
			display:block;
		}
	}
	&.dark{
		.cart-popup{
			background:#222;
			border:0;
		}
		.mini-cart .cart-popup{
			.cart_item{
				border-color: rgba(#e1e1e1, .5);
			}
			.remove-cart{
				a{
					color:$white !important;
				}
			}
			.top-total-cart{
				color:$white;
			}
			.product-name a{
				color:$white !important;
			}
			.quantity{
				background:$white !important;
			}
			.quantity,.product-subtotal{
				color:$white !important;
			}
			.total-cart{
				color:$white;
				border-color: rgba(#e1e1e1, .5);
			}
			.free-order{
				color:$white;
			}
			.product-remove a{
				color:$white;
				border-color:$white;
				&:hover{
					border-color:var(--theme-color,$theme-color);
				}
			}
			div.empty{
				color:$white !important;
				&:before{
					color:$white !important;
				}
				.go-shop{
					color:$white;
					border-color:$white !important;
					&:before{
						background:$white !important;
					}
				}
			}
			.free-ship {
				color:$white;
				border-color: rgba(#e1e1e1, .5);
			}
			.buttons{
				.button{
					&:hover{
						border-color: $white !important;
					}
				}
			}
		}
	}
}
.mafoil-topcart{
	&.popup{
		&:not(.show){
			.mini-cart{
				.remove-cart{
					a{
						.close-line1{
							@include transform(translateY(0) translateX(0) rotate(-45deg));
							animation-name: crossLeftClose;
							@include animation-delay(0s);
							@include animation-duration(.15s);
						}
						.close-line2{
							@include transform(translateY(0) translateX(0) rotate(45deg));
							-webkit-animation-name: crossRightClose;
							animation-name: crossRightClose;
							@include animation-delay(.06s);
							@include animation-duration(.15s);
						}
					}
				}
			}
		}
		&.show{
			.mini-cart{
				.cart-popup{
					@include transform(translateX(0) !important);
					@include opacity-s();
					>.inner{
						@include transform(translateX(0) !important);
					}
				}
				.remove-cart-shadow{
					display:block;
				}
			}
		}
		.mini-cart{
			position: relative;
			.cart-popup{
				position: fixed !important;
				height: 100%;
				right: 0 !important;
				width: 420px;
				max-width:calc(100vw - 30px);
				top: 0 !important;
				z-index: 10000;
				@include transform(translateX(107%) !important);
				@include transition(all .8s cubic-bezier(0.2,1,.3,1));
				overflow: hidden;
				padding: 0;
				.free-ship .total-percent{
					margin-bottom: 20px;
				}
				> .inner{
					@include transition(all .8s cubic-bezier(.2, 1, .3,1));
					@include transform(translateX(-107%) !important);
					height: 100%;
				}
				.woocommerce-cart-header{
					height:100%;
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
				}
				.cart-details{
					flex: 1;
					display: flex;
					flex-direction: column;
					overflow: auto;
				}
				.cart-header-form{
					overflow: auto;
    				flex: 1;
				}
				div.empty{
					margin-top:50px;
				}
				.box-cart-top{
					display:flex;
					justify-content:space-between;
					border-bottom:2px solid var(--gray-dark,$gray-dark);
					align-items:center;
					padding-bottom:10px;
					margin-bottom:30px;
				}
				.remove-cart{
					padding: 30px;
					background: #f8f5f4;
					z-index: 10;
					display: flex;
					align-items: center;
					margin-bottom: 30px;
					a{
						@include rtl-margin-left(auto);
						position: relative;
						@include transition(opacity .2s ease);
						$size: 15px;
						height: $size;
						width: $size;
						top: 0;
						@include rtl-right(0);
						box-sizing: border-box;
						line-height: $size;
						display: inline-block;
						@include border-radius(50%);
						cursor: pointer;
						&:before, &:after {
							$width: 15px;
							$height: 1px;
							@include transform(rotate(-45deg));
							content: '';
							position: absolute;
							top: 50%;
							left: calc(50% - 8px);
							display: block;
							height: $height;
							width: $width;
							background-color: var(--gray-dark,$gray-dark);
							@include transition(all .3s ease-out);
						}
						&:after {
							@include transform(rotate(-135deg));
						}
						&:hover{
							&:before, &:after {
								@include transform(rotate(0deg));
							}
						}
						.close-text{
							@include opacity-h();
						}
					}
				}
				.top-total-cart{
					font-size:calc(var(--font-size-body,14px) + 4px);
					margin: 0;
					width: auto;
					font-weight: 500;
					text-transform: uppercase;
				}
				.quantity{
					color:var(--gray-dark,$gray-dark);
					text-transform:uppercase;
					font-weight:600;
				}
				.price{
					color:var(--text-color,$text-color);
					font-size:var(--font-size-body,$font-size-body);
					font-weight:400;
				}
				.cart-header-form{
					>div{
						@include rtl-padding(0, 10px, 0, 30px);
						overflow-x: hidden;
    					overflow-y: auto;
						max-height: 100%;
						&::-webkit-scrollbar {
							width: 3px;
						}
						/* Track */
						&::-webkit-scrollbar-track {
							background: #f1f1f1;
						}
						   
						/* Handle */
						&::-webkit-scrollbar-thumb {
							background: #999;
						}
						.cart_item{
							margin-bottom: 30px;
						}
					}
					li.empty{
						text-align:center;
						position:relative;
						padding-top:85px;
						font-size:calc(var(--font-size-heading,25px) - 1px);
						color:var(--gray-dark,$gray-dark);
						&:before{
							content:"\e914";
							font-family:wpbingofont;
							position:absolute;
							top:0;
							font-size:70px;
							line-height:1;
							left:calc(50% - 35px);
							color:var(--gray-dark,$gray-dark);
						}
						span{
							display:block;
						}
						.go-shop{
							display:inline-block;
							font-size:calc(var(--font-size-body,14px) + 2px);
							line-height:28px;
							margin-top:15px;
							position: relative;
							&:after{
								content: "";
								position: absolute;
								display: block;
								bottom: 0;
								left: 0;
								width: 100%;
								height: 2px;
								background-color: var(--gray-dark,$gray-dark);
								@include transform(scaleX(1));
								transform-origin: 0 50%;
								transition: transform .48s cubic-bezier(.77,0,.175,1);
							}
							i{
								font-size:calc(var(--font-size-body,14px) + 4px);
								@include rtl-margin-left(5px);
								position:relative;
								top:3px;
							}
							&:hover{
								border-color:var(--theme-color,$theme-color);
								color:var(--theme-color,$theme-color);
								&:after{
									transform-origin: 100% 50%;
									@include transform(scaleX(0));
									animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
								}
							}
						}
					}
				}
				.free-ship{
					padding: 0 30px;
				}
				.ajaxcart__footer{
					background-color: $white;
					box-shadow:0 0 20px var(--gray-dark-25,rgba($gray-dark, 0.25));
					.total-cart{
						padding: 15px 30px 0;
					}
					.buttons{
						padding: 0 30px 30px;
					}
				}
				.buttons{
					margin-bottom:0;
				}
				.mini_cart_item{
					margin-bottom: 20px;
					padding-bottom: 20px;
					border-bottom:1px solid rgba(#e1e1e1,0.5);
					&:first-child{
						margin-top: 20px;
					}
					&:last-child{
						margin-bottom:0;
						padding-bottom:0;
						border-bottom:0;
					}
				}
				@media(max-width: $screen-xs){
					width: 350px;
					.cart-header-form{
						>div{
							@include rtl-padding(0, 10px, 0, 15px);
						}
					}
					.free-ship{
						padding: 0 15px;
					}
					.ajaxcart__footer{
						.total-cart{
							padding: 15px 15px 0;
						}
						.buttons{
							padding: 0 15px 30px;
						}
					}	
				}
			}
		}
	}
	&.dropdown{
		.top-total-cart,.remove-cart{
			display:none;
		}
		.mini-cart{
			position: relative;
		}
		&.show{
			.mini-cart{
				.cart-popup{
					@include opacity-s();
					@include transform(translateY(0) !important);
					.cart-header-form{
						max-height: 335px;
						overflow-y: auto;
						@include rtl-padding-right(3px);
						&::-webkit-scrollbar {
							width: 3px;
						}
						/* Track */
						&::-webkit-scrollbar-track {
							background: #f1f1f1;
						}
							
						/* Handle */
						&::-webkit-scrollbar-thumb {
							background: #888; 
						}
		
						/* Handle on hover */
						&::-webkit-scrollbar-thumb:hover {
							background: #555; 
						}
					}
				}
				.remove-cart-shadow{
					display:block;
				}
			}
		}
	}
}
.mini-cart{
    position: relative;
	.woocommerce-cart-form{
		border:0;
	}
	.cart-popup.loadings{
		&:before{
			content:"";
			position:absolute;
			width:100%;
			height:100%;
			background:rgba($white,0.75);
			top:0;
			left:0;
			z-index:10;
		}
		&:after{
			position: absolute;
			display: inline-block;
			content: "";
			border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
			border-top-color:var(--gray-dark,$gray-dark);
			@include square(30px);
			background: none;
			@include animation(2s linear 0s normal none infinite running spinAround);
			text-indent: 0;
			top:calc(50% - 15px);
			left:calc(50% - 15px);
			@include border-radius(50%);
			z-index:11;
		}
	}
	div.empty{
		text-align:center;
		position:relative;
		padding-top:55px;
		font-size:calc(var(--font-size-body,14px) + 4px);
		color:var(--gray-dark,$gray-dark);
		span{
			display:block;
		}
		.go-shop{
			display:inline-block;
			font-size:calc(var(--font-size-body,14px) + 2px);
			line-height:28px;
			margin-top:5px;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: var(--gray-dark,$gray-dark);
				@include transform(scaleX(1));
				transform-origin: 0 50%;
				transition: transform .48s cubic-bezier(.77,0,.175,1);
			}
			&:hover{
				&:after{
					transform-origin: 100% 50%;
					@include transform(scaleX(0));
					animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
					background-color: var(--theme-color,$theme-color); 
				}
			}
		}
	}
    .cart-popup {
		@include rtl-text-align-left();
		width: 380px;
		padding: 30px 20px;
		border: 1px solid #f3f3f3;
		background: $white;
		position: absolute;
		color: var(--gray-dark,$gray-dark);
		@include opacity-h();
		@include transition(all 0.3s ease);
		@include transform(translateY(20px) !important);
		z-index: 10001;
		@include border-radius(0);
		left:auto !important;
		@include rtl-right(0 !important);
		top: 45px !important;
		will-change: unset !important;
		@include box-shadow(0px 5px 5px 0px rgba(50, 50, 50, 0.2));
		@media (max-width: $screen-phone){
			width: 290px;
			.product-image{
				width: 60px !important;
			}
		}
		.total-cart{
			font-size:var(--font-size-body,$font-size-body);
			color: var(--gray-dark,$gray-dark);
			line-height: 26px;
			position: relative;
			font-weight: 500;
			display:flex;
			justify-content:space-between;
			padding-top:15px;
			text-transform: uppercase;
			.total-price{
				font-size:calc(var(--font-size-body,14px) + 2px);
			}
		}
		.free-ship{
			margin-bottom: 15px;
			font-size:calc(var(--font-size-body,14px) + 2px);
			.title-ship{
				color: var(--text-color,$text-color);
				strong{
					color: var(--gray-dark,$gray-dark);
				}
			}
			.total-percent{
				position:relative;
				width:100%;
				background: transparent;
				height: 7px;
				margin: 10px 0 35px;
				@include border-radius(3px);
				border: 1px solid var(--theme-color,$theme-color);
				.percent{
					position:absolute;
					height:100%;
					left:0;
					background: var(--theme-color,$theme-color);
					top:0;
					@include transition(all 0.5s ease);
					@include border-radius(3px);
					&.free{
						background: green;
					}
				}
				&.total-percent_free{
					border-color: green;
				}
			}
			@media (max-width:$screen-xs){
				font-size:var(--font-size-body,$font-size-body);
			}
		}
		.cart_item {
			position: relative;
			margin-bottom: 30px;
			overflow: hidden;
			display:flex;
			.blockOverlay{
				background:$white !important;
				opacity:0.75 !important;
				backdrop-filter: blur(5px);
			}
			&:last-child{
				margin-bottom:0;
			}
			a{
				color: var(--text-color,$text-color);
				&.remove{
					text-align:center;
					color:var(--text-color,$text-color);
					font-weight: 500;
					font-size: 10px;
					text-transform: uppercase;
					position: relative;
					padding-bottom: 1px;
					&:after{
						content: "";
						position: absolute;
						display: block;
						bottom: 2px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: var(--text-color,$text-color);
						@include transform(scaleX(1));
						transform-origin: 0 50%;
						transition: transform .48s cubic-bezier(.77,0,.175,1);
					}
					&:hover{
						color: var(--theme-color,$theme-color);
						&:after{
							background-color: var(--theme-color,$theme-color);
							transform-origin: 100% 50%;
							@include transform(scaleX(0));
							animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
						}
					}
				}
			}
			.woocommerce-product-rating{
				margin-top: 0;
				.star-rating{
					margin-bottom:0;
				}
			}
			.variation{
				font-size: 13px;
				margin-top: 0;
				margin-bottom: 7px;
				>*{
					margin-bottom:0;
				}
				dt.variation-Vendor{
					font-weight: 500;
				}
				>*{
					display: inline-block;
					>p{
						margin: 0;
					}
				}
			}
		}
		.content-cart-left {
			display: inline-block;
			width: 100px;
			flex:0 0 100px;
			@include rtl-margin-right(15px);
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
		.content-cart-right{
			flex: 1;
			@include rtl-padding-right(25px);
		}
		.product-flex{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 10px;
		}
		.product-name a{
			color: var(--gray-dark,$gray-dark) !important;
			text-transform: uppercase;
			font-size:var(--font-size-body,$font-size-body);
			display:block;
			line-height:1.3;
			margin-bottom:0;
			margin-bottom:10px;
			@include transition(all .3s ease);
			&:hover{
				color:var(--theme-color,$theme-color) !important;
			}
		}
		.quantity {
			color: var(--gray-dark,$gray-dark);
			margin: 0;
			display:inline-flex;
			border:1px solid #dfdfdf;
			@include rtl-margin-right(15px);
			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			button,input{
				float:unset;
				border:0;
				height:30px;
				line-height:35px;
				width:30px;
				color: var(--gray-dark,$gray-dark);
			}
			.plus{
				order:3;
			}
			.minus{
				order:1;
			}
			input{
				order:2;
			}
		}
		.product-subtotal{
			color: var(--theme-color,$theme-color);
			font-weight: 500;
		}
		.free-order{
			color:var(--gray-dark,$gray-dark);
			text-align:center;
			margin-top:20px;
			text-transform:uppercase;
			font-size:calc(var(--font-size-body,14px) - 2px);
			span{
				text-decoration:underline;
				font-weight:700;
			}
		}
		.total {
			text-transform: uppercase;
			color: #fff;
		}
		.buttons {
			margin-bottom: 10px;
			margin-top: 13px;
			width:100%;
			.button {
				width: 100%;
				padding: 0 15px;
				font-weight: 500;
				font-size:var(--font-size-body,$font-size-body);
				text-overflow: ellipsis;
				color:$white;
				background: var(--button-color-50,rgba($gray-dark, 0.5));
				@include transition(unset);
				display: inline-block;
				@include border-radius(0); 
				margin-bottom: 10px;
				border:0;
				position: relative;
				overflow: hidden;
				line-height: 50px;
				height: 50px;
				@include transition(all .3s ease);
				text-transform: uppercase;
				&:last-child{
					margin: 0;
				}
				&.view-cart{
					background: var(--button-color,$gray-dark);
				}
				&:before{
					@include border-radius(0);
					bottom:0;
					top:auto;
				}
			}
		}
    }
}


/*
    Cart
*---------------------------------------------------------------------------
*/
.cart_list{
	> div{
		border-bottom: 1px solid rgba(0, 0, 0, 0.1)!important;
		margin: 10px 0;
		padding: 10px 0;
	}
    .image{
        @include size(70px,70px);
    }
    .cart-item{
    	line-height: 1.1;
    	margin: 0;
    	font-size: var(--font-size-body,$font-size-body)!important;
    	.amount{
    		font-size: var(--font-size-body,$font-size-body)!important;
    	}
    }
    .cart-main-content{
        @include rtl-text-align-left();
        position: relative;
        .remove{
            position: absolute;
            @include rtl-right(0);
            top: auto;
            @include font-size(font-size,25px);
            bottom: 10px;
            height: 20px;
        }
    }
    + .total{
        @include rtl-text-align-right();
        margin-top: 10px;
        .amount{
    		font-size: var(--font-size-body,$font-size-body)!important;
    	}
    }
}


/** Plugins  add to wishlist, compare **/
.woosc_table tbody {
	tr.tr-add_to_cart td a{
		color:$white;
		display:inline-block;
		line-height:45px;
		background:var(--theme-color,$theme-color);
		padding:0 30px;
		@include border-radius(4px);
		&:hover{
			background: darken($theme-color, 10%);
			background: var(--theme-color);
		}
	}
	tr{
		td{
			border-left:1px solid var(--border-color,$border-color) !important;
			border-bottom:1px solid var(--border-color,$border-color) !important;
			padding:20px !important;
			background:$white !important;
			&:first-child{
				background:#f5f5f5 !important;
				font-size:calc(var(--font-size-body,14px) + 2px);
				color:var(--gray-dark,$gray-dark) !important;
				font-weight:500 !important;
			}
		}
	}
}
.woosw-popup .woosw-popup-inner .woosw-popup-content .woosw-popup-content-mid table.woosw-items .woosw-item .woosw-content-item--title, .woosw-popup .woosw-popup-inner .woosw-content .woosw-content-mid table.woosw-items .woosw-content-item .woosw-content-item--title a{
	font-weight:500;
}
.woosw-popup .woosw-popup-inner .woosw-popup-content{
	max-width: 700px;
	@include border-radius(0 !important);
	background:$white;
	@media (min-width:$screen-sm-max){
		min-height:600px;
	}
	.woosw-item--actions{
		@include rtl-text-align-right();
	}
	.woosw-popup-content-bot{
		background:$white;
		color:var(--gray-dark,$gray-dark);
		padding:0;
		border-top:1px solid var(--border-color,$border-color);
		line-height:1;
		display:flex;
		flex-wrap:wrap;
		align-items:center;
		.woosw-popup-content-bot-inner{
			padding: 5px 15px;
			a{
				margin: 5px 0;
			}
		}
		.woosw-notice{
			height:60px;
			line-height:60px;
			top:60px;
			background: var(--gray-dark,$gray-dark);
			@media (max-width:$screen-xs-max){
				height:100px;
				line-height:100px;
				top:100px;
			}
		}
		.woosw-popup-content-bot-inner{
			.woosw-page{
				font-size:calc(var(--font-size-body,14px) - 2px);
				text-transform:uppercase;
				display:inline-block;
				line-height:calc(var(--font-size-body,14px) + 1px);
				padding:10px 25px;
				background: var(--button-color,$gray-dark);
				color: $white;
				text-decoration: none !important;
				text-align: center;
				position: relative;
				font-weight: 500;
				@include transition(all .3s ease);
				&:hover{
					background: var(--theme-color,$theme-color);
					color: $white !important;
				}
			}
			.woosw-continue{
				display:inline-block;
				line-height:calc(var(--font-size-body,14px) + 1px);
				padding:8.5px 25px;
				border:1px solid var(--gray-dark,$gray-dark) !important;
				font-size:calc(var(--font-size-body,14px) - 2px);
				text-transform:uppercase;
				color:var(--gray-dark,$gray-dark) !important;
				text-decoration: none !important;
				text-align: center;
				font-weight: 500;
				position: relative;
				@include transition(all .3s ease);
				&:hover{
					color: $white !important;
					background: var(--theme-color,$theme-color);
					border-color: var(--theme-color,$theme-color) !important;
				}
			}
		}
	}
	.woosw-popup-content-top{
		background:$white;
		color:var(--gray-dark,$gray-dark) ;
		padding:0;
		font-size:calc(var(--font-size-body,14px) + 6px);
		font-weight:500;
		text-transform:capitalize;
		height:60px;
		line-height:60px;
		border-bottom: 1px solid var(--border-color,$border-color);
		padding:0 20px;
		&:before{
			font-size: calc(var(--font-size-heading,25px) - 3px);
			color: var(--theme-color,$theme-color);
			line-height: calc(var(--font-size-heading,25px) + 10px);
			@include transition(all 0.1s ease);
			content:"\e977";
			font-family: 'feather';
			margin:0;
			position:relative;
			top:3px;
		}
		.woosw-popup-close{
			$size: 35px;
			height: $size;
			width: $size;
			position: absolute;
			top: 10px;
			@include rtl-right(15px);
			box-sizing: border-box;
			line-height: $size;
			display: inline-block;
			@include border-radius(50%);
			background: $white;
			box-shadow: 0px 1px 12px 2px var(--gray-dark-50,rgba($gray-dark, 0.5));
			&:before, &:after {
				$width: 15px;
				$height: 1px;
				@include transform(rotate(-45deg));
				content: '';
				position: absolute;
				top: 50%;
				left: calc(50% - 8px);
				display: block;
				height: $height;
				width: $width;
				background-color: var(--gray-dark,$gray-dark);
				@include transition(all .3s ease-out);
			}
			&:after {
				@include transform(rotate(-135deg));
			}
			&:hover{
				&:before, &:after {
					@include transform(rotate(0deg));
				}
			}
		}
	}
	@media (max-width:$screen-xs-max){
		.woosw-content-bot {
			height:80px;
			.woosw-page{
				line-height:30px;
				padding:0 15px;
				margin:2.5px 0;
				font-size:10px;
			}
			.woosw-continue{
				line-height:26px;
				padding:0 15px;
				margin:2.5px 0;
				font-size:10px;
			}
			.woosw-notice{
				height:80px;
				line-height:80px;
				top:80px;
			}
		}
	}
	@media (max-width:$screen-xs){
		max-width: 370px;
		.woosw-popup-content-bot-inner {
			.woosw-page{
				width: 100%;
			}
			.woosw-continue{
				width: 100%;
			}
		}
	}
}
.woosw-popup{
	.woosw-items{
		border:0;
		padding: 0;
		.woosw-item{
			padding: 0;
			.woosw-item--price{
				font-size:calc(var(--font-size-body,14px) + 4px);
				color: var(--theme-color,$theme-color);
				font-weight: 500;
				ins{
					text-decoration: none;
					.woocommerce-Price-amount{
						color: var(--theme-color,$theme-color);
					}
				}
			}
			.woosw-item--time{
				font-size:calc(var(--font-size-body,14px) - 2px);
			}
			td{
				border:0;
				background:transparent !important;
				padding:15px 10px !important;
			}
			tr{
				border-bottom:1px solid var(--border-color,$border-color);
				&:last-child{
					border-bottom: 0;
				}
			}
			.woosw-item-inner{
				border-top: 1px solid var(--border-color,$border-color);
				padding: 20px 15px;
				flex-wrap: wrap;
			}
			.woosw-item--remove{
				@include rtl-margin-right(15px !important);
				span{
					&:before{
						content: "\e905" !important;
						font-family: wpbingofont !important;
						display: inline-block;
						@include transition(all .3s ease);
					}
					&:hover{
						&:before{
							@include transform(rotate(90deg));
							color: var(--theme-color,$theme-color);
						}
					}
				}
			}
			.woosw-item--image{
				img{
					width:95px !important;
					@include border-radius(0 !important);
				}
			}
			.woosw-item--info{
				.woosw-item--name{
					a{
						font-weight: 400;
						text-transform: uppercase;
					}
				}
			}
			.woosw-item--add{
				.add_to_cart_inline{
					margin:0;
				}
				a{
					display:inline-block !important;
					line-height: calc(var(--font-size-body,14px) + 2px);
					background: transparent;
					padding:8px 20px !important;
					font-size: calc(var(--font-size-body,14px) - 2px) !important;
					font-weight: 500;
					text-transform: uppercase;
					margin-top:10px !important;
					position: relative;
					text-align: center;
					@include transition(all .3s ease);
					border: 1px solid $border-btn;
					&:before{
						position: absolute;
						content: "\e902";
						font-family: icomoon;
						width: 100%;
						left: 0;
						color: $white;
						top: 50%;
						transform:translateY(-50%);
						line-height: 1;
						font-size:calc(var(--font-size-body,14px) + 4px);
						@include transition(all .3s ease);
						@include opacity-h();
					}
					&:hover{
						background: var(--theme-color,$theme-color);
						border-color: var(--theme-color,$theme-color);
						&:before{
							@include opacity-s();
						}
						span{
							@include opacity-h();
						}
					} 
					&.product_type_variable, &.product_type_external, &.read_more, &.product_type_grouped{
						&:before{
							content: "\24";
							font-family: 'ElegantIcons'; 
							text-indent: 0;
						}
					}
					&.added_to_cart{
						display:none !important;
					}
					&.loading{
						span{
							position: unset;
							border: none !important;
							animation: none;
						}
						color:transparent;
						position:relative;
						&:before {
							position: absolute;
							display: inline-block;
							content: "";
							border:2px solid var(--gray-dark-50,rgba($gray-dark, 0.5));
							border-top-color: var(--gray-dark,$gray-dark);
							@include square(16px);
							@include border-radius(50%);
							background: none;
							@include animation(2s linear 0s normal none infinite running spinAround);
							text-indent: 0;
							left: 0;
							top:calc(50% - 15px);
							@include transition(all 0s ease);
							@include opacity-s();
						}
						&:hover{
							&:before{
								border-color: rgba($white, .5);
								border-top-color: $white;
							}
						}
					}
				}
			}
			@media (max-width:$screen-xs-max){
				tr{
					display:flex;
					flex-wrap:wrap;
				}
				.woosw-item--image{
					flex:0 0 70px;
				}
				.woosw-item--actions{
					width:100%;
					display:flex;
					flex-wrap:wrap;
					justify-content:space-between;
					border:0;
					padding:15px !important;
					.woosw-item--add a{
						margin:0 !important;
					}
				}
				.woosw-item--image{
					img{
						width:65px !important;
					}
				}
			}
		}
	}
}
.woosc-area .woosc-inner .woosc-bar .woosc-bar-btn{
	background:var(--theme-color,$theme-color) !important;
	&:hover{
		background: darken($theme-color, 10%) !important;
		background: var(--theme-color) !important;
	}
}
.woosc_table{
	del{
		font-size:0;
	}
	.tr-price{
		.woocommerce-Price-amount{
			font-size:calc(var(--font-size-body,14px) + 6px);
			color:var(--gray-dark,$gray-dark);
		}
		del{
			font-size:0;
			.woocommerce-Price-amount{
				font-size:calc(var(--font-size-body,14px) + 2px);
				color:var(--text-color,$text-color);
			}
		}
		ins{
			text-decoration:none;
			.woocommerce-Price-amount{
				    color: #ff4545;
			}
		}
		
	}
	table tr{
		th{
			background:var(--gray-dark-10,rgba($gray-dark, 0.1)) !important;
		}
		td{
			border:0 !important;
			padding-left:15px !important;
			p:last-child{
				margin:0;
			}
		}
	}
}
.woosw-list{
	.woosw-actions{
		flex-wrap: wrap;
		.woosw-share-label{
			color:var(--gray-dark,$gray-dark);
			font-size:var(--font-size-body,$font-size-body);
			text-transform: uppercase;
			font-weight: 500;
		}
		.woosw-share{
			margin:5px 0;
			a{
				@include square(25px);
				@include border-radius(50%);
				text-align:center;
				line-height: 25px;
				color:$white;
				background:#3b5998;
				.woosw-icon{
					font-family: 'feather' !important;
					line-height: 25px;
				}
				&.woosw-share-facebook{
					.woosw-icon{
						&:before{
							content: '\e95e';
						}
					}
				}
				&.woosw-share-twitter{
					background:#55acee;
					.woosw-icon{
						&:before{
							content: '\e9ea';
						}
					}
				}
				&.woosw-share-pinterest{
					background:#cb2027;
					.woosw-icon{
						&:before{
							content: '\e9d5';
						}
					}
				}
				&.woosw-share-mail{
					background:#dc4e41;
					.woosw-icon{
						&:before{
							content: '\e918';
						}
					}
				}
			}
		}
		.woosw-copy{
			position:relative;
			margin:10px 0;
			flex-wrap:wrap;
			.woosw-copy-label{
				color:var(--gray-dark,$gray-dark);
				text-transform: uppercase;
				font-weight: 500;
			}
			.woosw-copy-url{
				input{
					border:1px solid var(--border-color,$border-color);
					height:50px;
					line-height:50px;
					@include border-radius(30px);
					min-width:450px;
					@include rtl-padding(0 ,120px ,0 ,20px);
					@media (max-width: 856px){
						min-width: 380px;
					}
					@media (max-width:$screen-xs){
						min-width:auto;
						width:100%;
					}
				}
			}
			.woosw-copy-btn{
				position:absolute;
				@include rtl-right(7px);
				input{
					background:var(--button-color,$gray-dark);
					color:$white;
					border:0;
					line-height:38px;
					padding:0 30px;
					@include border-radius(20px);
					font-size:var(--font-size-body,$font-size-body);
					font-weight:500;
					@include transition(all .3s ease);
					cursor:pointer;
					text-transform: uppercase;
					&:hover{
						background: var(--theme-color,$theme-color);
					}
				}
			}
		}
	}
	.woosw-items{
		border:1px solid var(--border-color,$border-color);
		@include border-radius(15px);
		tbody{
			tr{
				border-bottom: 1px solid #e5e5e5;
				&:last-child{
					border-bottom: 0;
				}
				td{
					border: 0;
					background-color: transparent !important;
					&.woosw-item--info{
						.woosw-item--name{
							>a{
								text-transform: uppercase;
								font-weight: 400;
							}
						}
					}
					&.woosw-item--remove{
						span{
							&:before{
								content: "\e905" !important;
								font-family: wpbingofont !important;
								display: inline-block;
								@include transition(all .3s ease);
							}
							&:hover{
								&:before{
									@include transform(rotate(90deg));
									color: var(--theme-color,$theme-color) !important;
								}
							}
						}
					}
				}
			}
		}
		.woosw-content-item--title{
			font-size:var(--font-size-body,$font-size-body);
			a{
				font-weight:500 !important;
			}
		}
		.woosw-item--info{
			.woosw-item--price{
				font-size:calc(var(--font-size-body,14px) + 4px);
				color: var(--theme-color,$theme-color);
				font-weight: 500;
				ins{
					text-decoration:none;
					.woocommerce-Price-amount{
						color: var(--theme-color,$theme-color);
					}
				}
				>span{
					color:var(--theme-color,$theme-color);
				}
			}
		}
		.woosw-item--actions{
			@include rtl-border-left(1px solid var(--border-color,$border-color));
			@include rtl-padding-left(30px !important);
			.woosw-item--add{
				a{
					color:$white;
					display:inline-block !important;
					line-height: 32px;
					height: 32px;
					background:var(--button-color,$gray-dark);
					padding:0 20px !important;
					font-size:13px !important;
					margin-top:10px !important;
					position: relative;
					overflow: hidden;
					text-align: center;
					text-transform: uppercase;
					font-weight: 500;
					&:hover{
						background: var(--theme-color,$theme-color);
					}
					&.added_to_cart{
						display:none !important;
					}
					&.loading{
						span{
							position: unset;
							border: none !important;
							animation: none;
						}
						color:transparent;
						position:relative;
						&:before {
							position: absolute;
							display: inline-block;
							content: "";
							border:2px solid rgba($white,0.5);
							border-top-color:$white;
							@include square(16px);
							@include border-radius(50%);
							background: none;
							@include animation(2s linear 0s normal none infinite running spinAround);
							text-indent: 0;
							left: 0;
							top:calc(50% - 15px);
							@include transition(all 0s ease);
							@include opacity-s();
						}
					}
				}
			}
		}
	}
	@media (max-width:$screen-sm-max){
		.woosw-actions{
			align-items: center;
		}
	}
	@media (max-width:$screen-xs){
		.woosw-actions{
			.woosw-copy-label{
				display: none;
			}
		}
	}
	@media (max-width:$screen-xs-max){
		.woosw-items{
			tr{
				display:flex;
				flex-wrap:wrap;
			}
			.woosw-item--remove{
				flex:0 0 30px;
			}
			.woosw-item--image{
				flex:0 0 100px;
			}
			.woosw-item--info{
				flex:0 0 calc(100% - 130px);
			}
			.woosw-item--actions{
				width:100%;
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				border:0;
				padding:15px !important;
				.woosw-item--add a{
					margin:0 !important;
				}
			}
			.woosw-item--image{
				img{
					width:95px !important;
				}
			}
		}
	}
}

//Shop filter
.woocommerce {
	.widget_price_filter {
		overflow: hidden;
		.ui-slider .ui-slider-handle {
			@include square(7px);
			@include border-radius(0);
			background: var(--theme-color,$theme-color);
			top: -2px;
		}
		.ui-slider .ui-slider-range {
			background: var(--theme-color,$theme-color);
		}
		.price_slider_wrapper {
			.ui-widget-content {
				@include border-radius(0);
				background: #d7d7d7;
				margin-top: 15px;
				margin-bottom: 0;
			}
		}
		.ui-slider-horizontal {
			height: 2px;
		}
		//button
		#respond input#submit, a.button, button.button, input.button {
			border: none;
			@include border-radius(0);
			background: var(--theme-color,$theme-color);
			color: $white;
			text-transform: uppercase;
		    height: 30px;
			line-height: 30px;
			padding: 0 15px;
			margin-top: 20px;
			@include rtl-float-right();
			&:hover{
				background: darken($theme-color, 10%);
				background: var(--theme-color);
			}
		}
		.price_slider_amount {
			margin-top: 10px;
			.price_label {
				@include rtl-text-align-left();
			}
		}
	}
	.widget_layered_nav{
		.dropdown_layered_nav_size{
			margin-top: 10px;
			padding: 5px 20px;
		}
	}
}

// bwp-top-bar
.bwp-top-bar {
	font-size:var(--font-size-body,$font-size-body);
	position: relative;
	@media (max-width:$screen-xs){
		&:after{
			display:none;
		}
		&.bottom{
			justify-content:center;
		}
	}
	.woocommerce-filter-title{
		width:100%;
		display: inline-block;
		color:var(--gray-dark,$gray-dark);
		text-transform:capitalize;
		font-weight:500;
		margin-top:10px;
		order:3;
		>span{
			display:inline-block;
			background:#eeeeee;
			line-height:26px;
			@include rtl-padding(0 ,10px ,0 ,15px);
			cursor:pointer;
			@include rtl-margin(2.5px ,10px ,2.5px ,0);
			&:after{
				font-family: ElegantIcons;
				content: "\4d";
				display:inline-block;
				@include rtl-margin-left(5px);
				font-size:calc(var(--font-size-body,14px) + 2px);
				line-height: 1;
				position: relative;
				top: 2px;
			}
			&:hover{
				background:var(--gray-dark,$gray-dark);
				color:$white;
			}
		}
		>button{
			background:transparent;
			border:0;
			padding:0;
			cursor:pointer;
			font-weight:500;
			line-height: 20px;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--gray-dark,$gray-dark);
				@include transform(scaleX(1));
				transform-origin: 0 50%;
				transition: transform .48s cubic-bezier(.77,0,.175,1);
			}
			&:hover{
				&:after{
					transform-origin: 100% 50%;
					@include transform(scaleX(0));
					animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
				}
			}
		}
	}
	.woocommerce-filter-title:empty{
		background:red;
	}
	.display {
		@include rtl-float-right();
		padding:9px 10px;
		background:$white;
		line-height:1;
		list-style:none;
		li {
			@include rtl-float-left();
			@include rtl-padding-right(17px);
			position: relative;
			a{		
				display: inline-flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include transition(all 0.2s ease);
				span{
					@include size(4px, 20px);
					background: transparent;
					border: 1px solid #999999;
					margin: 0 1.5px;
					display: inline-block;
					@include transition(all .3s ease);
				}
				&.view-list{
					position: relative;
					display: inline-block;
					&:before{
						content: "\e907";
						font-family: wpbingofont;
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						left: 0;
						font-size: 30px;
						color: var(--gray-dark,$gray-dark);
						@include transition(all .3s ease);
					}
					span{
						@include opacity-h();
					}
				}
			    &:hover, &.active{
					span{
						background: var(--theme-color,$theme-color);
						border-color: var(--theme-color,$theme-color);
					}
					&.view-list{
						&:before{
							color: var(--theme-color,$theme-color);
						}
					}
			    }
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
	&.top {
		margin-bottom:20px;
		margin-top:-5px;
		.button-filter-toggle{
			font-weight: 500;
            display: inline-block;
            position: relative;
            font-size: 12px;
            text-transform: uppercase;
			cursor: pointer;
			color:var(--gray-dark,$gray-dark);
			line-height:32px;
			border:1px solid var(--gray-dark,$gray-dark);
			@include rtl-float-left();
			background:$white;
			padding:0;
			@include rtl-margin-right(15px);
			@include square(36px);
			text-align:center;
            &:before{
               display:none;
            }
			&:after{
				font-size:calc(var(--font-size-body,14px) + 5px);
				position: relative;
				content: "\e9d1";
				font-family: feather;
				text-transform: none;
				line-height: 1;
				margin:0;
				line-height:32px;
			}
            &.active{
				background: var(--theme-color,$theme-color);
				&:after{
					color:$white;
				}
                &:before{
                    @include transform(scale(1));
                    opacity: 1;
                    visibility: visible;
                }
            }
			&:hover{
				background: var(--theme-color,$theme-color);
				&:after{
					color:$white;
				}
			}
		}	
	}
	&.bottom {
		clear: both;
		text-align:center;
		margin-top:30px;
		.loading-filter:before{
			opacity:1;
		}
	}
	.woocommerce-ordering {
		margin-bottom: 0;
		height:36px;
		line-height:36px;
		.woocommerce-ordering-content{
			@include rtl-float-right();
		}
		.orderby {
			border-color: var(--border-color,$border-color);
			color: var(--gray-dark,$gray-dark);
		}
		@media (max-width:$screen-xs){
			height:30px;
		}
	}
	.woocommerce-ordering, .woocommerce-sort-count{
		@include rtl-float-right();
		background: $white;
		color: var(--gray-dark,$gray-dark);
		font-size:var(--font-size-body,$font-size-body);
		line-height: 35px;
		border:1px solid #e1e1e1;
		padding:0 20px;
		text-transform:capitalize;
		cursor: pointer;
		.pwb-dropdown-toggle{
			position: relative;
			border: 1px solid var(--gray-dark,$gray-dark);
			display: inline-flex;
			align-items: center;
			justify-content: space-between;
			line-height: 35px;
			padding: 0 15px;
			min-width: 155px;
			@include transition(all .3s ease);
			&:after{
				content: "\e904";
				font-family: wpbingofont;
	  			font-size:calc(var(--font-size-body,14px) + 1px);
				color: var(--gray-dark,$gray-dark);
	  			@include rtl-padding-left(5px);
				@include transform(rotate(90deg));
				display:inline-block;
				width: auto;
				height: auto;
				margin-left: 0;
				vertical-align: 0;
				border-top: 0;
				border-right: 0;
				border-bottom: 0;
				border-left: 0;
				line-height:1;
				position:relative;
				top: 0;
				@include transition(all 0.3s ease);
				transform-origin: 65% 50%;
			}
			&:hover{
				background: var(--theme-color,$theme-color);
				border-color: var(--theme-color,$theme-color);
				color: $white;
				&:after{
					color: $white;
				}
			}
		}
		&.show{
			.pwb-dropdown-toggle{
				background: var(--theme-color,$theme-color);
				border-color: var(--theme-color,$theme-color);
				color: $white;
				&:after{
					@include transform(rotate(-90deg));
					color: $white;
				}
			}
		}
		.pwb-dropdown-menu{
			@include rtl-left(0);
			right: auto;
			li{
				line-height: 28px;
				text-transform: capitalize;
				font-weight:400;
				a{
					color:var(--text-color,$text-color);
					&:hover{
						color:var(--theme-color,$theme-color);
					}
				}
			}
		}
	}
	.woocommerce-ordering{
		border:0;
		padding:0;
		background:transparent;
		@include rtl-float-left();
		.pwb-dropdown-menu{
			min-width:210px;
			z-index: 100;
			@include border-radius(0);
		}
	}
	.mafoil-woocommerce-sort-count{
		@include rtl-margin-right(20px);
		font-weight:500;
		@include rtl-float-left();
		.list-show{
			@include rtl-padding-left(0);
			display:inline-flex;
			margin-bottom: 0;
			li{
				list-style:none;
				color:var(--gray-dark,$gray-dark);
				font-weight:500;
				@include rtl-margin-right(15px);
				@include rtl-padding-right(15px);
				font-weight:500;
				position:relative;
				&:before{
					content:"";
					position:absolute;
					top:calc(50% - 8px);
					@include size(1px,16px);
					background:#e1e1e1;
					right:0;
				}
				&:last-child{
					@include rtl-margin-right(0);
					@include rtl-padding-right(0);
					&:before{
						display:none;
					}
				}
				a{
					color:var(--text-color,$text-color);
					font-weight:500;
				}
				&.active,&:hover{
					a{
						color:var(--theme-color,$theme-color);
					}
				}
			}
		}
		@media (max-width:$screen-xs){
			@include rtl-float-right();
			@include rtl-margin(5px ,5px ,5px ,0);
			clear: both;
		}
	}
	.woocommerce-result-count{
		color:var(--text-color,$text-color);
		text-transform:initial;
		font-weight:400;
		position:relative;
		float:right;
		margin-right:10px;
		margin-top:8px;
	}
	.woocommerce-pagination {
		display: inline-block;
	}
	.woocommerce-found-posts {
		line-height: 25px;
		background: $white;
		@include rtl-padding-right(10px);
		@media (min-width: $screen-lg){
			@include rtl-padding-right(15%);
		}
		&:after{
			top: 12px;
			content: "";
			background: rgba($light-gray, 0.2);
			@include size(100%, 1px);
			position: absolute;
			@include rtl-left(0);
			z-index: -1;
		}
	}
	@media (max-width:480px){
		&.dropdown-left{
			.woocommerce-ordering .pwb-dropdown-menu{
				right: auto;
				left: 0 !important;
			}
		}
	}
}
.woocommerce-pagination{
	&.shop-loadmore{
		text-align: center;
		display: block;
		.woocommerce-product-count{
			margin: 0 0 15px;
		}
		.woocommerce-load-more{
			margin: 0 auto;
			font-weight: 500;
			@include transition(all .3s ease);
			border: 0;
			cursor: pointer;
			line-height: calc(var(--font-size-body,14px) + 16px);
			padding: 15px 60px;
			text-transform: uppercase;
			background: transparent;
			color:var(--gray-dark,$gray-dark);
			@include border-radius(0);
			position: relative;
			overflow: hidden;
			border: 1px solid var(--border-color,$btn-border);
			.lds-ellipsis {
				display: inline-block;
				position: absolute;
				width: 65px;
				height: 8px;
				top: calc(50% - 4px);
				left: calc(50% - 32.5px);
				@include opacity-h();
				strong{
					position: absolute;
					top: 0;
					@include square(8px);
					@include border-radius(50%);
					background: var(--gray-dark,$gray-dark);
					animation-timing-function: cubic-bezier(0,1,1,0);
					&:nth-child(1){
						left: 5px;
						animation: lds-ellipsis1 .6s infinite;
					}
					&:nth-child(2){
						left: 5px;
						animation: lds-ellipsis2 .6s infinite;
					}
					&:nth-child(3){
						left: 29px;
						animation: lds-ellipsis2 .6s infinite;
					}
					&:nth-child(4){
						left: 53px;
						animation: lds-ellipsis3 .6s infinite;
					}
				}
			}
			&.active{
				border-color: transparent;
				.lds-ellipsis {
					@include opacity-s();
				}
				.loadmore-button-text{
					@include opacity-h();
				}
				&:after{
					display: none;
				}
				&:hover{
					background: transparent;
					border-color: transparent;
				}
			}
			&:hover{
				background: var(--theme-color,$theme-color);
				color: $white;
				border-color: var(--theme-color,$theme-color);
			}
		}
		.woocommerce-load-more{
			.button{
				display: inline-block;
			}
		}
		.percent-content{
			width: 100%;
			max-width: 410px;
			padding: 2px;
			background: #f5f5f5;
			margin-bottom: 15px;
			display: inline-block;
			.percent{
				height: 2px;
				background: var(--theme-color,$theme-color);
			}
		}
	}
	&.shop-infinity{
		.woocommerce-load-more{
			.loading-infinity{
				.gooey{
					position: absolute;
					top: 0;
					left: calc(50% - 45px);
					width: 90px;
					height: 40px;
					background: $white;
					filter: contrast(20);
				}
				.dot{
					position: absolute;
					width: 12px;
					height: 12px;
					top: 12px;
					left: 15px;
					filter: blur(4px);
					background: var(--gray-dark,$gray-dark);
					@include border-radius(50%);
					@include transform(translateX(0));
					animation: loading-filter 2.8s infinite;
				}
				.dots{
					@include transform(translateX(0));
					margin-top: 12px;
					margin-left: 31px;
					animation: loading-filter2 2.8s infinite;
					div{
						display: block;
						float: left;
						width: 12px;
						height: 12px;
						margin-left: 5px;
						filter: blur(4px);
						background: var(--gray-dark,$gray-dark);
						border-radius: 50%;
					}
				}
			}
		}
	}
}