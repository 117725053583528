
/* ***********************************************************************************************
    PRODUC LISTING
************************************************************************************************ */
.content-product-list{
    .products-thumb{
        display: inline-block;
        vertical-align: top; 
    } 
}
.products-list{ 
    &.grid:not(.slick-carousel){
        display: flex;
        flex-wrap: wrap;
		list-style:none;
        @media (max-width: $screen-xs-max){
            justify-content: center;
        } 
    }
    &.grid{
        &.row.slick-carousel{   
            margin: 0; 
        }
        .form-variable{
			position:absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			padding:10px;
			z-index:9;
			@include opacity-h();
			@include scale(0);
			@include transition(transform 0.3s ease);
			&.active{
				@include opacity-s();
				@include scale(1);
			}
			.woocommerce-variation-availability,.quantity{
				display:none !important;
			}
			.content-variable{
				background:$white;
				height: 100%;
				display:flex;
				flex-wrap:wrap;
				align-items:center;
				justify-content:center;
				padding-top: 35px;
				@media(max-width:$screen-xs){
					padding-top: 10px;
				}
				.close-variable{
					position:absolute;
					top:15px;
					cursor:pointer;
					left:50%;
					@include transform(translateX(-50%));
					font-size:calc(var(--font-size-body,14px) + 4px);
					color:var(--gray-dark,$gray-dark);
					cursor:pointer;
					&:before{
						content:"";
						position:absolute;
						bottom:0;
						width:90px;
						left:calc(50% - 45px);
						height:1px;
						background:#e5e5e5;
					}
					&:hover{
						color:var(--theme-color,$theme-color);
					}
				}
			}
			.variations_form{
				padding:15px;
				.woocommerce-variation-add-to-cart{
					justify-content:center;
				}
				.price{
					font-size:16px !important;
				}
				@media(max-width:$screen-xs){
					padding:10px;
				}
			}
			.single_add_to_cart_button{
				background:var(--gray-dark,$gray-dark);
				text-transform: uppercase;
				color:$white;
				height:40px;
				font-size: 12px;
				letter-spacing: 2px;
				line-height:40px;
				background:var(--theme-color,$theme-color);
				padding:0 20px;
				border:0;
				cursor:pointer;
				position:relative;
				@media(max-width:$screen-xs){
					height:30px;
					line-height:30px;
					white-space: nowrap;
					padding:0 10px;
				}
				&:hover{
					background:var(--gray-dark,$gray-dark);
					color: $white;
				}
				&.disabled{
					opacity:0.5;
					cursor:not-allowed;
				}
				&.active{
					color:transparent;
					&:before{
						position: absolute;
						display: inline-block;
						content: "";
						border:2px solid rgba($white,0.25);
						border-top-color:$white;
						@include square(18px);
						background: none;
						@include animation(2s linear 0s normal none infinite running spinAround);
						text-indent: 0;
						top:calc(50% - 9px);
						left:calc(50% - 9px);
						@include border-radius(50%);
					}
				}
			}
			.woocommerce-variation-price{
				margin-bottom:10px;
				display: block !important;
				visibility: visible !important;
			}
			table{
				display: flex;
				border: 0;
				justify-content: center;
				margin-top:0 !important;
				margin-bottom:10px !important;
				tr,td{
					border:0;
					padding:0;
					display:block;
					text-align:center !important;
				}
				.type_attribute{
					margin-bottom:10px;
					.reset_variations{
						display:none !important;
					}
					&:last-child{
						margin-bottom:0;
					}
					&.color{
						label{
							color:var(--gray-dark,$gray-dark);
							margin:0;
							font-size:calc(var(--font-size-body,14px) + 4px);
							text-transform:unset;
							font-weight:500;
							@media(max-width:$screen-xs){
								font-size: 13px;
							}
						}
						.tawcvs-swatches{
							display:flex;
							flex-wrap: wrap;
							justify-content: center;
						}
						.tawcvs-swatches >div{
							padding:2.5px 5px;
							font-size: 0;
							margin:0 !important;
							.swatch{
								@include square(26px !important);
								@include border-radius(50%);
								font-size: 0;
								display: inline-block;
								vertical-align: middle;
								cursor:pointer;
								position: relative;
								border:0;
								box-shadow:0 0 0 1px var(--gray-dark-10,rgba($gray-dark, 0.1));
								transform: unset;
								text-indent: unset;
								@media(max-width:$screen-xs){
									@include square(18px);
								}
								&.selected{
									box-shadow:0 0 0 1px #000, 0 8px 20px var(--gray-dark-25,rgba($gray-dark, 0.25)), inset 0 0 0 4px #fff;
								}
								&.disabled{
									opacity:0.5;
									cursor:not-allowed;
									position:relative;
									&:before{
										content:"\ea02";
										font-family:feather;
										position:absolute;
										color:red;
										top: calc(50% - 13.25px);
										left: calc(50% - 12.25px);
										font-size:var(--font-size-heading,$font-size-heading);
										line-height: 27px;
										padding:0;
										@include transform(unset);
										@include opacity-s();
										background:transparent;
									}
									&:after{
										display:none;
									}
								}
								&:before{
									content: attr(data-value);
									position: absolute;
									padding: 0 10px;
									background: var(--gray-dark,$gray-dark);
									color: $white;
									top: -30px;
									line-height: 23px;
									white-space: nowrap;
									left: 50%;
									font-size: 12px;
									z-index: 10;
									-webkit-transform: translateX(-50%);
									-moz-transform: translateX(-50%);
									-ms-transform: translateX(-50%);
									-o-transform: translateX(-50%);
									transform: translateX(-50%);
									@include opacity-h();
									border-radius: 3px;
									-webkit-border-radius: 3px;
									-moz-border-radius: 3px;
									-ms-border-radius: 3px;
									-o-border-radius: 3px;
									pointer-events: none;
								}
								&:after{
									content: "";
									position: absolute;
									top: -7px;
									left: 50%;
									margin-left: -5px;
									border-width: 5px;
									border-style: solid;
									border-color: var(--gray-dark,$gray-dark) transparent transparent transparent;
									pointer-events: none;
									@include opacity-h();
								}
								&:hover{
									&:before,&:after{
										@include opacity-s();
									}
								}
							}
						}
					}
					&.label{
						label{
							color:var(--gray-dark,$gray-dark);
							margin:0;
							font-size:calc(var(--font-size-body,14px) + 4px);
							text-transform:unset;
							font-weight:500;
							@media(max-width:$screen-xs){
								font-size: 13px;
							}
						}
						.tawcvs-swatches{
							display:flex;
							flex-wrap: wrap;
						}
						.tawcvs-swatches >div{
							padding:2.5px 5px;
							font-size: 0;
							margin:0 !important;
							.swatch{
								height:35px;
								min-width:35px;
								padding:0 5px;
								font-size: 12px;
								line-height:33px;
								display: inline-block;
								vertical-align: middle;
								cursor:pointer;
								color:var(--gray-dark,$gray-dark);
								position: relative;
								border:1px solid #dedede;
								@media(max-width:$screen-xs){
									font-size: 13px;
									height:25px;
									line-height:25px;
									min-width:25px;
								}
								&.selected{
									border-color:var(--gray-dark,$gray-dark);
								}
								&.disabled{
									opacity:0.5;
									cursor:not-allowed;
									position:relative;
									&:before{
										content:"\ea02";
										font-family:feather;
										position:absolute;
										top:0;
										left:0;
										color:red;
										top: calc(50% - 13.25px);
										left: calc(50% - 12.25px);
										font-size:var(--font-size-heading,$font-size-heading);
										line-height: 27px;
									}
								}
							}
						}
					}
					&.image{
						label{
							color:var(--gray-dark,$gray-dark);
							margin:0;
							text-transform:unset;
							font-weight:500;
						}
						.tawcvs-swatches{
							display:flex;
							flex-wrap: wrap;
							justify-content: center;
						}
						.tawcvs-swatches >div{
							padding:2.5px 5px;
							margin:0;
							.swatch{
								@include square(30px);
								@include border-radius(50%);
								font-size: 0;
								display: inline-block;
								vertical-align: middle;
								cursor:pointer;
								position: relative;
								box-shadow:0 0 0 1px var(--gray-dark-10,rgba($gray-dark, 0.1));
								&.selected{
									box-shadow:0 0 0 1px #000, 0 8px 20px var(--gray-dark-25,rgba($gray-dark, 0.25)), inset 0 0 0 4px #fff;
								}
								&:before{
									content: attr(data-value);
									position: absolute;
									padding: 0 10px;
									background: var(--gray-dark,$gray-dark);
									color: $white;
									top: -30px;
									line-height: 23px;
									white-space: nowrap;
									left: 50%;
									font-size: 12px;
									z-index: 10;
									-webkit-transform: translateX(-50%);
									-moz-transform: translateX(-50%);
									-ms-transform: translateX(-50%);
									-o-transform: translateX(-50%);
									transform: translateX(-50%);
									@include opacity-h();
									border-radius: 3px;
									-webkit-border-radius: 3px;
									-moz-border-radius: 3px;
									-ms-border-radius: 3px;
									-o-border-radius: 3px;
									pointer-events: none;
								}
								&:after{
									content: "";
									position: absolute;
									top: -7px;
									left: 50%;
									margin-left: -5px;
									border-width: 5px;
									border-style: solid;
									border-color: var(--gray-dark,$gray-dark) transparent transparent transparent;
									pointer-events: none;
									@include opacity-h();
								}
								&.disabled{
									opacity:0.5;
									cursor:not-allowed;
									position:relative;
									&:before{
										content:"\ea02";
										font-family:feather;
										position:absolute;
										color:red;
										top: calc(50% - 13.25px);
										left: calc(50% - 12.25px);
										font-size:var(--font-size-heading,$font-size-heading);
										line-height: 27px;
										padding:0;
										@include transform(unset);
										@include opacity-s();
										background:transparent;
									}
									&:after{
										display:none;
									}
								}
								&:hover{
									&:before,&:after{
										@include opacity-s();
									}
								}
							}
						}
					}
					&.select{
						label{
							color:var(--gray-dark,$gray-dark);
							margin:0;
						}
						.tawcvs-swatches{
							display:flex;
							flex-wrap: wrap;
							justify-content: center;
						}
						select{
							border:1px solid #dedede;
							width:100%;
						}
					}
				}
			}
		}
        .product-wapper{  
            position: relative; 
            padding: 0;
			margin: 0 0 30px;
            &:hover{ 
				border:none;
				.products-thumb{
					.product-thumb-hover{
						.hover-image{
							opacity: 1;
							visibility: visible;
						}
						.wp-post-image{
							opacity: 0;
							visibility: hidden;
						}
					}
				}
				.products-thumb{
					.product-button{
						>*{
							@include opacity-s();
							@include transform(translateX(0) !important);
						}
					}
					.btn-quickview{
						.product-quickview{
							@include opacity-s();
							@include transform(translate(-50%, 0) !important);
						}
					}
					.btn-atc{
						>div{
							@include opacity-s();
							@include transform(translateY(0) !important);
						}
					}
				}
				.countdown{
					@include opacity-h();
				}
            }
			.product-attribute{
				bottom: 0;
				@include rtl-right(0);
				z-index: 9;
				@include opacity-s(); 
				@include transition(all 0.3s ease-in-out);
				display:flex;
				flex-wrap: wrap;
				align-items:center;
				margin:0 -5px;
				>div{
					padding:5px;
					position:relative;
					&:not(.label){
						&:before{
							content:attr(data-title);
							position:absolute;
							padding:0 10px;
							background:var(--gray-dark,$gray-dark);
							color:$white;
							top: -33px;
							line-height: 23px;
							white-space: nowrap;
							left: 50%;
							font-size: 12px;
							z-index: 10;
							@include transform(translateX(-50%));
							@include opacity-h();
							pointer-events: none;
							@include transition(all .2s ease-in-out);
						}
						&:after{
							content: "";
							position: absolute;
							top: -10px;
							left: 50%;
							margin-left: -5px;
							border-width: 5px;
							border-style: solid;
							border-color: var(--gray-dark,$gray-dark) transparent transparent transparent;
							pointer-events: none;
							@include opacity-h();
							@include transition(all .2s ease-in-out);
						}
						&:hover{
							&:before,&:after{
								@include opacity-s();
							}
							&:before{
								top: -25px;
							}
							&:after{
								top: -2px;
							}
						}
					}
					&.color{
						&:hover, &.active{
							span{
								@include box-shadow(none);
								border-color: var(--gray-dark,$gray-dark);
							}
						}
						span{
							@include square(22px);
							@include border-radius(50%);
							font-size: 0;
							display: inline-block;
							vertical-align: middle;
							cursor:pointer;
							@include transition(all .3s ease-in-out);
							position: relative;
							border: 2px solid $white;
							@include box-shadow(0 0 1px 1px #ddd);
						}
					}
					&.label{
						span{
							display:inline-block;
							border:1px solid #ccc;
							line-height:25px;
							padding:0 7px;
							cursor:pointer;
							font-size:calc(var(--font-size-body,14px) - 1px);
							min-width: 30px;
							text-align: center;
						}
						&:hover, &.active{
							span{
								background:var(--theme-color,$theme-color);
								border-color:var(--theme-color,$theme-color);
								color: $white;
							}
						}
					}
					&.images{
						img{
							@include square(25px);
							@include border-radius(50%);
							cursor:pointer;
							object-fit:cover;
							@include transition(all .3s ease-in-out);
							border: 2px solid $white;
							@include box-shadow(0 0 1px 1px #ddd);
						}
						&:hover, &.active{
							img{
								@include box-shadow(none);
								border-color: var(--gray-dark,$gray-dark);
							}
						}
					}
				}
				@media(max-width: $screen-xs){
					padding: 0;
				}
			}
			.countdown{
				position:absolute;
				bottom: 25px;
				right: 0;
				padding:0 5px;
				width: 100%;
				@include transition(all 0.3s ease);
				@media (max-width:$screen-xs-max){
					display:none;
				}
				.item-countdown{
					text-align:center;
				}
				.countdown-content{
					background: var(--theme-color,$theme-color);
					padding:0 15px;
					display: inline-block;
					line-height: 35px;
					>span{
						display:inline-block;
						font-size:calc(var(--font-size-body,14px) + 2px);
						color:$white;
						&:not(:last-child){
							&:after{
								content: ":";
								display: inline-block;
								margin: 0 5px;
							}
						}
					}
					.countdown-amount{
						position:relative;
					}
					@media (max-width:$screen-sm-max){
						padding:10px;
						line-height: 25px;
						>span{
							display:inline-block;
							font-size:calc(var(--font-size-body,14px) + 1px);
						}
					}
				}
			}
            .products-thumb{
            	margin-bottom: 14px;
                position: relative;
                display: inline-block;
                vertical-align: top;
				overflow: hidden;
                .product-thumb-hover{
                    position: relative;
                    display: block;
					padding-bottom: 2px;
                    .wp-post-image{ 
                        opacity: 1;
                        visibility: visible;
                        @include transition(all 0.5s ease);  
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        max-width: 100%;
                        height: auto;
                    }
                    .hover-image{
                        opacity: 0;
                        visibility: hidden;
                        max-width: 100%;
                        height: auto;
                    }               
                }
				.product-button{
					position: absolute;
					top: 15px;
					bottom: auto;
					right: 15px;
					left: auto;
					z-index: 9;
					width: 40px;
					padding: 0;
					@include transition(all 0.3s ease-in-out);
					.woosw-wishlist{
						display: inline-block;
					}
					@media(max-width: $screen-xs){
						display: block !important;
						width: 35px !important;
						padding: 0 !important;
						bottom: 15px !important;
						left: auto !important;
						top: auto !important;
						@include rtl-right(15px);
					}
					>*{
						margin:5px 0;
						@include transition(all 0.5s ease);
						@include opacity-h();
						@include transform(translateX(100%) !important);
						@media(max-width: $screen-xs){
							@include opacity-s();
							@include transform(translateX(0) !important);
							margin: 0;
							&:before, &:after{
								display: none;
							}
						}
						&:first-child{
							margin-top: 0;
						}
						&:nth-child(2){
							transition-delay: .1s;
						}
						&:last-child{
							transition-delay: .2s;
							margin-bottom: 0;
						}
						&:before{
							content:attr(data-title);
							position:absolute;
							padding:0 10px;
							background:var(--gray-dark,$gray-dark);
							color:$white;
							line-height: 24px;
							white-space: nowrap;
							font-size: 12px;
							z-index: 10;
							left: -18px;
							top: 50%;
							@include transform(translate(-100% ,-50%));
							@include opacity-h();
							@include transition(all .2s ease-in-out);
							pointer-events: none;
						}
						&:after{
							content: "";
							position: absolute;
							top: 50%;
							@include transform(translateY(-50%));
							left: -14px;
							margin-left: -5px;
							border-width: 5px;
							border-style: solid;
							border-color: transparent transparent transparent var(--gray-dark,$gray-dark);
							pointer-events: none;
							@include transition(all .2s ease-in-out);
							@include opacity-h();
						}
						&:hover{
							&:before,&:after{
								@include opacity-s();
							}
							&:before{
								left: -8px;
							}
							&:after{
								left: -3px;
							}
						}
					}
					.product-quickview{
						text-align: center;
						background: $white;
						font-size: 0;
						white-space: nowrap;
						display: inline-block;
						>a{
							color: var(--gray-dark,$gray-dark);
							@include square(40px);
							line-height: 40px;
							display: inline-block;
							position:relative;
							outline: unset;
							@include transition(all .5s ease);
							border: 0;
							&:before{
								content: "\e908";
								font-family: icomoon;
								font-size:16px;
								color: var(--gray-dark,$gray-dark);
								@include transition(all 0.1s ease);
								margin:0;
								display: inline-block;
							}
						}
						&:hover{
							>a{
								background:var(--theme-color,$theme-color);
								&:before{
									color: $white;
								}
							}
							.loading{
								&:before{
									color: $white;
								}
							}
						}
						.loading {
							i{
								display: none;
							}
							span{
								display: none;
							}
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(18px);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								@include border-radius(50%);
								top:9px;
							}
							&:hover{
								&:before{
									border-color:rgba($white,0.5);
									border-top-color:$white;
								}
							}
						}                       
					} 
					.woosw-btn{                     
						font-size: 0;
						background: $white;
						text-align: center;
						position: relative;
						white-space: nowrap;
						margin-top: 0;
						color: $white; 
						@include square(40px);
						line-height: 40px;
						border: 0;
						padding: 0;
						cursor:pointer;
						@media (max-width:$screen-xs){
							@include square(35px);
							line-height: 35px;
						}
						&:before{
							content: "\e904";
							font-family: icomoon;
							font-size:19px;
							line-height: 40px;
							color: var(--gray-dark,$gray-dark);
							@include transition(all 0.1s ease);
							@media (max-width:$screen-xs){
								line-height: 35px;
								font-size:calc(var(--font-size-body,14px) + 1px);
							}
						}
						&.woosw-adding{
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-25,rgba($gray-dark,0.25));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(18px);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								top: 9px;
								left: 0;
								@include border-radius(50%);
							}
							&:hover{
								&:before{
									border-color:rgba($white,0.5);
									border-top-color:$white;
								}
							}
							@media (max-width:$screen-xs){
								&:before {
									@include square(16px);
									top: 7px;
								}
							}
						}
						&.woosw-added{
							&:before{
								margin:0;
								color: var(--theme-color,$theme-color);
								position: relative;
								@media (max-width:$screen-xs){
									line-height: 35px;
									font-size:calc(var(--font-size-body,14px) + 1px);
								}
							}
						}
						&:hover{
							background:var(--theme-color,$theme-color);
							&:before{
								color: $white;
							}
						}
					}
					.compare-button{
						text-align: center;
						background: $white;
						font-size: 0;
						@include border-radius(50%);
						display: block;
						white-space: nowrap;
						>a{
							color: var(--gray-dark,$gray-dark);
							@include square(45px);
							line-height: 45px; 
							border: 0;
							display: inline-block;
							&:before{
								font-size:calc(var(--font-size-body,14px) + 2px);
								color: var(--gray-dark,$gray-dark);
								line-height: 45px;
								@include transition(all 0.1s ease);
								content: "\e900";
								font-family:'wpbingo2';
							}
						}
						&:hover{
							>a{
								background:var(--theme-color,$theme-color);
								&:before{
									color: $white;
								}
							}
							.loading{
								&:before{
									color: $white;
								}
							}
						}
						.loading {
							i{
								display: none;
							}
							&:before {
								position: relative;
								color: var(--gray-dark,$gray-dark);
								display: inline-block;
								content: "\e02d";
								width: auto;
								height: auto;
								@include rtl-margin-left(0);
								font-family: 'ElegantIcons';
								background-color: transparent !important;
								background: none;
								font-size:18px;
								@include animation(2s linear 0s normal none infinite running spinAround);
							}
						} 
					}
					.product_type_grouped,
					.add_to_cart_button,
					.product_type_external, .read_more,
					.added_to_cart, .product_type_variable{
						color: $white;   
						display: inline-block;                    
						text-align: center;
						background: $white;
						@include square(40px);
						line-height: 40px;
						border: 0;
						padding: 0;
						font-size: 0;
						white-space: nowrap;
						@media (max-width:$screen-xs){
							display: none;
						}
						&:before{
							content: "\e902";
							font-family: icomoon;
							font-size:20px;
							color: var(--gray-dark,$gray-dark);
							@include transition(all 0.1s ease);
							margin:0;
							display: inline-block;
						}
						&.added_to_cart{
							display: none;
						}
						&.loading {
							span{
								display: none;
							}    
							@include transition(all 0.2s ease);                   
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(18px);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								top:9px;
								@include border-radius(50%);
							}
							&:hover{
								&:before{
									border-color:rgba($white, .5);
									border-top-color:$white;
								}
							}
						}     
						&:hover{ 
							background:var(--theme-color,$theme-color);
							color:$white;
							&:before{
								color: $white;
							}
						}
					}
					.added_to_cart, .product_type_variable{                        
						&:before{
							content:"\e92b";
							font-family: 'feather';
							text-indent: 0px
						}
						&:after{
							display: none;
						}
					} 
					.product_type_variable{
						&:before{
							content: "\e912";
							font-family: 'feather';
							font-size:18px;
						}
					}  
					.read_more{
						&:before{
							content: "\e912";
							font-family: 'feather';
							text-indent: 0;
							font-size:18px;
						}
					}                 
				}
				.btn-quickview{
					.product-quickview{
						position: absolute;
						bottom: 0;
						left: 50%;
						@include transform(translate(-50%, 15px));
						@include opacity-h();
						@include transition(all .3s ease);
						width: 100%;
						>a{
							background: var(--gray-dark,$gray-dark);
							color: $white;
							width: 100%;
							height: 50px;
							line-height: 50px;
							padding: 0 10px;
							display: inline-block;
							@include transition(all .3s ease);
							text-align: center;
							position: relative;
							text-transform: uppercase;
							font-weight: 500;
						}
						&:hover{
							>a{
								background:var(--theme-color,$theme-color);
							}
						}
						.loading{
							i{
								display: none;
							}
							span{
								display: none;
							}
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid rgba($white,0.25);
								border-top-color:$white;
								@include square(18px);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								left: 0;
								top:5px;
								@include border-radius(50%);
								@include transition(all 0s ease);
								@include opacity-s();
							}
						} 
					}
				}
				.btn-atc{
					>div{
						position: absolute;
						bottom: 0;
						width: 100%;
						padding: 0;
						@include transform(translateY(15px));
						@include opacity-h();
						@include transition(all .3s ease);
						>a{
							display:inline-block;
							color: $white;
							height: 50px;
							line-height:50px;
							padding:0 10px;
							width: 100%;
							background: var(--gray-dark,$gray-dark);
							@include transition(all 0.3s ease);
							text-align:center;
							position: relative;
							text-transform: uppercase;
							font-weight: 500;
							&:hover{
								background: var(--theme-color,$theme-color);
							}
							label{
								margin:0;
							}
							&.loading {
								span{
									display: none;
								}   
								&:before {
									position: relative;
									display: inline-block;
									content: "";
									border:2px solid rgba($white,0.5);
									border-top-color:$white;
									@include square(18px);
									@include border-radius(50%);
									background: none;
									@include animation(2s linear 0s normal none infinite running spinAround);
									text-indent: 0;
									top: 5px;
									left: 0;
									@include transition(all 0s ease);
									@include opacity-s();
								}
							}
							&.added_to_cart, .product_type_variable{
								&:after{
									display: none;
								}
								&:hover{
									color: transparent;
								}
							}
							&.added{
								display: none;
							}
						}
					}
					@media(max-width: $screen-xs){
						>div{
							display: none;
						}
					}
				}
				.product-stock{
					position:absolute;
					top:15px;
					@include rtl-right(15px);
					z-index:2;
					background: $white;
					color: var(--gray-dark,$gray-dark);
					padding:0 10px;
					font-size: 13px;
					text-transform: unset;
					letter-spacing: 0;
					line-height:25px;
					white-space: nowrap;
					text-transform: uppercase;
					font-weight: 500;
					span{
						color: var(--gray-dark,$gray-dark) !important;
					}
					&.pre-order{
						span{
							color: var(--gray-dark,$gray-dark) !important;
						}
					}
				}
            }
            .products-content{
				.rating{
					margin-bottom: 3px;
				}
				.cat-products{
					font-size:10px;
					text-transform:uppercase;
					letter-spacing: 2px;
					margin-bottom: 5px;
				}
                h3.product-title{
                    font-size:var(--font-size-body,$font-size-body);
                    margin: 0 0 2px;
					font-weight: 400;
					text-transform: uppercase;
					a{
						display:inline-block;
						position:relative;
						overflow:hidden;
						color: var(--gray-dark,$gray-dark);
						&:hover{
							color: var(--theme-color,$theme-color);
						}
					}
                }
                .price {
                    line-height: 100%;
                    display: inline-block;
					position:relative;
					overflow:hidden;
                    del{line-height: 100%;}
                } 
                .rating{
                    .review-count{
                        display: inline-block;
						position: relative;
						top: -3px;
						color: var(--text-color,$text-color);
						font-size:calc(var(--font-size-body,14px) - 2px);
						@include rtl-margin-left(2px);
						@media (max-width:$screen-xs-max){
							display:none;
						}
                    }
                }
				.btn-atc{
					margin: -7px 0 0;
					height: 30px;
					@include opacity-h();
					position: absolute;
					@include transition(all .1s ease);
					a{
						display:inline-flex;
						align-items: center;
						color:var(--gray-dark,$gray-dark);
						text-align:center;
						position:relative;
						text-transform: uppercase;
						font-size: 12px;
						font-weight: 500;
						line-height: 30px;
						label{
							margin:0;
						}
						&:before{
							content: "\e9c9";
							font-family: 'feather';
							font-size: 0;
							margin:0;
							display: inline-block;
							position: relative;
							line-height: 30px;
							top: 0;
							@include transition(margin .5s ease);
						}
						&:hover{
							color: var(--theme-color,$theme-color);
							&:before{
								font-size:calc(var(--font-size-body,14px) + 4px);
								@include rtl-margin-right(7px);
							}
						}
						&.loading {    
							@include transition(all 0.5s ease);
							&:before {
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-50,rgba($gray-dark, 0.5));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(18px);
								@include border-radius(50%);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								top: 0;
								@include rtl-margin-right(7px);
							}
							&:hover{
								&:before{
									border-color:var(--theme-color-50,rgba($theme-color, 0.5));
									border-top-color:var(--theme-color,$theme-color);
								}
							}
						}
						&.added_to_cart, .product_type_variable{                        
							&:before{
								content:"\4e";
								font-family: 'ElegantIcons';
								text-indent: 0px
							}
							&:after{
								display: none;
							}
						} 
						&.product_type_variable{
							&:before{
								content: "\24";
								font-family: 'ElegantIcons';
							}
						}  
						&.read_more{
							&:before{
								content: "\24";
								font-family: 'ElegantIcons';
								text-indent: 0;
							}
						}
						&.added{
							display: none;
						}
					}
				}
            }
			.available-box{
				margin-top: 15px;
				.percent{
					background: #ebebeb;
					height: 4px;
					@include border-radius(2px);
					margin-bottom: 5px;
					.content{
						height: 100%;
						@include border-radius(2px);
						background: #48b252;
					}
				}
				.content-available{
					.available{
						color: var(--gray-dark,$gray-dark);
						font-weight: 700;
					}
					label{
						font-weight: 400;
						color: var(--text-color,$text-color);
						@include rtl-margin(0, 5px, 0, 0);
					}
					span{
						margin: 0 2px;
					}
				}
			}
			&.content-product1{
				text-align: center;
				&:hover{
					.products-thumb{
						.product-button{
							>*{
								@include transform(translateY(0) !important);
							}
						}
					}
				}
				.product-attribute{
					justify-content: center;
				}
				.product-button{
					display: flex;
					justify-content: center;
					top: auto;
					bottom: 15px;
					left:0;
					padding:0 15px;
					width:100%;
					>*{
						margin: 0 5px;
						@include transform(translateY(15px) !important);
						position:relative;
						@media(max-width: $screen-xs){
							@include transform(translateY(0) !important);
							margin: 0;
						}
						&:nth-child(2n + 1){
							@include transform(translateY(-15px) !important);
						}
						&:before{
							top: -39px;
							left: 50% !important;
							@include transform(translateX(-50%));
						}
						&:after{
							top: -15px;
							left: 50% !important;
							border-color: var(--gray-dark,$gray-dark) transparent transparent transparent;
							@include transform(translateY(0));
						}
						&:hover{
							&:before{
								top: -34px;
							}
							&:after{
								top: -10px;
							}
						}
					}
				}
			}
			&.content-product3{
				.products-content{
					@include rtl-text-align-left();
					position:relative;
					.product-title{
						width:calc(100% - 20px);
					}
					.product-attribute{
						justify-content:flex-start;
					}
					.woosw-wishlist{
						position:absolute;
						top:0;
						@include rtl-right(0);
						line-height: 1;
						&:before{
							content:attr(data-title);
							position:absolute;
							padding:0 10px;
							background:var(--gray-dark,$gray-dark);
							color:$white;
							top: 50%;
							line-height: 23px;
							white-space: nowrap;
							left: -72px;
							font-size: 12px;
							z-index: 10;
							@include transform(translateY(-50%));
							@include opacity-h();
							pointer-events: none;
							@include transition(all 0.2s ease-in-out);
						}
						&:after{
							content: "";
							position: absolute;
							top: 50%;
							left: -8px;
							@include transform(translateY(-50%));
							margin-left: -5px;
							border-width: 5px;
							border-style: solid;
							border-color: transparent transparent transparent var(--gray-dark,$gray-dark);
							pointer-events: none;
							@include opacity-h();
							@include transition(all 0.2s ease-in-out);
						}
						&:hover{
							&:before,&:after{
								@include opacity-s();
							}
							&:before{
								left: -70px;
							}
							&:after{
								left: -5px;
							}
						}
					}
					.woosw-btn{                     
						font-size: 0;
						background: transparent;
						text-align: center;
						position: relative;
						white-space: nowrap;
						margin-top: 0;
						padding:0; 
						@include square(auto);
						border: 0;
						cursor:pointer;
						&:before{
							content: "\e904";
							font-family: icomoon;
							font-size:19px;
							color: #c1c1c1;
							@include transition(all 0.1s ease);
						}
						&.woosw-adding{
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(16px);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								top:0;
								left: 0;
								@include border-radius(50%);
							}
						}
						&.woosw-added{
							&:before{
								margin:0;
								color: var(--theme-color,$theme-color);
							}
						}
						&:hover{
							&:before{
								color: var(--theme-color,$theme-color);
							}
						}
					}
				}
			}
			&.content-product4{
				&.product-wapper{
					.product-attribute{
						justify-content: flex-start;
					}
					.products-content{
						@include rtl-text-align-left;
					}
				}
			}
        }
    }
    &.list{
        .product-wapper{
            margin-bottom: 60px;
            @include transition(all .3s ease 0s);
            &:hover{
                .products-thumb .hover-image{ @include opacity-s(); }
                .products-thumb .product-thumb-hover:before{background: #5d5d5d;opacity: 0.2;}
                .products-thumb{
                    .product-quickview{
                        @include opacity-s(); @include scale(1);
                    }
                }
				.countdown{
					@include transform(rotate3d(1,0,0,-90deg));
					@include opacity-h();
				}
				.product-attribute{
					@include transform(translateY(0));
					@include opacity-s();
				}
            }
			.product-attribute{
				bottom: 0;
				left:0;
				z-index: 9;
				@include opacity-s(); 
				@include transition(all 0.3s ease-in-out);
				display:flex;
				align-items:center;
				justify-content: center;
				position:absolute;
				bottom:0;
				width:100%;
				padding:5px 10px;
				background:$white;
				@include transform(translateY(100%));
				@include opacity-h();
				>div{
					padding:5px;
					position:relative;
					&:not(.label){
						&:before{
							content:attr(data-title);
							position:absolute;
							padding:0 10px;
							background:var(--gray-dark,$gray-dark);
							color:$white;
							top: -30px;
							line-height: 23px;
							white-space: nowrap;
							left: 50%;
							font-size: 12px;
							z-index: 10;
							@include transform(translateX(-50%));
							@include opacity-h();
							pointer-events: none;
							@include transition(all .2s ease-in-out);
						}
						&:after{
							content: "";
							position: absolute;
							top: -10px;
							left: 50%;
							margin-left: -5px;
							border-width: 5px;
							border-style: solid;
							border-color: var(--gray-dark,$gray-dark) transparent transparent transparent;
							pointer-events: none;
							@include opacity-h();
							@include transition(all .2s ease-in-out);
						}
						&:hover{
							&:before,&:after{
								@include opacity-s();
							}
							&:before{
								top: -25px;
							}
							&:after{
								top: -2px;
							}
						}
					}
					&.color{
						&:hover, &.active{
							span{
								@include box-shadow(none);
								border-color: var(--gray-dark,$gray-dark);
							}
						}
						span{
							@include square(22px);
							@include border-radius(50%);
							font-size: 0;
							display: inline-block;
							vertical-align: middle;
							cursor:pointer;
							@include transition(all .3s ease-in-out);
							position: relative;
							border: 2px solid $white;
							@include box-shadow(0 0 1px 1px #ddd);
						}
					}
					&.label{
						span{
							display:inline-block;
							border:1px solid #ccc;
							line-height:25px;
							padding:0 7px;
							cursor:pointer;
							min-width: 30px;
							font-size:calc(var(--font-size-body,14px) - 1px);
							text-align: center;
						}
						&:hover, &.active{
							span{
								background:#000;
								color:$white;
								border-color:#000;
							}
						}
					}
					&.images{
						img{
							@include square(25px);
							@include border-radius(50%);
							cursor:pointer;
							object-fit:cover;
							@include transition(all .3s ease-in-out);
							border: 2px solid $white;
							@include box-shadow(0 0 1px 1px #ddd);
						}
						&:hover, &.active{
							img{
								@include box-shadow(none);
								border-color: var(--gray-dark,$gray-dark);
							}
						}
					}
				}
				@media(max-width: $screen-xs){
					padding: 0;
				}
			}
			.countdown{
				position:absolute;
				bottom: 25px;
				right: 0;
				padding:0 5px;
				width: 100%;
				@include transition(all 0.3s ease);
				@media (max-width:$screen-xs-max){
					display:none;
				}
				.item-countdown{
					text-align:center;
				}
				.countdown-content{
					background: var(--theme-color,$theme-color);
					padding:0 15px;
					display: inline-block;
					line-height: 35px;
					>span{
						display:inline-block;
						font-size:calc(var(--font-size-body,14px) + 2px);
						color:$white;
						&:not(:last-child){
							&:after{
								content: ":";
								display: inline-block;
								margin: 0 5px;
							}
						}
					}
					.countdown-amount{
						position:relative;
					}
					@media (max-width:$screen-sm-max){
						padding:10px;
						line-height: 25px;
						>span{
							display:inline-block;
							font-size:calc(var(--font-size-body,14px) + 1px);
						}
					}
				}
			}
            .products-thumb{
				position: relative;
				overflow:hidden;
				@media(max-width:$screen-xs-max){
					margin-bottom:20px;
				}
                .product-thumb-hover{
                    position: relative;
                    display: block;
                    overflow: hidden;
                    &:before{
                        content: "";
                        position: absolute;
                        @include size(100%, 100%);
                        top: 0;
                        @include rtl-left(0);
                        opacity: 0;
                        @include transition(all 0.5s ease);
                        z-index: 9;
                    }                   
                }
                .wp-post-image{ 
                    @include transition(all 0.3s );  
                }
                .hover-image{
                    top: 0;
                    position: absolute;
                    overflow: hidden;
                    @include rtl-left(0);
                    @include opacity(1);
                    @include translate(-100%, 0px);
                    @include transition(all 0.3s);
                }
				.product-quickview{
					text-align: center;
					font-size: 0;
					white-space: nowrap;
					display: inline-block;
					position:absolute;
					top:calc(50% - 21px);
					left:calc(50% - 21px);
					@include square(42px);
					background:$white;
					text-align:center;
					line-height:42px;
					z-index:9999;
					@include transition(all 0.3s );
					@include opacity-h();
					@include scale(0);
					>a{
						color: var(--gray-dark,$gray-dark);
						@include square(42px);
						line-height: 42px;
						display: inline-block;
						position:relative;
						outline: unset;
						@include transition(all .5s ease);
						border: 0;
						&:before{
							content: "\e908";
							font-family: icomoon;
							font-size:calc(var(--font-size-body,14px) + 2px);
							color: var(--gray-dark,$gray-dark);
							@include transition(all 0.1s ease);
							margin:0;
							display: inline-block;
						}
					}
					&:hover{
						>a{
							background:var(--theme-color,$theme-color);
							&:before{
								color: $white;
							}
						}
						.loading{
							&:before{
								color: $white;
							}
						}
					}
					.loading {
						i{
							display: none;
						}
						span{
							display: none;
						}
						&:before {
							position: relative;
							display: inline-block;
							content: "";
							border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
							border-top-color:var(--gray-dark,$gray-dark);
							@include square(18px);
							background: none;
							@include animation(2s linear 0s normal none infinite running spinAround);
							text-indent: 0;
							@include border-radius(50%);
							top:9px;
						}
						&:hover{
							&:before{
								border-color:rgba($white,0.5);
								border-top-color:$white;
							}
						}
					}                       
				}
				&:hover{
					.hover-image{
						@include translate(0px,0px);
					}
					.wp-post-image{
						@include translate(100%, 0px);
					}
				}
            }
            .products-content{
				position:relative;
                h3{ 
                    font-size:var(--font-size-heading,$font-size-heading); 
                    margin-top: 0;
                    margin-bottom: 10px;
					text-transform: uppercase;
					a{
						color:var(--gray-dark,$gray-dark);
						&:hover{
							color:var(--theme-color,$theme-color);
						}
					}
                }
                .product-button{
					display:inline-flex;
					>div{
						@include rtl-margin-right(15px);
					}
                    .product-quickview{
						text-align: center;
						background: $white;
						font-size: 0;
						display: block;
						border:1px solid var(--theme-color,$theme-color);
						@include border-radius(50%);
						position: relative;					
						white-space: nowrap;
						@include transition(all 0.4s ease-in-out);
						@include rtl-float-left();
						>a{
							color: $white;
							@include square(38px);
							line-height: 40px; 
							display: inline-block;
							>i{
								font-size:calc(var(--font-size-body,14px) + 2px);
								color: var(--theme-color,$theme-color);
								line-height: 39px;
								@include transition(all 0.2s ease);
							}
						}
						&:hover{
							background-color: var(--theme-color,$theme-color);
							>a{
								>i{
									color: $white;
								}
							}
							.loading{
								&:before{
									color: $white;
								}
							}
						}
						.loading {
							i{
								display: none;
							}
							&:before {
								position: absolute;
								left: 9px;
								color: var(--theme-color,$theme-color);
								display: inline-block;
								content: "\e02d";
								width: auto;
								height: auto;
								@include rtl-margin-left(0);
								font-family: 'ElegantIcons';
								background-color: transparent !important;
								background: none;
								font-size:calc(var(--font-size-body,14px) + 6px);
								@include animation(2s linear 0s normal none infinite running spinAround);
							}
						}
					}
                    .woosw-btn{                          
                        margin-top: 0;
						@include square(42px);
                        line-height: 42px;
						border:1px solid $gray-light;
                        text-align: center;
                        font-weight: 500;
                        padding: 0;
                        position: relative;
                        display: inline-block;
						@include rtl-margin-right(20px);
                        @include rtl-float-left();
						font-size:0;
						background:transparent;
						&:before{
							content:"\f08a";
							position:absolute;
							@include rtl-left(0px);
							width:100%;
							color:var(--gray-dark,$gray-dark);
							font-family: 'FontAwesome';
							font-size:calc(var(--font-size-body,14px) + 3px);
						}
						&.woosw-added{
							&:before{
								content: "\f004";
								font-family: 'FontAwesome';
								font-size:calc(var(--font-size-body,14px) + 4px);
								line-height: 43px;
								color: var(--gray-dark,$gray-dark);
								@include transition(all 0.1s ease);
							}
						}
						&.woosw-adding{
							&:before {
								position: relative;
								display: inline-block;
								content: "";
								border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
								border-top-color:var(--gray-dark,$gray-dark);
								@include square(16px);
								@include border-radius(50%);
								background: none;
								@include animation(2s linear 0s normal none infinite running spinAround);
								text-indent: 0;
								top:7px;
								left: 0;
							}
							&:hover{
								&:before{
									border-color:rgba($white,0.5);
									border-top-color:$white;
								}
							}
						}
						&:hover{
							cursor:pointer;
							background:var(--theme-color,$theme-color);
							border-color: var(--theme-color,$theme-color);
							&:before{
								color:$white;
							}
						}
                    }
					.woocommerce{
						@include square(42px);
						@include border-radius(50%); 
						@include rtl-float-left();
						overflow:hidden;
						position:relative;
						border: 1px solid var(--border-color,$border-color);
						.compare{
							font-size:0;
							position:absolute;
							@include square(42px);
							top:0;
							left:0;
							&:before{
								position: absolute;
								content: "\e900";
								font-family:'wpbingo2';
								font-size:calc(var(--font-size-body,14px) + 2px);
								line-height:42px;
								text-indent: -1px;
								left: 14px;
								color: var(--gray-dark,$gray-dark);
							}
						}
						&:hover{
							background:var(--theme-color,$theme-color);
							.compare{
								&:before{
									color:$white;
								}
							}
						}
					}
                }
                .price {
                    font-size:calc(var(--font-size-body,14px) + 4px);
                    color: var(--theme-color,$theme-color);
                    margin-bottom: 24px;
					display:block;
                    del{
                        font-size:calc(var(--font-size-body,14px) + 1px);
                    }
                }
				.rating{
					margin-bottom:10px;
					.star-rating{
						@include rtl-margin-left(0)
					}
					.review-count{
                        display: inline-block;
						position: relative;
						top: -3px
                    }
				}
                .item-description{
                    margin-top: 35px;
					display:inline-block;
					width:100%;
					padding-top:35px;
					border-top:1px solid #e5e5e5; 
                }
				.product_type_grouped,
				.add_to_cart_button,
				.product_type_external, .read_more,
				.added_to_cart, .product_type_variable{
					text-align: center;
					position: relative;
					clear:both;
					padding:0 30px;
					@include rtl-margin-right(15px);
					@include rtl-float-left();
					overflow:hidden;
					display:inline-block;
					color: $white;
					height: 42px;
					line-height:42px;
					width: 100%;
					background: var(--button-color,$gray-dark);
					@include transition(all 0.3s ease);
					text-transform: uppercase;
					font-weight: 500;
					&:hover{
						background: var(--theme-color,$theme-color);
					}
					label{
						margin:0;
					}
					&.loading{
						span{
							position: unset;
							border: 0;
							animation: none;
							color: transparent;
						}   
						&:before {
							position: absolute;
							display: inline-block;
							content: "";
							border:2px solid rgba($white,0.5);
							border-top-color:$white;
							@include square(18px);
							@include border-radius(50%);
							background: none;
							@include animation(2s linear 0s normal none infinite running spinAround);
							text-indent: 0;
							top: calc(50% - 9px);
							left: calc(50% - 10px);
							@include transition(all 0s ease);
							margin: 0;
						}
					}
					&.added_to_cart, .product_type_variable{                  
						&:before{
							content:"\4e";
							font-family: 'ElegantIcons';
							text-indent: 0px;
							position: relative;
							top: 2px;
						}
						&:after{
							display: none;
						}
					}
					&.added{
						display: none;
					}
				}
				.added_to_cart, .product_type_variable{                        
					&:after{
						display: none;
					}
				} 
            }
        }   
    }
}   

.products_loadmore, .blog_loadmore{
    text-align: center;
    .btn.loadmore{
        color: var(--gray-dark,$gray-dark);
		overflow: hidden;
		padding: 0 60px;
		border: 1px solid $btn-border;
		height: 60px;
		text-transform: uppercase;
		font-weight: 500;
		line-height: 60px;
		background: transparent;
		@include border-radius(0);
		margin-top: 20px;
		.lds-ellipsis {
			display: inline-block;
			position: absolute;
			width: 65px;
			height: 8px;
			top: calc(50% - 4px);
			left: calc(50% - 32.5px);
			@include opacity-h();
			strong{
				position: absolute;
				top: 0;
				@include square(8px);
				@include border-radius(50%);
				background: var(--gray-dark,$gray-dark);
				animation-timing-function: cubic-bezier(0,1,1,0);
				&:nth-child(1){
					left: 5px;
    				animation: lds-ellipsis1 .6s infinite;
				}
				&:nth-child(2){
					left: 5px;
    				animation: lds-ellipsis2 .6s infinite;
				}
				&:nth-child(3){
					left: 29px;
					animation: lds-ellipsis2 .6s infinite;
				}
				&:nth-child(4){
					left: 53px;
					animation: lds-ellipsis3 .6s infinite;
				}
			}
		}
        &.loading{
			border-color: transparent;
			.lds-ellipsis {
				@include opacity-s();
			}
			&:before{
				display:none;
			}
			.loadmore-button-text{
				@include opacity-h();
			}
			&:hover{
				background: transparent;
				border-color: transparent !important;
			}
        }
		&:before{
			display:none;
		}
		&:hover{
			border-color: var(--theme-color,$theme-color);
			background: var(--theme-color,$theme-color);
			color: $white;
		}
    }
}

@keyframes spinAround {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Woocommerce page ---------------------------------------------*/
.checkout{
    .form-row{
		margin-bottom : 30px;
		margin-left:0px;
		margin-right : 0px;
        label{
            color: var(--gray-dark,$gray-dark);
			@include rtl-float-left();
			margin-bottom : 10px;
        }
		.woocommerce-input-wrapper{
			width: 100%;
			@include rtl-float-left();
			.input-text {
				width: 100%;
				border-width: 0 0 2px;
				border-color: var(--border-color,$border-color);
				padding: 0;
				height : 40px;
				line-height: 40px;
				color: var(--gray-dark,$gray-dark);
			}
			textarea{
				height : auto !important;
			}
		}
        .selection .select2-selection--single{
			height: 40px;
			line-height: 40px;
			border-width: 0 0 2px 0;
			border-color: var(--border-color,$border-color);
			border-radius: 0;
            span{
                line-height: 40px;
                padding: 0;
                height: 40px;
            }
        }
    }
	#place_order{
		color:$white;
		background:var(--button-color,$gray-dark);
		font-size:calc(var(--font-size-body,14px) + 2px);
		padding:0 50px;
		line-height: 68px;
		cursor:pointer;
		border : 0;
		width:100%;
		font-weight:500;
		position: relative;
		@include transition(all .2s ease);
		text-transform: uppercase;
		&:hover{
			background: var(--theme-color,$theme-color);
		}
	}
	.woocommerce-checkout-payment{
		.place-order{
			padding:0;
		}
		.payment_methods {
			padding: 20px;
			margin-bottom: 30px;
			list-style: none;
			border:1px solid #d9d9d9;
			li {
				padding: 17px 0 0;
				margin-bottom:0;
				.payment_box{
					padding-bottom:17px;
				}
				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					border-bottom: 0;
				}
				& > label {
					float:unset;
					margin:0;
					@include rtl-padding-left(18px);
					position:relative;
					margin-bottom:15px;
					cursor:pointer;
					color:var(--gray-dark,$gray-dark);
					&:before{
						position:absolute;
						@include rtl-left(0);
						top:calc(50% - 6px);
						@include square(12px);
						border:1px solid var(--text-color,$text-color);
						content:"";
						@include border-radius(50%);
					}
				}
				.input-radio:checked + label {
					color: var(--gray-dark,$gray-dark);
					&:before {
						background:var(--gray-dark,$gray-dark);
						border-color:var(--gray-dark,$gray-dark);
					}
				}
			}
			img {
				height: 1.5625em;
				vertical-align: middle;
				margin: 0 16px;
			}
			.woocommerce-notice {
				padding:35px 20px 20px !important;
				min-height: auto;
			}
			.about_paypal {
				float: right;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					display: block;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background-color: var(--gray-dark,$gray-dark);
					@include transform(scaleX(1));
					transform-origin: 0 50%;
					transition: transform .48s cubic-bezier(.77,0,.175,1);
				}
				&:hover{
					&:after{
						transform-origin: 100% 50%;
						@include transform(scaleX(0));
						animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
					}
				}
			}
			input {
				&.input-radio {
					display: none;
				}
			}
			.payment_box {
				line-height:1.5;
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.woocommerce-shipping-methods{
		.shipping_method{
			display:none;
		}
		label{
			margin:0;
			@include rtl-margin-left(5px);
			font-weight:400;
			margin-bottom:0;
			position:relative;
			@include rtl-padding-left(18px);
			cursor:pointer;
			&:before{
				position:absolute;
				@include rtl-left(0);
				top:calc(50% - 6px);
				@include square(12px);
				border:1px solid var(--text-color,$text-color);
				content:"";
				@include border-radius(50%);
			}
		}
		.shipping_method:checked +label{
			&:before{
				background:var(--gray-dark,$gray-dark);
				border-color:var(--gray-dark,$gray-dark);
			}
		}
	}
    .radio label,
    .checkbox label{
        margin-left: 0;
        padding-left: 0;
        .input-checkbox{
            position: static;
            margin: 0;
        }
    }
    .woocommerce-form-login{
        .woocommerce-form__label{
            margin-top: 10px;
            width: 100%;
            display: block;
            span{
                @include rtl-margin-left(25px);
            }
            input[type="checkbox"]{
                position: absolute;
                margin-top: 5px;
            }
        }
        .form-row{
            clear: both;
            &.form-row-first, &.form-row-last{
                clear: none;
            }
        }
    }
    h3{
        margin-top: 0;
        font-size: calc(var(--font-size-heading,25px) - 1px);
		margin-bottom:30px;
		text-transform: uppercase;
    }
    ul.payment_methods{
        li{
            width: 100%;
            clear: both;
            label{
                padding-left: 10px;
                @include rtl-float-left();
				margin-top:-8px;
                .input-radio{
                    left: 0;
                    margin:6px 0 0 0;
                }
            }
            >input{
                @include rtl-float-left();
            }
            .payment_box{
                width: 100%;
                clear: both;
            }
            &.payment_method_paypal{
                >label{
                    img{
                        margin: 0 10px;
                    }
                }
            }
        }
    }
    .place-order{
        clear: both;
    }
    #payment_method_paypal{
        margin-top : 47px;
    }
    #payment_method_cheque{
         @include rtl-float-left();
    }
	.woocommerce-checkout-review-order{
		.checkout-review-order-table-wrapper{
			padding : 30px 40px;
			border:1px solid var(--gray-dark,$gray-dark);
			@media (max-width:$screen-xs){
				padding : 30px 20px;
			}
		}
		.title-product-name{
			font-size:calc(var(--font-size-heading,25px) - 3px);
			color:var(--gray-dark,$gray-dark);
			margin-bottom:22px;
			font-weight:500;
			text-transform: uppercase;
		}
		.cart_item{
			display:flex;
			margin-bottom:15px;
			justify-content:space-between;
			&:last-child{
				margin-bottom:0;
			}
		}
		.product-total{
			color: var(--theme-color,$theme-color);
			font-weight: 500;
		}
		.info-product{
			display:flex;
			.product-thumble{
				max-width:55px;
			}
			.product-name{
				@include rtl-padding-left(15px);
				color:var(--gray-dark,$gray-dark);
				word-break: break-word;
				text-transform: uppercase;
				.product-quantity{
					display:block;
					font-weight:700;
				}
			}
		}
		h2{
			margin:0;
			font-size:var(--font-size-body,$font-size-body);
			text-transform: uppercase;
		}
		.cart-subtotal{
			margin-top:30px;
		}
		.cart-subtotal,.order-total{
			display:flex;
			justify-content:space-between;
			align-items:center;
			border-top:1px solid #dedede;
			padding:27px 0;
			.subtotal-price{
				color: var(--theme-color,$theme-color);
				font-weight: 500;
			}
		}
		.woocommerce-shipping-totals{
			display:flex;
			align-items:center;
			justify-content:space-between;
			border-top:1px solid #dedede;
			padding:27px 0;
		}
		.total-price{
			font-size:calc(var(--font-size-body,14px) + 6px);
			color: var(--theme-color,$theme-color);
		}
		.woocommerce-shipping-methods{
			@include rtl-text-align-right();
			padding:0;
			list-style:none;
			li{
				margin:5px 0;
			}
		}
	}
    #ship-to-different-address{
        display : block;
        margin : 10px 0px 20px;
		font-size:var(--font-size-body,$font-size-body);
		@media (max-width:$screen-xs-max){
			@include rtl-margin-left(0);
		}
		label{
			font-weight:400;
			&.woocommerce-form__label-for-checkbox{
				cursor:pointer;
				span{
					position:relative;
					@include rtl-padding-left(18px);
					&:before{
						position:absolute;
						@include rtl-left(0);
						top:calc(50% - 6px);
						@include square(12px);
						border:1px solid var(--text-color,$text-color);
						@include border-radius(50%);
						content:"";
					}
				}
				.woocommerce-form__input:checked +span{
					&:before{
						background:var(--theme-color,$theme-color);
						border-color:var(--theme-color,$theme-color);
					}
				}
			}
		}
        #ship-to-different-address-checkbox{
			display:none;
        }
    }
    .create-account .woocommerce-form__label{
        span{
            @include rtl-margin-left(10px);
        }
        input[type="checkbox"]{
            @include rtl-margin-left(0);
            margin-top: 5px;
        }
    }
    .woocommerce-checkout{
        clear: both;
    }
    .checkout_coupon{
        >.form-row{
            margin-bottom: 30px;
        }
        input[name="apply_coupon"]{
            padding : 9px 10px;
        }       
    }
}

.woocommerce-order-received,.woocommerce-view-order{
    font-size:var(--font-size-body,$font-size-body);
    .woocommerce .woocommerce-thankyou-order-received{
        background: transparent;
        border: 2px dashed var(--theme-color,$theme-color);
        font-weight: 700;
        width: 100%;
        text-align: center;
        font-size:calc(var(--font-size-body,14px) + 2px);
        margin-bottom: 30px;
        line-height: 1.4;
        padding: 15px 25px;
        color: var(--theme-color,$theme-color);
    }
    .woocommerce-thankyou-order-details{
        list-style-type: none;
        padding: 0;
        margin-bottom: 50px;
        @media (min-width: $screen-sm-max){
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li{
                @include rtl-border-right(1px solid var(--border-color,$border-color));
                border-bottom: none !important;
                padding-bottom: 0px !important;
                &:last-child{
                    @include rtl-border-right(none);
                }
            }
        }
        li{
            flex-grow: 1;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 20px;
            text-align: center;
            border-bottom: 1px solid var(--border-color,$border-color);
            padding-bottom: 20px;
        }
    }
    .woocommerce-order-details{
        .woocommerce-order-details__title{
            font-size:calc(var(--font-size-body,14px) + 6px);
        }
		.dokan-info{
			background:var(--theme-color,$theme-color);
			color:$white;
			border-top-color:var(--gray-dark,$gray-dark);
			&:before{
				background:var(--gray-dark,$gray-dark);
			}
		}
		header >h2{
			font-size:calc(var(--font-size-body,14px) + 6px);
		}
		@media (max-width:730px){
			.shop_table.my_account_orders{
				display:block;
				overflow:auto;
				th,td{
					padding:15px;
					min-width:150px;
				}
			}
		}
    }
    .woocommerce-customer-details{
        .woocommerce-column.col-1{
            margin-top: 40px;
        }
		.woocommerce-columns{
			margin: 0 -15px;
			display:flex;
			flex-wrap:wrap;
			width:100%;
			.woocommerce-column{
				margin-top: 40px;
			   flex: 0 0 50%;
				max-width: 50%;
				padding: 0 15px;
			}
			@media (max-width:$screen-xs-max){
				margin: 0;
				.woocommerce-column{
					flex: 0 0 100%;
					max-width: 100%;
					padding:0;
				}
			}
		}
        address{
            padding: 25px 30px 30px 30px;
            border: 2px dashed var(--border-color,$border-color);
        }
        .woocommerce-column__title{
            font-size:calc(var(--font-size-body,14px) + 6px);
        }
    }
}

/* Category */
.term-description {
    margin-bottom: 30px;
}
.woocommerce-product-subcategorie-content{
	&:not(.active){
		opacity: 0;
		height: 0;
		.product-category{
			display: none;
		}
	}
	.subcategorie-content{
		position:relative;
		max-width:750px;
		margin:10px auto 0;
	}
	&.active{
		display:block;
	}
	&:hover{
		.slick-arrow{
			@include opacity-s();
		}
	}
	.slick-arrow{
		@include transition(all 0.3s ease);
		top: calc(35% + 5px);
		@include square(auto);
		@include border-radius(0);
		line-height:1;
		background:transparent;
		font-size: 30px;
		@include box-shadow(unset);
		border:0;
		&.fa-angle-right {
			@include rtl-right(-40px);
		}
		&.fa-angle-left {
			@include rtl-left(-40px);
		}
		&:hover{
			background:transparent;
			color:var(--theme-color,$theme-color);
		}
		@media (max-width:$screen-sm-max){
			&.fa-angle-right {
				@include rtl-right(0);
			}
			&.fa-angle-left {
				@include rtl-left(0);
			}	
		}
		@media(max-width: $screen-xs){
			font-size:var(--font-size-heading,$font-size-heading);
		}
	}
	&.image_categories2{
		margin-top:-50px;
		margin-bottom:50px;
		border-bottom:1px solid #ECECEC;
		padding-bottom:35px;
		.container{
			position:relative;
		}
		ul.woocommerce-product-subcategories{
			.slick-track{
				padding:0;
			}
			.slick-list{
				margin:0 -10px;
			}
			li{
				padding:0 10px;
				a{
					width: auto;
					height: auto;
					img{
						border-radius:0;
					}
				}
				h2{
					font-size:calc(var(--font-size-body,14px) + 4px);
					text-transform:revert;
				}
			}
			.product-category.active{
				>a{
					border:1px solid var(--gray-dark,$gray-dark);
					img{
						@include scale(0.9);
					}
				}
				h2 a{
					color:var(--theme-color,$theme-color);
				}
			}
		}
		@media (max-width:$screen-xs-max){
			margin-top: -15px;
			ul.woocommerce-product-subcategories li{
				h2{
					font-size:calc(var(--font-size-body,14px) + 2px);
				}
			}
		}
	}
}
ul.woocommerce-product-subcategories{
    list-style: none;
    padding: 0;
    width: 100%;
	position:relative;
	&.image_categories{
		li{
			>a{
				position:relative;
				&:hover{
					&:before{
						position:absolute;
						content:"";
						@include square(calc(100% + 11px));
						top:50%;
						left:50%;
						@include transform(translate(-50%,-50%));
						@include border-radius(50%);
						@include transition(all .3s ease);
						border:1px solid var(--theme-color,$theme-color);
					}
				}
			}
			&.active{
				>a{
					&:before{
						position:absolute;
						content:"";
						@include square(calc(100% + 11px));
						top:50%;
						left:50%;
						@include transform(translate(-50%,-50%));
						@include border-radius(50%);
						border:1px solid var(--theme-color,$theme-color);
					}
				}
				h2{
					a{
						&:before{
							width:100%;
						}
					}
				}
			}
		}
	}
	.slick-track{
		margin:auto;
		padding-top:25px;
	}
    li{
		text-align:center;
		>a{
			@include square(115px);
			display:inline-block;
			position:relative;
			img{
				width:100%;
				height: auto;
				@include transition(transform .8s cubic-bezier(.215,.61,.355,1),opacity .8s cubic-bezier(.215,.61,.355,1));
				@include border-radius(50%);
			}
			@media(max-width: $screen-xs){
				@include square(100%);
			}
		}
        h2{
            font-size: calc(var(--font-size-body,14px) - 2);
			text-transform: uppercase;
			margin: 10px 0 6px;
			a{
				display:inline-block;
				position:relative;
				padding-bottom:3px;
				&:after{
					content: "";
					position: absolute;
					display: block;
					bottom: 0;
					left: 0;
					@include size(0 ,1px);
					background: var(--theme-color,$theme-color);
					@include transition(width .48s cubic-bezier(.77,0,.175,1));
				}
				&:hover{
					&:after{
						width: 100%;
					}
				}
			}
        }
		.count-product{
			font-size:var(--font-size-body,$font-size-body);
			color:$white;
		}
    }
}

/* Product Detail ---------------------------------------------*/
.main-single-product{
	&.sidebar{
		display:flex;
		flex-wrap:wrap;
		margin-left:auto;
		margin-right:auto;
		max-width:1470px;
		@media (max-width:1440px){
			>div:not(.bwp-sidebar){
				padding:0;
			}
		}
	}
	>.col-xl-12{
		padding:0;
	}
}
.contents-detail{
    .bwp-single-product{
		max-width:1440px;
		padding-left:15px;
		padding-right:15px;
		margin-left:auto;
		margin-right:auto;
		margin-bottom: 50px;
        .slick-vertical .slick-slide{
            border: none;
        }
		.woocommerce-product-rating{
			a{
				color:var(--text-color,$text-color);
				display:inline-block;
				vertical-align: middle;
			}
		}
		.onsale,.hot{
			line-height:30px;
			padding:0 12px;
			font-size:calc(var(--font-size-body,14px) + 1px);
			top:15px;
			&:before{
				display:none;
			}
		}
		.img-thumbnail:not(.slick-slide){
			@include border-radius(0);
			padding:0;
			border: 0;
		}
		@media(min-width:$screen-xs-max){
			&:not(.moderm,.scroll,.grid_sticky,.light) .images.vertical{
				.woocommerce-product-gallery{
					>.row{
						display:inline-block;
						>div{
							@include rtl-float-left();
						}
					}
				}
			}
		}
		.bwp-single-info{
			margin-bottom:-9px;
			@include rtl-padding-left(80px);
			@media (max-width:1600px){
				@include rtl-padding-left(50px);
			}
			@media (max-width:$screen-md-max){
				@include rtl-padding-left(15px);
			}
			@media (max-width:$screen-sm-max){
				padding-top: 30px;
			}
			@media (max-width:$screen-xs-max){
				padding-top: 10px;
			}
			>div{
				position: relative;
			}
			.image-thumbnail-list{
				.slick-arrow{
					@include square(30px);
					font-size:calc(var(--font-size-body,14px) + 4px);
					line-height:30px;
					top: calc(50% - 5px);
					@media (max-width:$screen-sm-max){
						@include opacity-s();
					}
					&.fa-angle-left{
						@include rtl-left(-12.5px);
					}
					&.fa-angle-right{
						@include rtl-right(-12.5px);
					}
				}
				&:hover{
					.slick-arrow{
						@include opacity-s();
					}
				}
			}
		}
		.mafoil-bt-video{
			position:absolute;
			@include rtl-left(16px);
			bottom:18px;
			z-index:1111;
			.bwp-video{
				line-height:37px;
				font-weight:500;
				background:$white;
				@include border-radius(20px);
				@include box-shadow( 0px 1px 12px 2px rgba(144, 144, 144, .15));
				display:inline-block;
				padding:0 30px 0 20px;
				font-weight:500;
				cursor:pointer;
				color:var(--gray-dark,$gray-dark);
				&:before{
					display:inline-block;
					content: "\e908";
					font-family:wpbingofont;
					font-size:calc(var(--font-size-body,14px) - 1px);
					position:relative;
					top:1px;
					line-height:1;
					@include rtl-margin-right(5px);
				}
				&:hover{
					color:var(--theme-color,$theme-color);
				}
			}
			.content-video{
				position:fixed;
				width:100%;
				height:100%;
				top:0;
				left:0;
				display:none;
				z-index:10000;
				background:var(--gray-dark-50,rgba($gray-dark, 0.5));
				.remove-show-modal{
					position:fixed;
					top:0;
					left:0;
					width:100%;
					height:100%;
					display:none;
				}
				&.show{
					.remove-show-modal,.modal-dialog{
						display:block;
					}
				}
				.modal-dialog{
					position:absolute;
					top:50%;
					left:50%;
					@include transform(translate(-50%,-50%));
					display:none;
					iframe{
						height:60vh;
						width:70vw;
					}
				}
			}
		}
        &.scroll{
			.col-md-2{
				.slick-arrow{
					border:none;
					@include square(30px);
					line-height:30px;
					&:hover{
						background:var(--theme-color,$theme-color);
						color:$white;
					}
					&:after{
						display: none;
					}
					&.fa-angle-right{
						bottom: -35px;
						top: auto;
					}
				}	
			}
			&:hover{
				.col-md-2{
					.slick-arrow{
						opacity: 1;
						&.fa-angle-left{
							top: -30px;
						}
						&.fa-angle-right{
							bottom: -30px;
						}
					}
				}
			}
			div.col-sm-12 {
				.scroll-image{
					.slick-arrow{
						&.fa-angle-left{
							@include rtl-left(20px !important);
						}
						&.fa-angle-right{
							@include rtl-right(20px !important);
						}
					}
				}
			}
			.bwp-single-image{
				.row{
					margin:0 -9px;
					>div{
						padding:0 7.5px;
					}
				}
				.scroll-image{
					.image-additional{
						.img-thumbnail{
							margin-bottom: 0;
						}
					}
				}
			}
        }      
        &.one_column{
			padding:0;
			background:transparent;
            .img-thumbnail{
                padding-bottom : 15px;
				margin-bottom: 15px;
                &:last-child{
                    padding-bottom: 0;
					margin-bottom: 0;
                }
            }
			>.row{
				display:inline-block;
				margin:0;
				>div{
					@include rtl-float-left();
				}
			}
			.featured-icon .block-top-link{
				margin:0;
			}
			.simplePopup{
				position:absolute!important;
			}
			.bwp-single-info{
				position:sticky;
				position:-webkit-sticky;
				top:35px;
			}
        }
		&.two_column{
			padding:0;
			background:transparent;
			.image-additional{
				margin: 0 -12px;
				display: flex;
				flex-wrap: wrap;
			}
            .img-thumbnail{
                margin-bottom: 24px;
				width: 50%;
				padding: 0 12px;
                &:last-child{
                    padding-bottom: 0;
                }
            }
			>.row{
				display:inline-block;
				margin:0;
				>div{
					@include rtl-float-left();
				}
			}
			.featured-icon .block-top-link{
				margin:0;
			}
			.simplePopup{
				position:absolute!important;
			}
			.bwp-single-info{
				position:sticky;
				position:-webkit-sticky;
				top:35px;
			}
        }
		&.slider{
			max-width:100%;
			.featured-icon{
				max-width:1440px;
				margin-left: auto;
				margin-right: auto;
				
			}
			.prev_next_buttons{
				position:unset;
				margin-bottom:15px;
			}
			.size-guide{
				.size-guide__title{
					position: unset;
					@include transform(translateY(0));
				}
			}
			.bwp-single-info{
				max-width: 740px;
				margin: 50px auto 0;
				@include rtl-padding-left(15px);
				text-align: center;
				.entry-summary{
					padding:0;
				}
				.entry-heading{
					.price{
						border-bottom:0;
					}
				}
				.entry-cart{
					.variations{
						margin-top:0;
						tr{
							td{
								text-align: center;
								.tawcvs-swatches{
									justify-content: center;
									.swatch-item-wrapper{
										margin: 0 5px 10px;
									}
								}
							}
						}
					}
				}
				@media (max-width:$screen-sm-max){
					.entry-cart{
						margin-top:50px;
					}
				}
				.entry-summary .price-single{
					margin-bottom:30px;
					justify-content: center;
				}
				.countdown-single{
					margin-top: 25px;
					justify-content: center;
				}
				.size-guide{
					margin-bottom: 10px;
				}
				.product-shipping-delivers{
					li{
						justify-content: center;
					}
				}
			}
			.bwp-single-image{
				padding:0;
				&:hover{
					.slick-arrow{
						@include opacity-s();
					}
				}
				.slick-dots{
					margin:0;
					position: absolute;
					bottom: 30px;
					z-index: 1000;
				}
				.video-additional{
					iframe{
						width:100% !important;
					}
				}
				.slick-arrow{
					font-size:calc(var(--font-size-heading,25px) + 5px);
					color: var(--gray-dark,$gray-dark);
					top:calc(50% - 15px);
					@include square(auto);
					background:transparent;
					z-index:1000;
					@include opacity-s();
					&:hover{
						color: var(--theme-color,$theme-color);
					}
					&.fa-angle-right{
						@include rtl-right(calc(27.3% + 15px));
					}
					&.fa-angle-left{
						@include rtl-left(calc(27.3% + 15px));
					}
					@media (max-width:$screen-md-max){
						&.fa-angle-right{
							@include rtl-right(calc(20% + 15px));
						}
						&.fa-angle-left{
							@include rtl-left(calc(20% + 15px));
						}
					}
					@media (max-width:$screen-xs-max){
						&.fa-angle-right{
							@include rtl-right(15px);
						}
						&.fa-angle-left{
							@include rtl-left(15px);
						}
					}
				}
				.slick-slider{
					overflow: hidden;
				}
				.slick-list{
					padding: 0 26.3%;
					@media (max-width:$screen-md-max){
						padding: 0 20%;
					}
					@media (max-width:$screen-xs-max){
						padding: 0;
					}
				}
			}
		}
		&.grid{
			.simplePopup{
				position:absolute!important;
			}
			.bwp-single-info{
				position:sticky;
				position:-webkit-sticky;
				top:35px;
			}
			>.row{
				display:inline-block;
				>div{
					@include rtl-float-left();
				}
			}
			.image-additional{
				margin:0 -12px;
				display:flex;
				flex-wrap:wrap;
				.img-thumbnail,.video-additional{
					padding:0 12px;
					width:50%;
					margin-bottom:24px;
					&.woocommerce-product-gallery__image{
						width:100%;
					}
					iframe{
						width: 100% !important;
						height: 100% !important;
						min-height:300px;
						@media (max-width:$screen-xs){
							min-height:200px;
						}
					}
				}
				
			}
		}
		&.grid_sticky{
			.img-thumbnail{
				padding-bottom: 15px;
				>.img-thumbnail-scroll{
                    vertical-align: top;
                    display: inline-block;  
                    @include transition(all 0.2s ease);
					border: 1px solid $border-product;
					overflow: hidden;
					cursor: pointer;
                }
				&.slick-current >.img-thumbnail-scroll, >a.active, &:hover >a, &:hover >.img-thumbnail-scroll{
                    border-color:var(--gray-dark,$gray-dark);
					img{
						@include transform(scale3d(.8, .85, .85));
						@include transition(all .25s cubic-bezier(.645, .045, .355, .5));
					}
                } 
			}
			.col-md-2{
				.slick-arrow{
					border:none;
					@include square(30px);
					line-height:30px;
					&:hover{
						background:var(--theme-color,$theme-color);
						color:$white;
					}
					&:after{
						display: none;
					}
					&.fa-angle-right{
						bottom: -35px;
						top: auto;
					}
				}	
			}
			&:hover{
				.col-md-2{
					.slick-arrow{
						opacity: 1;
						&.fa-angle-left{
							top: -30px;
						}
						&.fa-angle-right{
							bottom: -30px;
						}
					}
				}
			}
			div.col-sm-12 {
				.scroll-image{
					.slick-arrow{
						&.fa-angle-left{
							@include rtl-left(20px !important);
						}
						&.fa-angle-right{
							@include rtl-right(20px !important);
						}
					}
				}
			}
			.bwp-single-image{
				.row{
					margin:0 -7.5px;
					>div{
						padding:0 7.5px;
					}
				}
			}
			@media(max-width: $screen-sm-max){
				.entry-summary{
					position: unset !important;
				}
			}
        }
		&.lagre_gallery{
			max-width:100%;
			figure{
				margin-bottom:0;
			}
			.img-thumbnail{
				>a img{
					border-width: 0 1px 0;
				}
			}
			.bwp-single-image{
				flex: 0 0 57%;
				max-width: 57%;
				padding:0;
				position:relative;
				&:hover{
					.slick-arrow{
						@include opacity-s();
					}
				}
			}
			.slick-dots{
				position:absolute;
				bottom:45px;
				padding:0 20px;
				@include rtl-text-align-right();
				z-index: 1000;
			}
			.slick-arrow{
				border:0;
				font-size: 30px;
				color: var(--gray-dark,$gray-dark);
				top:calc(50% - 17.5px);
				@include opacity-s();
				z-index:1000;
				@include transition(all .3s ease);
				&.fa-angle-right{
					@include rtl-right(30px);
				}
				&.fa-angle-left{
					@include rtl-left(30px);
				}
				&:hover{
					color: var(--theme-color,$theme-color);
				}
			}
			.bwp-single-info{
				flex: 0 0 43%;
				max-width: 585px;
				@include rtl-padding(80px ,15px ,0 ,120px);
			}
			.img-thumbnail{
				img{
					width:100%;
				}
			}
			@media (max-width:$screen-md-max){
				.bwp-single-info{
					@include rtl-padding(30px ,15px ,0 ,50px);
				}
			}
			@media (max-width:$screen-sm-max){
				.bwp-single-info{
					flex: 0 0 100%;
					max-width:100%;
					@include rtl-padding(0 ,15px ,0 ,15px);
					margin-top:30px;
				}
				.bwp-single-image{
					flex: 0 0 100%;
					max-width:100%;
				}
			}
			@media (max-width:$screen-xs){
				.slick-arrow{
					&.fa-angle-right{
						@include rtl-right(15px);
					}
					&.fa-angle-left{
						@include rtl-left(15px);
					}
				}
				.slick-dots{
					padding:0 15px;
				}
			}
		}
		&.clean{
			max-width:100%;
			.woocommerce-product-gallery{
				margin-bottom:0;
			}
			>.row{
				align-items:center;
			}
			.img-thumbnail{
				>a img{
					border-width: 1px 1px 0;
				} 
			}
			.mafoil-bt-video{
				position:unset;
				margin-bottom:10px;
				.bwp-video{
					background:transparent;
					@include box-shadow(unset);
					padding:0;
					font-size:var(--font-size-body,$font-size-body);
					letter-spacing:1.5px;
					&:before{
						font-size:calc(var(--font-size-heading,25px) + 5px);
						top:7px;
						content:"\e912";
					}
				}
			}
			.bwp-single-info{
				>.mafoil-360-button{
					position:unset;
					background:transparent;
					@include box-shadow(unset);
					padding:0;
					font-size:var(--font-size-body,$font-size-body);
					letter-spacing:1.5px;
					margin-bottom:10px;
					i{
						font-size:calc(var(--font-size-heading,25px) + 3px);
						top:0px;
					}
				}
			}
			.price-single{
				padding:0;
				margin:0;
				border:0;
				justify-content:center;
			}
			.entry-heading{
				max-width:420px;
				text-align:center;
				margin:40px auto;
				position: relative;
			}
			.star-rating{
				top: 4px;
			}
			.product_title{
				font-size: 35px;
				margin-top: 10px;
				line-height:1.1;
				width: auto;
			}
			.bwp-single-image{
				padding:0;
				position:relative;
				&:hover{
					.slick-arrow{
						@include opacity-s();
					}
				}
			}
			.size-guide{
				>.title-size-guide{
					position:unset;
					margin:0;
				}
			}
			.bwp-single-info{
				max-width:450px;
				margin:auto;
				padding:0;
				.description{
					display:none;
				}
				.countdown-single{
					margin:30px 0 0;
				}
			}
			.slick-dots{
				position:absolute;
				bottom:45px;
				padding:0 60px;
				@include rtl-text-align-right();
				z-index: 1000;
			}
			.slick-arrow{
				background:transparent;
				border:0;
				font-size:calc(var(--font-size-heading,25px) - 3px);
				font-family: wpbingofont;
				color:var(--theme-color,$theme-color);
				top:calc(50% - 17.5px);
				&:hover{
					color: darken($theme-color, 10%);
					color: var(--theme-color);
				}
				&.fa-angle-right{
					@include rtl-right(50px);
					&:before{
						content: "\e91b";
					}
				}
				&.fa-angle-left{
					@include rtl-left(50px);
					&:before{
						content: "\e91a";
					}
				}
			}
			@media (max-width:1440px){
				.entry-heading{
					margin:0 0 20px;
				}
				.entry-title{
					font-size:45px;
					margin:10px 0;
				}
			}
			@media (max-width:$screen-md-max){
				>.row{
					align-items:flex-start;
				}
				.entry-heading{
					@include transform(translateX(0));
					@include rtl-text-align-left();
					max-width:450px;
					margin:0 auto 20px;
				}
				.price-single{
					justify-content:flex-start;
				}
			}
			@media (max-width:$screen-sm-max){
				.entry-heading{
					max-width:100%;
					margin-top:30px;
				}
				.bwp-single-info{
					max-width:100%;
				}
				.slick-arrow{
					&.fa-angle-right{
						@include rtl-right(15px);
					}
					&.fa-angle-left{
						@include rtl-left(15px);
					}
				}
				.slick-dots{
					padding:0 15px;
				}
			}
		}
		&.moderm{
			max-width:100%;
			padding: 30px 0 50px;
			margin-bottom:0;
			>.row{
				align-items:center;
				max-width:1770px;
				padding:0 15px;
				margin:0 auto;
			}
			.mafoil-bt-video{
				position:unset;
				margin-top:15px;
				.bwp-video{
					background:transparent;
					@include box-shadow(unset);
					padding:0;
					font-size:var(--font-size-body,$font-size-body);
					letter-spacing:1.5px;
					&:before{
						font-size:calc(var(--font-size-heading,25px) + 5px);
						top:7px;
						content:"\e912";
					}
				}
			}
			.bwp-single-title{
				width:28.43%;
				@include rtl-padding(0 ,90px ,0 ,0);
				.mafoil-360-button{
					position:unset;
					background:transparent;
					@include box-shadow(unset);
					padding:0;
					font-size:var(--font-size-body,$font-size-body);
					letter-spacing:1.5px;
					margin-top:15px;
					i{
						font-size:calc(var(--font-size-heading,25px) + 3px);
						top:0px;
					}
				}
			}
			.bwp-single-image{
				width:43.14%;
			}
			.bwp-single-info{
				width:28.43%;
				@include rtl-padding(0 ,0 ,0 ,90px);
				.description{
					display:none;
					margin:0;
					padding:0;
				}
				.countdown-single{
					margin:30px 0 0;
				}
			}
			.content-image-thumbnail{
				position:relative;
				margin-top:30px;
				.slick-list{
					margin:0 -2px;
					.img-thumbnail,.img-thumbnail-video{
						padding:0 5px;
					}
				}
				&:hover{
					.slick-arrow{
						@include opacity-s();
						font-size:var(--font-size-heading,$font-size-heading);
						&.fa-angle-left{
							@include rtl-left(-35px);
						}
						&.fa-angle-right{
							@include rtl-right(-35px);
						}
					}
				}
			}
			.entry-summary .product-lable .onsale:after{
				border-color:#efefef;
				border-right-color: transparent;
			}
			.product_title{
				font-size: 35px;
				line-height:1.1;
				margin:5px 0 15px;
			}
			.price-single{
				border:0;
				padding:0;
				margin:0;
			}
			@media (max-width:1440px){
				.bwp-single-info{
					@include rtl-padding(0 ,0 ,0 ,30px);
				}
				.bwp-single-title{
					@include rtl-padding(0 ,30px ,0 ,0);
				}
			}
			@media (max-width:$screen-md-max){
				.bwp-single-title{
					width:30%;
				}
				.product_title{
					font-size:50px;
				}
				.bwp-single-image{
					width:40%;
				}
				.bwp-single-info{
					width:30%;
				}
				.product-countdown .countdown-content{
					.countdown-amount{
						@include square(55px);
						line-height:51px;
					}
					>span{
						@include rtl-margin-right(10px);
					}
				}
			}
			@media (max-width:$screen-sm-max){
				padding-top:60px;
				>.row{
					align-items:flex-start;
				}
				.bwp-single-image{
					width:100%;
					order:1;
					margin-bottom:50px;
				}
				.bwp-single-title{
					width:50%;
					order:2;
					@include rtl-padding(0 ,15px ,0 ,0);
				}
				.bwp-single-info{
					width:50%;
					order:3;
					@include rtl-padding(0 ,0 ,0 ,15px);
					margin:0;
				}
				.product_title{
					font-size:45px;
					margin:10px 0;
				}
				.content-image-thumbnail{
					.slick-arrow{
						display:none !important;
					}
				}
			}
			@media (max-width:$screen-xs-max){
				padding-top:60px;
				>.row{
					align-items:flex-start;
				}
				.bwp-single-image{
					width:100%;
					order:1;
					margin-bottom:50px;
				}
				.bwp-single-title{
					width:100%;
					order:2;
					@include rtl-padding(0 ,0 ,0 ,0);
					margin-bottom:30px;
				}
				.bwp-single-info{
					width:100%;
					order:3;
					@include rtl-padding(0 ,0 ,0 ,0);
					margin:0;
				}
				.product_title{
					font-size:45px;
					margin:10px 0;
				}
				.content-image-thumbnail{
					.slick-arrow{
						display:none !important;
					}
				}
			}
		}
		&.full_width{
			max-width:100%;
			position:relative;
			&:hover{
				.slick-arrow{
					@include opacity-s();
				}
			}
			.social-icon{
				position:absolute;
				bottom:70px;
				z-index:50;
				@include rtl-right(80px);
				z-index: 100;
				label{
					display:none;
				}
				.social-share{
					writing-mode: vertical-lr;
					a{
						margin:10px 0;
					}
				}
			}
			.size-guide{
				.size-guide__title{
					position: unset;
					@include transform(translateY(0));
				}
			}
			.slick-arrow{
				background:transparent;
				border:0;
				z-index: 1000;
				&:hover{
					color:var(--theme-color,$theme-color);
				}
				&.fa-angle-right{
					@include rtl-right(30px);
				}
				&.fa-angle-left{
					@include rtl-left(30px);
				}
			}
			.bwp-single-image{
				padding:0;
				margin-bottom: 20px;
				figure{
					margin:0;
				}
				.img-thumbnail{
					img{
						width:100%;
					}
				}
			}
			.bwp-single-info{
				padding:0;
				max-width: 740px;
				margin:auto;
				position: relative;
				text-align: center;
				.entry-cart .variations tr td {
					text-align: center;
					.tawcvs-swatches{
						justify-content: center;
						.swatch-item-wrapper{
							margin: 0 5px 10px;
						}
					}
				}
				.product-shipping-delivers{
					li{
						justify-content: center;
					}
				}
			}
			.product_title{
				line-height: 1.1;
				margin-bottom: 25px;
				font-size: 35px;
			}
			.price-single{
				margin:0 0 20px;
				padding:0;
				border:0;
				justify-content: center;
				.price{
					font-size:calc(var(--font-size-heading,25px) + 5px);
				}
			}
			form.cart{
				.quantity-button{
					width:auto !important;
					@include rtl-margin-right(10px);
				}
				.single_variation_wrap{
					display:flex;
					margin-bottom:20px;
					flex-wrap:wrap;
					align-items:center;
					justify-content:center;
					flex:1;
				}
				.variations_button{
					flex:1;
					@include rtl-margin-right(10px);
				}
				@media (min-width:$screen-md-max){
					.quantity{
						background:$white;
						.plus,.minus{
							height:46px;
						}
					}
				}
			}
			.countdown-single{
				margin-bottom:20px;
			}
			.kowine-product-button {
				.kowine-bt-video{
					position:unset;
					margin-bottom:10px;
					.bwp-video{
						background:transparent;
						@include box-shadow(unset);
						padding:0;
						font-size:var(--font-size-body,$font-size-body);
						letter-spacing:1.5px;
						&:before{
							font-size:calc(var(--font-size-heading,25px) + 5px);
							top:7px;
							content:"\e912";
						}
					}
				}
			}
			.entry-heading{
				>.kowine-360-button{
						position:unset;
					background:transparent;
					@include box-shadow(unset);
					padding:0;
					font-size:var(--font-size-body,$font-size-body);
					letter-spacing:1.5px;
					margin-bottom:10px;
					i{
						font-size:calc(var(--font-size-heading,25px) + 3px);
						top:0px;
					}
				}
			}
			.image-thumbnail{
				width:125px;
				position:absolute;
				top:225px;
				@include rtl-right(80px);
				padding-bottom:3px;
				z-index: 100;
				.slick-list{
					margin-bottom:-15px;
					padding-top:5px;
				}
				.img-thumbnail,.img-thumbnail-video{
					margin-bottom:15px;
				}
			}
			@media (max-width:1440px){
				.image-thumbnail{
					@include rtl-right(15px);
				}
				.social-icon{
					@include rtl-right(15px);
					bottom:150px;
				}
			}
			@media (max-width:$screen-md-max){
				form.cart .single_variation_wrap{
					min-width:380px;
				}
			}
			@media (max-width:$screen-md-max){
				.slick-arrow{
					display:none !important;
				}
				.image-thumbnail{
					top:50%;
					@include transform(translateY(-50%));
				}
				.product_title{
					max-width:100%;
				}
				.bwp-single-content-info{
					padding-bottom:0;
				}
				form.cart{
					.variations{
						width:100%;
						flex:0 0 100%;
					}
					.single_variation_wrap{
						min-width:auto;
						width:100%;
						margin-top:10px;
						justify-content:flex-start;
						.woocommerce-variation{
							flex: none;
							@include rtl-margin-right(20px);
						}
					}
					.quantity-button{
						min-width:auto;
						flex:1 ;
					}
				}
				.social-icon{
					position:unset;
					padding:0 15px;
					margin-top:15px;
					.social-share{
						writing-mode: inherit;
						a{
							margin:0px 15px;
						}
					}
					label{
						display:inline-block;
					}
				}
			}
			@media (max-width:$screen-xs-max){
				form.cart {
					.variations tbody{
						display:table;
					}
					.single_variation_wrap{
						.woocommerce-variation{
							flex: 0 0 100%;
							@include rtl-margin-right(0);
						}
					}
				}
				.price-single .price{
					font-size:var(--font-size-heading,$font-size-heading);
				}
				.image-thumbnail{
					position:unset;
					margin-top:20px;
					width:100%;
					@include transform(translateY(0));
					.slick-list{
						margin:0 -10px;
						.img-thumbnail{
							padding:0 10px;
							margin:0;
						}
					}
				}
			}
		}
        .image-thumbnail{
            .slick-slide{
                border: none;
                cursor: pointer;                    
                >a, >.img-thumbnail-scroll{
                    vertical-align: top;
                    display: inline-block;  
                    @include transition(all 0.2s ease);
					border: 1px solid transparent;
					overflow: hidden;
                }  
                &.slick-current >.img-thumbnail-scroll, >a.active, &:hover >a, &:hover >.img-thumbnail-scroll{
                    border-color:var(--gray-dark,$gray-dark);
					img{
						@include transform(scale3d(.8, .85, .85));
						@include transition(all .25s cubic-bezier(.645, .045, .355, .5));
					}
                }              
            }
			.img-thumbnail-video{
				&:before{
					content: "\e908";
					font-family: wpbingofont;
					font-size:var(--font-size-body,$font-size-body);
					color:$white;
					position:absolute;
					@include square(50px);
					@include border-radius(50%);
					text-align:center;
					top:calc(50% - 25px);
					left:calc(50% - 25px);
					z-index:10;
					line-height:48px;
					text-indent:3px;
					background:var(--gray-dark-50,rgba($gray-dark, 0.5));
					@media (max-width:$screen-xs){
						@include square(30px);
						top:calc(50% - 15px);
						left:calc(50% - 15px);
						line-height:30px;
					}
				}
				img{
					@include transition(all 0.2s ease);
					border:1px solid rgba($light-gray,0.25);
					&:hover{
						border-color:var(--gray-dark,$gray-dark);
					}
				}
				&.active{
					img{
						border-color:var(--gray-dark,$gray-dark);
					}
				}
			}
        }
        .woocommerce-product-gallery{
            .slick-slide{
                vertical-align: top;
				border:0;
                .img-thumbnail{
                    vertical-align: top;
                }
                img {
                    display: inline-block;
                    vertical-align: top;
					width:100%;
                }
            }
			.content-thumbnail-scroll.max-thumbnail{
				.slick-track{
					transform: unset !important;
				}
			}
            > .row{
				margin:0 -9px;
                >div{
					@media (max-width:$screen-xs-max){
						&.col-md-2{
							margin-top:10px;
							order:1;
						}
					}
					&.col-md-10{
						.slick-arrow{
							color:var(--gray-dark,$gray-dark);
							text-align:center;
							top:calc(50% - 15px);
							@include opacity-s();
							z-index:100;
							&:after{
								display: none;
							}
							&.fa-angle-left{
								left:20px;
							}
							&.fa-angle-right{
								right:20px;
							}
							&:hover{
								color: var(--theme-color,$theme-color);
							}
							@media(max-width: $screen-xs-max){
								@include square(30px);
								line-height:30px;
								&.fa-angle-left{
									left:10px;
								}
								&.fa-angle-right{
									right:10px;
								}
							}
						}
					}
                    &.col-md-2{
                        .slick-arrow{
                            &:after{
                                display: none;
                            }
							@media (max-width:$screen-sm-max){
								@include opacity-s();
								&.fa-angle-left{
									top: -12.5px;
								}
								&.fa-angle-right{
									bottom: -12.5px;
								}
							}
                        }
						.slick-arrow{
							right: calc(50% - 12.5px);
							left:auto;
							@include square(30px);
							line-height:30px;
							background:var(--gray-dark,$gray-dark);
							color:$white;
							text-align:center;
							font-size:calc(var(--font-size-body,14px) + 2px);
							@include opacity-h();
							&:after{
								display: none;
							}
							&:before{
								display: inline-block;
								@include transform(rotate(90deg));
							}
							&.fa-angle-left{
								top: -15px;
							}
							&.fa-angle-right{
								bottom: -15px;
							}
							@media (max-width:$screen-xs-max){
								@include square(25px);
								line-height:25px;
							}
							&:hover{
								background: var(--theme-color,$theme-color);
							}
						}
						&:hover{
							.slick-arrow{
								@include opacity-s();
							}
						}
                        @media (max-width: $screen-xs-max){
                            .slick-slider .slick-list {
                                margin: 0 -5px;
                                .slick-slide{
                                    padding: 0 5px;
                                }
                            }
                            .slick-arrow{
                               &.fa-angle-left{
									top:calc(50% - 15px);
									left:-10px;
								}
								&.fa-angle-right{
									bottom:calc(50% - 10px);
									right:-10px;
								}
								&:before{
									@include transform(rotate(0));
								}
                            }
							&:hover{
								.slick-arrow{
									&.fa-angle-left{
										top:calc(50% - 15px);
									}
									&.fa-angle-right{
										bottom:calc(50% - 15px);
									}
								}
							}
                        }
                    }
                    &.col-md-10{
						padding:0 7.5px;
                        @media (max-width: $screen-xs-max){
                            .slick-arrow{
                                @include opacity-s();
                            }
                            &:hover{
                                .slick-arrow{
                                    opacity: 1;
                                    bottom: -30px;
                                }
                            }  
                            .image-additional {
                                text-align: center;
                            } 
                        }
                    }
                    &.col-md-2{
						padding:0 7.5px;
						.content-thumbnail-scroll{
							position:relative;
							&.max-thumbnail{
								.slick-track{
									transform: unset !important;
								}
							}
						}
                        .image-thumbnail{
                            .slick-list{
                                padding: 0 !important;
								margin:-1px -5px;
                                .slick-slide{
                                    padding:1px 7.5px 15px;
									@media(max-width: $screen-md-max){
										padding:1px 7.5px 7.5px;
									}
                                }
                            }                           
                        }
                        @media (min-width: $screen-sm){
                            .slick-arrow{
                                &.fa-angle-right{
									bottom: 10px;
									top:auto;
								}
							}
                            &:hover{
                                .slick-arrow{
                                    &.fa-angle-right{
                                        bottom: 0;
                                    }
                                }
                            }   
                        }
                    }
                    &.col-sm-12{
						text-align:center;
                        .slick-arrow{
                            opacity: 0;
                            &:after{
                                display: none;
                            }
                        }
						&.content-thumbs-scroll{
							.slick-arrow{
								background: var(--gray-dark,$gray-dark);
								@include border-radius(0);
								@include opacity-h();
								color: $white !important;
								font-size:calc(var(--font-size-body,14px) + 2px);
								@include square(30px);
								line-height: 30px;
								&.fa-angle-left{
									@include rtl-left(-5px !important);
								}
								&.fa-angle-right{
									@include rtl-right(-5px !important);
								}
								&:hover{
									background: var(--theme-color,$theme-color);
								}
							}
							@media (min-width:1440px){
								.slick-arrow{
									&.fa-angle-left{
										@include rtl-left(-10px);
									}
									&.fa-angle-right{
										@include rtl-right(-10px);
									}
								}
							}
						}
                        .image-thumbnail{
                            margin-top: 20px;
                            .slick-list{
                                margin: 0 -7px;
                                .slick-slide{
                                    padding: 0 10px;
                                    padding-bottom: 0;
                                }
								@media (max-width:$screen-xs){
									 margin: 0 -4px;
									.slick-slide{
										padding: 0 5px;
									}
								}
                            }                       
                        }
						.slick-arrow{
							top: calc(50% - 12.5px);
							color:var(--gray-dark,$gray-dark);
							background:transparent;
							font-size: 30px;
							border:0;
							z-index:100;
							@include opacity-s();
							&.fa-angle-right{
								@include rtl-right(20px);
							}
							&.fa-angle-left{
								@include rtl-left(20px);
							}
							&:hover{
								color: var(--theme-color,$theme-color);
							}
							@media (max-width:1500px){
								&.fa-angle-right{
									@include rtl-right(10px);
								}
								&.fa-angle-left{
									@include rtl-left(10px);
								}
							}
							@media (max-width:$screen-sm-max){
								@include opacity-s();
							}
							@media (max-width:$screen-xs){
								top: calc(50% - 1.5px);
								@include square(20px);
								line-height:20px;
							}
						}
						&:hover{
							.slick-arrow{
								@include opacity-s();
							}
						} 
                    }
                }
            }
        } 
        .image-thumbnail-list{
			padding:0;
			margin-top:15px;
            position: relative;
			padding-top:40px;
			border-top:1px solid #e5e5e5;
			margin-bottom:10px;
			.slick-arrow{
				background: var(--gray-dark,$gray-dark);
				color: $white;
				@include opacity-h();
				&:hover{
					background: var(--theme-color,$theme-color);
				}
			}
			.content-thumbnail-scroll.max-thumbnail{
				.slick-track{
					transform: unset !important;
				}
			}
        }
		.wpb-iconbox{
			border:1px solid #dfdfdf;
			text-align:center;
			padding:20px 20px 30px;
			.icon{
				color:var(--gray-dark,$gray-dark);
				font-size:36px;
			}
			.title{
				font-size:var(--font-size-body,$font-size-body);
				text-transform:uppercase;
				font-weight:600;
				margin:0 0 10px;
			}
		}
		.featured-icon{
			margin-top:40px;
			.block-top-link{
				margin-left:-15px;
				margin-right:-15px;
				display:flex;
				>*{
					padding:0 15px;
					flex:1;
					margin:0;
					>div{
						height:100%;
					}
				}
				@media (max-width:$screen-xs-max){
					flex-wrap:wrap;
					>*{
						flex:50%;
						margin:0 0 20px;
						>div{
							height:100%;
						}
					}
				}
				@media (max-width:$screen-xs){
					>*{
						flex:100%;
					}
				}
			}
			.wpb-iconfeatured{
				text-align:center;
				border:1px solid $gray-light ;
				height:100%;
				padding:20px 20px 25px;
				.icon{
					color:var(--theme-color,$theme-color);
					font-size:calc(var(--font-size-heading,25px) + 5px);
					margin-bottom:10px;
				}
				.title{
					font-size:var(--font-size-body,$font-size-body);
					text-transform:uppercase;
					margin:0;
					font-weight:600;
				}
			}
		}
    }
}

.single-product, .quickview-container{
	.button_single{
		display:inline-flex;
		margin-bottom:5px;
		vertical-align:top;
	}
    .product_title {
        margin-top:0;
        margin-bottom: 15px;
        font-size:var(--font-size-heading,$font-size-heading);
        line-height: calc(var(--font-size-heading,25px) + 10px);
		color:var(--gray-dark,$gray-dark);
		word-break: break-word;
		display:block;
		text-transform: uppercase;
		@media(max-width: $screen-xs-max){
			font-size:calc(var(--font-size-body,14px) + 6px);
			line-height: 30px;
		}
    }
    .thumbnails {
        padding: 35px 80px;
        .img-thumbnail {
            position: relative;
            display: inline-block;
            vertical-align: top;
            img {
                @include opacity(0.8);
            }
            &:hover {
                border-color: var(--theme-color,$theme-color);
                img {
                    @include opacity(1);

                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: var(--theme-color,$theme-color);
                    @include opacity(0.35);
                    z-index: 1;
                }
            }
        }

        .carousel-control {
            @include square(42px);
            border: 1px solid var(--border-color,$border-color);
            background: #fff;
            top: 50%;
            margin-top: -21px;
            cursor: pointer;
            &.disabled {
                display: none;
            }
        }
    }
    .bwp-single-info{
		.breadcrumb-noheading{
			margin-bottom:5px;
			>.container{
				padding:0;
			}
			.breadcrumb{
				padding:0;
				margin-bottom:7px;
			}
		}
        .quantity-text{
            font-size:var(--font-size-body,$font-size-body);
            font-weight: 500;
            color: var(--gray-dark,$gray-dark);
            margin-bottom: 10px;
        }
        .quantity{
            margin: 0;
            border: 1px solid #e9e9e9 !important;
            height : 50px;
            display: inline-block;
            @include rtl-margin-right(7px);
			position:relative;
			@media(max-width:$screen-xs-max){
				@include rtl-margin-right(10px);
			}
            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input{
                width:125px;
				border:0;
				height:46px;
				text-align:center;
				padding:0 38px;
				font-size:calc(var(--font-size-body,14px) + 4px);
            }
			button{
				outline-color: transparent;
				color: var(--gray-dark,$gray-dark);
				font-size:0;
				&:hover,&:focus{
					border:none;
					color:var(--theme-color,$theme-color);
				}
				&:before{
					font-family: eleganticons;
					display:inline-block;
					font-size:calc(var(--font-size-body,14px) + 4px);
				}
				&.plus{
					position:absolute;
					top: 0;
					@include rtl-right(0);
					background:transparent;
					line-height:24px;
					border: none;
					width: 38px;
					height: 46px;
					&:before{
						content: "\4c";
						position:relative;
						top:4px;
					}
				}
				&.minus{
					position:absolute;
					@include rtl-left(0);
					bottom: 0;
					border: none;
					width: 38px;
					height: 46px;
					background:transparent;
					&:before{
						content: "\4b";
						position:relative;
						top:5px;
					}
				}
			}
			@media (max-width:$screen-xs){
				input{
					width:100px;
				}
			}
        }
        .product_meta{
            display: inline-block;
            width: 100%;
			text-transform:capitalize;
			border-top: 1px solid #e5e5e5;
			padding-top:20px;
			margin-top:20px;
			>span{
				margin-bottom:0;
			}
            #report_abuse{
                margin-bottom: 16px;
                display: inline-block;
                width: 100%;
                vertical-align: top;
                color: var(--theme-color,$theme-color);
            }
            #report_abuse_form{
                border: 0;
                padding: 20px;
				&.simplePopup{
					@include box-shadow( 0px 1px 10px 0px rgba(144, 144, 144, 0.75));
				}
                .simplePopupClose{
                    color: $white;
                    background: red;
                    @include square(20px);
                    line-height: 20.5px;
                    text-align: center;
                    @include border-radius(50%);
                    &:hover{
                        background: var(--theme-color,$theme-color);
                    }
                }
                h3.wcmp-abuse-report-title{
                    margin-bottom: 20px;
					font-size:calc(var(--font-size-body,14px) + 4px);
                }
                #report-abuse{
                    table{
                        border: 0;
                        tbody{
                            tr{
                                td{
                                    border: 0;
                                    padding: 0;
                                    padding-bottom: 20px !important;
                                }
                                input[type="submit"]{
                                    background: var(--theme-color,$theme-color);
                                    padding: 5px 15px;
                                    color: $white;
                                    border: 0;
                                    margin-top: 10px;
                                    &:hover{
                                        background: darken($theme-color, 10%);
										background: var(--theme-color);
                                    }
                                }
								textarea::placeholder,input::placeholder{
									font-size:var(--font-size-body,$font-size-body);
								}
                            }
                        }
                    }
                }
            }
            .sku_wrapper,
            .posted_in,
            .tagged_as{
                display: block;
                width: 100%;
                color: var(--text-color,$text-color);
				margin-bottom:10px;
				text-transform: uppercase;
                span{
                    color: var(--gray-dark,$gray-dark);
					font-weight: 500;
                }
                a{
                    color: var(--gray-dark,$gray-dark);
					font-weight: 500;
                    &:hover{color: var(--theme-color,$theme-color);}
                }
            }
        }
        .image-thumbnail{
            .slick-list{
                margin : 0 -9px;
                .slick-slide{
                    padding : 0 10px;
                    padding-bottom: 10px;
                }
            }
            .slick-arrow{
                top : 50%;
                margin-top : -17px;
                &.fa-angle-left{
                    left : 5px;
                    right : auto;
                }
                &.fa-angle-right{
                    right : 5px;
                }
            }
        }
        .woocommerce-product-rating{
			margin: -12px 0 7px; 
			padding: 0; 
			clear: both;
		}
        .star-rating{
			display: inline-block;
			@include rtl-margin-right(0);
			width: 85px;
			font-size: 13px;
			margin-bottom:0;
			&:before {
				letter-spacing: 4.8px;
			}
			span:before {
				letter-spacing: 4.8px;
			}
		}
        .woocommerce-review-link{
			position: relative; 
			top: -3px;
			right: -5px; 
			color: var(--text-color,$text-color);
			font-size:var(--font-size-body,$font-size-body);
		}
        .description{
            margin-bottom: 13px;
            padding-bottom: 15px;
            > p{
                &:last-child{
                    margin-bottom: 0;
                }
            }
            ul{
                margin-bottom: 15px;
                padding: 0;     
                list-style: none;
                li{
                    margin-bottom: 5px;
                    position: relative;
                    @include rtl-padding-left(22px);
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &:before{
                        content: "\f00c";
                        position: absolute;
                        @include rtl-left(0);
                        font-family: FontAwesome;
                        color: var(--theme-color,$theme-color);
                    }
                }
            }
        }
		.percent_quantity_stock{
			margin-bottom: 15px;
			.quantity_stock{
				color: var(--gray-dark,$gray-dark);
				margin: 0 0 10px;
				span{
					color: red;
					margin: 0 3px;
				}
			}
			.percent{
				height: 3px;
				background: #eeeeee;
				width: 100%;
				position: relative;
				.content{
					position: absolute;
					background: red;
					height: 100%;
					top: 0;
					left: 0;
					@include transition(width 2s ease);
				}
			}
		}
		.safe-checkout{
			margin-top: 25px;
			padding: 40px 25px 25px;
			background: #f8f5f4;
			text-align: center;
			.img-safe-checkout{
				margin-bottom: 15px;
			}
			.title-safe-checkout{
				color: var(--gray-dark,$gray-dark);
				letter-spacing: 2px;
			}
		}
		.product-shipping-delivers{
			padding: 0;
			list-style: none;
			margin-top: 20px;
			text-align: center;
			li{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin-bottom: 10px;
				@media(max-width: $screen-xs){
					justify-content: center;
				}
				&:last-child{
					margin-bottom: 0;
				}
				>a{
					@include rtl-margin-left(8px);
					position: relative;
					&:after{
						content: "";
						position: absolute;
						display: block;
						bottom: 3px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: var(--gray-dark,$gray-dark);
						@include transform(scaleX(1));
						transform-origin: 0 50%;
						transition: transform .48s cubic-bezier(.77,0,.175,1);
					}
					&:hover{
						&:after{
							background-color: var(--theme-color,$theme-color);
							transform-origin: 100% 50%;
							@include transform(scaleX(0));
							animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
						}
					}
				}
			}
			i{
				font-size:var(--font-size-heading,$font-size-heading);
				color: var(--gray-dark,$gray-dark);
				@include rtl-margin-right(15px);
				position: relative;
				top: -2px;
				@media(max-width: $screen-xs){
					width: 100%;
					@include rtl-margin-right(0 !important);
					margin-bottom: 5px;
				}
			}
			.wpb-icon-delivers{
				font-size: 30px;
				@include rtl-margin-right(10px);
			}
		}
        .stock.in-stock{
            margin-top:-10px;
            display: none;
        }
		.stock.out-of-stock{
			display:none;
		}
		.stock.available-on-backorder{
			display:none;
		}
		.variations_form .stock.out-of-stock{
			display:block;
		}
        .product-stock{
            margin-top: 2px;
            margin-bottom: 15px;
            i{
                color: var(--text-color,$text-color);
                padding: 0 10px;
                font-size:calc(var(--font-size-body,14px) + 6px);
                @include transform(translateY(3px));
            }
            .stock{color: var(--text-color,$text-color);}
        }
        .prices{
            margin-top: 0;
            margin-bottom: 15px;
            width: 50%;
            display: inline-block;
            .price{
                margin: 0;
                del{
                    font-size:calc(var(--font-size-body,14px) + 4px);
                    letter-spacing: 0;
                }
            }
        }
		.button_single{
			@media(max-width:$screen-xs-max){
				display:inline-flex;
			}
		}
        .cart{    
            padding: 0;
        }
		.woosw-wishlist{
			.woosw-btn{
				margin: 5px 0;
				text-align: center;
				color:var(--gray-dark,$gray-dark);
				font-weight:400;
				align-items:center;
				font-size:0;
				border:0;
				background:transparent;
				padding:0;
				@include square(50px);
				line-height: 50px;
				border: 1px solid #e9e9e9;
				@include transition(all .3s ease);
				cursor: pointer;
				&:before{
					content:"\e904";
					font-family: icomoon;
					font-size:calc(var(--font-size-body,14px) + 5px);
					@include transition(all .3s ease);
					color: var(--text-color,$text-color);
					font-weight: 400;
					margin: 0;
				}
				&.woosw-adding{
					&:before {
						position: relative;
						display: inline-block;
						content: "";
						border:2px solid var(--gray-dark-25,rgba($gray-dark, 0.25));
						border-top-color:var(--gray-dark,$gray-dark);
						@include square(20px);
						background: none;
						@include animation(2s linear 0s normal none infinite running spinAround);
						text-indent: 0;
						top: 9px;
						left: 0;
						@include border-radius(50%);
					}
					&:hover{
						&:before{
							border-color:rgba($white,0.5);
							border-top-color:$white;
						}
					}
				}
				&.woosw-added{
					&:before{
						content: "\f004";
						font-family: 'FontAwesome';
						font-size:calc(var(--font-size-body,14px) + 4px);
						color: var(--gray-dark,$gray-dark);
						@include transition(all 0.1s ease);
						top:0;
					}
				}
				&:hover{
					background: var(--theme-color,$theme-color);
					border-color: var(--theme-color,$theme-color);
					&:before{
						color: $white;
					}
				}
			}
		}
		.woocommerce{
			margin-top: 25px;
            display: inline-block;
            vertical-align: top;
			position:relative;
			cursor:pointer;
			a{
				font-weight:500;
				display:flex;
				text-transform: uppercase;
				align-items:center;
				&:before{
					content: "\e900";
					font-family:'wpbingo2';
					font-size:calc(var(--font-size-body,14px) + 4px);
					font-weight: 400;
					line-height:1;
					line-height:50px;
					@include border-radius(50%);
					border:1px solid #cecece;
					@include rtl-margin-right(10px);
					display:inline-block;
					@include square(50px);
					text-align:center;
				}
			}
			&:hover{
				a{
					color:var(--theme-color,$theme-color);
					&:before{
						color:$white;
						background:var(--theme-color,$theme-color);
						border-color:var(--theme-color,$theme-color);
					}
				}
			}
		}
		.button.quick-buy{
			@include transition(none);
			font-size:var(--font-size-body,$font-size-body);
			font-weight: 500;
			text-transform: uppercase;
            line-height: 50px;
            position: relative;
			background: transparent;
			color: #999999;
			text-align:center;
			@include rtl-margin-right(20px);
			padding:0 15px;
			cursor:pointer;
			display: inline-block;
			vertical-align: top;
			width:100%;
			margin:5px 0;
			white-space: nowrap;
			border: 1px solid #e9e9e9;
			@include transition(all .3s ease);
			position: relative;
			overflow: hidden;
			z-index: 0;
			@media(max-width:$screen-xs-max){
				@include rtl-margin-right(0);
				padding:0 15px;
			}
			&.disabled{
				cursor: not-allowed;
				opacity:0.5;
			}
            &:hover:not(.disabled){
                background: var(--theme-color,$theme-color);
				border-color: var(--theme-color,$theme-color);
				color: $white;
            }
		}
        .single_add_to_cart_button.button{
            @include transition(all .3s ease);
			font-size:var(--font-size-body,$font-size-body);
			font-weight: 500;
			height:50px;
            line-height: 50px;
			background: var(--button-color,$gray-dark);
			color:$white;
			text-align:center;
			@include rtl-margin-right(20px);
			padding:0 15px;
			cursor:pointer;
			display: inline-block;
			vertical-align: top;
			flex:1;
			margin:0;
			white-space: nowrap;
			position: relative;
			text-transform: uppercase;
			&.disabled{
				cursor: not-allowed;
				opacity:0.5;
			}
            &:hover:not(.disabled){
				background: var(--theme-color,$theme-color);
            }
        }
        .cart{
            a.single_add_to_cart_button.button{
                display: inline-block;
				width:100%;
            }
        }
	}
	/*------ social -------*/
	.social-icon{
		label{
			margin:0;
			text-transform: uppercase;
			font-weight: 400;
			@include rtl-margin-right(10px);
		}
		.social-share{
			display:inline-block;
			>a{
				margin:0 10px;
			}
		}
	}
	/*------ Infor -------*/
	.entry-summary {
		.price-single{
			width: 100%;
			margin-bottom : 20px;
			padding-bottom:20px;
			border-bottom:1px solid #e5e5e5;
			line-height: 100%;
			display:flex;
		}
		.price {
			color: var(--theme-color,$theme-color);
			font-size:var(--font-size-heading,$font-size-heading);
			display: inline-block;
			vertical-align: middle;
			font-weight: 500;
			del{
				font-size:calc(var(--font-size-body,14px) + 6px);
			}
			@media(max-width: $screen-xs-max){
				font-size:calc(var(--font-size-body,14px) + 6px);
				del{
					font-size:calc(var(--font-size-body,14px) + 2px);
				}
			}
		}
		.product-lable{
			position:relative;
			top:-2px;
			@include rtl-margin-left(15px);
			.hot{
				display:none;
			}
			.onsale{
				position:relative;
				font-size:calc(var(--font-size-body,14px) - 2px);
				text-transform:uppercase;
				background:transparent;
				border:2px solid #ff4545;
				border-right:0;
				color:#ff4545;
				line-height:22px;
				top:0;
				left:0;
				@include rtl-padding(0 ,2px ,0 ,10px);
				z-index:2;
				@include border-radius(0);
				&:before{
					content:"";
					position:absolute;
					@include square(26px);
					right:-20px;
					border-color:#ff4545;
					border-style:solid;
					border-width:13px;
					border-right-color:transparent;
					top:-2px;
					display:inline-block !important;
					z-index:-2;
				}
				&:after{
					content:"";
					position:absolute;
					@include square(22px);
					right:-15px;
					border-color:$white;
					border-style:solid;
					border-width:11px;
					border-right-color:transparent;
					top:0;
					z-index:-1;
				}
			}
		}
	}
    .product-type-variable, .product-type-simple{
        .bwp-single-info .entry-summary > .cart{
            .quantity, .single_add_to_cart_button{
                margin-bottom: 0;
            }
        }
    }
    .product-type-variable{
		.bwp-single-info .description{
			margin-bottom: 20px;
			padding-bottom: 0;
		}
        .variations_form.cart{
           float: none;
			.content-variations{
				position: relative;
		   }
           .single_variation_wrap{
            display:flex;
            vertical-align: top;
			flex-wrap:wrap;
			.woocommerce-variation.single_variation{
				display: inline-block;
				width: 100%;
			}  
            .price{
                margin-top: 0;
                margin-bottom: 15px;
                font-size: calc(var(--font-size-heading,25px) - 2px);
                del{
                    font-size:var(--font-size-body,$font-size-body);
                }
            }
           }
           table{
                border: none;
                margin-top: 10px;
				position:relative;
				margin-bottom:0;
                tr{
                    margin-bottom: 5px;
					display:flex;
					flex-wrap:wrap;
					&.image{
						td{
							&.value .tawcvs-swatches{
								.swatch-item-wrapper{
									margin: 0 15px 10px 0;
									&:hover{
										.swatch__tooltip{
											@include opacity-s();
											top: -35px;
										}
									}
								}
							}
						}
					}
                    td{
                        border: none;
                        padding: 0;
                        margin-bottom: 20px !important;
						@include rtl-text-align-left();
                        &.value .tawcvs-swatches{
                            padding:0;
							display:flex;
							flex-wrap:wrap;
							.swatch-item-wrapper{
								position:relative;
								margin: 0 10px 10px 0;
								&:hover{
									.swatch__tooltip{
										@include opacity-s();
										top: -30px;
									}
								}
							}
							.swatch{
								@include square(auto);
								text-align: center;
								line-height:25px;
								padding:5px 7px;
								min-width:40px;
								display:inline-block;
								background:transparent;
								border:1px solid #cccccc;
								@include box-shadow(0 0 1px 1px #ddd);
								@include transition(all .3s ease-in-out);
								border: 2px solid #fff;
								color:var(--text-color,$text-color);
								font-weight: 500;
								font-size:var(--font-size-body,$font-size-body);
								cursor:pointer;
								min-height:auto;
								overflow:unset;
								&:hover,&.selected{
									background:var(--gray-dark,$gray-dark);
									color:$white;
									border-color: var(--gray-dark,$gray-dark);
									@include box-shadow(none);
								}
								&.disabled{
									cursor:not-allowed;
									opacity:0.5;
									position: relative;
									&:before{
										position: absolute;
										content: "";
										@include size(1px, 100%);
										background: var(--text-color,$text-color);
										top: 0;
										left: 50%;
										@include transform(rotate(45deg));
									}
								}
								&.swatch-image {
									@include box-shadow(none);
									padding:0;
									margin: 0;
									font-size:0;
									display:inline-block;
									@include square(35px);
									@include border-radius(0);
									object-fit:cover;
									min-width:unset;
									@include transition(all .5s ease);
									&:hover, &.selected{
										border-color: var(--gray-dark,$gray-dark);
									}
									.swatch__tooltip{
										display:block;
									}
								}
								@media (max-width:$screen-xs){
									padding: 6.5px 10px;
									min-width: 35px;
								}
							}
                        }
						&.label{
							width:100%;
							margin-bottom: 10px !important;
						}
						&.value{
							margin-bottom: 0 !important;
							display: block;
							width: 100%;
						}
                        label{
                            font-size:var(--font-size-body,$font-size-body);
							text-transform: uppercase;
							color:var(--text-color,$text-color);
                            margin-bottom: 0;
                            @include rtl-margin-right(10px);
							line-height:40px;
							font-weight: 400;
							&:after{
								position: relative;
								content: ":";
								right: 0;
								top: 0;
								font-size:var(--font-size-body,$font-size-body);
							}
                        }
						.selected-term-name{
							color: var(--gray-dark,$gray-dark);
							text-transform: uppercase;
							font-weight: 500;
						}
						.swatch__tooltip{
							position:absolute;
							top:0;
							left:50%;
							@include transform(translateX(-50%));
							@include transition(all .2s ease-in-out);
							top:-38px;
							line-height:22px;
							color:$white;
							padding:0 10px;
							background:#000;
							@include opacity-h();
							font-size:calc(var(--font-size-body,14px) - 2px);
							&:before{
								content:"";
								position:absolute;
								border: 5px solid transparent;
								border-top-color: rgb(0, 0, 0);
								bottom: -9px;
								left: calc(50% - 3px);
							}
						}
                        > div{
                            .swatch-color{
                                opacity: 1;
								@include square(25px !important);
								@include border-radius(50% !important);
								box-shadow: 0 0 1px 1px #ddd;
								border: 2px solid #fff;
								border:0;
								@include box-shadow(none);
								position:relative;
								text-indent: 100%;
								font-size:0 !important;
								padding:0 !important;
								min-width:unset !important;
								@media (max-width:$screen-xs){
									.swatch__tooltip{
										display:none !important;
									}
								}
                            }
                        }
                        select{
                            @include rtl-padding(0, 40px, 0, 10px);
							height:40px;
							line-height:40px;
							color:var(--text-color,$text-color);
                            background-position: calc(100% - 18px) calc(1em + 3px), calc(100% - 13px) calc(1em + 3px), calc(100% - 2.5em) 0.5em;
							cursor:pointer;
							border-color:#cccccc;
							margin-bottom:15px;
                            &:focus{
                              background-position:calc(100% - 14px) calc(1em + 3px), calc(100% - 20px) calc(1em + 3px), calc(100% - 2.5em) 0.5em;
                            }
                        }
                    }
               }
               .reset_variations{
                    color: var(--gray-dark,$gray-dark);
                    font-size: 12px;
					font-weight: 500;
                    display: none;
					margin-bottom:-5px;
					position:relative;
					text-transform: uppercase;
					&:before{
						content: "\4d";
						font-family: ElegantIcons;
						display:inline-block;
						font-size:calc(var(--font-size-body,14px) + 6px);
						line-height:1;
						position:relative;
						top:3px;
						@include transition(all .5s ease);
					}
                    &:hover{
						color:var(--theme-color,$theme-color);
						&:before{
							@include transform(rotate(90deg));
						}
                    }
               }
				.reset_variations[style*="visibility: visible"]{
					display: inline-block  !important;
			   	}
           }
           .single_variation_wrap{
                .woocommerce-variation{
                    p{
                        margin-bottom: 30px;
                    }
                }
                .woocommerce-variation-add-to-cart{
                    display:flex;
					@include rtl-margin(5px, 10px, 5px, 0);
					flex-wrap:wrap;
					flex:1;
					button{
						margin:0;
					}
                }
           }
        }
        .entry-summary{
            .prices{
                margin: 0;
            }
        }
    }
    .product-type-grouped{
        .group_table{
            tr{
                >th{
                    padding: 20px 10px;
                    background-color: #f4f4f4;
                    text-align: center;
                    font-weight: 700;
                    @media (max-width: $screen-xs){
                        padding: 20px 1px;
                    }
                }
                td{
                    padding: 10px;
                    vertical-align: middle;
                    text-align: center;
                    &.product-thumb img{
                        max-width: 70px;
                    }
                    .product-title{
                        font-size:var(--font-size-body,$font-size-body);
                        font-weight: 400;
                        margin : 0px;
                        line-height : 35px;
                        a{
                            color : var(--text-color,$text-color);
                            &:hover{
                                color : var(--theme-color,$theme-color);
                            }
                        }
                    }
                    &.product-price{
                        font-size:var(--font-size-body,$font-size-body);
                        color: var(--gray-dark,$gray-dark);
                        line-height : 35px;
                        ins{
                            text-decoration : none;
                        }
                    }
                    .product_type_external{
                        line-height : 35px;
                    }
                }
            }
        }
        .bwp-single-info{
            .entry-summary >.cart{
				display:flex;
				flex-wrap:wrap;
				.group_table{
					border:1px solid #e5e5e5;
					margin-bottom:30px;
					width:100%;
				}
				.type-product{
					display:flex;
					padding:12px 15px;
					border-bottom:1px solid #e5e5e5;
					justify-content:space-between;
					align-items:center;
					flex-wrap:wrap;
					td{
						padding:0;
						border:0;
					}
					&:last-child{
						border-bottom:0;
					}
					.product-content{
						display:flex;
						align-items:center;
						flex:1;
						@media (max-width:$screen-xs){
							flex:0 0 100%;
						}
					}
					.product-thumb{
						flex:0 0 60px;
						@include rtl-margin-right(10px);
					}
				}
				.product-info{
					padding:0;
				}
				.product-title{
					font-size:var(--font-size-body,$font-size-body);   
					font-weight:400;  
					margin:0 0 2px;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					max-width: 250px;
					display: -webkit-box;
					text-transform: uppercase;
					a{
						color:var(--text-color,$text-color);
						&:hover{
							color:var(--theme-color,$theme-color);
						}
					}
				}
				del{
					font-size:calc(var(--font-size-body,14px) - 3px);
				}
				.product-price{
					color: var(--theme-color,$theme-color);
					font-weight: 500;
				}
                .single_add_to_cart_button{
                    display:block;
					flex:1;
					margin:5px 0;
                }
				.woosw-btn{
					@include rtl-margin-left(10px);
				}
                .quantity-content >a{
                	font-weight: 400;
                }
            }
            .quantity{
				margin: 0;
				border: 1px solid #e5e5e5 !important;
				height : 33px;
				display: inline-block;
				position:relative;
				width:135px;
				padding:0 20px;
				display:flex;
				&:before{
					display:none;
				}
				@media(max-width:$screen-xs){
					margin-top:10px;
				}
				input[type=number]::-webkit-inner-spin-button,
				input[type=number]::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
				input{
					border:0;
					height:31px;
					padding:0;
					font-size:calc(var(--font-size-body,14px) - 1px);
					font-weight:500;
					flex:1;
					width:100%;
				}
				button{
					outline-color: transparent;
					color:var(--text-color,$text-color);
					font-size:0;
					&:hover,&:focus{
						border:none;
						color:var(--theme-color,$theme-color);
					}
					&:before{
						display:inline-block;
						font-size:calc(var(--font-size-body,14px) - 1px);
					}
					&.plus{
						background:transparent;
						line-height:15px;
						border: none;
						width: 18px;
						height: 31px;
						right:10px;
						&:before{
							position:relative;
							top:4px;
						}
					}
					&.minus{
						border: none;
						width: 18px;
						height: 31px;
						background:transparent;
						left:10px;
						&:before{
							position:relative;
							top:2px;
						}
						&:after{
							display:none;
						}
					}
				}
			}
        }
    }
    .product-type-simple{
        .bwp-single-info{
            .entry-summary{
				> .cart{
                    margin-top: 0px;
                    width: 100%;
					display:flex;
					flex-wrap:wrap;
					.quantity-button{
						display:flex;
						flex:1;
						@include rtl-margin(5px, 10px, 5px, 0);
					}
                }
            }
        }
		.bwp-single-product.full_width{
			.bwp-single-info{
				.entry-heading{
					flex:1;
				}
				.entry-cart{
					margin-bottom:20px;
				}
				@media (max-width:$screen-sm-max){
					.entry-heading{
						flex:0 0 100%;
					}
					.entry-cart{
						width:100%;
					}
				}
			}
		}
    }
    .product-type-external{
        .bwp-single-info {
			.entry-summary{
				>.cart{
					margin-bottom: 15px;
					clear : both;
				}
				.woosw-btn{
					@include rtl-margin-left(0);
				}
			}
        }
		
    }
    .type-product{
        >div{
            &:last-of-type{
                &.woocommerce-tabs{
                    margin-bottom: 0;
                }
            }
        }
		&.outofstock{
			.variations{
				display:none;
			}
			.variations_button{
				display:none !important;
			}
			.size-guide{
				display:none;
			}
			.product-stock{
				margin:0;
				padding: 0 25px;
				line-height:50px;
				font-size:var(--font-size-body,$font-size-body);
				font-weight: 500;
				text-transform: uppercase;
				color:var(--gray-dark,$gray-dark);
				text-align:center;
				background:#ededed;
				cursor: no-drop;
				margin-bottom: 10px;
				span{
					color:var(--gray-dark,$gray-dark);
				}
			}
			.product-notify{
				margin:0;
				padding: 0 25px;
				line-height:50px;
				font-size:var(--font-size-body,$font-size-body);
				font-weight: 500;
				text-transform: uppercase;
				color:var(--gray-dark,$gray-dark);
				text-align:center;
				background: transparent;
				border: 1px solid #e9e9e9;
				cursor: pointer;
				position: relative;
				overflow: hidden;
				@include transition(all .3s ease);
				&:hover{
					color: $white;
					background: var(--theme-color,$theme-color);
					border-color: var(--theme-color,$theme-color);
				}
			}
		}
    }
	.offer-product{
		padding:20px 30px;
		border:2px solid #28af5b;
		position:relative;
		margin-bottom:30px;
		&:before{
			content:"";
			position:absolute;
			top:-17.5px;
			@include size(50px,25px);
			background: url('#{$image-theme-path}/gift.svg') no-repeat center;
			z-index:3;
			background-color:$white;
			background-size: contain;
		}
		ul{
			padding:0;
			list-style:none;
			li{
				color:#28af5b;
				font-weight:500;
				line-height:28px;
				font-weight:500;
				&:before{
					content:"\f0da";
					font-family:FontAwesome;
					display:inline-block;
					@include rtl-margin-right(10px);
				}
			}
		}
		@media (max-width:$screen-xs){
			padding:20px;
		}
	}
	.payment-product{
		margin-top:20px;
		h2{
			font-size:calc(var(--font-size-body,14px) - 2px);
			text-transform:uppercase;
			font-weight:700;
			margin:0 0 25px;
			display:flex;
			align-items:center;
			&:before{
				content:"\e900";
				font-family:wpbingofont;
				color:var(--theme-color,$theme-color);
				display:inline-block;
				font-size:calc(var(--font-size-heading,25px) + 5px);
				@include rtl-margin-right(5px);
			}
		}
	}
	.brands-single{
		display:flex;
		align-items:center;
		margin-bottom:15px;
		.title-brand{
			margin:0;
			font-size:calc(var(--font-size-body,14px) - 2px);
			text-transform:uppercase;
			font-weight:700;
		}
		ul{
			padding:0;
			list-style:none;
			li{
				display:inline-block;
				@include rtl-margin-left(5px);
				margin-bottom:5px;
				a{
					display:inline-block;
					border:1px solid #cccccc;
					height:80px;
					padding:10px;
					img{
						max-height:60px;
						max-width:unset;
						width:auto;
					}
					&:hover{
						border-color:var(--gray-dark,$gray-dark);
					}
				}
			}
		}
	}
	.countdown-single{
		margin-bottom: 25px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		border: 1px solid #e9e9e9;
		padding: 20px 15px;
		font-weight: 500;
		position: relative;
		&:before{
			position: absolute;
			content: "";
			top: -1px;
			@include rtl-left(-1px);
			border-left: 1px solid red;
			border-top: 1px solid red;
			@include square(40px);
		}
		&:after{
			position: absolute;
			content: "";
			bottom: -1px;
			@include rtl-right(-1px);
			border-right: 1px solid red;
			border-bottom: 1px solid red;
			@include square(40px);
		}
		.title-countdown{
			font-size:var(--font-size-body,$font-size-body);
			position: relative;
			color: red;
			text-transform: uppercase;
			margin: 0;
			@include rtl-margin-right(5px);
		}
		.product-countdown{
			.countdown-content{
				display: inline-block;
				line-height: 40px;
				>span{
					font-size:calc(var(--font-size-body,14px) + 4px);
					font-weight: 500;
					color: red;
					&:not(:last-child){
						&:after{
							content: ":";
							display: inline-block;
							margin: 0 5px;
						}
					}
				}
				.countdown-amount{
					position:relative;
				}
			}
		}
	}
	.product-count-view{
		color: var(--gray-dark,$gray-dark);
		font-weight: 500;
		margin: 10px 0;
		.feather-eye{
			@include rtl-margin-right(5px);
			font-size:calc(var(--font-size-body,14px) + 4px);
			position: relative;
			top: 3px;
			&:before{
				text-decoration: blink;
				-webkit-animation-name: blinker;
				-webkit-animation-duration: 0.6s;
				-webkit-animation-iteration-count:infinite;
				-webkit-animation-timing-function:ease-in-out;
				-webkit-animation-direction: alternate;
			}
		}
	}
	.size-guide{
		.size-guide__title{
			font-size:var(--font-size-body,$font-size-body);
			color: var(--gray-dark,$gray-dark);
			font-weight: 500;
			margin: 0;
			display:inline-block;
			cursor:pointer;
			z-index:10;
			position: absolute;
			top: 50%;
			@include rtl-right(0);
			@include transform(translateY(-50%));
			&:after{
				content: "";
				position: absolute;
				display: block;
				bottom: 3px;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--gray-dark,$gray-dark);
				@include transform(scaleX(1));
				transform-origin: 0 50%;
				transition: transform .48s cubic-bezier(.77,0,.175,1);
			}
			&:hover{
				color:var(--theme-color,$theme-color);
				&:after{
					background-color: var(--theme-color,$theme-color);
					transform-origin: 100% 50%;
					@include transform(scaleX(0));
					animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
				}
			}
		}
		.size-guide__overlay{
			position: fixed;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			margin:0;
			z-index: 9999;
			background: rgba(0,0,0,.4);
			display: none;
			-webkit-transition: opacity .4s cubic-bezier(0,.8,1,1);
			transition: opacity .4s cubic-bezier(0,.8,1,1);
		}
		.size-guide__img{
			position:fixed;
			top:50%;
			left:50%;
			@include transform(translate(-50%,-50%));
			z-index:99999;
			max-height:70vh;
			overflow:auto;
			display:none;
			@media (max-width:$screen-md-max){
				max-height:90vh;
			}
			@media (max-width:$screen-sm-max){
				max-width:80vw;
				width:100%;
			}
			&::-webkit-scrollbar-track
			{
				background-color: #F5F5F5;
			}

			&::-webkit-scrollbar
			{
				width: 5px;
				background-color: #F5F5F5;
			}

			&::-webkit-scrollbar-thumb
			{
				background-color: var(--gray-dark-10,rgba($gray-dark, 0.1));
			}
			.size-guide__close{
				$size: 30px;
				height: $size;
				width: $size;
				position: absolute;
				top: 10px;
				@include rtl-right(15px);
				box-sizing: border-box;
				line-height: $size;
				display: inline-block;
				@include border-radius(50%);
				background: $white;
				cursor: pointer;
				box-shadow:0px 1px 12px 2px var(--gray-dark-10,rgba($gray-dark, 0.1));
				&:before, &:after {
					$width: 15px;
					$height: 1px;
					@include transform(rotate(-45deg));
					content: '';
					position: absolute;
					top: 50%;
					left: calc(50% - 7px);
					display: block;
					height: $height;
					width: $width;
					background-color: var(--gray-dark,$gray-dark);
					@include transition(all .3s ease-out);
				}
				&:after {
					@include transform(rotate(-135deg));
				}
				&:hover{
					&:before, &:after {
						@include transform(rotate(0deg));
					}
				}
			}
		}
		&.active{
			.size-guide__img, .size-guide__overlay{
				display:block;
			}
		}
	}
}
.single-product{
	.prev_next_buttons{
		position:fixed;
		top:30%;
		@include rtl-left(20px);
		z-index:1000;
		@include transition(all 0.3s ease);
		@include opacity-h();
		@include transform(translateX(-100%));
		&.active{
			@include opacity-s();
			@include transform(translateX(0));
		}
		.image{
			position:absolute;
			@include opacity-h();
			min-width:180px;
			bottom:calc(50% - 60px);
			z-index:50000;
			left:calc(100% + 20px);
			@include transition(all 0.5s ease);
			.title{
				font-size:var(--font-size-body,$font-size-body);
				color:$white;
				position:absolute;
				text-transform: uppercase;
				bottom:0;
				margin:0;
				padding:15px;
				width:100%;
				background: -webkit-linear-gradient(bottom, var(--gray-dark-50,rgba($gray-dark, 0.5)) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to top, var(--gray-dark-50,rgba($gray-dark, 0.5)) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			}
		}
		>div:not(.continue-shop){
			position:relative;
			height:50px;
			line-height:50px;
			justify-content:center;
			font-size:0;
			@include transition(all 0.5s ease);
			&.prev_button{
				>a{
					display:inline-block;
					position:relative;
					&:before{
						content:"\e910";
						font-family:feather;
						display:inline-block;
						font-size:var(--font-size-heading,$font-size-heading);
						position: relative;
					}
				}
			}
			&.next_button{
				>a{
					display:inline-block;
					position:relative;
					&:after{
						content:"\e912";
						font-family:feather;
						display:inline-block;
						font-size:var(--font-size-heading,$font-size-heading);
						position: relative;
					}
				}
			}
			&:hover{
				.image{
					@include opacity-s();
					left:calc(100% + 5px);
				}
			}
		}
		.continue-shop{
			font-size:var(--font-size-heading,$font-size-heading);
			height:50px;
			line-height:50px;
			position:relative;
			span{
				font-size:var(--font-size-body,$font-size-body);
				font-weight: 500;
				text-transform: uppercase;
				color:var(--gray-dark,$gray-dark);
				position:absolute;
				white-space:nowrap;
				left:calc(100% + 20px);
				@include opacity-h();
				@include transition(all 0.5s ease);
				top:-2px;
			}
			&:hover{
				span{
					@include opacity-s();
					left:calc(100% + 5px);
				}
			}
		}
		@media (max-width:1500px){
			display:none;
		}
	}
	.single-product-notify-me-form{
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		display: none;
		padding: 0 15px;
		&.active{
			display: flex;
			.notify-me-form-close{
				.close-line1{
					@include transform(translateY(-30px) translateX(30px) rotate(45deg));
					animation-name: crossRight;
					animation-delay: .14s;
				}
				.close-line2{
					animation-name: crossLeft;
					@include transform(translateY(-30px) translateX(-30px) rotate(-45deg));
				}
			}
		}
		&:not(.active){
			.notify-me-form-close{
				.close-line1{
					@include transform(translateY(0) translateX(0) rotate(-45deg));
					animation-name: crossLeftClose;
					@include animation-delay(0s);
					@include animation-duration(.15s);
				}
				.close-line2{
					@include transform(translateY(0) translateX(0) rotate(45deg));
					-webkit-animation-name: crossRightClose;
					animation-name: crossRightClose;
					@include animation-delay(.06s);
					@include animation-duration(.15s);
				}
			}
		}
		.title-contact{
			text-align: center;
			margin-bottom: 20px;
			h2{
				margin: 0 0 10px;
				font-size:var(--font-size-heading,$font-size-heading);
				text-transform: uppercase;
			}
		}
		.notify-me-form-close{
			$size: 35px;
			height: $size;
			width: $size;
			position: absolute;
			top: 10px;
			@include rtl-right(15px);
			box-sizing: border-box;
			line-height: $size;
			display: inline-block;
			@include border-radius(50%);
			background: $white;
			cursor: pointer;
			box-shadow: 0px 1px 12px 2px var(--gray-dark-10,rgba($gray-dark, 0.1));
			&:before, &:after {
				$width: 15px;
				$height: 1px;
				@include transform(rotate(-45deg));
				content: '';
				position: absolute;
				top: 50%;
				left: calc(50% - 8px);
				display: block;
				height: $height;
				width: $width;
				background-color: var(--gray-dark,$gray-dark);
				@include transition(all .3s ease-out);
			}
			&:after {
				@include transform(rotate(-135deg));
			}
			&:hover{
				&:before, &:after {
					@include transform(rotate(0deg));
				}
			}
		}
		.notify-me-form {
			margin-top: 20px;
		}
		.notify-me-form-popup{
			max-width: 550px;
			margin: auto;
			width: 100%;
			background: $white;
			z-index: 1000;
			position: relative;
			max-height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 45px 30px 30px;
		}
		.close-back_notify_me-form{
			&.full{
				position: fixed;
				width: 100%;
				height: 100%;
				background: rgba(#000, 0.75);
				left: 0;
				top: 0;
				z-index: 999;
			}
		}
		.notify-me-form {
			>.row{
				margin:0 -7.5px;
				>div{
					padding:0 7.5px;
				}
			}
			label{
				color:var(--gray-dark,$gray-dark);
				font-size:var(--font-size-body,$font-size-body);
				margin-bottom:10px;
				position:relative;
				font-weight:600;
			}
			.form-required{
				.wpcf7-form-control-wrap{
					position: relative;
					&:after{
						position: absolute;
						content: "*";
						color: red;
						@include rtl-right(15px);
						top: -3px;
						font-size:calc(var(--font-size-body,14px) + 6px);
					}
				}
			}
			input[type="text"], input[type="email"]{
				height: 50px;
				line-height:50px;
				margin-bottom: 20px;
				width: 100%;
				padding: 10px 20px;
				border:0;
				font-weight:400;
				@include placeholder(var(--text-color-75,rgba($text-color, 0.75)));
				border: 1px solid var(--border-color,$border-color);
				&:focus{
					border-color:var(--gray-dark,$gray-dark);
				}
			}
			textarea{
				width: 100%;
				max-height: 370px;
				padding: 10px 20px;
				border:0;
				font-weight:400;
				margin-bottom:23px;
				border: 1px solid var(--border-color,$border-color);
				&:focus{
					border-color:var(--gray-dark,$gray-dark);
				}
			}
			.ajax-loader{
				position:absolute;
				right:20px;
				top:30px;
			}
			.button{
				position:relative;
				display:inline-block;
			}
			input[type="submit"]{
				height: 55px;
				line-height: 55px;
				font-size:calc(var(--font-size-body,14px) + 1px);
				padding: 0 75px;
				color: $white;
				background: var(--gray-dark,$gray-dark);
				border: none;
				@include transition(all 0.3s ease); 
				cursor:pointer;
				font-weight:500;
				text-transform: uppercase;
				&:hover{
					background: var(--theme-color,$theme-color);
				} 
			}
			.wpcf7-text,.wpcf7-textarea{
				&::placeholder {
					font-size:var(--font-size-body,$font-size-body);
					font-weight:300;
				}
			}
		}
	}
	.sticky-product{
		position:fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		background:$white;
		z-index:9;
		padding:15px 0;
		@include box-shadow(0 0 15px 3px rgba(0, 0, 0, .15));
		-webkit-animation-name: sticky-product;
		animation-name: sticky-product;
		-webkit-animation-duration: 0.5s;
		animation-duration: 0.5s;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		display:none;
		@media (max-width:$screen-sm-max){
			display:none !important;
		}
		&.sticky{
			display:block;
		}
		.stock{
			display:none;
		}
		.content{
			max-width:1440px;
			padding:0 15px;
			margin-left:auto;
			margin-right:auto;
			display:flex;
			justify-content:space-between;
			align-items:center;
		}
		.content-product{
			display:flex;
			flex: 1;
			align-items:center;
		}
		.content-cart{
			.cart{
				margin:0;
			}
		}
		.item-title{
			font-size: 13px;
			margin-top: -5px;
			text-transform: uppercase;
		}
		.price{
			color: var(--theme-color,$theme-color);
			font-size:calc(var(--font-size-body,14px) + 1px);
			margin-bottom:0;
			font-weight:500;
			display:flex;
			align-items: baseline;
			del{
				font-size:calc(var(--font-size-body,14px) - 1px);
			}
		}
		.content-bottom{
			@include rtl-padding-left(15px);
		}
		.item-thumb{
			img{
				max-width: 70px;
			}
		}
		.quantity{
			margin: 0;
			height : 48px;
			display: inline-block;
			@include rtl-margin(20px ,25px ,0 ,0);
			position:relative;
			border: 0 !important;
			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			input{
				width:75px;
				border:0;
				border-bottom:1px solid #cbcbcb;
				height:auto;
				text-align:center;
				padding:0 15px;
				font-size:calc(var(--font-size-body,14px) + 4px);
			}
			button{
				display: none;
			}
		}
		.select-cart-option{
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 36px;
			cursor: pointer;
			color: var(--gray-dark,$gray-dark);
			border: 1px solid var(--gray-dark,$gray-dark);
			padding: 0 20px;
			&:after{
				font-family: eleganticons;
				content: "\24";
				display: inline-block;
				font-size:calc(var(--font-size-body,14px) + 4px);
				position: relative;
				line-height: 1;
				top: 4px;
				margin-left: 5px;
				@include transition(all 0.3s ease);
			}
			&:hover{
				background: var(--theme-color,$theme-color);
				color: $white;
				&:after{
					@include transform(rotate(-90deg));
					top:2px;
				}
			}
		}
		.quick-buy.button{
			@include transition(none);
			font-size:var(--font-size-body,$font-size-body);
			font-weight: 500;
			line-height: 38px;
			background: transparent;
			border: 1px solid var(--gray-dark,$gray-dark);
			color: var(--gray-dark,$gray-dark);
			text-align:center;
			padding:0 50px;
			cursor:pointer;
			display: inline-block;
			vertical-align: top;
			flex:1;
			margin:0;
			white-space: nowrap;
			position: relative;
			overflow: hidden;
			@include transition(all .3s ease);
			&:before{
				line-height: 38px;
			}
			&:hover:not(.disabled){
				color: $white;
			}
			@media(max-width:$screen-xs-max){
				@include rtl-margin-right(0);
				padding:0 15px;
			}
			@media(max-width:$screen-xs){
				font-size:calc(var(--font-size-body,14px) - 2px);
			}
			&.disabled{
				opacity:0.5;
				cursor:not-allowed;
			}
		}
		.single_add_to_cart_button.button{
			@include transition(none);
			font-size:var(--font-size-body,$font-size-body);
			font-weight: 500;
			line-height: 38px;
			background: var(--gray-dark,$gray-dark);
			color:$white;
			text-align:center;
			padding:0 40px;
			cursor:pointer;
			display: inline-block;
			vertical-align: top;
			flex:1;
			margin:0;
			@include rtl-margin-right(10px);
			white-space: nowrap;
			position: relative;
			overflow: hidden;
			border: 1px solid transparent;
			@include transition(all .3s ease);
			@media(max-width:$screen-xs-max){
				padding:0 15px;
			}
			@media(max-width:$screen-xs){
				font-size:calc(var(--font-size-body,14px) - 2px);
			}
			&.disabled{
				opacity:0.5;
				cursor:not-allowed;
			}
		}
		.quantity-button{
			display:flex;
			align-items:center;
		}
		.variations_form{
			display:flex;
			align-items:center;
			.variations_button{
				display:flex;
				align-items:center;
			}
			.single_variation_wrap{
				display:flex;
				align-items:center;
				.woocommerce-variation{
					@include rtl-margin(0 ,40px ,0 ,0);
				}
			}
			table{
                border: none;
				position:relative;
				margin:0;
				width:100%;
				tbody{
					display:flex;
					align-items:center;
				}
                tr{
                    margin: 0;
					display:flex;
					flex-wrap:wrap;
                    td{
                        border: none;
                        padding: 0;
                        margin-bottom: 20px !important;
						margin:0 12px;
						@include rtl-text-align-left();
                        &.value .tawcvs-swatches{
                            padding:0;
							.swatch-item-wrapper{
								display:inline-block;
								@include rtl-margin-right(10px);
							}
							.swatch{
								@include square(auto);
								line-height:20px;
								padding:7px;
								min-width:36px;
								display:inline-block;
								@include border-radius(0);
								background:transparent;
								border:1px solid #cccccc;
								color:var(--gray-dark,$gray-dark);
								font-weight:500;
								@include rtl-margin-right(10px);
								margin-bottom:0;
								font-size:calc(var(--font-size-body,14px) - 2px);
								@include border-radius(50%);
								cursor:pointer;
								text-align: center;
								&:hover,&.selected{
									border:1px solid var(--gray-dark,$gray-dark);
								}
								&.disabled{
									cursor:not-allowed;
									position: relative;
									&:before{
										position: absolute;
										content: "";
										@include size(1px, 100%);
										background: var(--text-color,$text-color);
										top: 0;
										left: 50%;
										@include transform(rotate(45deg));
									}
								}
								@media (max-width:$screen-xs){
									padding: 6.5px 10px;
									min-width: 35px;
								}
							}
                        }
						&.label{
							display:none;
						}
						&.value{
							margin-bottom: 0 !important;
							display: block;
							width: 100%;
						}
                        label{
                            font-size:var(--font-size-body,$font-size-body);
							text-transform:uppercase;
							color:var(--gray-dark,$gray-dark);
                            margin-bottom: 0;
                            @include rtl-margin-right(15px);
							line-height:40px;
							letter-spacing:1.5px;
                        }
						.swatch__tooltip{
							display:none;
						}
                        > div[data-attribute_name="attribute_pa_color"]{
                            .swatch-color{
								@include rtl-margin-right(5px !important);
								font-size:0 !important;
								padding:0 !important;
								min-width:unset !important;
								@include square(22px !important);
								display: inline-block;
								vertical-align: middle;
								cursor:pointer;
								@include transition(all .3s ease-in-out);
								position: relative;
								border: 2px solid $white !important;
								@include box-shadow(0 0 1px 1px #ddd !important);
								&:last-child{
									@include rtl-margin-right(0 !important);
								}
								.swatch__tooltip{
									font-size:10px;
									display:inline-block;
								}
								&:hover, &.selected{
									@include box-shadow(none);
									border-color: var(--gray-dark,$gray-dark) !important;
								}
								@media (max-width:$screen-xs){
									@include rtl-margin-right(15px !important);
									.swatch__tooltip{
										display:none !important;
									}
								}
                            }
                        }
						> div[data-attribute_name="attribute_pa_size"]{
							.swatch-label{
								@include border-radius(0 !important);
							}
						}
                        select{
                            @include rtl-padding(0, 40px, 0, 10px);
							height:40px;
							line-height:40px;
							color:var(--text-color,$text-color);
                            background-position: calc(100% - 18px) calc(1em + 3px), calc(100% - 13px) calc(1em + 3px), calc(100% - 2.5em) 0.5em;
							cursor:pointer;
							border-color:#cccccc;
							margin-bottom:15px;
                            &:focus{
                              background-position:calc(100% - 14px) calc(1em + 3px), calc(100% - 20px) calc(1em + 3px), calc(100% - 2.5em) 0.5em;
                            }
                        }
                    }
               }
               .reset_variations{
                    display:none !important;
               }
			}
		}
		.group_table{
			display:flex;
			flex-wrap:wrap;
			>div{
				@include rtl-margin-right(20px);
				text-align:center;
				max-width:150px;
			}
			.quantity{
				margin:0 0 -15px;
			}
			.product-title{
				font-size:calc(var(--font-size-body,14px) - 2px);
				margin:0;
				font-weight:500;
			}
			.product-price{
				font-size:var(--font-size-body,$font-size-body);
				color:var(--gray-dark,$gray-dark);
			}
			.product-thumb{
				display:none;
			}
			.product-info{
				padding-bottom:5px;
			}
		}
		form.cart{
			display:flex;
			align-items:center;
		}
	}
}
.wishlist-items-wrapper{
	.product-price{
		font-size:calc(var(--font-size-body,14px) + 1px);
		ins{
			text-decoration:none;
		}
	}
}

.woocommerce-account{
	.container{
		overflow: hidden;
	}
	.woocommerce-form-row, .form-row{
		margin:0;
		margin-bottom:14px;
		label{
			display:block;
			width:100%;
		}
		.password-input, span{
			width:100%;
		}
	}
	.woocommerce-MyAccount-content{
		fieldset{
			margin-top:30px;
		}
	}
    #customer_login{
		@media (max-width:$screen-sm-max){
			>div{
				margin:25px 0;
			}
		}
		.box-form-login{
			border:2px solid #eaeaea;
			padding:50px 0 40px;
			height:100%;
		}
		.required{
			color:red;
		}
        h2{
			text-align:center;
			position:relative;
			margin:0 0 40px;
			font-size:calc(var(--font-size-body,14px) + 6px);
			padding-bottom:15px;
			&:before{
				font-size:calc(var(--font-size-heading,25px) - 4px);
				content: "\e909";
				font-family: icomoon;
				display:inline-block;
				@include rtl-margin-right(10px);
			}
			&:after{
				content:"";
				@include size(132px,2px);
				background:var(--gray-dark,$gray-dark);
				position:absolute;
				bottom:-2px;
				left:calc(50% - 66px);
			}
			&.register{
				&:before{
					font-family: 'feather';
					content:"\e915";
					position: relative;
					top: 2px;
				}
			}
		}
		.button-login,.button-register{
			text-align:center;
			input[type="submit"]{
				background:var(--gray-dark,$gray-dark);
				border:0;
				font-size:calc(var(--font-size-body,14px) + 1px);
				color:$white;
				text-transform:uppercase;
				cursor:pointer;
				font-weight:500;
				position: relative;
				@include transition(all .3s ease);
				&:hover{
					background: var(--theme-color,$theme-color);
				}
			}
		}
		.button-register{
			input[type="submit"]{
				background:#b9b9b9;
			}
		}
		.box-content{
			padding:0 90px 0;
			input{
				width:100%;
				height:55px;
				line-height:55px;
			}
			.username,.password,.email{
				input{
					padding:0 20px;
					margin-bottom:15px;
				}
			}
			@media (max-width:$screen-md-max){
				padding:0 50px;
			}
			@media (max-width:$screen-sm-max){
				padding:0 30px;
			}
			@media (max-width:$screen-xs){
				padding:0 15px;
			}
		}
		.user-role{
			margin:0 0 18px;
			justify-content:space-between;
			&:after,&:before{
				display:none;
			}
			input{
				@include square(16px);
				display:inline-block;
				position:relative;
				top:3px;
			}
			.radio{
				color:var(--gray-dark,$gray-dark);
				font-weight:500;
				cursor: pointer;
			}
		}
		.form-row{
			margin-left:0;
			margin-right:0;
		}
		.woocommerce-password-hint{
			margin-bottom:20px;
			display:block;
		}
		.rememberme-lost{
			display:flex;
			justify-content:space-between;
			flex-wrap:wrap;
			margin-bottom:20px;
			.rememberme{
				@include rtl-margin-right(10px);
				position:relative;
				input{
					position:absolute;
					opacity:0;
					height:22px;
					cursor:pointer;
					z-index: 3;
					&:checked + label:after{
						opacity:1;
					}
					&:checked + label:before{
						border-color:#0075ff;
					}
				}
			}
			.inline{
				color:var(--gray-dark,$gray-dark);
				font-weight:500;
				cursor: pointer;
				&:before{
					@include square(16px);
					content:"";
					display:inline-block;
					@include border-radius(50%);
					border:1px solid var(--gray-dark,$gray-dark);
					@include rtl-margin-right(5px);
					position:relative;
					top:3px;
					z-index:2;
				}
				&:after{
					@include square(16px);
					content:"";
					display:inline-block;
					@include border-radius(50%);
					position:absolute;
					top: 5px;
					border:2.5px solid $white;
					@include rtl-left(0);
					background:#0075ff;
					z-index:1;
					opacity:0;
				}
			}
			.lost_password{
				font-weight:500;
				a{
					display:inline-block;
					position: relative;
					&:after{
						content: "";
						position: absolute;
						display: block;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: var(--gray-dark,$gray-dark);
						@include transform(scaleX(1));
						transform-origin: 0 50%;
						transition: transform .48s cubic-bezier(.77,0,.175,1);
					}
					&:hover{
						&:after{
							transform-origin: 100% 50%;
							@include transform(scaleX(0));
							animation: animate-btn-underline .96s .36s forwards cubic-bezier(.77,0,.175,1);
						}
					}
				}
			}
		}
    }
	legend{
		color:var(--gray-dark,$gray-dark);
		font-weight:500;
		margin-top:30px;
		text-transform: uppercase;
	}
}

.woocommerce-cart{
    .woocommerce-cart-form{
        .actions{
            .coupon{
				flex: 0 0 50%;
				display:flex;
				input{
					line-height:40px;
					padding:0 10px;
					width: 100%;
					flex:1;
					@include rtl-margin-right(5px);
					@include border-radius(0px);
				}
            }
			.bottom-cart{
				display:flex;
				justify-content:space-between;
				align-items:center;
				flex-wrap:wrap;
				h2{
					margin:0;
					font-size:calc(var(--font-size-body,14px) + 1px);
					font-weight: 400;
					a{
						padding:10px 35px;
						background:var(--button-color,$gray-dark);
						color:$white;
						text-transform: uppercase;
						@include border-radius(0);
						line-height: calc(var(--font-size-body,14px) + 6px);
						cursor:pointer;
						@include transition(all .2s ease);
						position: relative;
						display: inline-block;
						text-align: center;
						width: 100%;
						font-weight: 500;
						&:hover{
							background: var(--theme-color,$theme-color);
						}
					}
				}
				@media (max-width:$screen-sm-max){
					h2{
						margin:8px 0 15px;
						width: 100%;
					}
					.coupon{
						flex:0 0 100%;
						margin-bottom:10px;
						flex-wrap:wrap;
					}
				}
			}
        } 
    }
    .cart-collaterals{
		background:#f6f6f6;
		padding-bottom:45px;
		max-width:370px;
		@include rtl-margin-left(auto);
        h2{
            font-size:calc(var(--font-size-body,14px) + 2px);
			color:var(--gray-dark,$gray-dark);
			padding:15px 30px 11px;
			background:#e9e9e9;
			margin:0;
			line-height:1.8;
			border-bottom: 0;
			text-transform: uppercase;
        }
		.shop_table_responsive{
			>div{
				display:flex;
				padding:15px 30px;
				align-items:center;
			}
			>div{
				>*{
					flex: 1;
				}
			}
		}
		.woocommerce-shipping-totals{
			h2{
				font-size:calc(var(--font-size-body,14px) + 1px);
				text-transform: uppercase;
				background:transparent;
				padding:0;
			}
			>*{
				flex:1;
			}
		}
		.cart-subtotal{
			>*{
				flex:1;
				.woocommerce-Price-amount{
					font-size:calc(var(--font-size-body,14px) + 6px);
					color:var(--gray-dark,$gray-dark);
				}
			}
		}
		.order-total{
			display:flex;
			padding:15px 30px;
			align-items:center;
			>div{
				flex:1;
			}
			.woocommerce-Price-amount{
				font-size:calc(var(--font-size-heading,25px) - 1px);
				color:var(--gray-dark,$gray-dark);
			}
			
		}
		.title{
			color:var(--gray-dark,$gray-dark);
			text-transform: uppercase;
			font-weight: 500;
		}
		.woocs_special_price_code{
			color:var(--gray-dark,$gray-dark);
			font-size:calc(var(--font-size-body,14px) + 6px);
		}
		a.shipping-calculator-button{
			color: var(--gray-dark,$gray-dark);
			font-weight:700;
			position:relative;
			display:inline-block;
			padding-bottom:2px;
			font-size:calc(var(--font-size-body,14px) - 1px);
			&:before{
				content:"";
				@include size(100%,2px);
				position:absolute;
				bottom:0;
				left:50%;
				@include transform(translateX(-50%));
				background:var(--gray-dark,$gray-dark);
				@include transition(all 0.3s ease);
			}
			&:hover{
				color:var(--theme-color,$theme-color);
				&:before{
					background:var(--theme-color,$theme-color);
					width:60%;
				}
			}
		}
		.shipping-calculator-form{
			margin-top:20px;
			.button{
				padding:0 20px;
				line-height:40px;
				font-size:var(--font-size-body,$font-size-body);
				text-transform:uppercase;
				font-weight:500;
			}
		}
		ul {
			padding:0;
			list-style:none;
			&.woocommerce-shipping-methods{
				margin-bottom:10px;
				@media (max-width:$screen-xs-max){
					display:block;
				}
			}
			li {
				@include rtl-margin-right(20px);
				input[type="radio"], input[type="checkbox"]{
					margin-top:0;
				}
				.shipping_method{
					display:none;
				}
				label{
					margin:0;
					@include rtl-margin-left(0);
					font-weight:400;
					margin-bottom:0;
					position:relative;
					@include rtl-padding-left(18px);
					cursor:pointer;
					&:before{
						position:absolute;
						@include rtl-left(0);
						@include border-radius(50%);
						top:calc(50% - 6px);
						@include square(12px);
						border:1px solid var(--text-color,$text-color);
						content:"";
					}
				}
				.shipping_method:checked +label{
					&:before{
						background:var(--gray-dark,$gray-dark);
						border-color:var(--gray-dark,$gray-dark);
					}
				}
			}
		}
		@media (max-width:1440px){
			max-width:100%;
		}
    }
}
.woocommerce-wishlist{
    .wishlist-title {
        h2{
            font-size:calc(var(--font-size-body,14px) + 5px);
            padding: 0;
            margin-bottom: 0;
        }
    }
}

/*! Wcvendors Vendors */
.vendor_dashboard.logged-in{
    .form-row{
        label{
            &.checkbox{
                display: inline-block;
            }
        }
        input[type="submit"]{
            background: var(--theme-color,$theme-color);
            color: $white;
            border: 1px solid var(--theme-color,$theme-color);
            &:hover{
                background: darken($theme-color, 10%);
				background: var(--theme-color);
            }
        }
    }
    center >p >a{
        padding: 0 10px;
        position: relative;
        &:before{
            content: "";
            @include size(1px, 13px);
            position: absolute;
            background: $light-gray;
            top: 5px;
            @include rtl-left(-3px);
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
    }
    input[type="submit"]{
        background: var(--theme-color,$theme-color);
        border: 0;
        color: $white;
        &:hover{
            background: darken($theme-color, 10%) !important;
			background: var(--theme-color) !important;
        }
    }
    table.table-vendor-sales-report{
        thead >tr >th{
            &:empty{
                display: none;
            }
        }
    }
}
.shop_settings.logged-in{
    input[type="submit"]{
        background: var(--theme-color,$theme-color);
        border: 0;
        color: $white;
        &:hover{
            background: darken($theme-color, 10%) !important;
			background: var(--theme-color) !important;
        }
    }
    #pv_shop_description_container, #pv_seller_info_container{
        .wp-editor-wrap{
            border: 0;
            margin-bottom: 20px;
        }
    }
}
ul.wcv_vendorslist{
    list-style-type: none;
    padding: 0;
    @media (min-width: $screen-md){
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        >li{
            width: calc(50% - 30px);
            @include rtl-float-left();
            margin-left: 15px;
            margin-right: 15px;
            &:nth-last-child(2){
                &:nth-child(2n+1){
                    border: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    >li{
        padding-bottom: 30px;
        border-bottom: 1px solid var(--border-color,$border-color);
        margin-bottom: 30px;
        @media (max-width: $screen-xs-max){
            padding-bottom: 25px;
        }
        &:last-child{
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .shop-name{
            font-size:calc(var(--font-size-body,14px) + 6px);
            padding-bottom: 10px;
            display: inline-block;
        }
        .vendor-list-infor{
            @media (max-width: $screen-xs-max){
                padding-top: 15px;
            }
            i{
                color: var(--theme-color,$theme-color);
                text-align: center;
                width: 15px;
                @include rtl-margin-right(10px);
            }
            >div{
                margin-bottom: 4px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.vender-main-header{
    margin-bottom: 45px;
    h2{
        font-size:var(--font-size-heading,$font-size-heading);
        margin-bottom: 20px;
    }
    .vendor-list-top{
        border: 1px solid var(--border-color,$border-color);
        padding: 20px;
        margin-bottom: 25px;
        .vendor-list-infor{
            i{
                color: var(--theme-color,$theme-color);
                text-align: center;
                width: 15px;
                @include rtl-margin-right(10px);
            }
            >div{
                margin-bottom: 4px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
}
.wcv-store-header,.pv_shop_description{
	@media (min-width: 1280px){
		max-width: 1280px;
		margin:auto;
		padding:0 15px;
	}
}
.pv_shop_description{
	margin-bottom:50px;
}
.wcv-store-header{
	&.header-modern{
		.cover{
			height:500px;
			background-size: contain;
			background-repeat: no-repeat;
			background-position:center;
		}
		.name{
			color:var(--gray-dark,$gray-dark);
			font-weight:700;
			text-shadow: unset;
		}
		.desc{
			color:var(--text-color,$text-color);
			text-shadow: unset;
		}
		.meta{
			margin-bottom:30px;
			margin-top:20px;
		}
	}
}

/*! Dokan Vendors */
.dokan-stores{
    .main-content{
        position: relative;
        .dokan-overlay{
            position: absolute;
            background: rgba(255,255,255,.3);
        }
    }
    .dokan-seller-search{
        border: 1px solid var(--border-color,$border-color) !important;
        @include border-radius(0 !important);
        margin-bottom:10px;
    }
    #dokan-seller-listing-wrap ul.dokan-seller-wrap li{
        margin-bottom: 30px;
        &.no-banner-img .store-content .store-data{
            p{
                color: var(--text-color,$text-color);
            }
            h2 a{
                color: var(--gray-dark,$gray-dark);
                &:hover{
                    color: var(--theme-color,$theme-color);
                }
            }
        }
        .store-footer{
            a{
                background: var(--theme-color,$theme-color);
                border: none;
                @include border-radius(0);
                &:hover{
                    background: darken($theme-color, 10%) !important;
					background: var(--theme-color) !important;
                }
            }
        }
    }
}
.dokan-store{
    .dokan-single-store{
        .profile-frame .profile-info-box{
			margin-bottom: 50px;
			&.profile-layout-default{
				.store-social{
					li{
						a{
							@include square(30px);
							@include border-radius(50%);
							line-height:28px;
							text-align:center;
							background:var(--text-color,$text-color);
							i{
								font-size:13px !important;
								color:$white !important;
								@include text-shadow(unset !important);
							}
							&:hover{
								background:var(--theme-color,$theme-color);
							}
						}
					}
				}
			}
			&.profile-layout-layout1{
				.profile-info-summery-wrapper{
					.profile-info-summery{
						.profile-info{
							ul.dokan-store-info li{
								width: 100%;
								&:before{
									display: none;
								}
								i{
									color:var(--theme-color,$theme-color);
									margin-top: 4px;
									font-size:calc(var(--font-size-body,14px) + 4px);
									width: 25px;
									text-align:center;
								}
								&.dokan-store-rating,&.dokan-store-open-close{
									i{
										font-size:calc(var(--font-size-body,14px) + 1px);
									}
								}
							}
						}
					}
				}
			}
			&.profile-layout-layout2{
				.profile-info-summery-wrapper{
					height:auto;
				}
				.profile-info{
					margin-bottom:-40px;
					h2{
						color:var(--gray-dark,$gray-dark) !important;
						font-size:25px !important;
					}
				}
			}
			&.profile-layout-layout3{
				.profile-info-summery-wrapper{
					.profile-info-summery{
						border-color: var(--border-color,$border-color);
						padding: 20px;
						.profile-info-head{
							@include rtl-margin-right(0);
							.profile-img{
								@media (min-width: $screen-sm){
									margin-bottom: 0;
								}
								img{
									border: 0;
									padding: 0;
								}
							}
						}
						.profile-info{
							@media (max-width: $screen-xs-max){
								padding: 0;
							}
							@media (min-width: $screen-sm){
								width: calc(100% - 150px);
							}
							h2.store-name{
								font-size:var(--font-size-heading,$font-size-heading);
								font-weight: 700;
								margin-top:0;
								line-height:1;
							}
							ul.dokan-store-info li{
								width: 100%;
								&:before{
									display: none;
								}
								i{
									color:var(--theme-color,$theme-color);
									margin-top: 4px;
									font-size:calc(var(--font-size-body,14px) + 4px);
									width: 25px;
								}
								&.dokan-store-rating,&.dokan-store-open-close{
									i{
										font-size:calc(var(--font-size-body,14px) + 1px);
									}
								}
							}
						}
						.profile-info .store-social-wrapper .store-social{
							display: block;
						}
					}
				}
			}
        }
        .dokan-store-tabs{
            ul.dokan-list-inline{
                border: 0;
                width: 100%;
                display: none;
                vertical-align: top;
                margin-bottom: 30px;
                li{
                    @include rtl-border-right(1px solid $white);
                    background: transparent;
                    box-shadow: none;
                    margin: 0;
                    @include rtl-padding-right(30px);
                    @include rtl-float-left();
                    &:last-child{
                        @include rtl-border-right(0);
						@include rtl-padding-right(0);
                    }
                    a {
                        padding:0;
                        color: var(--text-color,$text-color);
                        text-transform: uppercase;
                        font-size:var(--font-size-body,$font-size-body);
						font-weight:700;
						position:relative;
						padding:0 35px;
						line-height:40px;
						display:inline-block;
						background:#f4f4f4;
                    }
                    &.active, &:hover{
                        a {
                            color: $white;
							background:var(--theme-color,$theme-color);
                        }
                    }
                }
            }
        }        
    }
    .dokan-store-sidebar{
		i.fa{
			@include transition(all 0.3s ease);
		}
        .dokan-store-menu{
            ul li{
                a{
                    border: 0 !important;
                }
            }
            .caret-icon{
                @include rtl-float-right();
                i.fa{
					@include transition(all 0.3s ease);
                    &:before{
                        content: "\f105";
                    }
                    &.fa-rotate-90{
                        @include transform(rotate(90deg));
                    }
                }
            }
        }
        .dokan-store-contact{
            ul{
                padding: 0 !important;
                li{
                    margin-bottom: 20px;
                    padding: 0;
					>label{
						display:none;
					}
					&:before{
						display:none !important;
					}
                }
            }
            .dokan-btn-theme{
                background: var(--theme-color,$theme-color);
                border-color: var(--theme-color,$theme-color);
                @include border-radius(0);
                &:hover{
                    background: darken($theme-color, 10%);
					background: var(--theme-color);
                }
            }
        }
    }
    @media (max-width: $screen-sm-max){
        .dokan-single-store.dokan-w8, .dokan-store-sidebar.dokan-w3{
            width: 100%;
        }
        .dokan-store-sidebar.dokan-w3{
            margin-bottom: 50px;
        }
    }
}
.dokan-dashboard{
	.dokan-dashboard-content{
		article.dashboard-content-area .dashboard-widget{
			&.sells-graph .chart-placeholder.main{
				.legend{
					table, >div{
						width: calc(100% - 50px);
					}
				}
			}
			&.big-counter li{
				&:last-child{
					border: 0;
					>.count{
						border: 0;
					}
				}
			}
			&.products{
				display: block;
			}
		}
	}
	.dokan-dashboard-wrap{
		.dokan-dash-sidebar{
			background:var(--gray-dark,$gray-dark);
			ul.dokan-dashboard-menu{
				background:var(--gray-dark,$gray-dark);
				li{
					&.active,&:hover{
						background:var(--theme-color,$theme-color);
					}
					&.dokan-common-links a:hover{
						background:var(--theme-color,$theme-color);
					}
				}
			}
		}
	}
	.pagination-wrap ul.pagination > li{
		>span{
			&.current,&:hover{
				background:var(--theme-color,$theme-color);
				color:$white;
			}
		}
		a{
			&:hover{
				background:var(--theme-color,$theme-color);
				color:$white;
			}
		}
	}
}
input[type="submit"].dokan-btn-theme, a.dokan-btn-theme, .dokan-btn-theme{
	background:var(--theme-color,$theme-color) !important;
	border-color:var(--theme-color,$theme-color) !important;
	font-weight:500 !important;
	@include border-radius(0 !important);
	text-transform:uppercase;
	font-size:calc(var(--font-size-body,14px) - 2px);
	padding:0 20px;
	line-height:33px;
	font-weight:700;
	&:hover{
		background: darken($theme-color, 10%) !important;
		background: var(--theme-color) !important;
	}
}
#dokan-store-listing-filter-wrap{
	.dokan-btn-theme{
		background:var(--theme-color,$theme-color);
		@include border-radius(0 !important);
		text-transform:uppercase;
		font-size:calc(var(--font-size-body,14px) - 1px);
		letter-spacing:2px;
		font-weight:700;
		&:hover{
			background: darken($theme-color, 10%);
			background: var(--theme-color);
		}
	}
	.toggle-view{
		>span{
			&:hover,&.active{
				color:var(--theme-color,$theme-color) !important;
			}
		}
	}
}
#dokan-seller-listing-wrap{
	.store-footer{
		a{
			.dokan-btn-theme{
				background:transparent !important;
				font-size:var(--font-size-heading,$font-size-heading);
				font-family: ElegantIcons;
				position : relative;
				@include size(40px,40px);
				@include rtl-margin-right(0);
				line-height : 38px;
				border:1px solid $gray-light !important;
				color:var(--gray-dark,$gray-dark);
				&:before{
					content: "\35";
					position : absolute;
					@include size(40px,40px);
					@include rtl-left(0px);
					text-align : center;
				}
			}
			&:hover{
				.dokan-btn-theme{
					background:var(--theme-color,$theme-color) !important;
					border-color:var(--theme-color,$theme-color);
					color:$white;
				}
			}
		}
		.seller-avatar{
			img{
				margin:0;
			}
		}
	}
	.store-header{
		.store-banner{
			img{
				object-fit: cover;
			}
		}
	}
	ul.dokan-seller-wrap li{
		&.no-banner-img .store-content .store-data{
			h2 a{
				color:var(--gray-dark,$gray-dark) !important;
				font-size:calc(var(--font-size-body,14px) + 6px);
				&:hover{
					color:var(--theme-color,$theme-color) !important;
				}
			}
			p{
				color:var(--text-color,$text-color) !important;
			}
		}
		.store-content .store-data h2 a{
			font-size:20px !important;
		}
		.store-phone{
			i{
				@include rtl-margin-right(10px);
			}
		}
	}
	&.list-view{
		.store-content .store-data h2{
			margin-bottom:-3px !important;
		}
		.store-address{
			margin-bottom:5px !important;
			display:block !important;
			margin-top:0 !important;
			&:before{
				color:var(--theme-color,$theme-color) !important;
			}
		}
		.store-phone{
			margin-bottom:0 !important;
			display:block !important;
			i{
				color:var(--theme-color,$theme-color) !important;
			}
		}
		.store-footer{
			a{
				.dokan-btn-theme{
					@include border-radius(0 !important);
				}
			}
		}
	}
}

/*! Wc Marketplace */
#wcmp-store-conatiner{
	overflow: visible;
	background: transparent;
	padding: 0;
	margin: 0;
	border: 0;
	white-space: normal;
	color: var(--text-color,$text-color);
	.wcmp-store-map-pagination{
		border-bottom:0;
	}
	input[type="submit"]{
		background: var(--theme-color,$theme-color);
		color: $white;
		border: none;
		&:hover{
			background: darken($theme-color, 10%);
			background: var(--theme-color);
		}
	}
	.wcmp-store-locator-wrap{
		.wcmp-store-map-filter{
			background: #f5f5f5;
		}
		.wcmp-store-map-pagination{
			margin-top: 30px;
			border: 0;
			padding: 0;
		}
	}
	.wcmp-store-list-wrap{
		margin-top: 30px!important;
		margin-bottom:-40px!important;
		margin-left: -15px!important;
		margin-right: -15px!important;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: $screen-xs-max){
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.wcmp-store-list{
			margin-left: 15px !important;
			margin-right: 15px !important;
			width: calc(33.333333% - 30px);
			@media (min-width: $screen-sm) and (max-width: $screen-sm-max){
				width: calc(50% - 30px);
			}
			@media (max-width: $screen-xs-max){
				width: 100%;
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
			margin-bottom: 40px;
			@include box-shadow(none);
			border: 1px solid var(--border-color,$border-color);
			.wcmp-store-picture{
				&:after{
					display: none;
				}
			}
			.wcmp-store-detail-list li {
				padding : 0px;
				i{
					display : none;
				}
			}
		}
	}
	.wcmp-pagination li{
		min-width: 30px;
		line-height: 30px;
		background: transparent;
		margin: 0 10px;
		a, span{
			background: $white;
			display: table-cell;
			padding: 0;
			float: none;
			height: 30px;
			min-width: 30px;
			line-height: normal;
			text-align: center;
			padding: 0 7px;
			vertical-align: middle;
			color: var(--gray-dark,$gray-dark);
			font-size:var(--font-size-body,$font-size-body);
			border: 1px solid $light-gray;
			@include transition(none);
			&:focus{
			  background-color: var(--theme-color,$theme-color);
			  color: $white;
			  border-color: var(--theme-color,$theme-color);
			}
			&:hover, &.current{
			  background: var(--theme-color,$theme-color) !important;
			  color: $white;
			  border-color: var(--theme-color,$theme-color);
			}
		}
	}			
}
.wcmp_vendor_banner_template.template2{
    border-color: rgba($light-gray, 0.2) !important;
    margin-bottom: 60px;
    .vendor_address{
        margin-top: 25px;    
        label{
            color: $light-gray;
        }
        i{
            color: var(--theme-color,$theme-color);
        }
        a.wcmp_vendor_detail{
            color: $light-gray;
            &:hover{
                color: var(--theme-color,$theme-color);
            }
        }
    }
    .description_data{
        margin-top: 15px;
        p{
            color: var(--text-color,$text-color);
        }
    }
    .vendor_description{
        padding: 30px;
    }
}

@-webkit-keyframes rightSpring {
  0% {
    left: 0;
  }

  50%{
    left:-30px;
  }

  100% {
    left: 0;
  }
}

@keyframes rightSpring {
  0% {
    left: 0;
  }

  50%{
    left:-30px;
  }

  100% {
    left: 0;
  }
}
@-webkit-keyframes leftSpring {
  0% {
    left: 0;
  }

  50%{
    left:30px;
  }

  100% {
    left: 0;
  }
}
@keyframes leftSpring {
  0% {
    left: 0;
  }

  50%{
    left:30px;
  }

  100% {
    left: 0;
  }
}
.simplePopupBackground{
	display:none!important;
}
.woocommerce-account.my-account{
	.woocommerce-MyAccount-content{
		.woocommerce-Addresses{
			display:flex;
			flex-wrap:wrap;
			margin:0 -15px;
		}
		.woocommerce-Address{
			flex: 0 0 50%;
			max-width: 50%;
			address{
				padding:15px;
				border:1px solid #f5f5f5;
				margin-bottom:0;
			}
			@media (max-width:$screen-sm-max){
				flex: 0 0 100%;
				max-width: 100%;
				margin-bottom:30px;
			}
		}
		.woocommerce-Address-title{
			display:flex;
			justify-content:space-between;
			flex-wrap:wrap;
			align-items:center;
			margin:20px 0 0;
			padding:15px;
			background:#f5f5f5;
			h3{
				margin:0;
				font-size:calc(var(--font-size-body,14px) + 6px);
				font-weight:600;
			}
			a{
				color:var(--theme-color,$theme-color);
				&:hover{
					color:var(--gray-dark,$gray-dark);
				}
			}
		}
	}
}
.woocommerce-page-header{
	margin-bottom:80px;
	text-align: center;
	ul{
		list-style:none;
		padding:0;
		position:relative;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
		li{
			display:inline-block;
			@include rtl-margin-right(40px);
			font-size:calc(var(--font-size-body,14px) + 6px);
			&:last-child{
				@include rtl-margin-right(0);
			}
			a{
				display:inline-block;
				position:relative;
				color:var(--text-color,$text-color);
				padding-bottom: 3px;
				text-transform: uppercase;
				&:before{
					content:"";
					@include size(0,1px);
					background: var(--theme-color,$theme-color);
					@include transform(translateX(-50%));
					left:50%;
					@include transition(all 0.3s ease);
					position:absolute;
					bottom: 0;
				}
			}
			&.active{
				a{
					color: var(--theme-color,$theme-color);
					font-weight: 500;
					&:before{
						width:100%;
					}
				}
			}
			&:hover{
				a{
					color: var(--theme-color,$theme-color);
					&:before{
						width:100%;
					}
				}
			}
		}
		@media (max-width:$screen-sm-max){
			li{
				font-size:calc(var(--font-size-body,14px) + 6px);
				@include rtl-margin-right(20px);
			}
		}
		@media (max-width:$screen-xs){
			li{
				font-size:calc(var(--font-size-body,14px) + 2px);
				a{
					width:100%;
				}
			}
		}
	}
	@media(max-width: $screen-xs-max){
		margin-bottom:50px;
	}
}
.woocommerce-form-track-order{
	color:var(--gray-dark,$gray-dark);
	max-width:600px;
	margin:auto;
	font-size:var(--font-size-body,$font-size-body);
	>p:first-child{
		margin-bottom:60px;
		font-size:calc(var(--font-size-body,14px) + 2px);
	}
	.input-text{
		height:50px;
		line-height:50px;
		padding:0 20px;
		font-size:var(--font-size-body,$font-size-body);
	}
	.form-row{
		margin:20px 0;
	}
	label{
		font-weight:700;
	}
	.button{
		width:100%;
		font-size:16px !important;
		line-height:53px !important;
		font-weight: 500;
		position: relative;
		@include transition(all .2s ease);
		text-transform: uppercase;
		&:hover{
			background: var(--theme-color,$theme-color) !important;
		}
	}
}
.order-info{
	color:var(--text-color,$text-color);
	font-size:var(--font-size-body,$font-size-body);
}
.woocommerce-order-details{
	color:var(--text-color,$text-color);
	font-size:var(--font-size-body,$font-size-body);
	@media (max-width:730px){
		.shop_table.my_account_orders{
			display:block;
			overflow:auto;
			th,td{
				padding:15px;
				min-width:150px;
			}
		}
	}
	.dokan-info{
		background:var(--theme-color,$theme-color);
		color:$white;
		border-top-color:var(--gray-dark,$gray-dark);
		&:before{
			background:var(--gray-dark,$gray-dark);
		}
	}
}
.woocommerce-customer-details{
	color:var(--text-color,$text-color);
	font-size:var(--font-size-body,$font-size-body);
	.woocommerce-column.col-1{
		margin-top: 40px;
	}
	.woocommerce-columns{
		margin: 0 -15px;
		display:flex;
		flex-wrap:wrap;
		width:100%;
		.woocommerce-column{
			margin-top: 40px;
		   flex: 0 0 50%;
			max-width: 50%;
			padding: 0 15px;
		}
		@media (max-width:$screen-xs-max){
			margin: 0;
			.woocommerce-column{
				flex: 0 0 100%;
				max-width: 100%;
				padding:0;
			}
		}
	}
	address{
		padding: 25px 30px 30px 30px;
		border: 2px dashed var(--border-color,$border-color);
	}
	.woocommerce-column__title{
		font-size:calc(var(--font-size-body,14px) + 6px);
	}
}
.checkout-top{
	display:flex;
	margin:-40px -15px 70px;
	flex-wrap:wrap;
	>div{
		padding:0 15px;
		flex:1;
		@media (max-width:$screen-sm-max){
			flex:0 0 100%;
			margin:15px 0;
		}
	}
	.woocommerce-info{
		font-size:var(--font-size-body,$font-size-body);
		margin:0;
		color:var(--text-color,$text-color);
		text-align:center;
		background:#f2f2f2;
		padding:18px 15px 21px;
		border:0;
		a{
			font-weight:500;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				display: block;
				bottom: -3px;
				left: 0;
				@include size(0 ,1px);
				background: var(--gray-dark,$gray-dark);
				@include transition(width .48s cubic-bezier(.77,0,.175,1));
			}
			&:hover{
				&:after{
					width: 100%;
				}
			}
		}
		&:before{
			@include rtl-left(0);
			line-height:1;
			font-size:calc(var(--font-size-heading,25px) - 4px);
			color:var(--gray-dark,$gray-dark);
			position:relative;
			top:3px;
			@include rtl-margin-right(10px);
			content:"\e90a";
			font-family:wpbingofont;
			font-weight:400;
			margin-bottom: 0;
			margin-top: -13px;
		}
	}
	.woocommerce-form-coupon-toggle{
		.woocommerce-info{
			&:before{
				content:"\e909";
				font-size:calc(var(--font-size-heading,25px) + 5px);
				position: relative;
				top: 10px;
				margin-bottom:0;
			}
		}
	}
	.woocommerce-form{
		margin:0 -9px;
		flex-wrap:wrap;
		display:none;
		height:0;
		@include transition(all 0.3s ease);
		&.active{
			display:flex;
			height:100%;
		}
		.description{
			flex:0 0 100%;
			padding:0 9px;
			p{
				color:var(--gray-dark,$gray-dark);
				max-width:580px;
				margin:30px auto 35px;
				text-align:center;
			}
		}
		.username,.password{
			flex:1;
			padding:0 9px;
			@media (max-width:$screen-xs-max){
				flex:0 0 100%;
				margin-top:15px;
			}
		}
		.rememberme-lost,.button-login{
			flex:0 0 100%;
			padding:0 9px;
		}
		.input-text{
			width:100%;
			height:55px;
			line-height:53px;
			padding:0 20px;
		}
		.rememberme{
			color:var(--gray-dark,$gray-dark);
			@include rtl-text-align-right();
			margin-top:20px;
		}
		.button{
			width:100%;
			font-size:var(--font-size-body,$font-size-body);
			margin-top:5px;
			line-height:55px;
			text-transform:uppercase;
			font-weight:500;
		}
	}
	.checkout_coupon{
		display:none;
		.description{
			max-width:200px;
			color:var(--gray-dark,$gray-dark);
			margin:30px auto 35px;
			text-align:center;
		}
		.input-button{
			display:flex;
			flex-wrap:wrap;
			.input-text{
				flex:1;
				height:55px;
				line-height:53px;
				padding:0 20px;
			}
			.button{
				@include rtl-margin-left(5px);
				line-height:53px;
				padding:0 70px;
				text-transform:uppercase;
				font-weight:500;
				position: relative;
				@include transition(all .3s ease);
				&:hover{
					background: var(--theme-color,$theme-color);
				}
			}
			@media (max-width:$screen-xs-max){
				.input-text{
					flex:0 0 100%;
				}
				.button{
					width:100%;
					@include rtl-margin-left(0);
					margin-top:15px;
				}
			}
		}
	}
}
.checkout-page-style-1{
	.page-title{
		display:none;
	}
	.woocommerce-page-header{
		margin-top:50px;
	}
}
.checkout-page-style-2{
	.bwp-header{
		display:none;
	}
	.wpbingoLogo{
		margin-bottom:60px;
		img{ 
			max-width: 130px;
			width: 100%;
		}
	}
	.checkout_style2_content{
		position: absolute;
		width: 100%;
		z-index:10;
		top:130px;
		.content_style2{
			margin: auto;
			padding: 0 15px;
			max-width:1440px;
			>.content{
				max-width: 930px;
			}
		}
	}
	.woocommerce-form,.checkout_coupon{
		margin-top: 5px;
		background:#fff;
		padding:20px 15px;
		@include box-shadow( 0px 1px 12px 2px rgba(144, 144, 144, 0.3));
	}
	.page-title,.woocommerce-page-header,.bwp-footer{
		display:none;
	}
	.checkout-top{
		margin:0 0 40px;
		margin:0 -15px;
		.woocommerce-info{
			padding:0;
			background:transparent;
			@include rtl-text-align-left();
		}
		@media (max-width:$screen-sm-max){
			padding:0 !important;
		}
	}
	.woocommerce-checkout{
		.row{
			margin:0;
			>div{
				padding:0;
				padding-top:300px;
			}
		}
	}
	#bwp-main{
		.container{
			max-width:100%;
			padding:0;
			>.row{
				margin:0;
				>.col-lg-12{
					padding:0;
				}
			}
		}
	}
	.woocommerce-checkout{
		.col-xl-8{
			flex: 0 0 63.5%;
			max-width:63.5%;
			>div{
				max-width:990px;
				padding:0 30px;
				@include rtl-margin-left(auto);
				@media (max-width:1790px){
					max-width:970px;	
				}
				@media (max-width:1690px){
					max-width:960px;	
				}
				@media (max-width:$screen-md-max){
					padding:0 15px;
				}
			}
		}
		.col-xl-4{
			flex: 0 0 36.5%;
			max-width:36.5%;
			background:#f0f0f0;
			>div{
				max-width:450px;
				@include rtl-margin-right(auto);
			}
		}
		.checkout-review-order-table-wrapper{
			@include rtl-padding(0 ,0 ,0 ,90px);
			border:0;
			@media (max-width:$screen-md-max){
				padding:0 30px;
			}
			@media (max-width:$screen-sm-max){
				padding:0 15px 50px;
			}
		}
		.payment_methods{
			border:0;
			background:$white;
		}
		@media (max-width:$screen-sm-max){
			.col-xl-8{
				flex: 0 0 100%;
				max-width:100%;
				>div{
					max-width:990px;
					padding:0 15px;
				}
			}
			.col-xl-4{
				flex: 0 0 100%;
				max-width:100%;
				background:#f0f0f0;
				>div{
					max-width:100%;
					@include rtl-margin-right(auto);
				}
			}
		}
	}
	#customer_details{
		>div{
			padding:0;
			position:relative;
		}
		.back-to-cart{
			font-weight:700;
			position:absolute;
			top:2px;
			@include rtl-right(0);
			span{
				@include rtl-margin-left(5px);
				font-size:calc(var(--font-size-body,14px) + 6px);
				position:relative;
				top:4px;
			}
		}
	}
	@media (max-width:$screen-sm-max){
		.checkout_style2_content{
			position:unset;
			margin-top:50px;
		}
		.woocommerce-checkout .row > div{
			padding-top:50px;
		}
		.checkout_coupon,.woocommerce-form{
			@include box-shadow(unset);
			padding:0;
		}
	}
}
body.single-product{
	&.single-background{
		.main-single-product{
			margin:0;
		}
	}
	&.sticky{
		padding:0;
	}
}
body.single-product{
	&.clean{
		@media (min-width:$screen-md-max){
			.main-single-product{
				margin:0;
				.woocommerce-notices-wrapper{
					position:absolute;
					top:30px;
					max-width:100%;
					z-index:10;
					>div{
						max-width:1410px;
						margin:auto;
					}
				}
			}
		}
	}
	&.moderm{
		.main-single-product{
			margin:0;
			@media (min-width:$screen-sm-max){
				.woocommerce-notices-wrapper{
					position:absolute;
					top:100px;
					max-width:100%;
					z-index:10;
					>div{
						max-width:1410px;
						margin:auto;
					}
				}
			}
		}
	}
	&.full_width{
		.main-single-product{
			margin:0;
			@media (min-width:$screen-sm-max){
				.woocommerce-notices-wrapper{
					position:absolute;
					top:100px;
					max-width:100%;
					z-index:10;
					>div{
						max-width:1410px;
						margin:auto;
					}
				}
			}
		}
	}
	&.single-background{
		.main-single-product{
			margin:0;
		}
	}
	&.sticky{
		padding:0;
	}
}
body{
	&.background-breadcrumb-shop:not(.page-template-homepage,.error404,.order-tracking){
		.bwp-header{
			.header-desktop{
				position:absolute;
				width:100%;
				border-color:var(--gray-dark-10,rgba($gray-dark, 0.1));
			}
		}
		@media (min-width:$screen-sm-max){
			.bwp-main .page-title{
				padding-top:190px;
			}
		}
	}
}
.single-product-background {
	padding: 75px 0 60px;
	background:#f6f6f6;
	margin-bottom: 50px;
	.bwp-single-product{
		margin-bottom:0;
	}
}
body{
	&.woocommerce-cart,&.order-tracking{
		.bwp-header{
			margin-bottom:70px;
			@media(max-width: $screen-xs-max){
				margin-bottom:50px;
			}
		}
		.page-title{
			display:none;
		}
	}
}
.shop-layout-full{
	>.container{
		@media (min-width: 1440px){
			max-width: 100%;
		}
		@media (min-width: 1770px){
			max-width: 1770px;
		}
	}
}
.buy-together-products {
	padding:0 15px;
	.item-products-wrap:not(.wpb-check){
		.item-product{
			width:20%;
			flex:0 0 20%;
			padding:0 15px;
			position:relative;
			text-align: center;
			.thumbnail-wrap{
				padding: 0;
				border: none;
			}
			.item-product-title{
				min-height:auto;
				margin:20px 0 5px;
				line-height:18px;
				font-weight:400;
				text-transform: uppercase;
				color: var(--gray-dark,$gray-dark);
				@include transition(all .3s ease);
				&:hover{
					color: var(--theme-color,$theme-color);
				}
			}
			.item-product-info{
				.buy-together-price{
					font-size:calc(var(--font-size-body,14px) + 4px);
					color:$product-price-color;
					font-weight:500;
					del{
						color:var(--text-color,$text-color);
					}
					ins{
						text-decoration:none;
						color: var(--theme-color,$theme-color);
					}
				}
			}
			&.buy-together-hidden{
				display:block;
				opacity:0.3;
			}
			@media (max-width:$screen-md-max){
				width:33.33%;
				flex:0 0 33.33%;
			}
			@media (max-width:$screen-xs-max){
				width:50%;
				flex:0 0 50%;
			}
		}
	}
	.item-products-wrap.wpb-check{
		&:not(.buy-together-main-item){
			cursor:pointer;
		}
		.buy-together-price{
			font-size:var(--font-size-body,$font-size-body);
			color:var(--theme-color,$theme-color);
			font-weight:500;
			del{
				color:var(--text-color,$text-color);
				font-size:calc(var(--font-size-body,14px) - 2px);
			}
			ins{
				text-decoration:none;
				color:red;
			}
		}
		.buy-together-hidden{
			display:block;
			opacity:0.7;
		}
		.item-product{
			margin-bottom:0;
		}
	}
	.buy-together-right-part{
		padding:0 15px;
		width:20%;
		flex:0 0 20%;
		margin-bottom:50px;
		.total-price-html{
			color:var(--theme-color,$theme-color);
			font-size:calc(var(--font-size-heading,25px) + 5px);
			font-weight: 500;
			@include rtl-margin-right(10px);
		}
		.total-price-wrap{
			margin-bottom: 5px;
			.for-items-text{
				font-size: 13px;
			}
		}
		.buy-together-add-all-to-cart-btn-wrap{
			.buy-together-add-all-to-cart{
				font-size:var(--font-size-body,$font-size-body);
				padding:10px 30px;
				line-height:calc(var(--font-size-body,14px) + 6px);
				@include border-radius(0);
				@include transition(all .3s ease);
				font-weight: 500;
				text-transform: uppercase;
				&:before{
					display:none;
				}
				&:hover{
					background: var(--theme-color,$theme-color);
				}
			}
			.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle{
				background:var(--gray-dark,$gray-dark);
				border-color:var(--gray-dark,$gray-dark);
			}
		}
		@media (max-width:$screen-md-max){
			width:33.33%;
			flex:0 0 33.33%;
		}
		@media (max-width:$screen-xs-max){
			width:100%;
			flex:0 0 100%;
		}
	}
}
/*------------- photoswipe -------------------*/
.pswp{
	position:fixed;
	width:100%;
	top:0;
	left:0;
	z-index:10000;
	display:none;
	&.pswp--open{
		display: block;
		.pswp__top-bar{
			position:relative;
			z-index:9;
			.pswp__counter{
				color:$white;
				font-size:calc(var(--font-size-body,14px) + 4px);
				font-weight:500;
				@include rtl-float-left();
				height: 44px;
				line-height:44px;
				@include rtl-padding-left(15px);
			}
			.pswp__button{
				@include rtl-float-right();
				background-size: 264px 88px;
				width: 44px;
				height: 44px;
				border:0;
				padding:0;
				cursor:pointer;
				&.pswp__button--close{
					background-position: 0 -44px;
				}
				&.pswp__button--share{
					background-position: -44px -44px;
					display:none;
				}
				&.pswp__button--zoom{
					background-position: -88px 0;
				}
			}
		}
		.pswp__button--arrow--left{
			position:absolute;
			top:50%;
			font-family: wpbingofont;
			border:0;
			padding:0;
			font-weight:500;
			font-size:calc(var(--font-size-body,14px) + 6px);
			color:$white;
			cursor:pointer;
			left:50px;
			&:before{
				content: "\e91a";
			}
		}
		.pswp__button--arrow--right{
			position:absolute;
			top:50%;
			font-family: wpbingofont;
			border:0;
			padding:0;
			font-weight:500;
			font-size:calc(var(--font-size-body,14px) + 6px);
			color:$white;
			right:50px;
			cursor:pointer;
			&:before{
				content: "\e91b";
			}
		}
		@media (max-width:$screen-xs-max){
			.pswp__button--arrow--left{
				color:var(--theme-color,$theme-color);
				left:15px;
			}
			.pswp__button--arrow--right{
				color:var(--theme-color,$theme-color);
				right:15px;
			}
		}
	}
}
.content-cart-popup{
	position:fixed;
	background:rgba(#000, 0.5);
	width:100%;
	height:100%;
	top:0;
	left:0;
	z-index:9999;
	display:none;
	align-items:center;
	justify-content:center;
	overflow:auto;
	@media (max-width:$screen-sm-max){
		display:none;
	}
	&.active{
		display:flex;
	}
	.woocommerce-cart-page-popup{
		width:900px;
		background:$white;
		&.loadings{
			position:relative;
			&:before{
				display:none;
			}
			&:after{
				background:rgba($white,0.75);
				content:"";
				position:absolute;
				width:100%;
				height:100%;
				left:0;
				top:0;
				z-index:12;
			}
		}
		.close-full{
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 1;
		}
	}
	.woocommerce-cart-page{
		padding:50px 30px;
		position:relative;
		z-index:10;
		>h2{
			margin:0 0 25px;
			text-align:center;
			font-size:calc(var(--font-size-heading,25px) + 3px);
		}
		@media(max-width: $screen-xs-max){
			padding:50px 15px;
			>h2{
				font-size:var(--font-size-heading,$font-size-heading);
			}
		}
	}
	.shop_table{
		max-height:302px;
		overflow:auto;
		overflow-x:hidden;
		&::-webkit-scrollbar-track
		{
			background-color: #e5e5e5;
		}

		&::-webkit-scrollbar
		{
			width: 2px;
			background-color: #e5e5e5;
		}

		&::-webkit-scrollbar-thumb
		{
			background-color: var(--gray-dark-10,rgba($gray-dark, 0.1));
		}
	}
	.woocommerce-cart-form{
		margin:0;
		border:1px solid var(--border-color,$border-color);
		border-bottom:0;
	}
	.cart_item{
		border-bottom:1px solid var(--border-color,$border-color);
		display:flex;
		justify-content:space-between;
		padding:20px;
		@media(max-width: $screen-xs-max){
			padding: 15px;
		}
	}
	.content-cart-left{
		display:flex;
		.product-thumbnail{
			max-width:80px;
			flex:0 0 80px;
			@include rtl-margin-right(15px);
		}
		.product-info{
			padding:0;
		}
		.product-name a{
			font-size:calc(var(--font-size-body,14px) + 1px);
			color: var(--gray-dark,$gray-dark);
			text-transform: uppercase;
			font-weight: 400;
			&:hover{
				color:var(--theme-color,$theme-color);
			}
		}
		.product-price{
			font-size:calc(var(--font-size-body,14px) + 2px);
			color:var(--text-color,$text-color);
		}
	}
	.content-cart-right{
		position:relative;
		@include rtl-padding-right(35px);
		.product-remove{
			position:absolute;
			top:2px;
			@include rtl-right(0);
			a{
				display:inline-block;
				text-align:center;
				color:var(--text-color,$text-color);
				font-size:0;
				&:before{
					content: "\ea02";
					font-family:feather;
					display: inline-block;
					font-size:calc(var(--font-size-body,14px) + 2px);
				}
				&:hover{
					color:red;
				}
			}
		}
		.product-subtotal{
			font-size:calc(var(--font-size-body,14px) + 2px);
			color:var(--gray-dark,$gray-dark);
			margin-top:5px;
		}
		.quantity{
			border:1px solid var(--gray-dark,$gray-dark);
			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			.plus{
				float: right;
			}
			button{
				border:0;
				height:30px;
				width:30px;
				color:var(--gray-dark,$gray-dark);
			}
			input{
				border:0;
				height:30px;
				color:var(--gray-dark,$gray-dark);
				float: right;
				text-align: center;
			}
			@media(max-width: $screen-xs){
				width: 50px;
				button, input{
					width: 100%;
				}
			}
		}
	}
	.cart-subtotal{
		color:var(--gray-dark,$gray-dark);
		font-weight:700;
		display:flex;
		font-size:calc(var(--font-size-body,14px) + 6px);
		text-transform: uppercase;
		padding: 20px 0 5px;
		.title{
			@include rtl-margin-right(20px);
		}
	}
	.bottom-cart{
		display:flex;
		justify-content:space-between;
		margin-top:25px;
		.close-cart-popup{
			display:inline-block;
			line-height: calc(var(--font-size-body,14px) + 4px);
			border:1px solid var(--gray-dark,$gray-dark);
			padding:10px 30px;
			cursor:pointer;
			color:var(--gray-dark,$gray-dark);
			font-weight: 500;
			position: relative;
			text-transform: uppercase;
			&:after{
				content: "";
				position: absolute;
				z-index: -1;
				@include transition(all .3s ease);
				width: 0;
				height: 103%;
				top: 50%;
				left: 50%;
				background: var(--theme-color,$theme-color);
				@include opacity-h();
				@include transform(translate(-50%, -50%));
			}
			&:hover{
				color: $white;
				z-index: 2;
				border-color: var(--theme-color,$theme-color);
				&:after{
					width: 100%;
					@include opacity-s();
				}
			}
		}
		.wc-proceed-to-checkout a{
			display:inline-block;
			line-height: calc(var(--font-size-body,14px) + 4px);
			border:0px solid var(--gray-dark,$gray-dark);
			padding:11px 30px;
			cursor:pointer;
			background:var(--button-color,$gray-dark);
			color:$white;
			font-weight: 500;
			@include transition(all .3s ease);
			position: relative;
			text-transform: uppercase;
			&:hover{
				background: var(--theme-color,$theme-color);
			}
		}
		@media(max-width: $screen-xs){
			.close-cart-popup, .wc-proceed-to-checkout a{
				padding: 0 10px;
			}
		}
	}
	.free-ship{
		font-size:var(--font-size-body,$font-size-body);
		text-align:center;
		font-weight:500;
		span{
			color:var(--gray-dark,$gray-dark);
			font-weight:700;
		}
		.percent-2{
			color:var(--gray-dark,$gray-dark);
			position: absolute;
			@include rtl-right(0);
			top: -10px;
			font-size:calc(var(--font-size-body,14px) + 3px);
		}
		.total-percent{
			position:relative;
			width:100%;
			background:#e9e9e9;
			height:2px;
			margin:10px 0 5px;
			.percent{
				position:absolute;
				height:100%;
				left:0;
				background: var(--theme-color,$theme-color);
				top:0;
				@include transition(all 0.5s ease);
				color:var(--gray-dark,$gray-dark);
				font-size:calc(var(--font-size-body,14px) + 1px);
				font-weight:700;
				line-height:1px;
			}
		}
	}
}
body.single-product{
	&:not(.elementor-default){
		.contents-detail .bwp-single-product.zoom .image-additional{
			width:100%;
			.woocommerce-product-gallery__image img{
				width:100%;
			}
		}
		.zoomWindowContainer >div{
			background-size:cover;
		}
	}
	&.lagre_gallery, &.full_width{
		.breadcrumb-noheading{
			display:none;
		}
	}
}